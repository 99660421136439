import { ActionIcon, Button, Drawer, Flex, Grid, Group, Modal, Text, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import PLComboBox from './PLComboBox';
import PLTextBox from './PLTextBox';
import PLNumberBox from './PLNumberBox';
import PLDataGrid from './PLDataGrid';
import SITableUserField from '../Transaction/SaleEntry/SITableUserField';
import GlobalClass from '../utils/GlobalClass';

const PLBillToBill = (props) => {

    const { T05Data, T05Format, setT05Data, T41ObjSend, T05UserField,OS11Obj } = props;

    console.log("BillToBillTable props", props);
    // const { T05Format, T05Data } = props;
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: ""
    }

    const [DrawerObj, setDrawerObj] = useState(dra);
    const [columns, setColumns] = useState([])
    const [T05, setT05] = useState([])
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [T05RowObj, setT05RowObj] = useState({
        "FIELD01": null,
        "FIELD02": 0.0,
        "FIELD03": null,
        "FIELD04": null,
        "FIELD05": "",
        "FIELD10": 0,
        "FIELD11": null,
        "FIELD12": 0,
        "FIELD15": null,
        "FIELD16": null,
        "FIELD21": null,
        "FIELD22": null,
        "FIELD23": null,
        "FIELD31": null,
        "FIELD32": null,
        "FIELD33": null,
        "FIELD35": null,
        "FIELD40": null,
        "USALM": null,
        "USALE": null,
        "FLDUNQ": null,
        "FLDAED": null,
        "FLDBRC": null,
        "EGKEYID": 1,
        "T05PAR": null,
        "T05CRDB": null,
        "PRTCOD": null,
        "USERFLD": null,
        "PRTICLR": null,
        "LSELECT": null,
        "LAPPEN": 1,
        "SAMOUNT": 0,
        "CURRNM": null
    })

    const columnHelper = createColumnHelper();

    useEffect(() => {
        if (T05Data) {
            setT05(T05Data)
        }
    }, [T05Data])

    useEffect(() => {
        if (T05Format) {
            let col = [];
            T05Format?.map((d, i) => {
                col.push(
                    columnHelper.accessor(d?.S53F04, {
                        header: d?.S53F02 == "T05B007" ? "" : d?.S53F03,
                        size: d?.S53F02 == "T05B007" ? 0 : 150,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(d, val, i)
                        },
                        Cell: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderRowValue(d, val)
                        }
                    })
                )
                console.log("Row=>", d.S53F03, ":", d.S53F04);
            })
            setColumns(col)
        }

    }, [T05Format, T05RowObj])

    // const UserField = () => {
    //     console.log("UserField",props.userField);
    // }

    const seperate = (e) => {
        var paraArr = e.split('~C~').join('~R~').split('~R~')
        // console.log('paraArr==>', paraArr);
        return paraArr
    }

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus()
        }
    }

    const renderInputBox = (d, val, index) => {
        let setInput;
        switch (d?.S53F14) {
            case "PLPOPUP":
                setInput = <PLDataGrid
                    id={`T05Ent_${index}`}
                    p1={seperate(d.S53F15)[1]}
                    value={T05RowObj?.[d?.S53F04]}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, [d?.S53F04]: e?.FIELD01 })
                    }}
                />
                break;
            case "COMBOBOX":
                if (d?.S53F02 == "T05B002") { //Reference Type
                    setInput = <PLComboBox
                        id={`T05Ent_${index}`}
                        copno={seperate(d.S53F15)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        value={T05RowObj?.[d?.S53F04]}
                        setEdit={(e) => {

                            // if (((nBillAmt * (OS11["S11F21"] == "C" ? 1 : -1)) - nAdjustedAmt) > 0){
                            //     "Credit"
                            // }else{
                            //     "Debit"
                            // }

                                if (T41ObjSend?.AccountCode) {
                                    setT05RowObj({
                                        ...T05RowObj,
                                        [d?.S53F04]: e?.value,
                                        T05PAR: props?.isM01Form == true ? T41ObjSend?.FIELD01 : T41ObjSend?.FIELD12,
                                        FIELD10: props?.isM01Form == true ? T41ObjSend?.FIELD90 : T41ObjSend?.FIELD07,
                                        FIELD05: T41ObjSend?.AccountCode
                                    })
                                } else {
                                    let nBillAmt = props?.isM01Form == true ? T41ObjSend?.FIELD90 : T41ObjSend?.FIELD07;
                                    setT05RowObj({
                                        ...T05RowObj,
                                        [d?.S53F04]: e?.value,
                                        T05PAR: props?.isM01Form == true ? T41ObjSend?.FIELD01 : T41ObjSend?.FIELD12,
                                        FIELD10: props?.isM01Form == true ? T41ObjSend?.FIELD90 : T41ObjSend?.FIELD06,
                                        T05CRDB:((nBillAmt * (OS11Obj["S11F21"] == "C" ? 1 : -1))-nBillAmt) > 0 ? "C":"D"
                                    })
                                }
                        }}
                    />
                } else {
                    setInput = <PLComboBox
                        id={`T05Ent_${index}`}
                        copno={seperate(d.S53F15)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        value={T05RowObj?.[d?.S53F04]}
                        setEdit={(e) => {
                            setT05RowObj({ ...T05RowObj, [d?.S53F04]: e?.value })
                        }}
                    />
                }
                break;
            case "TEXTBOX":
                if (d?.S53F02 == "T05B007") { //UserField
                    setInput = <Button
                        // id={`T05Ent_${index}`}
                        style={{ height: 30, marginTop: 5, width: "0px", padding: "0px" }}
                        onFocus={() => {
                            document.activeElement.blur();
                            if (T05UserField?.length > 0) {
                                T05UserFieldFun()
                            } else {
                                // NextIndexFun(index)
                                document.getElementById("Adj_Amount").focus()
                            }
                        }}
                        variant="default"
                        onClick={() => T05UserField?.length > 0 ? T05UserFieldFun() : document.getElementById("Adj_Amount").focus()}
                    >
                    </Button>

                } else {
                    setInput = <PLTextBox
                        id={`T05Ent_${index}`}
                        value={T05RowObj?.[d?.S53F04]}
                        setEdit={(e) => {
                            setT05RowObj({ ...T05RowObj, [d?.S53F04]: e?.target.value })
                        }}
                    />
                }

                // }
                break;
            case "NUMBOX":
                setInput = <PLNumberBox
                    id={d?.S53F02 == "T05B008" ? "Adj_Amount" : `T05Ent_${index}`}
                    onKeyDown={(e) => {
                        if (e.key == "Tab" && d?.S53F02 == "T05B006") { //Credit Days
                            console.log("onKeyDown=>", e);
                            NextIndexFun(index - 1)
                        }
                    }}
                    value={T05RowObj?.[d?.S53F04]}
                    setEdit={(e) => {
                        // if(d?.S53F02 == "T05B006"){ //Credit Days
                        //     Promise.resolve(setT05RowObj({ ...T05RowObj, [d?.S53F04]: e })).then(()=>{})
                        // }else{
                        setT05RowObj({ ...T05RowObj, [d?.S53F04]: e })
                        // }
                    }}
                />
                break;
            default:
                setInput = <PLTextBox
                    value={T05RowObj?.[d?.S53F04]}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, [d?.S53F04]: e?.target.value })
                    }}
                />
                break;
        }
        return setInput
    }


    const T05UserFieldFun = (index) => {
        setDrawerObj({
            ...dra, title: <Text fw={700}>User Field</Text>, body: <>
                <SITableUserField
                    T02UF={T05UserField}
                    T02ObjSend={T05RowObj}
                    setT02Objsend={(e) => {
                        Promise.resolve(setT05RowObj(e)).then(() => document.getElementById("Adj_Amount").focus())

                        //   Promise.resolve(setT02Obj(e)).then(() => {
                        //     const nextInd = index + 1;
                        //     console.log("nextInd=>", nextInd)
                        //     const nextInput = document.getElementById(`T02Ent_${nextInd}`)
                        //     console.log("nextInput=>", nextInput)

                        //     if (nextInput) {
                        //       nextInput.focus()
                        //     }
                        //   })
                    }}
                    DrawerOBJ={DrawerObj}
                    setDrawerOBJ={(e) => setDrawerObj(e)}
                />
            </>, open: true, size: "sm", position: "right"
        })
    }

    const renderRowValue = (d, v) => {
        let value;
        switch (d?.S53F03) {
            case "Reference Type":
                value = v == "N" ? 'New Reference' :
                    v == 'P' ? 'Advance Payment' :
                        v == 'O' ? 'On Account' :
                            v == 'A' ? 'Against Ref.' :
                                v == 'M' ? 'New Against Adv. Pay' : v
                break;
            case "CR/DB":
                value = v == 'C' ? 'Credit' :
                    v == 'D' ? 'Debit' : v
                break;
            default:
                value = v
                break;
        }
        return value
    }

    // add new row
    const handleCreateUser = () => {

        console.log("handleCreateUser =>", T05RowObj);

        setT05([...T05, T05RowObj])

        table?.setCreatingRow(null)

        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })
    }

    // update row
    const handleSaveUser = () => {
        console.log("handleSaveUser =>", [{
            T05RowObj: T05RowObj,
            tableEditingRow: tableEditingRow
        }]);

        let newArray = [...T05]

        newArray[tableEditingRow?.index] = T05RowObj
        setT05(newArray)
        table.setEditingRow(null)
        setTableEditingRow(null)

        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })
    }

    const openDeleteConfirmModal = (row) => {
        console.log("openDeleteConfirmModal", row);

        let newArray = T05.filter((d, i) => i != row.index)

        setT05(newArray)

    }

    const handleNullT41Obj = () => {
        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })
    }

    console.log("T05RowObj==>", T05RowObj);
    console.log("columns=>", columns);

    const table = useMantineReactTable({
        data: T05,
        columns,
        enableTopToolbar: true,
        enableFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableSorting: false,
        enableColumnActions: false,
        mantineTableContainerProps: { sx: { maxHeight: "200px", minHeight: "200px" } },
        initialState: {
            density: "0px",
            columnVisibility: {
                EGKEYID: false,
                LSELECT: false, FIELD21: false, FIELD22: false, FIELD35: false, T05MCF01: false, T05MCF02: false, T05MCF04: false
            }

        },
        defaultColumn: { minSize: 60, maxSize: 120, size: 150 },
        enableEditing: true,
        editDisplayMode: 'row',
        createDisplayMode: 'row',
        onCreatingRowSave: () => {
            if (T05RowObj?.T05CRDB) {
                handleCreateUser()
            } else {
                GlobalClass.Notify("warning", "Empty Field", "Credit/Debit Require")
            }
        },
        onEditingRowSave: handleSaveUser,
        onCreatingRowCancel: handleNullT41Obj,
        onEditingRowCancel: handleNullT41Obj,
        renderRowActions: ({ row, table }) => (
            <Flex gap="xs">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row)
                        console.log('table.setEditingRow=>', row);
                        setT05RowObj(row.original);
                        setTableEditingRow(row)
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                    <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
                        <IconTrash size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
                size='xs'
            >
                Add New
            </Button>
        ),
    })

    useEffect(() => {
        console.log("T05", T05);
        if (T05) {
            setT05Data(T05)
        }
    }, [T05])

    console.log("T05Data", T05Data);

    let rowSum = T05.reduce((a, b) => a = a + b?.FIELD10, 0)

    // const BillToBillSubmit=()=>{
    //     Promise.resolve(setT05Data(T05)).then(() =>props.context.closeModal(props.id))
    // }

    return (<>
        <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false}//
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
                onClick: (e) => {
                    setDrawerObj(dra);
                },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? 'bottom'}
            onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
        // scrollAreaComponent={ScrollArea.Autosize}
        >
            {DrawerObj.body}
        </Modal>
        <Grid gutter={4}>
            <Grid.Col span={12}>
                <MantineReactTable table={table} />
            </Grid.Col>
            {/* <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button size='xs' variant='filled' onClick={() => BillToBillSubmit()}>
                        Ok
                    </Button>
                </Group>
            </Grid.Col> */}
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={4}>
                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>Total Amount : {rowSum}</Text>
                    {
                        rowSum - (props?.isM01Form == true ? T41ObjSend?.FIELD90 : T41ObjSend?.FIELD07 == 0) ? null :
                            <Text style={{ fontSize: 15, fontWeight: "bold" }}>Pending:{rowSum - (props?.isM01Form == true ? T41ObjSend?.FIELD90 : T41ObjSend?.FIELD07 == 0)}</Text>
                    }
                </Grid.Col>
                <Grid.Col span={8}>
                    <Group spacing={'xs'} style={{ display: "flex", flexDirection: "row" }}>
                        <Button size='xs' variant='filled' >
                            Edit
                        </Button>
                        <Button size='xs' variant='filled'>
                            Bill No. Search
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid.Col>
        </Grid>

    </>)
}

export default PLBillToBill