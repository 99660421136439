import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconCurrencyRupee, IconEdit } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { modals } from "@mantine/modals";
import Store from "../../../utils/store";
import GlobalClass from "../../../utils/GlobalClass";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLNarration from "../../../PlusComponent/PLNarration";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import SITableUserField from "../../SaleEntry/SITableUserField";
import {
  BatchLogAddRemoveFun,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import {
  GetAccountList,
  GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import T02Table from "../../T02/T02Table";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PendingIssue from "../../JobWorkOut/RIOJobOutForm/PendingIssue";
import {
  GetJWListData,
  RefreshIOrderPopUp,
  RefreshIssuePopUp,
} from "../../../utils/TransactionSlices/PendingIssueSlice";

export default function RIPForm(props) {
  // console.log(props, "JobWorkOutForm--");
  const [group, setGroup] = useState({
    FIELD01: "JOPV73BZX483",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JO",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    UUSALESB: "",
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD39NM: "",
    FIELD34NM: "",
    CFRMTITLE: "Add Jobwork Outward (Issue)",
    BTOBEFF: "C",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [t02Data, setT02Data] = useState([]);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [t02Obj, setT02Obj] = useState( {
    "FIELD01": "",
    "FIELD02": "",
    "FIELD03": "",
    "FIELD04": "",
    "FIELD05": "",
    "FIELD06": 0.0,
    "FIELD07": 0.0,
    "FIELD08": 0.0,
    "FIELD09": 0.0,
    "FIELD10": 0.0,
    "FIELD11": "",
    "FIELD12": "",
    "FIELD56": "",
    "FIELD13": "",
    "FIELD14": "",
    "FIELD15": "",
    "FIELD16": "",
    "FIELD17": "",
    "FIELD18": "",
    "FIELD19": "",
    "FIELD20": "",
    "FIELD21": 0.0,
    "FIELD22": 0.0,
    "FIELD23": 0.0,
    "FIELD24": "",
    "FIELD25": 0.0,
    "FIELD26": "",
    "FIELD27": "",
    "FIELD28": false,
    "FIELD29": 0.0,
    "FIELD30": "",
    "FIELD31": 0.0,
    "FIELD32": 0.0,
    "FIELD33": 0.0,
    "FIELD41": "",
    "FIELD50": 0.0,
    "FIELD51": "",
    "FIELD52": 0.0,
    "FIELD71": "",
    "FIELD81": 0.0,
    "FIELD82": "",
    "FIELD83": "",
    "FIELD84": "",
    "FIELD85": "",
    "FIELD86": "",
    "FIELD61": 0.0,
    "FIELD62": 0.0,
    "FIELD63": "",
    "FIELD64": 0.0,
    "FIELD65": "",
    "FIELD66": 0.0,
    "FIELD67": 0.0,
    "FIELD72": "",
    "FIELD68": "",
    "FIELD69": "",
    "FIELD53": "",
    "FIELD54": 0.0,
    "FIELD55": 0.0,
    "FIELD091": 1.0,
    "FIELD58": "",
    "FIELD57": "",
    "FIELD60": 0.0,
    "T41PVT": "",
    "FIELD87": 0.0,
    "FIELD88": "",
    "FLDUNQ": 0,
    "FLDAED": "",
    "FLDBRC": "",
    "FIELD90": 0.0,
    "IA00000001": "",
    "IA00000002": "",
    "IA00000003": "",
    "IA00000004": "",
    "IA00000005": "",
    "IA00000006": "",
    "IA00000007": "",
    "IA00000008": "",
    "IA00000009": "",
    "IA00000010": "",
    "IA00000011": "",
    "IA00000012": "",
    "IA00000013": "",
    "IA00000014": "",
    "IA00000015": "",
    "IA00000016": "",
    "IA00000017": "",
    "IA00000018": "",
    "IA00000019": "",
    "IA00000020": "",
    "IA00000021": "",
    "IA00000022": "",
    "IA00000023": "",
    "IA00000024": "",
    "IA00000025": "",
    "ID00000001": 0.0,
    "ID00000002": 0.0,
    "ID00000003": 0.0,
    "ID00000004": 0.0,
    "ID00000005": 0.0,
    "ID00000006": 0.0,
    "ID00000007": 0.0,
    "ID00000008": 0.0,
    "ID00000009": 0.0,
    "ID00000010": 0.0,
    "ID00000011": 0.0,
    "ID00000012": 0.0,
    "ID00000013": 0.0,
    "ID00000014": 0.0,
    "ID00000015": 0.0,
    "ID00000016": 0.0,
    "ID00000017": 0.0,
    "ID00000018": 0.0,
    "ID00000019": 0.0,
    "ID00000020": 0.0,
    "ID00000021": 0.0,
    "ID00000022": 0.0,
    "ID00000023": 0.0,
    "ID00000024": 0.0,
    "ID00000025": 0.0,
    "IP00000001": 0.0,
    "IP00000002": 0.0,
    "IP00000003": 0.0,
    "IP00000004": 0.0,
    "IP00000005": 0.0,
    "IP00000006": 0.0,
    "IP00000007": 0.0,
    "IP00000008": 0.0,
    "IP00000009": 0.0,
    "IP00000010": 0.0,
    "IP00000011": 0.0,
    "IP00000012": 0.0,
    "IP00000013": 0.0,
    "IP00000014": 0.0,
    "IP00000015": 0.0,
    "IP00000016": 0.0,
    "IP00000017": 0.0,
    "IP00000018": 0.0,
    "IP00000019": 0.0,
    "IP00000020": 0.0,
    "IP00000021": 0.0,
    "IP00000022": 0.0,
    "IP00000023": 0.0,
    "IP00000024": 0.0,
    "IP00000025": 0.0,
    "IT00000001": "",
    "IT00000002": "",
    "IT00000003": "",
    "IT00000004": "",
    "IT00000005": "",
    "IT00000006": "",
    "IT00000007": "",
    "IT00000008": "",
    "IT00000009": "",
    "IT00000010": "",
    "IT00000011": "",
    "IT00000012": "",
    "IT00000013": "",
    "IT00000014": "",
    "IT00000015": "",
    "IT00000016": "",
    "IT00000017": "",
    "IT00000018": "",
    "IT00000019": "",
    "IT00000020": "",
    "IT00000021": "",
    "IT00000022": "",
    "IT00000023": "",
    "IT00000024": "",
    "IT00000025": "",
    "UAP": 0.0,
    "UBA": "",
    "UDDATE": "",
    "UDIS": 0.0,
    "UDISC": 0.0,
    "UGRO": 0.0,
    "UISSUERA": 0.0,
    "UMUR": 0.0,
    "UPE": 0.0,
    "URATE": 0.0,
    "URE": "",
    "UREMARK": "",
    "IA00000026": "",
    "ID00000026": 0.0,
    "IP00000026": 0.0,
    "IT00000026": "",
    "UUWQ": "",
    "USERFLD": "",
    "F03NM": "",
    "F20NM": "",
    "CATNM": "",
    "GRPNM": "",
    "CATCD": "",
    "GRPCD": "",
    "USERFLDB": "",
    "SRDETAIL": "",
    "EXPDUMMY": " ",
    "BARCODE": "",
    "OLDPRD": "",
    "OLDQTY": 0.0,
    "OQTY2": 0.0,
    "OLDRATE": 0.0,
    "LADDNEW": "Y",
    "PRICELIST": " ",
    "_BATSTOCK": " ",
    "_LOCWISE": " ",
    "LDUALSTK": " ",
    "SRNOSTK": " ",
    "MRPSTK": " ",
    "RORI": " ",
    "ORDDUMMY": " ",
    "JODUMMY": " ",
    "SRIDUMMY": " ",
    "SRRDUMMY": " ",
    "SRIDUMMYF": " ",
    "SRRDUMMYF": " ",
    "SRISSCD": "",
    "OLDSRNO": "",
    "SCNOCL": "",
    "SCNOCL1": "",
    "MANUDATE": "",
    "EXPDATE": "",
    "T02F08EXP": 0.0,
    "_EXPTOTALAMT": 0.0
});
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  // const [columns, setColumns] = useState([]);
  const [hasFocused, setHasFocused] = useState(true);
  const [hasFocused2, setHasFocused2] = useState(false);
  const [M01PRow, setM01PRow] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [billAmt, setBillAmt] = useState(0);
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [pendingIssue, setPendingIssue] = useState([]);
  const [partyData, setParty] = useState([]);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [dayVal, setDayVal] = useState("");
  const [selectedProductObj, setSelectedProductObj] = useState({});
  // console.log("selectedProductObj",selectedProductObj)
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [flag, setFlag] = useState(true);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const oYear = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC?._JYSUBJOB
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state?.Transaction
  );
  const formVouData = useSelector(
    (state) => state?.Transaction?.TransactionData
  );
  const copyRow = useSelector((state) => state?.GetPendingIssue?.copyRow);

  const inputRef = useRef();
  const input2Ref = useRef();
  const formRef = useRef(null);
  const columnHelper = createColumnHelper();
  // let dataEnt;

  // const { JWListData, JWOrderData } =
  // useSelector((state) => state?.GetPendingIssue);
  let dataEnt = formVouData;
  let editData = props?.obj;
  let editId = editData?.id;

  let p0 = props?.obj.p0;
  let page = props?.obj.page;
  let { index, obj } = props;
  // const IssueJobInDataEnt = useSelector(
  //   (state) => state?.Transaction?.TransactionData
  // );
  // const ProductJobInDataEnt = useSelector(
  //   (state) => state?.Transaction?.TransactionData
  // );

  // if (page === "04200417") {
  //   dataEnt = formVouData;
  // } else if (page === "04260417") {
  //   dataEnt = formVouData;
  // } else if (page === "04230417") {
  //   dataEnt = formVouData;
  // }
  const cType = "JD"; //Temporay defined.

  console.log(dataEnt, "dataEnt---inJoutIssue");
  const T02 = dataEnt?.T02;
  const M01PDT = dataEnt?.M01PDT;
  const M01P = dataEnt?.M01P;
  const P_M31JDT = dataEnt?.P_M31JDT;
  const P_M31J = dataEnt?.P_M31J;
  const M01PRT = dataEnt?.M01PRT;
  const UFT41H = dataEnt?.UFT41H;
  const T92 = dataEnt?.T92;
  const T92DT = dataEnt?.T92DT;
  const T41 = dataEnt?.T41;
  const lEditVou = dataEnt?.lEditVou;
  const OS11 = dataEnt?.OS11;
  const OSV = dataEnt?.OSV;
  // console.log(T92DT, "P_M31JDT--", UFT41H);
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(editId, "editId", obj);
  
    Promise.resolve(Store.dispatch(TransactionApi(obj)))
      .then(() =>
        dispatch(
          GetAccountList({
            id: "T_M32C",
            name: "",
            type: "A",
            p0: "T_M32C",
            p1: "T_M32C",
            p2: "",
            p3: "",
          })
        )
      )
      .then(() =>
        dispatch(
          GetTMFormat({
            id: "T_M32C",
            name: "",
            type: "A",
            p0: "T_M32C",
            p1: "T_M32C",
            p2: "",
            p3: "",
          })
        )
      )
      .then(() => setHasFocused(true));
  }, []);
  // useEffect(() => {
  //   console.log(hasError, "hasError-");
  //   if (hasError) {
  //     Promise.resolve(Store.dispatch(ModalDelete(index))).then(() => {
  //       Store.dispatch(DataSaveLoading(false));

  //     });
  //   }
  // }, [hasError]);

  // console.log("T02OBJ",t02Obj);
  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    return a;
  }, 0);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    let date = group?.FIELD02;
    // console.log(date, "group?.FIELD02");
    if (date?.length > 0) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [group?.FIELD02]);
  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
      // setHasFocused2(true);
    }
  }, [hasFocused]);
  useEffect(() => {
    if (creatingModeOn == true) {
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11?.FIELD22]);
  useEffect(() => {
    // console.log(hasFocused2, "hasFocused2");
    if (hasFocused2) {
      input2Ref?.current?.handlePopoverToggle();
    }
  }, [hasFocused2]);

  useEffect(() => {
    // console.log(dataEnt?.T41, "dataEnt");
    setGroup(dataEnt?.T41);
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);
  useEffect(() => {
    if (obj.p0 == "E") {
      setM01PRow(M01PRT);
    }
  }, [M01PRT]);
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  // console.log("paraArr=>", dataEnt);
  function NarrationBox() {
    setFlag(false);
    // if(flag )
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Naration</Text>,
      body: (
        <PLNarration
          NarrationArray={NarrationData}
          Flag={SelectedNarrationData?.length > 0 ? false : true}
          selectedNarrationArray={SelectedNarrationData}
          // NarrationVal={T41Obj}
          setNarrationArray={(e) => {
            // setT41Obj(e)
            console.log("setNarrationArray", e);
            setNarrationData(e.NarrationData);
            setSelectedNarrationData(e.selectedNarration);
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
  }
  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={group?.[h?.S13F02]}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={group?.[f?.S13F02]}
                          // p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }

  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      // console.log("stringOfNames", stringOfNames);
    }
    setGroup({
      ...group,
      FIELD15:
        group?.FIELD15 != ""
          ? group?.FIELD15 + " " + stringOfNames
          : stringOfNames,
    });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  // useEffect(() => {
  //   // console.log("setBillAmt isVisible", t02Data);
  //   if (isVisible)
  //     setBillAmt(t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0));
  // }, [isVisible, t02Data]);

  function handleOkButton() {
    // console.log("OK BTN", t02Data, group, "t02Obj--", t02Obj);

    if (group?.FIELD04 == "") {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      // const sortedEditT41 = JSON.stringify(editT41);
      // const sortedGroup = JSON.stringify(group);
      // console.log("sortedGroup", sortedGroup);
      // // console.log(
      // //   "Are these passed in array of objects equal? : " , sortedEditT41 === sortedGroup &&
      // //   compareTwoArrayOfObjects(editT02, t02Data) , group?.FIELD12
      // // );
      // if (
      //   sortedEditT41 === sortedGroup &&
      //   compareTwoArrayOfObjects(editT02, t02Data)
      // ) {
      //   Store.dispatch(ModalDelete(index));
      // }
      console.log(T02, "compareTwoArrayOfObjects(T02,t02Data)", t02Data, JSON.stringify(T41) == JSON.stringify(group))
      let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "")
      if (
        obj.p0 == "E" &&
        compareTwoArrayOfObjects(newT02,t02Data) &&
        JSON.stringify(T41) === JSON.stringify(group)
      ) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
          dispatch(ModalDelete(props.index))
        );
        return;
      } else {
        Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(() =>
          GlobalClass.Notify(
            "info",
            p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
          )
        );
        console.log(group?.FIELD34, "group?.FIELD07Pos");
        let newT41 = {
          ...group,
          FIELD06: group?.FIELD07 == 0 ? rowSum : group?.FIELD07,
        };
        console.log(t02Data, "t02Data--", T02);
        let dataToSend = { T41: newT41, T02: t02Data };

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : page == "04200417"
              ? "JXJX"
              : page == "04260417"
              ? "JYJY"
              : page == "04230417"
              ? "JWJW"
              : "",
          cSData: JSON.stringify(dataToSend),
        };
        // console.log(dataToSend, "data Num");
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode:
            p0 == "E"
              ? editId
              : page == "04200417"
              ? "JXJX"
              : page == "04260417"
              ? "JYJY"
              : page == "04230417"
              ? "JWJW"
              : "",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        // console.log(dataToSend, "param Stock Journal");
        // try {
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            // console.log("api data=>", data);
            // console.log("api data status=>", data?.status);

            if (data?.status == "SUCCESS") {
              Promise.resolve(Store.dispatch(DataSaveLoading(false)))
                .then(() => {
                  if (p0 == "E") {
                    Store.dispatch(ModalDelete(index));
                  } else Promise.resolve(Store.dispatch(TransactionApi(obj)));
                })
                .then(() => {
                  setBillSeriesHideShow({
                    ...BillSeriesHideShow,
                    BillNoVal: "",
                  });
                  GlobalClass.Notify(
                    "success",
                    p0 == "A" ? "Added" : "Edited",
                    p0 == "A" ? "Added successfully" : "Edited successfully"
                  );
                })
                .then(() => {
                  Store.dispatch(RefreshIssuePopUp({}));
                })
                .then(() => {
                  Store.dispatch(RefreshIOrderPopUp({}));
                });
              let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              // console.log(index, "ModalDelete");
              // Store.dispatch(ModalDelete(index));
              if (page == "04200417") {
                Promise.resolve(
                  Store.dispatch(
                    GetAccountList({
                      id: "04200417",
                      name: "Receipt",
                      p0: "MT",
                      p1: "TXNVOULISTJX",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["04200417"],
                    })
                  )
                );
              } else if (page == "04260417") {
                Promise.resolve(
                  Store.dispatch(
                    GetAccountList({
                      id: "04260417",
                      name: "Issue",
                      p0: "MT",
                      p1: "TXNVOULISTJY",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["04260417"],
                    })
                  )
                );
              } else if (page == "04230417") {
                Promise.resolve(
                  Store.dispatch(
                    GetAccountList({
                      id: "04230417",
                      name: "Production",
                      p0: "MT",
                      p1: "TXNVOULISTJW",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["04230417"],
                    })
                  )
                );
              }
            } else if (data?.status == "FAIL") {
              console.log(data?.status, "data?.status");
              Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(() =>
                GlobalClass.Notify("error", data?.status, data?.message)
              );
            }
          })
          .catch((e) => {
            console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Store.dispatch(DataSaveLoading(false));
          });
      }
    }
  }
  // useEffect(() => {
  //   // console.log(pendingIssue?.length, "pendingIssue");
  //   if (pendingIssue?.length > 0) {
  //     switch (OS11?.S11F01) {
  //       case "JJ":
  //       case "JZ":
  //         let newArr = pendingIssue?.map((v) => {
  //           return {
  //             ...t02Obj,
  //             FIELD33:
  //               oYear !== "N"
  //                 ? v?.FIELD61 - v?.FIELD54
  //                 : v?.FIELD06 - v?.FIELD54,
  //             FIELD66:
  //               v?.FIELD66 == 0
  //                 ? oYear !== "N"
  //                   ? v?.FIELD61 - v?.FIELD54
  //                   : v?.FIELD06 - v?.FIELD54
  //                 : v?.FIELD66,
  //             FIELD06:
  //               oYear !== "N"
  //                 ? v?.FIELD61 - v?.FIELD54
  //                 : v?.FIELD06 - v?.FIELD54,
  //             FIELD02: t02Obj?.FIELD02 == "" ? v?.T41F02 : t02Obj?.FIELD02,
  //             FIELD21: v?.FIELD21 - v?.FIELD55,
  //             FIELD07: v?.FIELD07,
  //             FIELD64: v?.FIELD64 == 0 ? v?.FIELD21 - v?.FIELD55 : v?.FIELD64,
  //             FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
  //             FIELD88: v?.FIELD88,
  //             FIELD63: v?.FIELD63,
  //             FIELD65: OS11?.S11F01 == "JZ" ? v?.T41F12 : v?.T41F10,
  //             FIELD13:
  //               OS11?.S11F01 == "JJ" && v?.T41PVT == "JO"
  //                 ? v?.FIELD01 + v?.FIELD09 != null
  //                   ? v?.FIELD09
  //                   : 4
  //                 : t02Obj?.FIELD13,
  //           };
  //         });

  //         setT02Obj({ ...newArr[0] });
  //         let extraRow = newArr?.slice(1);
  //         setT02Data([...t02Data, ...extraRow]);
  //         break;
  //       case "JI":
  //       case "IN":
  //         console.log("Its Here ", OS11?.S11F01);
  //         let newArr2 = pendingIssue?.map((v) => {
  //           return {
  //             ...t02Obj,
  //             FIELD06: t02Obj?.FIELD06 == 0 ? v?.FIELD06 - v?.FIELD54 : "",
  //             FIELD21: t02Obj?.FIELD06 == 0 ? v?.FIELD21 - v?.FIELD55 : "",
  //             FIELD33: t02Obj?.FIELD06 == 0 ? v?.FIELD06 : "",
  //             FIELD63: v?.FIELD63,
  //             FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
  //             FIELD82: v?.FIELD02,
  //             FIELD65: v?.T41F10,
  //             FIELD61: v?.FIELD06,
  //             FIELD62: v?.FIELD21,
  //             FIELD41: v?.FIELD41,
  //             FIELD72: v?.FIELD12,
  //           };
  //         });
  //         setT02Obj({ ...newArr2[0] });
  //         let extraRow2 = newArr2?.slice(1);
  //         setT02Data([...t02Data, ...extraRow2]);
  //         break;
  //       case "JO":
  //       case "JY":
  //       case "JW":
  //         console.log("Its Here ", OS11?.S11F01);
  //         let newArr3 = pendingIssue?.map((v) => {
  //           return {
  //             ...t02Obj,
  //             FIELD33:
  //               cType == "JD"
  //                 ? v?.FIELD33 - v?.FIELD54
  //                 : v?.FIELD61 - v?.FIELD54,
  //             FIELD21: cType == "JD" ? "" : v?.FIELD62 - v?.FIELD55,
  //             FIELD06: v?.FIELD06,
  //             FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
  //             FIELD82: v?.FIELD02,
  //             FIELD65: v?.T41F10,
  //             FIELD63: v?.FIELD63,
  //             FIELD53: "P",
  //             FIELD41: v?.FIELD41,
  //             FIELD07: cType == "JD" ? t02Obj?.FIELD07 : v?.FIELD07,
  //             FIELD19: OS11?.S11F01 == "JW" ? "R" : "",
  //           };
  //         });
  //         setT02Obj({ ...newArr3[0] });
  //         let extraRow3 = newArr3?.slice(1);
  //         setT02Data([...t02Data, ...extraRow3]);

  //         break;

  //       case "JX":
  //         console.log("Its Here ", OS11?.S11F01);
  //         let newArr4 = pendingIssue?.map((v) => {
  //           if (oYear == "Y") {
  //             return {
  //               ...t02Obj,
  //               FIELD21: v?.FIELD21 - v?.FIELD55,
  //               FIELD33: v?.FIELD33 - v?.FIELD54,
  //               FIELD06: v?.FIELD33 - v?.FIELD54,
  //               FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
  //               FIELD82: v?.FIELD02,
  //               FIELD65: v?.T41F10,
  //               FIELD63: v?.FIELD63,
  //               FIELD07: v?.FIELD07,
  //               FIELD53: "P",
  //               FIELD41: v?.FIELD41,
  //             };
  //           }
  //         });
  //         setT02Obj({ ...newArr4[0] });
  //         let extraRow4 = newArr4?.slice(1);
  //         setT02Data([...t02Data, ...extraRow4]);
  //         break;
  //       default:
  //         console.log(oYear, "Its Here ", OS11?.S11F01);

  //         break;
  //     }
  //   }
  // }, [pendingIssue]);

  // useEffect(() => {
  //   // console.log("t02Obj?.FIELD03", OS11?.S11F01, t02Obj);
  //   if (page === "04200417" ) {
  //     console.log(page , "page--")
  //     if (t02Obj?.FIELD03 !== "" && t02Obj?.FIELD03 !== undefined) {
  //       // console.log(dataToSend, "data Num");
  //       let data = {
  //         CorpID: gensetting.getcorpid(),
  //         cUser: gensetting.getcuser(),
  //         cPass: gensetting.getcpass(),
  //         CmpNo: gensetting.getcmpno(),
  //         cAction: "",
  //         cCode: OS11?.S11F01,
  //         cSData: JSON.stringify({
  //           cPCode: t02Obj?.FIELD03,
  //           cACode: group?.FIELD04,
  //           cPrcCode: group?.FIELD34,
  //           cDate: group?.FIELD02,
  //           cType: "",
  //         }),
  //       };
  //       // setPendingIssData(data)
  //       Promise.resolve(Store.dispatch(GetJWListData(data))).then((data) => {
  //         if (
  //           data?.payload?.T02JDT?.length > 0 &&
  //           data?.payload?.T02J?.length > 0
  //         ) {
  //           // console.log(data?.payload, "GetJWListData--");
  //           // Promise.resolve(
  //           //   Store.dispatch(getPendingIssueData(data?.payload))
  //           // ).then(() => {
  //             setDrawerObj({
  //               ...dra,
  //               title: <Text fw={700}>Pending Issues</Text>,
  //               body: (
  //                 <PendingIssue
  //                   data={data?.payload}
  //                   setData={(e) => setPendingIssue(e)}
  //                   DrawerOBJ={DrawerObj}
  //                   setDrawerOBJ={(e) => {
  //                     setDrawerObj(e);
  //                   }}
  //                 />
  //               ),
  //               open: true,
  //               size: "xl",
  //               position: "right",
  //             });
  //           // });
  //         }
  //       });
  //     }
  //   }
  // }, [t02Obj?.FIELD03, group?.FIELD04, group?.FIELD34, group?.FIELD02]);
  useEffect(() => {
    if (M01P?.length > 0) {
      setParty(M01P);
    } else if (M01P?.jData?.length > 0) {
      setParty(M01P?.jData);
    } else {
      setParty([]);
    }
  }, [M01P]);

  const spoilerControlRef = useRef();
  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        DataGridInput: true,
        TextInput: false,
      });
    }
  }, [BillSeriesHideShow.BillNoVal]);
  return (
    <>
      {SaveLoading == true || isLoading || AccNameisLoading["T_M32C"] ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <div ref={formRef}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={() => {}}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: () => {
                setDrawerObj(dra);
              },
            }}
            title={DrawerObj.title}
            size={DrawerObj.size}
            closeOnClickOutside={true}
            position={DrawerObj?.position ?? "right"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                    setDrawerObj(dra);
                  }
            }
          >
            {DrawerObj.body}
          </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "10",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Party A/c.</Text>
                  </Grid.Col>
                  <Grid.Col span={9} style={{ overflow: "hidden" }}>
                    <PLDataGrid
                      width={"500px"}
                      ref={inputRef}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={{ value: group?.FIELD04, label: group?.FIELD04NM }}
                      TmData={M01P}
                      // data={partyData}
                      dispexpr="FIELD02"
                      valexpr="FIELD01"
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          })
                        ).then(() => {
                          // console.log(e.FIELD01, "group?.FIELD04?.length");
                          if (e?.FIELD01?.length > 1) setHasFocused2(true);
                        });
                        setM01PRow(e);
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                        // M48Dhandlechange(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.date}</Text>
                  </Grid.Col>
                  <Grid.Col span={7}>
                    <PLDateBox
                      style={{ margin: "0", padding: "0" }}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("Stocke PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        if (startDate?.length == 8) {
                          setGroup({ ...group, FIELD02: startDate });
                        }
                      }}
                      defaultval={group?.FIELD02}
                    />
                  </Grid.Col>
                  <Grid.Col span={1} style={{ color: "red" }}>
                    {dayVal}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc No.</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      // disabled={true}
                      disabled={OSV?._DOCNODATE == "N"}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={group?.FIELD10}
                      setEdit={(e) => {
                        setGroup({ ...group, FIELD10: e?.target?.value });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}></Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    Balance : {M01PRow != null ? M01PRow?.FIELD99 : null} DB
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{props?.No}</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    {p0 == "E" ? (
                      <PLTextBox
                        value={group?.FIELD12}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          setGroup({
                            ...group,
                            T92F00: "",
                            FIELD12: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <>
                        {T92 ? (
                          <>
                            {BillSeriesHideShow?.DataGridInput == true &&
                              (!group?.T92F00 ? (
                                <PLDataGrid
                                  onKeyDown={(e) => {
                                    GlobalClass.formNextInput(e, formRef);
                                  }}
                                  width={"500px"}
                                  position={"unset"}
                                  value={group?.FIELD12}
                                  valexpr={"FIELD00"}
                                  TmData={T92}
                                  // data={T92DT ?? []}
                                  setEdit={(e) => {
                                    // console.log(e?.FIELD00, "FIELD00");
                                    Promise.resolve(
                                      setGroup({
                                        ...group,
                                        T92F00: e.FIELD00,
                                        FIELD12: "",
                                      })
                                    ).then(() => {
                                      if (e?.FIELD02) {
                                        let letr = e.FIELD02;
                                        // console.log("num=>", num);
                                        let num = parseInt(e.FIELD03) + 1;
                                        setBillSeriesHideShow({
                                          ...BillSeriesHideShow,
                                          DataGridInput: false,
                                          TextInput: true,
                                          BillNoVal: letr + num,
                                        });
                                      }
                                    });
                                  }}
                                />
                              ) : (
                                <PLTextBox
                                  value={group?.FIELD12}
                                  // value={T41Obj?.T92F00}
                                  setEdit={(e) => {
                                    // console.log("setEdit=>", e);
                                    // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                    setGroup({
                                      ...group,
                                      T92F00: "",
                                      FIELD12: e.target.value,
                                    });
                                  }}
                                />
                              ))}
                            {BillSeriesHideShow?.TextInput == true && (
                              <PLTextBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                value={BillSeriesHideShow?.BillNoVal}
                                setEdit={(e) => {
                                  // //console.log(e)

                                  setBillSeriesHideShow({
                                    ...BillSeriesHideShow,
                                    BillNoVal: e.target.value,
                                  });
                                  setGroup({
                                    ...group,
                                    T92F00: "",
                                    FIELD12: e.target.value,
                                  });
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <PLTextBox
                            value={BillSeriesHideShow?.BillNoVal}
                            // value={T41Obj?.T92F00}
                            setEdit={(e) => {
                              console.log("setEdit=>", e);
                              setBillSeriesHideShow({
                                ...BillSeriesHideShow,
                                BillNoVal: e.target.value,
                              });
                              setGroup({
                                ...group,
                                T92F00: "",
                                FIELD12: e.target.value,
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      // disabled={true}
                      disabled={OSV?._DOCNODATE == "N"}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      style={{ margin: "0", padding: "0" }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("e PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        if (startDate?.length == 8) {
                          setGroup({ ...group, FIELD11: startDate });
                        }
                      }}
                      defaultval={group?.FIELD11?.trim()}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              {page == "20400399" ? (
                ""
              ) : (
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Jobwork Process</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={input2Ref}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      width={"500px"}
                      isMulti={true}
                      value={{ value: group?.FIELD34, label: group?.FIELD34NM }}
                      // value= {p0 == "E" ? {value:group?.FIELD34,label:group?.FIELD34NM} : group?.FIELD34 }
                      pid={"P_M31J"}
                      // value={group?.FIELD34}
                      TmData={P_M31J}
                      // data={P_M31JDT?.jData}
                      setEdit={(e) => {
                        // console.log("handleClose", e);
                        let arr = e?.map((v) => v?.original?.FIELD01).join();
                        let arr2 = e?.map((v) => v?.original?.FIELD02).join();
                        Promise.resolve(
                          setGroup({ ...group, FIELD34: arr, FIELD34NM: arr2 })
                        ).then(() => setHasFocused2(false));
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {/* </Grid.Col> */}
              {/* {page == "20400399" ? (
              ""
            ) :  */}
            </Grid>

            {UFT41H?.length > 0 && UFT41Hval?.length > 0 ? (
              <>
                <Divider
                  my="xs"
                  label="User Fields"
                  labelPosition="center"
                  labelProps={{ component: "strong" }}
                />
                <Spoiler
                  maxHeight={80}
                  showLabel="Show more"
                  hideLabel="Hide"
                  controlRef={spoilerControlRef}
                >
                  <Grid gutter={4}>
                    {/* <Grid.Col span={12} style={{ display: "flex" }}> */}
                    {UFT41HFieldRenderer("Header")}
                    {/* </Grid.Col> */}
                  </Grid>
                </Spoiler>
              </>
            ) : (
              ""
            )}
            <Grid gutter={4}>
              <Grid.Col span={12} my={7}>
                {/* <MantineReactTable table={table} /> */}
                <T02Table
                  accList={accList}
                  tmList={tmList}
                  dataEnt={dataEnt}
                  obj={obj}
                  setT02Data={setT02Data}
                  t02Data={t02Data}
                  setIsVisible={setIsVisible}
                  setCreatingModeOn={setCreatingModeOn}
                  setSelectedProductObj={setSelectedProductObj}
                  selectedProductObj={selectedProductObj}
                  t41={group}
                  t02Obj={t02Obj}
                  setT02Obj={setT02Obj}
                  // JWListData={JWListData}
                  // JWOrderData={JWOrderData}
                />
              </Grid.Col>
            </Grid>
            <Center h={10}>
              <Button
                size="xs"
                compact
                onClick={() => {
                  console.log(copyRow?.original, "tableData-", copyRow);
                  if (
                    typeof copyRow?.original == "object" &&
                    copyRow?.original != null &&
                    !creatingModeOn
                  ) {
                    setT02Data([...t02Data, copyRow?.original]);
                  }
                }}
              >
                Copy
              </Button>
              <Button size="xs" compact style={{ marginLeft: "1vw" }}>
                Serial No.
              </Button>
            </Center>
            {UFT41Fval?.length > 0 ? (
              <>
                <Divider my={2} />
                <Grid>
                  {/* <Grid.Col span={6}> */}
                  {UFT41HFieldRenderer("Footer")}
                  {/* </Grid.Col> */}
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid
              span={12}
              my={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <Grid.Col span={5}>
                <Text>Narration</Text>

                <PLTextarea
                  minRows={2}
                  value={group?.FIELD15}
                  onChange={(e) => {
                    // //console.log(e)
                    setGroup({ ...group, FIELD15: e.target.value });
                  }}
                  onFocus={(e) => {
                    // console.log(e, "OnFocusNarr")
                    if (OSV?._ANARRHELP == "Y" && flag) {
                      NarrationBox();
                    } else {
                      return;
                    }
                  }}
                  onKeyDown={(e) => {
                    // console.log("Narration=>", e.shiftKey);
                    if (e.ctrlKey && e.key === "F1") {
                      NarrationBox();
                      //console.log("Narration call");
                      // setFlag(false)
                      // setDrawerObj({
                      //   ...dra,
                      //   title: <Text fw={700}>Naration</Text>,
                      //   body: (
                      //     <PLNarration
                      //       NarrationArray={NarrationData}
                      //       selectedNarrationArray={SelectedNarrationData}
                      //       setNarrationArray={(e) => {
                      //         setNarrationData(e.NarrationData);
                      //         setSelectedNarrationData(e.selectedNarration);
                      //       }}
                      //       DrawerOBJ={DrawerObj}
                      //       setDrawerOBJ={(e) => {
                      //         setDrawerObj(e);
                      //       }}
                      //     />
                      //   ),
                      //   open: true,
                      //   size: "md",
                      //   position: "right",
                      // });
                    }
                  }}
                />
              </Grid.Col>

              <Grid.Col
                span={4}
                // offset={2}
              >
                {/* <Box style={{ overflow: "hidden" }}> */}
                <Group mb={3}>
                  <Grid.Col>
                    {isVisible ? (
                      <Grid>
                        <Text style={{ fontSize: 12 }}> Total Amount: </Text>
                        <IconCurrencyRupee />
                        <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                          {/* {billAmt} */}
                          {rowSum}
                          CR
                        </Text>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                  <Button
                    onClick={handleOkButton}
                    disabled={
                      creatingModeOn
                      // || t02Data?.length < 1
                    }
                  >
                    Ok
                  </Button>
                  <Button style={{ marginLeft: "1vw" }}>Print</Button>{" "}
                </Group>
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}
