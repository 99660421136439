import { Button, Grid, Group, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { AssignItemDefault, CalCFI, RecalcFun } from './AssignItemDefaultFun'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import GlobalClass from '../../utils/GlobalClass'

const styles = {
    GridClass: { maxHeight: "300px", overflow: "hidden", overflowY: "scroll" }
}

const ExpenseEntryForm = (props) => {
    console.log("ExpenseEntryForm====>", props)
    const { ExpenseObj, expenseArr, M01PData, M01Format, valueSaveObj, onChange, isExpense, TableExpenseArr, TableExpenseValue, ProductObj, expenseArrDef, saveBtnPara, DrawerOBJ, setDrawerOBJ, SendselectedTaxAcc, T41ObjSend } = props

    const [DtExpTableField, setDtExpTableField] = useState([])
    const [M01D, setM01D] = useState([])
    const [M01F, setM01F] = useState()
    const [FieldObj, SetFieldObj] = useState(null)
    const [FooterExpFlag, setFooterExpFlag] = useState(false)

    const dispatch = useDispatch()

    const [formValue, setFormValue] = useState(0)

    const InvTypeDropDown = useSelector((state) => state?.TSalesSlices?.InvTypeDropDown)
    const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData)


    //for oYear Obj Get
    const GetGeneral = useSelector((state) => state?.Language?.General)
    // const { OS11, OSV } = useSelector((state) => state.Transaction?.TransactionData)
    // const { oYear } = useSelector((state) => state?.Language?.General)

    const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11)
    const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV)
    const oYear = useSelector((state) => state?.Language?.General?.oYear)

    // const M01SP = useSelector((state) => state.Transaction?.TransactionData?.M01SP);
    // const M01SPDT = useSelector((state) => state.Transaction?.TransactionData?.M01SPDT);

    const dtM14DT = useSelector((state) => state.Transaction?.TransactionData?.dtM14);
    const dtM51DT = useSelector((state) => state.Transaction?.TransactionData?.dtM51);
    const dtM52DT = useSelector((state) => state.Transaction?.TransactionData?.dtM52);
    const dtM46DT = useSelector((state) => state.Transaction?.TransactionData?.dtM46);

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    // {
    //     name: "",
    //     type: "A",
    //     id: id ?? props?.TmData?.split("~C~")[0],
    //     p0: props.TmData?.split("~C~")[0],
    //     p1: props.TmData?.split("~C~")[0],
    //     p2: props.TmData?.split("~C~")[1],
    //   }


    // const TotalAmt = (ExpenseType == true ? T02ObjSend?.FIELD08 : T02ObjSend?.FIELD07);
    // console.log("TableExpenseArr ==>", TableExpenseArr);


    useEffect(() => {
        // console.log("FieldObj ONBLUR useEffect ", FieldObj);
        if (isExpense == true) {
            dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": "",
                "cWhere": `M21.FIELD01 LIKE '%${valueSaveObj?.FIELD03}%'`
                // "cWhere": ""
            }))
        }
        //   "M21.FIELD12 LIKE '%CHVEAJP4%'"
    }, [isExpense])

    //     useEffect(()=>{
    // console.log("AccNameisLoading status =>",AccNameisLoading);
    //     },[AccNameisLoading])

    useEffect(() => {
        if (isExpense == true) {
            console.log("Expense component Product =>", [{
                "accList": accList["P_M21"],
                "valueSaveObj": valueSaveObj,
                "isExpense": isExpense
            }]);
        }
    }, [isExpense, accList])



    useEffect(() => {
        if (isExpense && valueSaveObj) {
            SetFieldObj(valueSaveObj)
        } else {
            // .reduce((a, b) => a = a + b?.ID00000018, 0)
            if (TableExpenseArr) {
                var exp = { ...valueSaveObj };
                TableExpenseArr?.map((tbl) => {
                    Object.keys(tbl)?.map((e) => {
                        if (e.includes("ID")) {
                            exp[e] = TableExpenseArr?.reduce((a, b) => a = a + b[e], 0)
                        }
                    })
                })

                console.log("TOTALEXP=>> ", TableExpenseArr);
                SetFieldObj(exp)
            } else {
                SetFieldObj(valueSaveObj)
            }
        }
    }, [isExpense, valueSaveObj, TableExpenseArr])

    useEffect(() => {
        if (M01PData && M01Format) {
            setM01D(M01PData)
            setM01F(M01Format)
        }
    }, [M01PData, M01Format])

    useEffect(() => {
        if (isExpense) {
            setFormValue(valueSaveObj.FIELD08)
        } else {
            setFormValue(TableExpenseValue)
        }
    }, [isExpense, valueSaveObj, TableExpenseValue])

    useEffect(() => {

        console.log("InvTypeDropDown", InvTypeDropDown);

        if (InvTypeDropDown && InvTypeDropDown != {}) {

            SetControls().then((dt) => {

                console.log("dt", dt);

                let newArray = [...dt];

                if (!isExpense) {

                    console.table("TableExpenseArr", TableExpenseArr);

                    newArray?.map((d, i) => {

                        let IDTotal = (TableExpenseArr?.reduce((a, b) => a = a + (b["ID" + d.FIELD54]), 0) * 100)

                        let FIELD07Total = TableExpenseArr?.reduce((a, b) => a = a + parseInt(b["FIELD08"]), 0)

                        // console.log("IDTotal FIELD07Total =>", [{
                        //     "IDTotal": IDTotal,
                        //     "FIELD07Total": FIELD07Total,
                        //     "IDTotal + FIELD07Total": IDTotal / FIELD07Total,
                        //     "TableExpenseArr": TableExpenseArr
                        // }]);

                        newArray[i] = {
                            ...d,
                            EXPPERC: IDTotal / FIELD07Total,
                            EXPAMT: TableExpenseArr?.reduce((a, b) => a = a + (b["ID" + d.FIELD54] * ((["R", "C"].includes(b["FIELD05"]) ? "C" : "D") == (OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)), 0)
                        }
                    })
                    console.table("newArray", newArray);
                }
                setDtExpTableField(newArray)
            })
        }
    }, [expenseArr, InvTypeDropDown, TableExpenseArr, isExpense])


    const SetControls = async () => {

        return (expenseArr?.length > 0 ? [...expenseArr] : [])
    }

    const Recalc = async (d, index1, t, dr1) => {
        // console.log("Recalc function", d);

        // console.table("Recalc function call expenseEntry", [{
        //     "d": d,
        //     "index1": index1,
        //     "isExpense": t,
        //     "dr1": dr1,
        //     "FieldObj?.FIELD07": FieldObj?.FIELD07,
        //     "isExpense": t,
        //     "formValue": formValue
        // }])

        let oExp = d;
        let lItemWise = t
        let lFItemwise = false;
        // let TotalAmt = lItemWise ? d.FIELD08 : TableExpenseValue;
        let TotalAmt = parseFloat(formValue)
        let GstAmt = 0;
        let FreeAmt = 0;
        let TmpVal = 0;
        let nAmt = TotalAmt;
        let nShAmt = nAmt;
        let cVType = OS11?.S11F01;
        let EqnAmt = "";
        let sFQty = "";
        let sSQty = "";
        let dFQty = 0;
        let dSQty = 0;
        let nTemp = 0;
        let SurcVal = 0;
        let nType = 0;

        if (oYear?.OSC._GSTFREQTY == "Y") {
            // Check if FIELD08 is empty and FIELD81 is not empty
            if (lItemWise) {
                if (oExp.FIELD08 == "" && oExp.FIELD81 != "") {
                    FreeAmt = oExp.FIELD07 * oExp.FIELD81;
                }
                else if (oExp.FIELD33 != "" && oExp.FIELD81 != "") {
                    // Check if FIELD33 and FIELD81 are not empty
                    FreeAmt = oExp.FIELD08 / oExp.FIELD33 * oExp.FIELD81;
                }
            }
        };
        // let TmpVal = 0;
        //    let nT = MApp.pc.ToInt(dr["SeqNo"]); // Unknown
        console.log("TmpVal Amt", nAmt);
        let newarray = [...DtExpTableField];
        let newFieldObj = { ...FieldObj };
        DtExpTableField?.map((dr, index) => {

            console.table("ExpenseObj?.dtExp_I map =>", [{
                "index": index,
                "dr": dr
            }]);
            GstAmt = parseFloat(GstAmt);
            FreeAmt = parseFloat(FreeAmt);
            TmpVal = parseFloat(TmpVal);
            dFQty = parseFloat(dFQty);
            dSQty = parseFloat(dSQty);
            nTemp = parseFloat(nTemp);
            SurcVal = parseFloat(SurcVal);
            nType = parseFloat(nType);

            // let dr = aa[i]
            lFItemwise = dr?.FIELD53 == "D";
            let nT = dr?.SeqNo;
            let lObjItemwise = dr?.FIELD53 == "I";  // Expense is Itemwise
            let lFItemExp = dr?.FIELD53 == "D";     // Fix-Itemwise
            let lSurcharge = dr?.FIELD06 == "U";    // Surcharge
            let lCum = dr?.FIELD06 == "C";          // Cummulative
            let lFQty = dr?.FIELD06 == "Q";         // First Qty
            let lSQty = dr?.FIELD06 == "S";         // Second Qty
            let cType = dr?.FIELD06 == "M" ? "F" : dr?.FIELD06;//Calc Type
            // let Perc =((isNaN(dr?.EXPPERC) || typeof dr?.EXPPERC == "string") ? 0 : dr?.EXPPERC || 0) * ((("R".includes(oExp["FIELD05"]) || "C".includes(oExp["FIELD05"])) ? "C" : "D") == (OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)
            let Perc = dr?.EXPPERC && !isNaN(dr?.EXPPERC) ? (parseFloat(dr?.EXPPERC) * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)) : 0; //oExp['IP' + dr.FIELD54] //dr.EXPPERC   // Expense Percentage
            let Amt = dr?.EXPAMT && !isNaN(dr?.EXPAMT) ? parseFloat(dr?.EXPAMT) : 0;
            // let Amt = (!dr?.EXPAMT || typeof dr?.EXPAMT == "string" ? 0 : dr?.EXPAMT);   // Expense Amount // EXPAMT ma value set nathi ... FIELD07 ma set kr tyare aama pan thavi joi e ...
            // let Amt = lItemWise ? oExp.FIELD08 : oExp.FIELD07;
            let cVCO = dr?.FIELD03;                 // Expense Type
            let cRoundOff = dr?.FIELD75;            // Round off Type 
            //lRound = MApp.pc.InList(cRoundOff, "F", "C", "Y");  // Round Off - Yes/No             
            // let lRound = cRoundOff?.includes("F", "C", "Y");
            let lRound = ["F", "C", "Y"].includes(cRoundOff)
            let lAChange = false; //unknown

            console.table("Recalc map Values", [{
                "dr?.FIELD02": dr?.FIELD02,
                "dr?.EXPAMT": dr?.EXPAMT,
                "dr?.EXPPERC": dr?.EXPPERC,
                "TotalAmt": TotalAmt,
                "Amt": Amt,
                "perc": Perc,
                "lRound": lRound,
                "cRoundOff": cRoundOff
            }]);

            if (dr?.EXPPERDISP == "1" || dr?.EXPACCDISP == "1") {
                // d.EXPPERC = d.EXPPERC == null ? 0 : d.EXPPERC; //(EXPPERC- Expense%) Unknown
                if (lItemWise && !lObjItemwise && !lFItemExp) { //1
                    console.log("RecalcCondition 1");
                    return null
                } else if (nT < index) { //2
                    console.log("RecalcCondition 2");
                    TmpVal = Amt;
                    if (["3", "4"].includes(cVCO)) {
                        GstAmt = GstAmt + TmpVal;
                    }
                } else if (!lItemWise && lObjItemwise) { //3
                    console.log("RecalcCondition 3");
                    TmpVal = Amt;
                    if (["3", "4"].includes(cVCO)) {
                        GstAmt = GstAmt + TmpVal;
                    }
                } else if (lSurcharge) { // 4
                    console.log("RecalcCondition 4");
                    TmpVal = Amt;

                    if (dr.EXPPERC != null && !lAChange) {
                        let drArr = DtExpTableField.filter((a) => a.EXPSURSEQ >= 0);
                        drArr?.map((dtSur, dtSuri) => {
                            let nIndex = dtSur.EXPSURSEQ;

                            SurcVal = SurcVal + DtExpTableField[nIndex].EXPAMT ? 0 : DtExpTableField[nIndex].EXPAMT
                                * (DtExpTableField[nIndex].EXPDeduct == "-" ? -1 : 1) * (DtExpTableField[index].EXPDeduct == "-" ? -1 : 1);
                        })
                        TmpVal = Math.round(SurcVal * Perc / 100).toFixed(2);
                    }
                    // IF lRound the set Calculated value to roundoff According to Expense defination
                    if (lRound) {
                        switch (cRoundOff) {
                            case "Y":
                                TmpVal = Math.round(TmpVal).toFixed(0);
                                break;
                            case "C":
                                TmpVal = Math.ceil(TmpVal);
                                break;
                            case "F":
                                TmpVal = Math.floor(TmpVal);
                                break;
                        }
                    }

                } else if (EqnAmt != "" && !lAChange && ["E", "T"].includes(cVCO) || (["V", "C"].includes(cVCO) && oYear?.OSC?._STATE == "S02")) { // 5
                    console.log("RecalcCondition 5");
                    if (lRound) {
                        switch (cRoundOff) {
                            case "Y":
                                TmpVal = Math.round(TmpVal).toFixed(0);
                                break;
                            case "C":
                                TmpVal = Math.ceil(TmpVal);
                                break;
                            case "F":
                                TmpVal = Math.floor(TmpVal);
                                break;
                        }
                    }
                    //Equation Will be perform Here Pending this case right now
                } else if (dr?.FIELD06 == "R") { //6
                    console.log("RecalcCondition 6");
                    TmpVal = nAmt - Math.round(nAmt);
                    TmpVal = TmpVal * (TmpVal <= 0.50 ? -1 : 1);
                } else if (dr?.EXPPERREQ != "1" || lAChange) { //7
                    console.log("RecalcCondition 7");
                    TmpVal = Amt;
                    if (lRound) {
                        switch (cRoundOff) {
                            case "Y":
                                TmpVal = Math.round(TmpVal).toFixed(0);
                                break;
                            case "C":
                                TmpVal = Math.ceil(TmpVal);
                                break;
                            case "F":
                                TmpVal = Math.floor(TmpVal);
                                break;
                        }
                    }
                    if (["3", "4"].includes(cVCO)) {
                        GstAmt = GstAmt + TmpVal;
                    }
                } else if (lFQty || cType == "K") { //8
                    console.log("RecalcCondition 8");
                    if (cType == "K") {
                        TmpVal = (dr.EXPADDICESS ?? 0) * (oExp.FIELD06 ?? 0);
                    }
                    else {
                        sFQty = lItemWise ? oExp.FIELD06 : "";
                        dFQty = sFQty ?? 0;
                        TmpVal = Perc * dFQty; // Percentage Value & Quantity
                    }

                } else if (lSQty) { // 9
                    console.log("RecalcCondition 9");
                    sSQty = lItemWise ? d.FIELD21 : "";
                    dSQty = sSQty ?? 0;
                    TmpVal = Perc * dSQty; // Percentage Value & Quantity
                } else if (dr.EXPPERREQ == "1" && ["V", "D"].includes(cVCO) && cType == "A" && dr.M45F08) {//10
                    nTemp = nAmt - DtExpTableField[index - 1]?.EXPAMT;
                    TmpVal = Math.round(nTemp * (dr.EXPPERC / 100)).toFixed(2);
                    if (lRound) {
                        switch (cRoundOff) {
                            case "Y":
                                TmpVal = Math.round(TmpVal).toFixed(0);
                                break;
                            case "C":
                                TmpVal = Math.ceil(TmpVal);
                                break;
                            case "F":
                                TmpVal = Math.floor(TmpVal);
                                break;
                        }
                    }
                } else if (dr?.EXPPERREQ == "1" && cType != "A") { //11
                    console.log("RecalcCondition 10");
                    // console.log("Perc", Perc);
                    if (Perc == 0 && Amt != 0 && (!["V", "D", "3", "4"].includes(cVCO))) {
                        console.log("RecalcCondition 101");
                        if ((nType > 0 || dr.FIELD53 == "D") && cVType != "SH" && !lItemWise) {

                            TmpVal = 0;
                        } else {
                            TmpVal = Amt;
                        }
                    } else {
                        // console.log("RecalcCondition 102", [{
                        //     "cType": cType,
                        //     '"B".includes(cType)': "B".includes(cType),
                        //     '"D".includes(cType)': "D".includes(cType),
                        //     '"I".includes(cType)': "I".includes(cType),
                        //     '"J".includes(cType)': "J".includes(cType)
                        // }]);
                        // if (("B".includes(cType)) || ("D".includes(cType))|| ("I".includes(cType)) || ("J".includes(cType)))
                        if (["B", "D", "I", "J"].includes(cType)) {
                            console.log("RecalcCondition 1021")
                            nTemp = nAmt - GstAmt;
                            if (oYear.OSC._GSTFREQTY)//AND NOT This.oYear.lBlwThre
                            {
                                nTemp = nTemp + FreeAmt;
                            }
                        } else {
                            nTemp = lCum ? nAmt : TotalAmt;
                            console.log("RecalcCondition 1022=>", [{
                                "nTemp": nTemp,
                                "lCum": lCum,
                                "nAmt": nAmt,
                                "TotalAmt": TotalAmt
                            }])
                        }
                        TmpVal = Math.round(nTemp * (Perc / 100)).toFixed(2);

                    }

                    // console.log("RecalcCondition 10 parameter =>", [{
                    //     "lRound": lRound,
                    //     "cRoundOff": cRoundOff,
                    //     "cVCO": cVCO
                    // }]);

                    if (lRound) {
                        switch (cRoundOff) {
                            case "Y":
                                TmpVal = Math.round(TmpVal).toFixed(0);
                                break;
                            case "C":
                                TmpVal = Math.ceil(TmpVal);
                                break;
                            case "F":
                                TmpVal = Math.floor(TmpVal);
                                break;
                        }
                    }

                    if (["3", "4"].includes(cVCO)) {
                        if (oYear.OSC._GR1RTTAX && oExp.FIELD53 == "Y" && (["SS", "SR", "PP", "PR", "HC", "HD"].includes(cVType))) {
                            // oVou.cVType.includes("SS", "SR", "PP", "PR", "HC", "HD")) {

                            TmpVal = TmpVal * 0.65;
                        }
                        GstAmt = GstAmt + TmpVal;

                    }
                }
                // dr.EXPAMT = TmpVal; //Unknown
                //  oExp[dr.EXPAMTSRC] = dr.EXPAMT; // Unknown
                // Add To Total Only If Account effect Is Yes. Do Not Add In Case Of Account Effect No/Seperate

                newarray[index] = { ...dr, EXPAMT: (TmpVal * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)) }
                if (dr.FIELD61 == "Y") {
                    // Add calculated value to final total
                    // console.log("Add calculated value to final total =>", [{
                    //     "nAmt": nAmt,
                    //     "TmpVal": TmpVal,
                    //     "dr.EXPDEDUCT": dr.EXPDEDUCT,
                    //     'dr.EXPDEDUCT == "-"': (dr.EXPDEDUCT == "-" ? -1 : 1)
                    // }]);
                    nAmt = nAmt + (TmpVal * (dr.EXPDEDUCT == "-" ? -1 : 1));
                    //    nAmt = (nAmt + TmpVal);
                    console.log("nAmt", nAmt);
                    // return FieldObj['ID' + MObj?.FIELD54] = nAmt
                    if (lItemWise) {
                        // console.log("TmpVal==>", TmpVal);
                        newFieldObj = { ...newFieldObj, FIELD90: nAmt, ['ID' + dr?.FIELD54]: TmpVal }
                        // newFieldObj = { ...newFieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal, ExpAmount: nAmt }
                        // SetFieldObj({ ...FieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
                        console.log("newFieldObj Expense entryform true =>", [{
                            "newFieldObj": newFieldObj,
                            "nAmt": nAmt,
                            "TmpVal": TmpVal
                        }]);
                    } else {
                        // console.log("namtnamt", nAmt);
                        let FooterKey = (dr?.FIELD53 == "F" ? dr?.FIELD13 : ('ED' + dr?.FIELD54))
                        newFieldObj = { ...newFieldObj, FIELD06: nAmt, [FooterKey]: TmpVal }
                        // console.log("newFieldObjnewfieldobj", newFieldObj);
                        // SetFieldObj({ ...FieldObj, FIELD07: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
                        console.log("newFieldObj Expense entryform false =>", [{
                            // "FooterKey":FooterKey,
                            "newFieldObj": newFieldObj,
                            "nAmt": nAmt,
                            "TmpVal": TmpVal,
                            "dr": dr
                        }])
                    }
                    return nAmt
                }
                else {
                    newFieldObj = { ...newFieldObj, ['ID' + dr?.FIELD54]: TmpVal }
                    // SetFieldObj({ ...FieldObj, ['ID' + dr?.FIELD54]: TmpVal })
                }
            } else {
                // dr.EXPAMT = 0;
                // oExp[dr.EXPAMTSRC] = dr.EXPAMT //(EXPAMTSRC - sourcefield name)
                // // console.log("oExp[dr.EXPAMTSRC]", oExp[dr.EXPAMTSRC]);
                // let newarray = [...DtExpTableField];
                // newarray[index] = { ...dr, EXPAMT: 0 }
                // // console.log("newarray", newarray);
                // setDtExpTableField(newarray)
            }

        })

        let FQty1 = (OSV?._FREEQTY == "N" ? false : true);
        let FQty2 = (oYear?.OSC?._GSTFREQTY == "N" ? false : true);
        let lFQtyOnGST = (FQty1 && FQty2 ? true : false);
        // let lForce = false;
        let YPara = (oYear?.OSC?._GR1RTTAX == "N" ? false : true)
        let lFCalc;
        if (lFQtyOnGST) {
            lFCalc = false
        } else if (YPara) {
            lFCalc = true
        } else {
            lFCalc = false
        }

        console.log("CalCFI run para=>", [{
            "FQty1": FQty1,
            "FQty2": FQty2,
            "lFQtyOnGST": lFQtyOnGST,
            "YPara": YPara,
            "lFItemwise": lFItemwise,
            "lItemWise1": lItemWise,
            "lItemWise2": !lItemWise,
            "lFCalc": lFCalc
        }]);

        // if (lItemWise) {
        //     setDtExpTableField(newarray);
        //     SetFieldObj(newFieldObj);
        // } else if (lFItemwise == true || lItemWise == false || lFCalc == true) { //testing mate "AND" ni balde "OR" condition karel 6e
        //     setDtExpTableField(newarray);
        //     SetFieldObj(newFieldObj);
        //     let para = { ...ExpenseObj, T41Obj: newFieldObj, dtExp: newarray, lItemWise: false, ProductAllData: accList["P_M21"] }
        //     let objectsArray = [...para?.T02Data]
        //     let updatedObjects = [];

        //     objectsArray.map((obj)=>{
        //         console.log('ExpenseObj?.T02Data 1', newarray);
        //         let updatedObject2 = CalCFI(para, obj); // Ensure each object is fully processed
        //         console.log('ExpenseObj?.T02Data 2', updatedObject2);
        //         updatedObjects.push(updatedObject2);
        //     })
        //     props.setT02Data(updatedObjects)
        //     console.log("ExpenseObj?.T02Data CalCFI", updatedObjects)
        //     // setDtExpTableField(newarray);
        //     // SetFieldObj(newFieldObj);
        //     //  updatedObjects;

        //     // processObjects(para, ExpenseObj?.T02Data).then((e) => console.log("ExpenseObj?.T02Data CalCFI", e))

        // } else {
            setDtExpTableField(newarray);
            SetFieldObj(newFieldObj);
        // }

    }

    const processObjects = async (para, objectsArray) => {
        let updatedObjects = [];

        for (let obj of objectsArray) {
            const updatedObject = await CalCFI(para, obj); // Ensure each object is fully processed
            updatedObjects.push(updatedObject);
        }

        return updatedObjects;
    };

    const GetSlab = (cM21F03, dDate, nRate = 0) => {

        if (cM21F03 == "") {
            return "";
        }
        console.log("cM21F03=>", cM21F03);
        //Unknown name
        let dTRM14 = [...dtM14DT];
        let DateTime;
        let OSC;
        //Unknown name end
        let sCode = "";
        let curCode = "";
        let sDate;
        let lRate = false;
        let DTM = STOD(dDate);
        let curDate = DateTime?.MinValue;
        // let dTRM14 = ODB.GetTable(oYear.TblYr+"M14", "", "","FIELD01 = '" + cM21F03 + "'"); // Unknown
        console.log("dtM14DT =>", dtM14DT);
        dTRM14 = dtM14DT?.filter((a) => a?.FIELD01 == cM21F03)

        console.log("dTRM14 =>", dTRM14);

        lRate = nRate != "" && OSC.GETL._GSTONRATE;
        if (dTRM14[0]["FIELD03"]) {
            curCode = dTRM14[0]["FIELD03"];
        }
        for (let nI = 0; nI < dTRM14.length; nI++) {
            if (STOD(dTRM14[0]["FIELD04"]) <= DTM) {
                if (lRate) {
                    if (nRate > dTRM14[0]["FIELD06"])
                        curCode = dTRM14[0]["FIELD03"];
                }
                else {
                    curCode = dTRM14[0]["FIELD03"];
                }
            }
        }
        return curCode;
    }

    const DTSeek = (data, dFilter, lBlank) => {
        let DR = data.filter(dFilter);
        return DR.length > 0 ? DR[0] : lBlank ? data : null
    }

    const STOD = (dDate) => {
        let StringSlice;
        if (dDate == "") {
            StringSlice = "";
        } else {
            StringSlice = dDate?.slice(0, 4) + "-" + dDate?.slice(4, 6) + "-" + dDate?.slice(6, 8)
        }
        return StringSlice
    }

    // useEffect(()=>{
    //     if(isExpense == true && valueSaveObj){
    //         console.log("Fieldobject field18=>",valueSaveObj["IA00000018"]);
    //     }
    // },[valueSaveObj])

    const RetTaxAcc = (DType, lEnable, ExpName = "", nPer = 0, cAcc = "", oExpEntry = null, lAdditional = false) => {
        console.log("RetTaxAcc function call");
        console.log("oExpEntry =>", oExpEntry);
        console.log("oExpEntry?.oExp[ExpName] =>", oExpEntry[ExpName]);

        //oExpEntry = Expense Object

        //Unknown name
        let cVType = OS11.S11F01;
        let oM21D = { ...ProductObj };
        let dr = [...M01D];
        let lCapGoods = (InvTypeDropDown.FIELD10 == "Y");
        let oT41D = { ...T41ObjSend };
        //  oExpEntry = {...FieldObj}
        //Unknown name end

        if (!oYear.OSC._VatSetup && !oYear.OSC._GSTSETUP) {
            return oExpEntry[ExpName];
        }

        // Example = Expence object dr["EXPSPSRC"] = "IA00000018"

        let cRet = "";
        let lSales = ["SS", "HS", "SR", "OS", "HC", "JZ", "QT", "RB"].includes(cVType);

        let nType = 0;
        let nFound = 0;//count how many sale/purchase a/c. exist in account master.
        if (ExpName == "") {
            if (!["TS", "TA", "STA", "CTA", "ITA", "ETA"].includes(DType)) {
                cRet = nPer ? nPer : 0
                // eval(nPer, 0);
            }
        } else {
            cRet = oExpEntry[ExpName];
        }
        // oM21D["FIELD20"] = GetSlab(oM21D["FIELD03"], oT41D["FIELD02"]);
        // let oM52 = new M52Table(oYear); // Unknown
        // let oM52 = [...dtM52DT]
        let cF02 = "";
        switch (cVType) {
            case "SS":
            case "HS":
            case "SR":
                cF02 = "S";
                break;
            case "JJ":
                cF02 = "J";
                break;
            case "JZ":
                cF02 = "Z";
                break;
            default:
                cF02 = "P";
                break;
        }
        let dtM01 = [...M01D];
        //let dtM52 = [...dtM52DT] // Unknown
        // let dtM52 = oM52.GetList("FIELD02='" + cF02 + "' AND FIELD03='" + oM21D["FIELD20"] + "' AND FIELD04='" + oT41D["FIELD13"] + "' AND FIELD05='" + oM21D["FIELD27"] + "'"); // Unknown

        let dtM52 = dtM52DT?.filter((a, i) => a?.FIELD02 == cF02 && a?.FIELD03 == oM21D["FIELD21"] && a?.FIELD04 == oT41D["FIELD13"] && a?.FIELD05 == oM21D["FIELD27"])

        console.log("RetTaxAcc dtM52 =>", dtM52);

        if (dtM52?.length > 0 && (["JJ", "JZ"].includes(cVType) ? DType == "TS" : true)) {
            console.log("dtM52 =>", dtM52, "#dtM52?.length > 0 =>", dtM52?.length > 0);
            let drM52 = dtM52[0];
            if (DType == "TS") {
                cRet = drM52["FIELD11"];
                if (ExpName != "") {
                    oExpEntry[ExpName] = cRet;
                    // nPer = MApp.pc.EvlStr(drM52["FIELD13"], "0");
                    nPer = drM52["FIELD13"] ? drM52["FIELD13"] : "0"
                    oExpEntry.lSaleAcc = false;
                }
            } else {

                if (!["JJ", "JZ"].includes(cVType)) {
                    switch (DType) {
                        case "TP":
                            //        IF FIELD13 == m.nPer And NOT _VPType == "T"
                            //    m.RetVal = FIELD13
                            //ELSE
                            //    m.RetVal = 0
                            //ENDIF
                            break;
                        case "STP": //   SGST %
                            cRet = drM52["FIELD07"];
                            break;
                        case "CTP": //   CGST %
                            cRet = drM52["FIELD06"];
                            break;
                        case "ITP": //   IGST %
                            cRet = drM52["FIELD08"];
                            break;
                        case "ETP": //   Cess %
                            cRet = drM52["FIELD09"];
                            break;
                        case "TA":
                            if (lAdditional) {
                                cRet = drM52["FIELD14"];
                                lEnable = false;
                            }
                            else {
                                cRet = drM52["FIELD12"];
                                lEnable = false;
                            }
                            break;
                        case "CTA":
                            cRet = drM52["FIELD12"];
                            lEnable = false;
                            break;
                        case "STA":
                            cRet = drM52["FIELD14"];
                            lEnable = false;
                            break;
                        case "ITA":
                            cRet = drM52["FIELD15"];
                            lEnable = false;
                            break;
                        case "ETA":
                            cRet = drM52["FIELD16"];
                            lEnable = false;
                            break;
                        default:
                            break;
                    }
                }
            }

            if (!["TP", "STP", "CTP", "ITP", "ETP"].includes(DType)) {
                if (cRet != "") {
                    console.log("");
                    let newcRet = (item) => item?.FIELD01 === cRet;
                    console.log("DTSeek 1 =>", newcRet);
                    if (DTSeek(dtM01, newcRet, false) == null) {
                        if (DType == "TS") {
                            oExpEntry.lSaleAcc = true;
                        } else {
                            lEnable = true;
                        }
                    }
                } else {
                    if (DType == "TS") {
                        oExpEntry.lSaleAcc = true;
                    }
                    else {
                        lEnable = true;
                    }
                }
            }
        } else {
            //  Record not found in PLUSM52, So give possible values
            let cAccCode = "";
            let PrvSlb = "";
            if (["STA", "CTA", "ITA", "ETA"].includes(DType)) {
                switch (DType) {
                    case "STA":
                        nType = 4;
                        break;
                    case "CTA":
                        nType = 2;
                        break;
                    case "ITA":
                        nType = 3;
                        break;
                    case "ETA":
                        nType = 1;
                        break;
                }

                cAccCode = oExpEntry["FIELD16"];
                PrvSlb = oExpEntry["FIELD56"];
                var drM01filter = (item) => item.FIELD01 === cAccCode;
                console.log("DTSeek 2 =>", drM01filter);
                let drM01 = DTSeek(dtM01, drM01filter);
                if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                    PrvSlb = drM01["FIELD20"];
                }
                nFound = 0;
                // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'G' AND FIELD13='" + nType.ToString() + "'");
                let drM01Arr = dtM01.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "G" && d.FIELD13 == nType)
                // foreach(dr in drM01Arr) // Unknown
                dr.map((d) => {
                    if (d in drM01Arr) {
                        if (["V", "C", "D"].includes(oExpEntry.VorC)) {
                            if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                                cRet = d["FIELD01"];
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        } else {
                            if (d["FIELD08"] == (lSales ? "O" : "I")) {
                                cRet = d["FIELD01"];
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        }
                    }
                })


                lEnable = nFound != 1;

            } else if (["STP", "CTP", "ITP", "ETP", "AETP"].includes(DType)) {
                // PrvSlb = MApp.pc.EvlStr(oExpEntry.oExp["FIELD56"], oM21D["FIELD20"]);
                PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"] ? oM21D["FIELD20"] : "")
                // let oM51 = new M51Table(oYear); Unknown
                // let dtM51 = oM51.GetList("FIELD01='" + PrvSlb + "'"); Unknown
                let dtM51 = dtM51DT?.filter((a) => a.FIELD01 == PrvSlb)
                let oM51;
                // let dtM51;
                if (dtM51 != null && dtM51?.length > 0) {
                    let drM51 = dtM51[0];
                    switch (DType) {
                        case "STP":
                            cRet = drM51["FIELD05"];
                            break;
                        case "CTP":
                            cRet = drM51["FIELD07"];
                            break;
                        case "ITP":
                            cRet = drM51["FIELD06"];
                            break;
                        case "ETP":
                            cRet = drM51["FIELD09"];
                            break;
                        case "AETP":
                            cRet = ((drM51["FIELD11"] ? drM51["FIELD11"] : "0") / ((drM51["FIELD12"] ? drM51["FIELD12"] : "1") == 0 ? 1 : (drM51["FIELD12"] ? drM51["FIELD12"] : "1")));
                            break;
                        default:
                            break;
                    }
                } else {
                    cRet = "0";
                }
            } else if (DType == "TA" && lAdditional) {
                cAccCode = oExpEntry["FIELD16"];
                PrvSlb = oExpEntry["FIELD56"];
                let FIELD01cAccCode = (item) => item?.FIELD01 == cAccCode;
                console.log("DTSeek 3 =>", FIELD01cAccCode);
                let drM01 = DTSeek(dtM01, FIELD01cAccCode);
                if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                    PrvSlb = drM01["FIELD20"];
                }
                // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'V'AND FIELD13='A'");
                let drM01Arr = dtM01?.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "V" && d.FIELD13 == "A")
                nFound = 0;
                // foreach(DataRow dr in drM01Arr)
                dr.map((d) => {
                    if (d in drM01Arr) {
                        if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                            cRet = d["FIELD01"];
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }
                        }
                    }
                })

                lEnable = nFound != 1;

            } else if (DType == "TA" && !lAdditional) {
                if (["V", "D"].includes(oExpEntry.VorC)) {
                    cAccCode = oExpEntry["FIELD16"];
                    PrvSlb = oExpEntry["FIELD56"];
                    let FIELD01cAccCode = (item) => item?.FIELD01 == cAccCode;
                    console.log("DTSeek 4 =>", FIELD01cAccCode)
                    let drM01 = DTSeek(dtM01, FIELD01cAccCode);
                    if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                        PrvSlb = drM01["FIELD20"];
                    }
                    // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'V' AND FIELD13<>'A'");
                    let drM01Arr = dtM01?.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "V" && d.FIELD13 == "A")
                    nFound = 0;
                    // foreach(DataRow dr in drM01Arr)
                    dr.map((d) => {
                        if (d in drM01Arr) {
                            if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                                cRet = d["FIELD01"];
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        }
                    })

                    lEnable = nFound != 1;
                } else {
                    if (cRet == "" && cAcc != "") {
                        cRet = cAcc;
                    }
                }
            } else if (DType == "TP") {
                cRet = cRet != "" ? cRet : nPer;
            } else if (DType == "TS") {
                let SPStatus = "";
                let PrTAcc = "";
                let cEffect = "";
                if (oExpEntry.VorC == "V" || lCapGoods) {
                    PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"]);//Vat Slab
                    PrTAcc = oM21D["FIELD77"];    // Trading A/c.
                    cAccCode = (oExpEntry[ExpName] ? oExpEntry[ExpName] : "");
                    SPStatus = lCapGoods ? "FA" : lSales ? "TS" : "TP";
                    cEffect = lCapGoods ? "B" : "T";

                    // Seek old account code. If Vatslab and trading a/c. is not same then make account code blank so that it not assigned to field16 again
                    let FIELD01cAccCode = (item) => item?.FIELD01 == cAccCode;
                    console.log("DTSeek 5 =>", FIELD01cAccCode)
                    let drM01 = DTSeek(dtM01, FIELD01cAccCode);
                    if (drM01 != null) {
                        cAccCode = (drM01["FIELD20"] == PrvSlb && drM01["FIELD18"] == PrTAcc) ? cAccCode : "";
                    }
                    // let drM01Arr = dtM01.Select("FIELD07='" + SPStatus + "' AND FIELD04 = '" + cEffect + "'");
                    let drM01Arr = dtM01.filter((d) => d.FIELD07 == SPStatus && d.FIELD04 == cEffect)
                    nFound = 0;
                    // foreach(DataRow dr in drM01Arr)
                    dr.map((d) => {
                        if (d in drM01Arr) {
                            if (d["FIELD20"] == PrvSlb && d["FIELD18"] == PrTAcc) {
                                if (ExpName == "") {
                                    cRet = d["FIELD01"];
                                } else {
                                    oExpEntry[ExpName] = d["FIELD01"];
                                }
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }

                            }
                        }
                    })

                    //If account code is not empty then assign that code to keep old account selected.
                    if (cAccCode != "") {
                        oExpEntry[ExpName] = cAccCode;
                    }
                    oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
                } else if (["3", "4", "T", "E", "X", "I", "U", "O", "M", "S", "F", "N", "8", "9", "K", "G"].includes(oExpEntry.VorC)) {
                    nFound = 0;
                    SPStatus = lCapGoods ? "FA" : (["JJ", "JZ"].includes(cVType)) ? "J" : lSales ? "TS" : "TP";
                    cEffect = lCapGoods ? "B" : "T";
                    let cF22 = "";
                    if (["JJ", "JZ"].includes(cVType)) {
                        cF22 = "V";
                    } else if (["X", "I", "M", "S", "F", "G"].includes(oExpEntry.VorC)) {
                        cF22 = "4";
                    } else if (["N", "K"].includes(oExpEntry.VorC)) {
                        cF22 = "3";
                    } else {
                        cF22 = oExpEntry.VorC;
                    }
                    // let drM01Arr = dtM01.Select("FIELD07='" + SPStatus + "' AND FIELD22 = '" + cF22 + "'");
                    let drM01Arr = dtM01.filter((d) => d.FIELD07 == SPStatus && d.FIELD22 == cF22)
                    nFound = 0;

                    // foreach(DataRow dr in drM01Arr)
                    dr.map((d) => {
                        if (d in drM01Arr) {
                            if (cRet == "") {
                                if (ExpName != "")
                                    oExpEntry[ExpName] = d["FIELD01"];
                                cRet = d["FIELD01"];
                            }
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }
                        }
                    })

                    // IF Invoice Type is of Capital Goods then Ask Sale/Purchase A/c.                        
                    oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
                } else if (["C", "D"].includes(oExpEntry.VorC)) {// Entry of CST/CST (Other) type
                    PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"]);//Vat Slab
                    PrTAcc = oM21D["FIELD77"];                // Trading A/c.
                    SPStatus = lCapGoods ? "FA" : lSales ? "TS" : "TP"; // Sales/Purchase A/c. Status
                    cEffect = lCapGoods ? "B" : "T";
                    let nPerc = 0;
                    let nAddPerc = 0;
                    // let oM51 = new M51Table(oYear);
                    // let dtM51 = oM51.GetList("FIELD01='" + PrvSlb + "'");
                    let oM51;
                    let dtM51 = dtM51DT?.filter((a) => a?.FIELD01 == PrvSlb)
                    if (dtM51 != null && dtM51.length > 0) {
                        nPerc = (dtM51[0]["FIELD05"] ? dtM51[0]["FIELD05"] : "0");
                        nAddPerc = (dtM51[0]["FIELD07"] ? dtM51[0]["FIELD07"] : "0");
                    }
                    // dtM01 = TM01.GetListByQry("SELECT * FROM " + oYear.TblYr + "M01 M01 INER JOIN " + oYear.TblYr + "M51 M51 on M01.FIELD20=M51.FIELD01 WHERE M01.FIELD07='" + SPStatus + "' AND M01.FIELD04 ='" + cEffect + "'"); // Unknown
                    // foreach(DataRow dr in dtM01.Rows)
                    dr.map((d) => {
                        if (d in dtM01) {
                            if (d["FIELD03"] == oExpEntry.VorC && dr["FIELD18"] == PrTAcc && (oExpEntry.VorC == "D" ? (d["M51F05"] ? d["M51F05"] : "0") == nPer : (d["FIELD07"] ? d["FIELD07"] : "0") == nAddPerc)) {
                                if (ExpName == "") {
                                    cRet = d["FIELD01"];
                                } else {
                                    oExpEntry[ExpName] = dr["FIELD01"];
                                }
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        }
                    })

                    //If only one account is found, then donot take sale/purchase a/c. input in itemwise expense entry.
                    //IF Invoice Type is of Capital Goods then Ask Sale/Purchase A/c.
                    oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
                }
            }
        }
        console.log("RetTaxAcc cRet=>", cRet);
        return cRet;
    }

    const AssignItemDefaultnon = async (ItemCode, lNoRecalc, cItemExp, cMSG) => {
        console.log("AssignItemDefault Function Call");

        console.log("AssignItemDefault ItemCode =>", ItemCode);

        //Unknown name
        let dtExp = [...expenseArr];
        let cVouId = OS11.S11F23;
        let lEdited = false;
        let oExp = { ...FieldObj };
        let SpAcc = oExp["FIELD05"] == "" ? "" : oExp["FIELD05"];
        let lEnable;
        let VorC = valueSaveObj?.FIELD45;
        let cVType = OS11?.S11F01;
        let TotalAmt = formValue;

        //Unknown name end
        let newDtExpe = [...expenseArr]
        //  console.log("new Dtexp =>");

        if (ItemCode && dtExp != null && dtExp?.length > 0) {

            console.log("AssignItemDefault if condition when itemCode null");

            // console.log("ItemCode =>", ItemCode, "dtExp =>", dtExp);

            // dtExp.DefaultView.RowFilter = "EXPACCDISP=1 OR EXPPERDISP=1";
            dtExp = expenseArr?.filter((a) => a.EXPACCDISP == 1 || a.EXPPERDISP == 1)


            let dr = null;
            let lObjItemwise = false
            let lFItemwise = false;
            let lFlag = true;
            let lSales = false;
            let lFixed = false;
            let cVCO = "";
            let cAcType = "";
            let cType = "";
            let cAccFld = "";
            let cPerFld = "";
            //    let oM46 = new M46Table(oYear); //Unknown
            // let dtP = oM46.GetItemDet(oVou.oM21D, cVouId); //ItemExpense(ItemCode); // Unknown
            let oM46 = [...dtM46DT]
            let dtP = [...dtM46DT];
            // let dtM46 = [...dtM46DT]
            // oM46 = null;
            let drM46 = null;
            let M46F13 = "";
            let c52Flt = "";
            // for ( i = 0; i < dtExp.DefaultView.Count; i++)
            dtExp?.map((d, ind) => {
                dr = { ...d };

                console.log('dr["FIELD03"]=>', d);
                lObjItemwise = dr["FIELD53"] == "I";
                lFItemwise = dr["FIELD53"] == "D";
                cAcType = dr["FIELD05"];
                lSales = cAcType == "S"; // Decide if Sales Account
                lFixed = cAcType == "F";
                cVCO = dr["FIELD03"];
                cType = dr["FIELD06"] == "M" ? "F" : dr["FIELD06"];
                if (!lObjItemwise && lFItemwise) {
                    // continue;
                    return;
                }
                lFlag = true;

                // console.log("dtP=>", dtP, 'cVCO.includes("E", "T", "X")=>', cVCO.includes("E", "T", "X"));

                if (dtP != null && dtP?.length > 0 && (["E", "T", "X"].includes(cVCO))) {

                    // for (int j = 0; j < dtP.Rows.Count; j++)
                    dtP.map((e) => {
                        // dtM46.DefaultView.RowFilter = "FIELD02='" + dtP.Rows[0]["CODE"].ToString() + "' AND FIELD12='" + dr["FIELD54"].ToString() + "' AND FIELD10='" + cVouId + "'";

                        console.log("ItemCode =>", ItemCode);

                        let dtM46 = dtM46DT?.filter((f) => f?.FIELD02 == ItemCode && f?.FIELD12 == dr["FIELD54"] && f?.FIELD10 == cVouId);
                        console.log("dtM46 after filter==>", dtM46);
                        if (dtM46?.length > 0) {
                            drM46 = [...dtM46];
                            if (!lEdited) {
                                M46F13 = drM46["FIELD13"];
                                if ((lFixed || !lSales) && M46F13 != "")
                                    oExp["IA" + dr["FIELD54"]] = M46F13;
                                else
                                    oExp["IA" + dr["FIELD54"]] = SpAcc;
                            }

                            // If Calculation Type is not fixed or Round and Percentage is empty and new entry 
                            // then make percentage textbox visible and assign calculation type to quantity 
                            // so that if from setup calculation type is qty and from itemwise Exp(master)
                            // it is changed then calculation on Qty is prevented.


                            if (!["F", "R"].includes(drM46[0]["FIELD03"])) { //Unknown
                                console.log('condition 1');
                                dr["FIELD06"] = drM46[0]["FIELD03"];
                                dr["EXPPERREQ"] = 1;
                                dr["EXPPERDISP"] = 1;
                            }
                            console.log('drM46["FIELD03"]=>', drM46[0]["FIELD03"]);
                            if (drM46["FIELD03"] == "C") { //Unknown
                                console.log('condition 2');
                                dr["FIELD06"] = drM46[0]["FIELD03"];
                            }
                            if ((dr["EXPPERC"] == "" || dr["EXPPERC"] == "0") && !lEdited) {
                                console.log('condition 3');
                                dr["EXPPERC"] = drM46[0]["FIELD04"];
                                oExp[dr["EXPPERSRC"]] = dr["EXPPERC"];// Update Dictionary so get default values in save without Editing Expense
                            }

                            //If Fixed Expense and amount is empty and new entry then make percentage textbox invisible.
                            if (["F", "R"].includes(drM46["FIELD03"]) || cType == "K") {
                                console.log('condition 4');

                                dr["EXPPERREQ"] = 1;
                                dr["EXPPERDISP"] = 0;
                                if ((dr["EXPPERC"] == "" || dr["EXPPERC"] == "0") && !lEdited) {
                                    dr["EXPAMT"] = drM46[0]["FIELD04"];
                                    oExp[dr["EXPAMTSRC"]] = dr["EXPAMT"];    // Update Dictionary so get default values in save without Editing Expense
                                }
                            }

                            lFlag = false;
                            // break;
                        }
                        // dtM46.DefaultView.RowFilter = ""; // Unknown
                    })
                }

                if (lFlag) {
                    console.log("lFlag=>", lFlag);
                    cAccFld = "IA" + dr["FIELD54"];
                    cPerFld = "IP" + dr["FIELD54"];
                    // If Item Contain It's Own Account Effect, Otherwise Take From Invoice Type.
                    // If New Entry and Expense is not VAT / CST / CST(Other) or Expense is VAT / CST / CST(Other)
                    // and Account is empty then assign account code
                    if (lFixed &&
                        (!lEdited || (!["V", "C", "D", "3", "4"].includes(cVCO)))
                        ||
                        (!lEdited || (["V", "C", "D", "3", "4"].includes(cVCO))
                            && oExp["IA" + dr["FIELD54"]] == "")) {
                        oExp[cAccFld] = dr["EXPSPACC"];
                    }
                    if (!lFixed && !lSales && oExp["IA" + dr["FIELD54"]] == "") {
                        oExp[cAccFld] = dr["EXPSPACC"];
                    }

                    // console.log('cVCO?.includes("3", "4")=>', "3".includes(cVCO) || "4"?.includes(cVCO));
                    if (["3", "4"].includes(cVCO)) {
                        // console.log('cVCO?.includes("3")', "3".includes(cVCO) || "4"?.includes(cVCO));
                        lEnable = false;
                        if (cVouId == "SS" && false)// true is (This.oYear.lBlwThre OR This.oYear.lCompound)----Pending
                        {
                            // Do Nothing
                        } else if ((false && cVouId == "PP") || (["K", "G"].includes(VorC)))// true id (This.oYear.lCompound)--- Pending
                        {
                            switch (cAcType) {
                                case "B":   //SGST Account
                                    oExp[cPerFld] = RetTaxAcc("STP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                case "D":   //CGST Account
                                    oExp[cPerFld] = RetTaxAcc("CTP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                case "I":   //IGST Account
                                    oExp[cPerFld] = RetTaxAcc("ITP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                default:
                                    break;
                            }
                            if (cAcType == "J" && cType != "K") {
                                oExp[cPerFld] = RetTaxAcc("ETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                            }
                            if (cType == "K") {
                                dr["EXPADDICESS"] = RetTaxAcc("AETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                            }
                            oExp[cAccFld] = RetTaxAcc("TS", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                        } else {
                            switch (cAcType) {
                                case "B":   //SGST Account
                                    oExp[cAccFld] = RetTaxAcc("STA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    oExp[cPerFld] = RetTaxAcc("STP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                case "D":   //CGST Account
                                    oExp[cAccFld] = RetTaxAcc("CTA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    oExp[cPerFld] = RetTaxAcc("CTP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                case "I":   //IGST Account
                                    oExp[cAccFld] = RetTaxAcc("ITA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    oExp[cPerFld] = RetTaxAcc("ITP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                    break;
                                default:
                                    break;
                            }
                            if (cAcType == "J" && cType != "K") {
                                oExp[cAccFld] = RetTaxAcc("ETA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                                oExp[cPerFld] = RetTaxAcc("ETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                            }
                            if (cType == "K") {
                                dr["EXPADDICESS"] = RetTaxAcc("AETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false);
                            }
                        }
                    }

                    //if Type is Expense
                    if (["E", "T", "X"].includes(cVCO)) {
                        if (["F", "R"].includes(cType)) {
                            dr["EXPPERREQ"] = 0;
                        }
                        else {
                            dr["EXPPERREQ"] = 1;
                            dr["EXPPERDISP"] = 1;
                        }
                    }

                    if (cVCO == "V" && lFixed) {
                        // c52Flt = "FIELD02='" + (MApp.pc.InList(oVou.cVType, "SS", "HS", "SR") ? "S" : "P") + "'" +
                        //     " AND FIELD03='" + oVou.oM21D["M21F20"].ToString() + "'" +
                        //     " AND FIELD04='" + (oExp[lItemWise ? "FIELD13" : "FIELD13"].ToString()) + "'" +
                        //     " AND FIELD05='" + oVou.oM21D["M21F77"].ToString() + "'" +
                        //     " AND ISNULL(FIELD12,'')<>''";

                        // c52Flt = (FIELD02 == (cVType.includes("SS", "HS", "SR") ? "S" : "P") && FIELD03 == oVou.oM21D["M21F20"] && FIELD04 == (oExp[lItemWise ? "FIELD13" : "FIELD13"]) && FIELD05 == oVou.oM21D["M21F77"] && (FIELD12 != null || FIELD12 != "")) // Unknown

                        // let dtM52 = new M52Table(oYear).GetList(c52Flt, "", "FIELD02,FIELD03,FIELD04,FIELD05"); // Unknown


                        let dtM52 = [...dtM52DT];
                        if (dtM52 != null && dtM52?.length > 0) {
                            oExp[cAccFld] = dtM52[0][cType == "A" ? "FIELD14" : "FIELD12"];
                        }
                    }

                    //If Item Does Not Contain It's Own % Value, Then Take From General Expense Definition
                    if ((!lEdited || (["V", "C", "D", "3", "4"].includes(cVCO))) && dr["EXPPERREQ"] == "1" && (dr["EXPPERC"] == "" || dr["EXPPERC"] == "0")) {
                        if (oExp[dr["EXPPERSRC"]] == "" || oExp[dr["EXPPERSRC"]] == "0") {
                            dr["EXPPERC"] = dr["FIELD07"];
                        } else {
                            dr["EXPPERC"] = oExp[dr["EXPPERSRC"]];
                        }
                        oExp[dr["EXPPERSRC"]] = dr["EXPPERC"];// Update Dictionary so get default values in save without Editing Expense
                    }

                    if (!(lEdited || ["V", "C", "D", "3", "4"].includes(cVCO))
                        && dr["EXPAMT"] == ""
                        && dr["EXPPERREQ"] == "0" && !lFItemwise) {
                        dr["EXPAMT"] = (oExp[dr["EXPAMTSRC"]] ? oExp[dr["EXPAMTSRC"]] : dr["FIELD07"]);
                        oExp[dr["EXPAMTSRC"]] = dr["EXPAMT"];    // Update Dictionary so get default values in save without Editing Expense
                    }
                }
                console.log("AssignItemDefault dr =>", dr);
                newDtExpe[ind] = { ...dr }
                // Recalc(FieldObj, ind, isExpense, dr)
            })

            console.log("AssignItemDefault dtExp =>", dtExp);

            console.log("AssignItemDefault oExp =>", oExp);
            console.log("AssignItemDefault newDtExpe =>", newDtExpe);
            Promise.resolve().then(() => setDtExpTableField(newDtExpe)).then(() => SetFieldObj(oExp))


            // setDtExpTableField(newDtExpe)
            // SetFieldObj(oExp)

            // dtExp.DefaultView.RowFilter = ""; // Unknown
            // dtExp.AcceptChanges(); // Unknown

            // if (!lNoRecalc) {
            //     TotalAmtAssign(TotalAmt, cItemExp, cMSG, false, false, !lNoRecalc);
            // }
            // GC.Collect(); // Unknown
        }
    }

    const TotalAmtAssign = (nTotAmount, cItemExp, cMSG, lForce = false, lfrq = false, lFromT02 = false) => {
        console.log("TotalAmtAssign Function call");
        //unknown name
        let TotalAmt = formValue;
        let lVFQty = OSV._FREEQTY;
        let lFQtyOnGST = lVFQty && oYear.OSC._GSTFREQTY;
        let lItemWise = isExpense;
        let oVou;
        //Unknown name end
        if (!(TotalAmt == nTotAmount || lFQtyOnGST || nTotAmount == 0) || lFromT02 || lForce) {
            TotalAmt = nTotAmount;
            let lFromTotal = true;

            if (lItemWise) {
                cItemExp = TranForm(TotalAmt * (oVou.S11Dic["S11F21"] == "C" ? -1 : 1));
            }

            if (lFQtyOnGST) {
                Recalc(0, lfrq, cMSG);
            } else if (oYear.OSC._GR1RTTAX) {
                Recalc(0, true, cMSG);
            } else {
                Recalc(0, lForce, cMSG);
            }
        }
    }

    const formatString = (template, value) => {
        return template.replace("/{0}/g", value);
    };

    const TranForm = (nAmt, cFormat = "", lCRDB = true) => {
        // let string;
        let cTranAmt = "";
        let cFormatVal = (cFormat != "" ? cFormat : "n");

        if (lCRDB) {
            cTranAmt = formatString(`{0:${cFormatVal}}`, Math.abs(nAmt)) + (nAmt == 0 ? "   " : nAmt > 0 ? " CR" : nAmt < 0 ? " DB" : "");
        } else {
            cTranAmt = formatString(`{0:${cFormatVal}}`, nAmt);
        }
        return cTranAmt;
    }

    // useEffect(() => {
    //     if ((FieldObj) &&(!isExpense)) {
    //         SaveExpenseDetail()
    //     }
    // }, [isExpense])

    const SaveExpenseDetail = async () => {
        Promise.resolve(onChange(FieldObj)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    const nextIndFunction = (ind, e) => {
        // if (e == "one") {
        //     const nextInd = ind;
        //     const nextInput = document.getElementById(`arr2_${nextInd}`)
        //     if (nextInput) {
        //         nextInput.focus()
        //     }
        // }
        const nextInd = ind + 1;
        console.log("nextInd==>", nextInd);
        if (nextInd < DtExpTableField?.length) {
            const nextInput = document.getElementById(`arr1_${nextInd}`)
            if (nextInput) {
                nextInput.focus()
            }
            else {
                return nextIndFunction(nextInd)
            }
        }
    }

    // const taxAccFunction=()=>{
    //     SetFieldObj(prevFieldObj => {
    //         // Use functional update to avoid overwriting previous state
    //         return DtExpTableField?.reduce((updatedFieldObj, d) => {
    //             // Only update if the corresponding IA key exists in FieldObj
    //             if (d?.FIELD54) {
    //                 return { ...updatedFieldObj, ['EA' + d.FIELD54]: SendselectedTaxAcc };
    //             } else {
    //                 return updatedFieldObj;
    //             }
    //         }, { ...prevFieldObj, FIELD16: SendselectedTaxAcc }); // Spread previous state to avoid modifying it directly
    //     })
    // }

    useEffect(() => {
        if (isExpense && ExpenseObj && valueSaveObj && expenseArr) {

            console.log("valueSaveObj", [{
                "valueSaveObj": valueSaveObj,
                'accList["P_M21"]': accList["P_M21"]
            }]);

            let productObjFind = {}
            let newObject = {}
            let dt;

            new Promise((resolve, reject) => {
                accList["P_M21"]?.map((p, i) => {
                    if (p.FIELD01 == valueSaveObj?.FIELD03) {
                        productObjFind = { ...p }
                    }
                })
                resolve(productObjFind)
            }).then((PObject) => {

                // console.log("PObject", PObject);
                let ExpArr = [...expenseArr]

                newObject = {
                    ...ExpenseObj,
                    dtExp_I: ExpArr?.map((e) => {
                        let updatedObj = { ...e };
                        Object.keys(valueSaveObj).map((v) => {
                            if (v == ("IP" + e?.FIELD54)) {
                                updatedObj.EXPPERC = valueSaveObj[v]
                            } else if (v == ("ID" + e?.FIELD54)) {
                                updatedObj.EXPAMT = valueSaveObj[v]
                            }
                        })
                        return updatedObj
                    }),
                    ItemCode: PObject?.FIELD03,
                    selectedProductObj: PObject,
                    T02Obj: valueSaveObj
                }

                console.log("UseEffect Itemwise Expense Open =>", [{
                    "newObject": newObject
                }]);
                return newObject
            }).then((newobj) => {
                console.log("newobj======>", newobj);
                dt = AssignItemDefault(newobj);
                return { dt, newobj }
            }).then((expdt) => {
                // let a = expdt.dt.dtExpArr;
                // let b = expdt.dt.T02Object;
                // let c = expdt.newobj
                // console.log("AssignItemDefault response =>", [{ "expdt.T02Object": expdt.T02Object,"expdt":expdt.dtExpArr }]);
                console.log("expdt =>", expdt);
                return RecalcFun(expdt?.dt?.T02Object, "", true, "", { ...expdt.newobj, dtExp_I: expdt.dt.dtExpArr })
            }).then((res) => {
                console.log("Response RecalcFun =>", res)
                Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
            })

            console.table("isExpense,ExpenseObj,valueSaveObj =>", [{
                "isExpense": isExpense,
                "ExpenseObj": ExpenseObj,
                "valueSaveObj": valueSaveObj,
                // "newObject": newObject
            }]);

        }
    }, [isExpense, ExpenseObj, valueSaveObj, expenseArr])

    console.table("FieldObj & DtExpTableField =>", [{
        "FieldObj": FieldObj,
        "DtExpTableField": DtExpTableField
    }])

    useEffect(() => {
        console.log("FooterExpFlag if out =>", FooterExpFlag);
        if (FooterExpFlag) {
            console.log("FooterExpFlag if in =>", [{
                "FooterExpFlag": FooterExpFlag,
                "FieldObj": FieldObj,
                "DtExpTableField": DtExpTableField
            }]);
            setTimeout(() => {
                SaveExpenseDetail()
                setFooterExpFlag(false)
            }, 500)
        }
    }, [FooterExpFlag])



    return (<>
        {
            isExpense == true && AccNameisLoading["P_M21"] ? GlobalClass.RenderLoader('dots') : <>

                <Grid gutter={4}>

                    <>
                        {
                            isExpense == true &&
                            <>
                                <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                    <Grid.Col span={3} ></Grid.Col>
                                    <Grid.Col span={3}>

                                    </Grid.Col>
                                    <Grid.Col span={1}>
                                    </Grid.Col>
                                    <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                                        Item Amount:
                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        {
                                            isExpense == true ? valueSaveObj?.FIELD08 : valueSaveObj?.FIELD07
                                        }
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }}>
                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Sales A/C.</Grid.Col>
                                    <Grid.Col span={3}>
                                        <PLDataGrid
                                            width={'500px'}
                                            // disabled={FieldObj?.FIELD16 == "" ? false : true}
                                            value={FieldObj?.FIELD16}
                                            // TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('TP','JE','JP','JT')"}
                                            valexpr="FIELD01"
                                            dispexpr="FIELD02"
                                            TmData={ExpenseData?.M01SP}
                                            data={ExpenseData?.M01SPDT?.jData}
                                            setEdit={(e) => {
                                                // console.log(e)
                                                // Promise.resolve()
                                                // SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
                                                if (isExpense) {
                                                    // let FieldObjtemp = { ...FieldObj}
                                                    // DtExpTableField?.map((d, ind) => {
                                                    //     FieldObjtemp = { ...FieldObj, ['IA' + d?.FIELD54]: e?.FIELD01 }
                                                    //     // SetFieldObj({ ...FieldObj, ['IA' + d?.FIELD54]:FieldObj?.FIELD16 })
                                                    // })
                                                    // SetFieldObj({ ...FieldObjtemp, FIELD16: e?.FIELD01 })

                                                    SetFieldObj(prevFieldObj => {
                                                        // Use functional update to avoid overwriting previous state
                                                        return DtExpTableField?.reduce((updatedFieldObj, d) => {
                                                            // Only update if the corresponding IA key exists in FieldObj
                                                            if (d?.FIELD54) {
                                                                return { ...updatedFieldObj, ['IA' + d.FIELD54]: e?.FIELD01 };
                                                            } else {
                                                                return updatedFieldObj;
                                                            }
                                                        }, { ...prevFieldObj, FIELD16: e?.FIELD01 }); // Spread previous state to avoid modifying it directly
                                                    });

                                                } else {
                                                    SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
                                                }

                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={1}>
                                    </Grid.Col>
                                    <Grid.Col span={2}>
                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                    </Grid.Col>
                                </Grid.Col>
                            </>
                        }
                    </>
                    {
                        DtExpTableField?.map((d, ind) => {
                            // console.log("d", d);
                            return (<>

                                <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }} key={ind}> {/* Account name */}
                                    <Grid.Col span={3} style={{ fontSize: 12 }}>{d?.FIELD02}</Grid.Col>
                                    <Grid.Col span={3}>
                                        <PLDataGrid
                                            width={'500px'}
                                            disabled={d?.EXPACCED}
                                            // disabled={d?.FIELD05 == "V" ? false : true}
                                            // value={d?.FIELD04}
                                            value={isExpense ? (d?.FIELD04 == "" ? FieldObj?.['IA' + d?.FIELD54] : d?.FIELD04) : (d?.FIELD04 == "" ? FieldObj?.['EA' + d?.FIELD54] : d?.FIELD04)}
                                            // value={isExpense ? (d?.FIELD04 == "" ? FieldObj?.['IA' + d?.FIELD54] : d?.FIELD04) : (d?.FIELD53 == "F" ? d?.FIELD28 : ('EA' + d?.FIELD54))}
                                            valexpr="FIELD01"
                                            dispexpr="FIELD02"
                                            TmData={ExpenseData?.M01SP}
                                            data={ExpenseData?.M01SPDT?.jData}
                                            // TmData={M01F}
                                            // data={M01D}
                                            // TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('TP','JE','JP','JT')"}
                                            setEdit={(e) => {
                                                // console.log(e)
                                                if (isExpense == true) {
                                                    SetFieldObj({ ...FieldObj, ['IA' + d?.FIELD54]: e?.FIELD01 })
                                                } else {
                                                    let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD28 : ('EA' + d?.FIELD54))
                                                    SetFieldObj({ ...FieldObj, [FooterKey]: e?.FIELD01 })
                                                }
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={1}>
                                        {
                                            d?.FIELD14 === 'D' && <Text style={{ fontSize: 12 }}>(-)</Text>
                                        }
                                    </Grid.Col>
                                    <Grid.Col span={2}> {/* Percentage */}
                                        {
                                            d?.EXPPERDISP == "1" && <PLNumberBox
                                                // disabled={d?.EXPPERED == "0"}
                                                decimalSeparator={true}
                                                value={parseInt(d?.EXPPERC)}
                                                // value={isExpense ? (FieldObj?.['IP' + d?.FIELD54]) : (d?.FIELD53 == "F" ? FieldObj?.[d?.FIELD23] : FieldObj?.['EP' + d?.FIELD54])}
                                                setNumber={async (e) => {

                                                    if (isExpense == false) {
                                                        // SaveExpenseDetail()
                                                        // console.log("FieldObj focus out=>",FieldObj)
                                                        Promise.resolve(Recalc(FieldObj, ind, isExpense, d)).then(() => {
                                                            setTimeout(() => {
                                                                setFooterExpFlag(true)
                                                            }, 200)
                                                        })
                                                    } else {
                                                        Recalc(FieldObj, ind, isExpense, d)
                                                    }
                                                }}
                                                id={`arr1_${ind}`}

                                                // onKeyDown={(e) => {
                                                //     if (e.key == "Tab") {
                                                //         e.preventDefault();
                                                //         Promise.resolve(Recalc(FieldObj, ind, isExpense, d)).then(() => {
                                                //             nextIndFunction(ind, "one")
                                                //         })
                                                //     }
                                                // }}

                                                setEdit={(e) => {
                                                    // console.log("d?.FIELD54", d?.FIELD54);

                                                    if (isExpense == true) {
                                                        SetFieldObj({ ...FieldObj, ['IP' + d?.FIELD54]: e })
                                                    } else {
                                                        let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD23 : ('EP' + d?.FIELD54))
                                                        SetFieldObj({ ...FieldObj, [FooterKey]: e })
                                                    }
                                                    // setDtExpTableField([...DtExpTableField,{...d,EXPPERC:e}])

                                                    let newarray = [...DtExpTableField];
                                                    newarray[ind] = { ...d, EXPPERC: e }
                                                    setDtExpTableField(newarray)
                                                }}
                                            />
                                        }
                                    </Grid.Col>
                                    <Grid.Col span={3}> {/* Amount */}
                                        <PLNumberBox
                                            disabled={d?.EXPAMTED == "0"}
                                            // decimalSeparator={true}
                                            setNumber={async (e) => {
                                                // Promise.resolve(Recalc(FieldObj, ind, isExpense, d)).then(()=>{
                                                //     if (isExpense == false) {
                                                //         SaveExpenseDetail()
                                                //         // console.log("FieldObj focus out=>",FieldObj)
                                                //     }
                                                // })

                                                console.log("onblur", e);

                                                if (isExpense == false) {
                                                    // SaveExpenseDetail()
                                                    // console.log("FieldObj focus out=>",FieldObj)
                                                    Promise.resolve(Recalc(FieldObj, ind, isExpense, d)).then(() => {
                                                        setTimeout(() => {
                                                            setFooterExpFlag(true)
                                                        }, 200)
                                                    })
                                                } else {
                                                    Recalc(FieldObj, ind, isExpense, d)
                                                }
                                            }}
                                            value={d?.EXPAMT}
                                            // value={isExpense ? FieldObj?.['ID' + d?.FIELD54] :(d?.FIELD53 == "F" ? FieldObj?.[d?.FIELD13] : FieldObj?.['ED' + d?.FIELD54])}
                                            id={`arr2_${ind}`}
                                            setEdit={(e) => {
                                                if (isExpense == true) {
                                                    SetFieldObj({ ...FieldObj, ['ID' + d?.FIELD54]: e })
                                                } else {
                                                    let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD13 : ('ED' + d?.FIELD54))
                                                    SetFieldObj({ ...FieldObj, [FooterKey]: e })
                                                }
                                                let newarray = [...DtExpTableField];
                                                newarray[ind] = { ...d, EXPAMT: e }
                                                setDtExpTableField(newarray)
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Col></>)
                        })
                    }

                    {
                        isExpense == true &&
                        <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={3} ></Grid.Col>
                            <Grid.Col span={3}>

                            </Grid.Col>
                            <Grid.Col span={1}>
                            </Grid.Col>
                            <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                                Total Amount:
                            </Grid.Col>
                            <Grid.Col span={3}>
                                {/* {isNaN(FieldObj?.FIELD90) ? "" : FieldObj?.FIELD90} */}

                                {FieldObj?.FIELD90}
                            </Grid.Col>
                        </Grid.Col>
                    }
                </Grid>
                {
                    isExpense == true &&
                    <Grid gutter={4}>
                        <Grid.Col span={12}>
                            <Group spacing={"sm"} position='center'>
                                <Button
                                    id='submitExpense'
                                    size='sm'
                                    onKeyDown={(e) => {
                                        console.log("e.preventDefault()=>", e);
                                        e.preventDefault();
                                        if (e.key == "Enter" || e.key == " ") {
                                            // field06
                                            SaveExpenseDetail()
                                        }
                                    }}
                                    onClick={() => SaveExpenseDetail()}
                                >
                                    Ok
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                }
            </>
        }

    </>)
}

export default ExpenseEntryForm