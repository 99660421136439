import { Button, FileInput, Grid, Group, Modal, Paper, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { GetEInvoice } from '../../utils/TransactionSlices/EInvoiceSlice';
import GlobalClass from '../../utils/GlobalClass';
import EWayBill from './EWayBill';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';
import { GetEWayBill } from '../../utils/TransactionSlices/EWayBillSlice';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import EForm_A from '../../Utility/Files/einvoice/EForm_A.xlsm'
const EInvoice = (props) => {
    console.log('EInvoice props', props)
    const { obj } = props
    console.log("obj", obj)

    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const { oYear } = useSelector((state) => state?.Language?.General);
    console.log('oYear', oYear)
    const { isLoading, hasError, ErrorMsg } = useSelector(
        (state) => state.EInvoice
    );
    // const isLoading = useSelector((state) => state.EInvoice?.isLoading)
    const EInvoiceData = useSelector((state) => state.EInvoice?.EInvoiceData)
    const { COPE01, COPE02, E01, P_G07DF, P_G07DT } = EInvoiceData || {}
    console.log('EInvoiceData', EInvoiceData)
    const dispatch = useDispatch();
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: null
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [EinvoiceObj, setEinvoiceObj] = useState({})
    const [format, setFormat] = useState(null)
    const [buttonLoading, setButtonLoading] = useState({
        generate: false,
        upload: false,
    });
    const [jsonData, setJsonData] = useState([])

    useEffect(() => {
        console.log("jsonData", jsonData)
    }, [jsonData])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
            // dispatch(ModalDelete(props.index))
        }
    }, [hasError]);

    useEffect(() => {
        dispatch(GetEInvoice())
    }, [])

    useEffect(() => {
        if (E01) {
            setEinvoiceObj(E01)
        }
    }, [E01])

    // console.log('EinvoiceObj', EinvoiceObj)

    const PostFormatData = (action) => {
        let JData = { "E01": { ...EinvoiceObj, E01F01: obj?.DataGridRow?.FIELD01 } }
        console.log("jdata", JData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: obj.id,
            cOPara: "",
            Custid: "PL-P-4486",
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: obj.id,
            cOPara: "",
            Custid: "PL-P-4486",
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostE01Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("Data====>", data)
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => {
                            if (format == "J") {
                                saveJsonToFile(data?.data?.Json, `EINV_${obj.DataGridRow?.FIELD12}`)
                            } else {
                                jsonDataFun(data?.data?.Json)
                            }
                        })
                        // .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                        .then(() => setTimeout(() => {
                            setButtonLoading({ ...buttonLoading, generate: false })
                        }, 1000))
                        .then(() => GlobalClass.Notify("success", "Successfull", `The file has been created`))
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)));
            });
    }

    const GenerateFun = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Format Type</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3} style={{ fontSize: 12 }}>File</Grid.Col>
                            <Grid.Col span={9}>
                                <PLComboBox
                                    data={COPE01}
                                    value={format}
                                    dispexpr={"DisplayMember"}
                                    valexpr={"ValueMember"}
                                    setEdit={(e) => {
                                        console.log("setEdit", e)
                                        setFormat(e)
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        {/* <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3} style={{ fontSize: 12 }}>File Path</Grid.Col>
                            <Grid.Col span={9}>
                                <FileInput
                                    value={file}
                                    // accept="image/png,image/jpeg"
                                    // placeholder="Select Company Logo"
                                    onChange={(e) => {
                                        console.log("onChange=>", e)
                                        setFile(e)
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col> */}
                        <Grid.Col span={12} style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => {
                                    PostFormatData("J")
                                }}
                            >OK</Button>
                        </Grid.Col>
                    </Grid>
                </Paper>
            </>,
            open: true,
            size: "md",
            position: "right"
        })
    }

    const EWBForm = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>E-Way Bill</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <EWayBill
                    OBJ={obj}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>,
            open: true,
            size: "60%",
            position: "right"
        })
    }

    const uploadInvoice = () => {
        let JData = { "E01": { ...EinvoiceObj, E01F01: obj.id } }
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "U",
            cCode: obj.id,
            cOPara: "",
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "U",
            cCode: obj.id,
            cOPara: "",
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostE01Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    let PaginationObj =
                        Store.getState().DataSaveLoadingSlice.PaginationObj;
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => setButtonLoading({ ...buttonLoading, upload: false }))
                } else {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    ).then(() => setButtonLoading({ generate: false, upload: false }))
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)))
                    .then(() => setButtonLoading({ generate: false, upload: false }))
            });
    }

    const saveJsonToFile = (jsonData, filename) => {
        // Convert the JSON data to a string
        console.log('jsonData', [{
            "jsonData": jsonData,
            "filename": filename
        }])
        let parsedJsonData;
        try {
            parsedJsonData = JSON.parse(jsonData);
            console.log("parsedJsonData", parsedJsonData)
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return; // Exit if JSON parsing fails
        }
        const jsonString = JSON.stringify(parsedJsonData, null, 2); // Pretty-print with 2 spaces indentation
        console.log("jsonString", jsonString)

        // // Create a Blob from the JSON string
        // const blob = new Blob([jsonString], { type: 'application/json' });

        // // Create a link element
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        // link.download = filename; // Set the filename for the download

        // // Append the link to the body (required for Firefox)
        // document.body.appendChild(link);

        // // Trigger the download by simulating a click
        // link.click();

        // // Clean up by removing the link
        // document.body.removeChild(link);
    };

    const jsonDataFun = (e) => {
        let parsedJsonData = []
        try {
            parsedJsonData = JSON.parse(e);
            console.log("parsedJsonData", parsedJsonData)
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return;
        }
        setJsonData(parsedJsonData)
        fetch(EForm_A)
      .then((response) => response.arrayBuffer()) // Get file as array buffer
      .then((arrayBuffer) => {
 // Read the Excel file
 const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    
 // Assuming the second sheet is the one you need (based on your docx file)
 const sheet = workbook.Sheets[workbook.SheetNames[1]];

 // Fill in the data from the JSON into the Excel file
 // Header Fields
 sheet['A2'] = { v: parsedJsonData.userGstin };    // GSTIN
 sheet['B2'] = { v: parsedJsonData.fromTrdName };  // From Party Name
 sheet['C2'] = { v: parsedJsonData.fromAddr1 };    // From Address
 sheet['D2'] = { v: parsedJsonData.fromPlace };    // From Place
 sheet['E2'] = { v: parsedJsonData.fromPincode };  // From Pincode
 sheet['F2'] = { v: parsedJsonData.toTrdName };    // To Party Name
 sheet['G2'] = { v: parsedJsonData.toGstin };      // To GSTIN
 sheet['H2'] = { v: parsedJsonData.toAddr1 };      // To Address
 sheet['I2'] = { v: parsedJsonData.toPlace };      // To Place
 sheet['J2'] = { v: parsedJsonData.toPincode };    // To Pincode

 // Line items (in a loop)
 let currentRow = 3;
 const itemList = [jsonData]; // Replace with actual item list

 itemList.forEach(item => {
     sheet[`A${currentRow}`] = { v: item.productName };  // Product Name
     sheet[`B${currentRow}`] = { v: item.hsnCode };      // HSN Code
     sheet[`C${currentRow}`] = { v: item.quantity };     // Quantity
     sheet[`D${currentRow}`] = { v: item.taxableAmount };// Taxable Amount
     sheet[`E${currentRow}`] = { v: item.cgstValue };    // CGST Value
     sheet[`F${currentRow}`] = { v: item.sgstValue };    // SGST Value
     currentRow += 1;
 });

 // Convert the workbook to binary format and save it
 const newWorkbook = XLSX.write(workbook, { bookType: 'xlsm', type: 'binary' });
 const buffer = new ArrayBuffer(newWorkbook.length);
 const view = new Uint8Array(buffer);
 for (let i = 0; i < newWorkbook.length; i++) {
     view[i] = newWorkbook.charCodeAt(i) & 0xFF;
 }
 const blob = new Blob([buffer], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' });
 saveAs(blob, 'updated_eway_bill.xlsm');
      })
        
    }

    const [file, setFile] = useState()

   

    // fileReader.readAsArrayBuffer(file);

    return (
        <>
            {
                SaveLoading == true ?
                    GlobalClass.RenderLoader("dots")
                    :
                    <>
                        {
                            isLoading ? GlobalClass.RenderLoader("dots") :
                                EInvoiceData &&
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        // onClose={close}
                                        withCloseButton={true}

                                        fullScreen={false}
                                        // overlayProps={{ opacity: 0.5, blur: 4 }}
                                        closeButtonProps={{
                                            onClick: (e) => {
                                                setButtonLoading({ ...buttonLoading, generate: false })
                                                if (typeof DrawerObj?.onClickCloseButton == "function") {
                                                    DrawerObj?.onClickCloseButton();
                                                }
                                                else {
                                                    setDrawerObj(dra);
                                                }
                                            },
                                        }}
                                        title={DrawerObj?.title}
                                        size={DrawerObj?.size}
                                        // centered={.Centered}
                                        closeOnClickOutside={true}
                                        // overlayProps={m.Overlay}
                                        position={DrawerObj?.position ?? 'bottom'}
                                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                    // scrollAreaComponent={ScrollArea.Autosize}
                                    >
                                        {DrawerObj.body}
                                    </Modal>
                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={3} style={{ fontSize: 12 }}>Invoice No</Grid.Col>
                                                <Grid.Col span={9}>
                                                    <PLTextBox
                                                        disabled={true}
                                                        value={obj?.DataGridRow?.FIELD12}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        {
                                            oYear?.OSC._EINVDISFM == "Y" &&
                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch From</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            value={EinvoiceObj?.E01F07}
                                                            // p1={"P_G07DF"}
                                                            TmData={P_G07DF}
                                                            setEdit={(e) => {
                                                                setEinvoiceObj({
                                                                    ...EinvoiceObj,
                                                                    E01F07: e.FIELD01
                                                                })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>
                                        }
                                        {
                                            oYear?.OSC._EINVDISTO == "Y" &&
                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch To</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            value={EinvoiceObj?.E01F12}
                                                            // p1={"P_G07DT"}
                                                            TmData={P_G07DT}
                                                            setEdit={(e) => {
                                                                setEinvoiceObj({
                                                                    ...EinvoiceObj,
                                                                    E01F12: e.FIELD01
                                                                })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>
                                        }
                                        <Grid gutter={4}>
                                            <Grid.Col
                                                my={5}
                                                span={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Group>
                                                    <Button
                                                        loading={buttonLoading?.generate}
                                                        style={{ height: "30px" }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setButtonLoading({ ...buttonLoading, generate: true })
                                                            GenerateFun()
                                                        }}
                                                    >Generate</Button>
                                                    <Button
                                                        loading={buttonLoading?.upload}
                                                        disabled={oYear?.OSC?._EINVUPLOD == "N" ? true : false}
                                                        style={{ height: "30px" }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setButtonLoading({ ...buttonLoading, upload: true })
                                                            uploadInvoice()
                                                        }}
                                                    >Upload</Button>
                                                    {
                                                        oYear?.OSC?._EWBILLGST == "Y" &&
                                                        !['NC', 'ND'].includes(obj?.DataGridRow?.FIELD01.substring(0, 2)) &&
                                                        <Button
                                                            style={{ height: "30px" }}
                                                            size="sm"
                                                            onClick={() => {
                                                                EWBForm()
                                                            }}
                                                        >EWB Details</Button>
                                                    }
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </Paper>
                                </>
                        }
                    </>
            }
        </>
    )
}

export default EInvoice