import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { Box, Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import FormatForm from "./FormatForm";
import Store from "../../utils/store";
import {
  GetVouchNum,
  GetVouchNumData,
} from "../../utils/slices/VoucherNumSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import DataGrid from "../../components/DataGrid";
import { ModalFunction } from "../../utils/slices/ModalSlice";

export default function FormatListTable(props) {
  const { obj, index } = props;
  const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  // const [activeBtn, setActiveBtn] = useState(true);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState("");

  const columnHelper = createColumnHelper();
  console.log(obj, "hhbh-");
  const p0 = obj?.p0;
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const {
    successMsg,
    VoucherNumTemp: tmFrmt,
    isLoading,
    VoucherNumdata: genData,
  } = useSelector((state) => state?.VoucherNum);
  console.log(genData, "genData", tmFrmt);
  const tabData = genData?.jData;
  useEffect(() => {
    if (!isLoading && tmFrmt && tmFrmt?.oDCFrmt && tmFrmt?.oDCFrmt?.aDCol) {
      var cols = [];
      tmFrmt?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v.ColCap,
            enableHiding: true,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });

      setColumns(cols);
    }
  }, [tmFrmt]);
  function EditFormatForm() {
    // console.log("OnNewBtn");
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>Edit</Text>,
        MAction: true,
        MSize: "30%",
        MBody: (i) => <FormatForm idToEdit={editData} cAction="E" index={i} />,
        MClose: true,
        onclose: () => {},
        closeOnClickOutside: true,
      })
    );
    setRowSelection(false);
  }
  function handleRowDblClk(row, cell) {
    EditFormatForm();
  }
  const table = useMantineReactTable({
    data: tabData ?? [],
    columns,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    initialState: {
      density: "xs",
      expanded: true,
    },
    state: {
      rowSelection,
      globalFilter: search,
    },

    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {
        handleRowDblClk(row, cell);
      },
      onClick: () => {
        // setActiveBtn(false);
        setEditData(row?.original?.FIELD00);
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
      },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
      height: 30,
    }),
  });
  useEffect(() => {
    if (Object.values(rowSelection)[0]) {
      console.log(Object.values(rowSelection)[0], "Object.values(rowSelection)[0]")
      // setActiveBtn(false);
      // setClick(false)
    } else {
      // setActiveBtn(true);
    }
  }, [rowSelection]);

  useEffect(() => {
    const fetchData = async () => {
      if (p0 == "S") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_SMS", FmtID: true }));
        await Store.dispatch(GetVouchNum({ RepId: "P_SMS", FmtID: true }));
      } else if (p0 == "E") {
        await Store.dispatch(
          GetVouchNumData({ RepId: "P_EMAIL", FmtID: true })
        );
        await Store.dispatch(GetVouchNum({ RepId: "P_EMAIL", FmtID: true }));
      } else if (p0 == "W") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_WAPP", FmtID: true }));
        await Store.dispatch(GetVouchNum({ RepId: "P_WAPP", FmtID: true }));
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "right"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
      >
        {DrawerObj.body}
      </Modal>
      {isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Box>
          <Grid>
            {/* {!SaveLoading ? ( */}
            <>
              <Grid.Col span={12}>
                <PLTextBox
                  placeholder="Search here..."
                  value={search}
                  setEdit={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
                {/* <DataGrid
                  ValueEdit={(e)=>{
                    console.log("ValueEdit",e);
                    setSelected(e?.FIELD00);
                  }}
                  setPopOver={()=>{}}
                  obj={listObj}
                  form={false}
                  isButton={true}
                  btnName="Print"
                /> */}
              </Grid.Col>
              
            </>
           
          </Grid>
        </Box>
      )}
      <ButtonPanel
        // tableRef={TableRef}
        data={tmFrmt?.oS60?.DTHKey}
        Skey={table.getSelectedRowModel().flatRows[0]}
        from={obj?.id}
        btnPnlObj={obj}
      />
    </>
  );
}
