import { useEffect } from "react";
import Store from "../../utils/store";
import { TabAdd } from "../../utils/slices/TabList";

export function SLED(d) {
    const data = JSON.parse(d);
    console.log("SLED data=>", [{
        "data":data,
        'data.p0[0]':data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01':data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);
    // console.log("data", data.DataGridRow.FIELD01);

    let obj;
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: data.p0,
        p1: data.p0,
        p2: "TFORM0000001",
        OParam: data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01
    }
    // console.log("SLED data 2=>",obj);
    // switch (data?.p0) {
       
    //     case "PRODLEDGER":
    //         obj = {...obj,OParam: "PCODE~K~" + data.DataGridRow.FIELD01}
    //         break;
    //     case "ASLEDGER":
    //         obj = {
    //             ...obj,
    //             OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         }
    //         break;
    //     default:
    //         obj = {}
    //         //  {
    //         //     name: data.DataGridRow.FIELD02,
    //         //     type: "A",
    //         //     id: data.id,
    //         //     p0: data.p0,
    //         //     p1: data.p0,
    //         //     p2: "TFORM0000001",
    //         //     OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         // }
    //         break;
    // }
    // const OBJ = JSON.parse(data);
    Store.dispatch(TabAdd(obj));
}
