import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Text,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { useSelector } from "react-redux";
import Store from "../../utils/store";
import { GetAutoBillData } from "../../utils/TransactionSlices/AutoBillSlice";
import PartyMaster from "../MasterControl/PartyMaster";
import ProductMaster from "../MasterControl/ProductMaster";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import PendingBillTable from "./PendingBillTable";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import { modals } from "@mantine/modals";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import PLTextBox from "../../PlusComponent/PLTextBox";

export default function AutoBillFormSE(props) {
  const [group, setGroup] = useState({
    cType: "",
    FromType: "",
    ToType: "",
    FromDt: "",
    TODT: "",
    T41F02: "",
    T41F12: "",
    T41F13: "",
    T41F21: "",
    T41F16: "",
    T41F05: "",
    cBillType: "",
    prtData: "",
    prdData: "",
  });
  const [toType, setToType] = useState([]);
  const [cType, setcType] = useState("");
  const [saveLoading, SetSaveLoading] = useState(true);
  const [CorrectDate, setCorrectDate] = useState(false);
  const [opened, setOpened] = useState(false);
  const [pendingData, setPendingData] = useState([]);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  let formId = props?.OBJ?.id;
  const {
    AutoBillData: fieldData,
    isLoading,
    hasError,
    partyData,
    productData,
  } = useSelector((state) => state?.ABillFormData);
  const oYear = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC?._MTAXSALE
  );
  const formRef = useRef(null);
  const salesAcRef = useRef(null);
  const invRef = useRef(null);
  const toField = document.getElementById("#toField");
  const billTypeId = document.getElementById("#billTypeId");
  const taxSupId = document.getElementById("#TaxSupId");
  const cdId = document.getElementById("#cdId");
  // console.log("oYear", oYear);

  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e?.toLocaleString("en-IN", { year: "numeric" });
    let month = e?.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e?.toLocaleString("en-IN", { day: "2-digit" });

    let startDate = year + month + day;
    return startDate;
  }
  function handleOkButton() {
    Promise.resolve(SetSaveLoading(true)).then(
      GlobalClass.Notify(
        "info",
        "Submitting..",
        "Please Wait While Submitting Form."
      )
    );

    // if (group?.FromType == "" || CorrectDate) {
    // if (CorrectDate) {
    //   GlobalClass.Notify(
    //     "warning",
    //     "Please Enter Proper Date",
    //     "From Date Should be less then To Date"
    //   );
    //   SetSaveLoading(false);
    //   return;
    // }

    // else {
    let currentDate = DateToString(new Date());
    // console.log(
    //   currentDate,
    //   "currentDate-",
    //   group?.TODT,
    //   "group?.FromDt-",
    //   group?.FromDt
    // );

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      // cAction: "A",
      cCode: "",
      cSData: JSON.stringify({
        ...group,
        FromDt: group?.FromDt == "" ? currentDate : DateToString(group?.FromDt),
        ToDt: group?.TODT == "" ? currentDate : DateToString(group?.TODT),
        cType: formId == "04350417" ? "JZ" : cType,
        FromType: formId == "04350417" ? "Z" : group?.FromType,
        ToType: formId == "04350417" ? "Z" : group?.ToType,
        T41F02: group?.T41F02 == "" ? currentDate : DateToString(group?.T41F02),
        T41F21: formId == "04350417" ? "T" : group?.T41F21,
        T41F16: formId == "04350417" ? "D" : group?.T41F16,
        prtData: partyData,
        prdData: productData,
        TODT: group?.TODT == "" ? currentDate : DateToString(group?.TODT),
      }),
      // };
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      // cAction: "A",
      cCode: "",
      // cSData: JSON.stringify(group),
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    // console.log(data, "requestOptions");
    // try {
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostAutoVouList + "?pa=" + param,
      requestOptions
    )
      .then((response) => {
        if (!response?.ok) {
          throw new Error(`HTTP error! Status: ${response?.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("api data=>", data);
        // console.log("api data status=>", data?.data?.dtFTbl?.length);

        if (data?.status == null) {
          // Store.dispatch(ModalDelete(props.index));
          if (data?.data?.dtFTbl?.length != 0) {
            // console.log(data?.data, "data?.data");
            setOpened(true);
            setPendingData(data?.data);

            Promise.resolve(
              modals.open({
                title: <Text fw={700}>Pending Bills</Text>,
                size: "50%",
                zIndex: "300",
                centered: true,
                closeOnClickOutside: false,
                closeOnEscape: false,
                withCloseButton: true,
                children: <PendingBillTable data={data?.data} />,
              })
            ).then(() => {
              SetSaveLoading(false);
            });
          } else {
            Store.dispatch(ModalDelete(props.index));
            Promise.resolve(SetSaveLoading(false)).then(
              GlobalClass.Notify(
                "info",
                "No Bills",
                "No Pending Bills To Display!"
              )
            );
            return;
          }

          Promise.resolve(SetSaveLoading(false)).then(
            GlobalClass.Notify("success", "Successful", "Added Successfully")
          );
        } else if (data?.status == "FAIL") {
          Promise.resolve(SetSaveLoading(false)).then(
            GlobalClass.Notify(
              "error",
              "Error occured!",
              `responded with status ${data?.status}`
            )
          );
        }
      })
      .catch((e) => {
        console.log("inside catch", e);
        GlobalClass.Notify("error", "Error occured!", `responded with ${e}`);
        SetSaveLoading(false);
      });
    // }
  }
  // console.log(isLoading, "isLoading--", saveLoading)
  useEffect(() => {
    Promise.resolve(Store.dispatch(GetAutoBillData(formId))).then(() => {
      SetSaveLoading(false);
    });
  }, []);

  function FormTypeFun(e) {
    // console.log(e, "e in formfun", fieldData?.COPTO);
    const toTypeData = fieldData?.COPTO ?? [];
    let filterData;
    switch (e) {
      case "Q":
        setToType(toTypeData);
        break;
      case "O":
        filterData = toTypeData.filter((v) => v.ValueMember !== e);
        // console.log(filterData, "infilter");
        setToType(filterData);
        break;
      case "C":
        filterData = toTypeData.filter((v) => v.ValueMember == "S");
        // console.log(filterData, "infilter");
        setToType(filterData);
        break;

      default:
        setToType(toTypeData);
        break;
    }
  }
  function ToTypeFun(e) {
    switch (e) {
      case "O":
        setcType("OS");
        break;
      case "C":
        setcType("HS");
        break;
      case "S":
        setcType("SS");
        break;
      default:
        setcType("OS");
        break;
    }
  }
  useEffect(() => {
    setcType("JZ");
  }, []);
  
  useEffect(() => {
    // let defVal = fieldData?.COPFROM[2]?.ValueMember;
    // console.log(formId, "fieldData");
    if (formId == "02370213") {
      console.log(fieldData, "formId", isLoading);
      if (!isLoading && fieldData && fieldData?.oDATA) {
        // console.log(fieldData?.oDATA, "fieldData?.oDATA-")
        setGroup({
          ...fieldData?.oDATA,
          FromType: "Q",
          // ToType: "S",
          T41F21: "O",
          cBillType: "V",
          T41F16: "D",
        });
        FormTypeFun("Q");
      }
    } else {
      setGroup({ ...fieldData?.oDATA, cBillType: "V" });
    }
  }, [isLoading, formId, fieldData]);
  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({
        ...BillSeriesHideShow,
        DataGridInput: true,
        TextInput: false,
      });
    }
  }, [BillSeriesHideShow.BillNoVal]);

  useEffect(() => {
    // console.log(group?.FromDt, "group?.FromDt", group?.ToDt);
    const date1 = new Date(group?.FromDt);
    const date2 = new Date(group?.TODT);
    // Compare the dates
    if (date1 > date2) {
      setCorrectDate(true);
    } else if (date1 < date2) {
      setCorrectDate(false);
    } else {
      setCorrectDate(false);
    }
  }, [group?.TODT]);
  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    console.log(fieldData?.T92DT, "fieldData?.T92DT");
  }, [fieldData?.T92DT]);

  return (
    <>
      {/* {pendingData ? (
       
      ) : (
        GlobalClass.RenderLoader("dots")
      )} */}

      {saveLoading == true || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <div ref={formRef}>
          {/* <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title={<Text fw={700}>Pending Bills</Text>}
            padding="xl"
            size="lg"
            position="right"
            // open="true"
          >
            <PendingBillTable data={pendingData} />
          </Modal> */}

          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                {formId == "02370213" ? (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>From</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          initiallyOpened={opened ? false : true}
                          value={group?.FromType}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          data={fieldData?.COPFROM}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            FormTypeFun(e);
                            Promise.resolve(
                              setGroup({ ...group, FromType: e })
                            ).then(() => {
                              toField?.focus();
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>To</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          cmbid="#toField"
                          value={group?.ToType}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          data={toType}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            ToTypeFun(e);
                            setGroup({ ...group, ToType: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                ) : (
                  ""
                )}
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>From</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      setEdit={(e) => {
                        // console.log(e, "FromData");
                        setGroup({ ...group, FromDt: e });
                      }}
                      value={group?.FromDt}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      showClearButton={false}
                      showDropDownButton={false}
                      useMaskBehavior={true}
                      dispformat="DD/MM/YYYY"
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>To</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      setEdit={(e) => setGroup({ ...group, TODT: e })}
                      value={group?.TODT}
                      showClearButton={false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      onBlur={() => {
                        if (CorrectDate) {
                          GlobalClass.Notify(
                            "warning",
                            "Please Enter Proper Date",
                            "From Date Should be less then To Date"
                          );
                          // SetSaveLoading(false);
                          return;
                        }
                      }}
                      showDropDownButton={false}
                      useMaskBehavior={true}
                      dispformat="DD/MM/YYYY"
                      setDate={(e) => {}}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Bill Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      setEdit={(e) => setGroup({ ...group, T41F02: e })}
                      value={group?.T41F02}
                      showClearButton={false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      showDropDownButton={false}
                      useMaskBehavior={true}
                      dispformat="DD/MM/YYYY"
                      setDate={(e) => {}}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Bill Number</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    {BillSeriesHideShow?.DataGridInput ? (
                      fieldData?.T92DT?.length > 0 ? (
                        <PLDataGrid
                          width={"500px"}
                          value={group?.T41F12}
                          TmData={fieldData?.T92}
                          valexpr={"FIELD00"}
                          data={
                            fieldData?.T92DT?.filter(
                              (v) => v?.FIELD01 == cType
                            ) }
                          // data={fieldData?.T92DT?.filter((v) =>v?.FIELD01 )}

                          setEdit={(e) => {
                            Promise.resolve(
                              setGroup({ ...group, T41F12: e?.FIELD02 })
                            )
                              .then(() => {
                                invRef?.current?.handlePopoverToggle();
                              })
                              .then(() => {
                                if (e?.FIELD02) {
                                  let letr = e.FIELD02;
                                  let num = parseInt(e.FIELD03) + 1;
                                  console.log(num, "num=>", letr);

                                  setBillSeriesHideShow({
                                    ...BillSeriesHideShow,
                                    DataGridInput: false,
                                    TextInput: true,
                                    BillNoVal: letr + num,
                                  });
                                }
                              });
                          }}
                          handleClose={(e) => {
                            // console.log("handleClose", e);
                            // M48Dhandlechange(e);
                          }}
                        />
                      ) : (
                        <PLTextBox
                          value={group?.T41F12}
                          // value={T41Obj?.T92F00}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            console.log("setEdit=>", e);
                            // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                            setGroup({
                              ...group,
                              // T92F00: "",
                              T41F12: e.target.value,
                            });
                          }}
                        />
                      )
                    ) : (
                      <PLTextBox
                        value={BillSeriesHideShow?.BillNoVal}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          console.log(e, "BillSeriesHideShowdd");
                          setBillSeriesHideShow({
                            ...BillSeriesHideShow,
                            BillNoVal: e.target.value,
                          });

                          setGroup({
                            ...group,
                            // T92F00: "",
                            T41F12: e.target.value,
                          });
                        }}
                      />
                    )}
                    {/* : <PLNumberBox/> }   */}
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Inv Type</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={invRef}
                      width={"500px"}
                      disabled={cType === "SS" ? false : true}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={group?.T41F13}
                      TmData={fieldData?.M45I}
                      data={fieldData?.M45IDT}
                      setEdit={(e) => {
                        Promise.resolve(
                          setGroup({ ...group, T41F13: e?.FIELD01 })
                        ).then(() => {
                          taxSupId?.focus();
                        });
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                        // M48Dhandlechange(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                {formId == "02370213" ? (
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Tax/Bill Of Supply</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        cmbid="#TaxSupId"
                        value={group?.T41F21}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        data={fieldData?.COP75}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          // console.log("{group?.T41F21", e);
                          Promise.resolve(setGroup({ ...group, T41F21: e }))
                            .then(() => {
                              if (oYear !== "N")
                                salesAcRef?.current?.handlePopoverToggle();
                            })
                            .then(() => {
                              billTypeId?.focus();
                            });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Sales A/c.</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={salesAcRef}
                      width={"500px"}
                      disabled={oYear == "N" ? false : true}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={group?.T41F05}
                      TmData={fieldData?.P_M01}
                      data={fieldData?.P_M01DT?.jData}
                      setEdit={(e) => {
                        // setGroup((prevState) => ({
                        //   ...prevState,
                        //   T41F13: e,
                        // }));
                        setGroup({ ...group, T41F13: e?.FIELD01 });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  {/* <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Cash/Debit</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLComboBox />
              </Grid.Col> */}
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col
                  span={6}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Bill Type</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLComboBox
                      cmbid="#billTypeId"
                      value={group?.cBillType}
                      dispexpr="DisplayMember"
                      valexpr="ValueMember"
                      data={fieldData?.COPBT}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      setEdit={(e) => {
                        Promise.resolve(
                          setGroup({ ...group, cBillType: e })
                        ).then(() => {
                          cdId?.focus();
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                {formId == "02370213" ? (
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Cash/Debit</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        cmbid="#cdId"
                        value={group?.T41F16}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        data={fieldData?.COP74}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          setGroup({ ...group, T41F16: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>

              {/* <Grid
              style={{
                border: "1px solid #aaa",
                width: "xl",
              }}
              gutter={7}
            > */}
              <Grid.Col span={12}>
                <Paper shadow="md" radius="md" p="xs" withBorder>
                  <PartyMaster
                    ref={formRef}
                    dispexpr="DisplayMember"
                    valexpr="ValueMember"
                    data={fieldData}
                  />
                </Paper>{" "}
              </Grid.Col>
              <ProductMaster
                ref={formRef}
                dispexpr="DisplayMember"
                valexpr="ValueMember"
                data={fieldData}
                // setProduct={(e) => {
                //   console.log(e, "setProduct");
                //   let data = e.map((v) => v.FIELD01);
                //   console.log(data, "data setProduct");

                //   setGroup({ ...group, prdData: data });
                // }}
              />

              <Grid.Col
                span={12}
                // // offset={9}
                // style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Center>
                  <Button
                    style={{ width: "130px" }}
                    onClick={handleOkButton}
                    size="xs"
                    // mx={4}
                    compact
                  >
                    Ok
                  </Button>
                </Center>
              </Grid.Col>
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
}
