import { Alert, Button, Center, Container, Grid, Group, List, Overlay, Paper, Text } from "@mantine/core";
import NewReportViewer from "./NewReportViewer";
import Store from "../utils/store";
import { ModalDelete, ModalFunction } from "../utils/slices/ModalSlice";
import VoucherFmtForm from "../SetupComponent/VoucherFormat/VoucherFmtForm";
import useWindowDimensions from "../utils/UseWindowDimensions";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import PLComboBox from "../PlusComponent/PLComboBox";
import MyReportDesigner from "./ReportDesigner";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList, GetTMFormat } from "../utils/slices/AccountListSlice";
import { useEffect, useState } from "react";
import { GetReportDesignerData } from "../utils/PrintSlice/ReportDesignerSlice";
import GlobalClass from "../utils/GlobalClass";
import DataGrid from "./DataGrid";
import ReportViewerTest from "./ReportViewerTest";
import gensetting from "../utils/gensetting";
import { IconAlertCircle } from "@tabler/icons-react";
let globalid ="";
let pageid="";
let pageformat="";
export const TM_PRINTVF=(data)=>{
    const obj = JSON.parse(data); 
    console.log("TM_PRINTVF 123=>",obj);

    if (obj?.id != '') {
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{obj?.text}</Text>,
            MAction: true,
            MSize: "auto",
            // position:'right', 
            fullScreen:false,
            MBody: (i) => <VoucherPrintForm obj={obj}/>,//<NewReportViewer obj={obj} index={i} />,
            MClose: true,
            onclose: () => { },
            onClickCloseButton:()=>{
                // Store.dispatch(GetInvTypeDropDown(null))
            }
        }))
    }
}

export function VoucherPrintForm({obj}) {
    const { height } = useWindowDimensions();
    const {isLoading,hasError,ErrorMsg,Data } = useSelector((state) => state?.ReportDesigner);
    const {TMFormatData: tmList} = useSelector((state) => state.AccountList);
    const [ccode,setccode] =useState(null);
    const [listObj,setListobj] =useState(null);
    const [selected,setSelected] = useState();
    const [ReportOn,setReportOn] = useState("s");
    const dispatch = useDispatch();
    console.log("obj",obj);
    
    useEffect(()=>{
      dispatch(
        GetTMFormat(obj.BtnPnlObj)
      );
      globalid = obj?.BtnPnlObj?.id
      pageid =obj?.id
    },[])
    useEffect(()=>{
        console.log("Report TMFORMAT",tmList[obj?.BtnPnlObj?.id]);
        
      if(tmList[obj?.BtnPnlObj?.id]){
        setccode(tmList[obj?.BtnPnlObj?.id]?.oS60?.cS92ID)
      }
      
    },[tmList,obj?.BtnPnlObj?.id])
    const getData =()=>{
      dispatch(GetReportDesignerData({id:obj?.BtnPnlObj?.id,cCode:ccode}));
    }
    useEffect(() => {
      console.log("ccc",ccode);
       if(ccode){
        getData();
      }
    }, [ccode]);
    useEffect(()=>{
      // if(!Data[obj?.BtnPnlObj?.id]){
      //   GlobalClass.Notify("error","Report Editing Data Not Available","Contact Support",false,6000)
      // }
      if(hasError){
        GlobalClass.Notify("error",ErrorMsg,"Contact Support",false,6000)
      }
      if(!isLoading && Data[obj?.BtnPnlObj?.id]){
        let objpc50 = {
          name: "",
          type: "A",
          id: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
          p0: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
          p1: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
          p2: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[1],
        }
        setListobj(objpc50)
        pageformat = objpc50;
      }
  },[Data,isLoading,hasError]);
  const renderExtraForm = (r) =>{
    switch(r){
      case "F":
        return <Grid>
          <Grid.Col>
            
          </Grid.Col>
        </Grid>;
        break;
      default:
        break
    }
  }
  return (
    <>
            {isLoading ? GlobalClass.RenderLoader("dots") : (listObj && 
            <Grid>
              <Grid.Col span={6}>
                <DataGrid
                  ValueEdit={(e)=>{
                    console.log("ValueEdit",e);
                    setSelected(e?.FIELD00);
                    
                  }}
                  setPopOver={()=>{}}
                  obj={listObj}
                  // data={Data[obj?.BtnPnlObj?.id]?.P_C50DT}
                  form={false}
                  isButton={true}
                  btnName="Print"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Grid span={12}>
                  <Grid.Col span={1}/>
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>
                      Report On:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={7}>
                    <PLComboBox
                      onKeyDown={(e) => {
                        // GlobalClass.formNextInput(e, formRef);
                      }}
                      value={ReportOn}
                      data={[{label:"Screen",value:"s"},{label:"Printer",value:"p"},{label:"File",value:"f"},{label:"Email",value:"e"},{label:"Whatsapp",value:"w"}]}
                      dispexpr={"label"}
                      valexpr={"value"}
                      setEdit={(e) => {
                        setReportOn(e);
                        // Promise.resolve(setT41Obj({ ...T41Obj, FIELD21: e })).then(() => setGSTNoStatus({ ...GSTNoStatus, status: true }))
                      }}
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  {ReportOn && renderExtraForm(ReportOn) }
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                
              </Grid.Col>
            </Grid>
            )}
    </>
  );
}
export function PrintingFunc(data) {
  const OBJ = JSON.parse(data);
  console.log("dataOBJ=>", globalid);
  const {hasError,ErrorMsg,Data } =Store.getState().ReportDesigner //useSelector((state) => state?.ReportDesigner);
  const {TMFormatData: tmList} = Store.getState().AccountList;
  
  switch(OBJ.p0){
    case "A":
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{"Create New Report Format"}</Text>,
            MAction: true,
            MSize: "100%",
            // position:'right',
            fullScreen:true,
            MBody: (i) => <MyReportDesigner modalIndex={i} Data={Data[globalid].final} cCode={tmList[globalid]?.oS60?.cS92ID} getData={()=>{Store.dispatch(GetAccountList(pageformat));}} />,//<NewReportViewer obj={obj} index={i} />,
            MClose: true,
            onclose: () => { },
            onClickCloseButton:()=>{
                // Store.dispatch(GetInvTypeDropDown(null))
            }
        }))
        break;
    case "E":
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{"Edit Report Format: " + OBJ.DataGridRow.FIELD04}</Text>,
            MAction: true,
            MSize: "100%",
            // position:'right',
            fullScreen:true,
            MBody: (i) => <MyReportDesigner modalIndex={i} Data={Data[globalid].final} cCode={tmList[globalid]?.oS60?.cS92ID} edit={OBJ.DataGridRow.FIELD00} getData={()=>{Store.dispatch(GetAccountList(pageformat));}} />,//<NewReportViewer obj={obj} index={i} />,
            MClose: true,
            onclose: () => { },
            onClickCloseButton:()=>{
                // Store.dispatch(GetInvTypeDropDown(null))
            }
        }))
        break;
      case "P":
        Store.dispatch(ModalFunction({
          MTitle: <Text fw={700}>{"Print Report"}</Text>,
          MAction: true,
          MSize: "100%",
          // position:'right',
          fullScreen:true,
          MBody: (i) => <ReportViewerTest 
          cCode={pageid}
          cOPara={OBJ.DataGridRow.FIELD00}
          Data={Data[globalid].final} />,//<NewReportViewer obj={obj} index={i} />,
          MClose: true,
          onclose: () => { },
          onClickCloseButton:()=>{
              // Store.dispatch(GetInvTypeDropDown(null))
          }
        }))
        break;
      case "D":
        GlobalClass.Confirm(
          "red",
          "Are you Sure to Delete This Report?",
          "It will be Deleted Forever and Cannot Be Recovered!!!",
          ()=>{
                    GlobalClass.Notify("info","Deleting Report Format",OBJ.DataGridRow.FIELD04,true,false,"delrep")
                    let param = {
                      CorpID: gensetting.getcorpid(),
                      cUser: gensetting.getcuser(),
                      cPass: gensetting.getcpass(),
                      CmpNo: gensetting.getcmpno(),
                      cCode: OBJ.DataGridRow.FIELD00,
                      cAction: "D",
                      cOPara:"",
                    };

                    fetch(GlobalClass.ApiUrl+'Vouformat/GetC50Ent?pa='+JSON.stringify(param))
                    .then((res)=>res.json())
                    .then((e)=>{
                      if(e.status == "SUCCESS"){
                        GlobalClass.Notifyupdate("success","Deleted Successfully","",false,3000,"delrep")
                        Store.dispatch(GetReportDesignerData({id:globalid,cCode:tmList[globalid]?.oS60?.cS92ID}));
                        
                      }
                      else{
                        GlobalClass.Notifyupdate("error","Error Deleting Report Format",e.message,false,3000,"delrep")
                      }
                    })
                    .catch((e)=>{
                      GlobalClass.Notifyupdate("error","Error Deleting Report Format","Network Error!!",false,3000,"delrep")
                    })
          },
          ()=>{})
        break;
    default:
        break;
  }
  // Store.dispatch(
  //   ModalFunction({
  //     onclose: (e) => { },
  //     MTitle: <Text fw={700}>{OBJ?.text}</Text>,
  //     MAction: true,
  //     MSize: "xl",
  //     position:"right",
  //     MBody: (i) => <PLPrint data={OBJ} index={i} />,
  //     MClose: true,
  //     Overlay: {
  //       backgroundOpacity: 0.8,
  //       blur: 5,
  //     },
  //   })
  // );
}