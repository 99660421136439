import { Button, Drawer, Grid, Modal, Text, Textarea } from '@mantine/core';
import { useEffect, useRef, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import { useDispatch, useSelector } from 'react-redux';
import { CashBankApi, getDeletedRowStatus, getData, CashBankDeleteApi, getSuccessStatus, setToggleButton, CashBankAddApi, setInvoiceValue, setSuccess } from '../../utils/TransactionSlices/CashBankSlice';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { CashBankTable } from './CashBankTable';
import GlobalClass from '../../utils/GlobalClass';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import Store from '../../utils/store';
import PLNarration from '../../PlusComponent/PLNarration';
import ExpenseEntryForm from '../SaleEntry/ExpenseEntryForm';
import { TransactionApi } from '../../utils/TransactionSlices/Transaction';
import { GetExpenseData, GetExpenseDataNull, GetInvTypeDropDown } from '../../utils/TransactionSlices/TSalesSlices';

export const CashBank = (props) => {

  let cVType;

  const { obj } = props;
  console.log("props",props);
  // console.log(obj);

  // const defaultDate = new Date().getDate() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getFullYear();

  const dispatch = useDispatch();
  const inputRef = useRef();

  const CashBankData = useSelector(store => store?.CashBank?.CashBankData);
  const dYSDate = useSelector(store => store?.Language?.General?.dYSDate);
  const dYEDate = useSelector(store => store?.Language?.General?.dYEDate);
  const isDeleted = useSelector(store => store?.CashBank?.isDeleted);
  const { hasError, ErrorMsg, isLoading, AddEditDeleteApiHasError, AddEditDeleteApiErrorMsg, invoiceValue } = useSelector((store) => store?.CashBank);
  const { hasError: transactionHasError, isLoading: transactionIsLoading } = useSelector(store => store?.Transaction);
  const toggleButton = useSelector(store => store?.CashBank?.toggleButton);
  const ExpenseData = useSelector((store) => store?.TSalesSlices?.ExpenseData)


  const CashBankAddData = useSelector(store => store?.CashBank?.CashBankAddData);
  const CashBankEditData = useSelector(store => store?.CashBank?.CashBankEditData);

  const [state, setState] = useState();
  const [T41List, setT41List] = useState([]);
  const [T41GRD, setT41GRD] = useState();
  const [accBalance, setAccBalance] = useState();
  const [data, setData] = useState({
    From: new Date(dYSDate),
    To: new Date(dYEDate),
  });
  const [enable, setEnable] = useState(true);
  const [tablevisibility, setTableVisibility] = useState(true);
  const [cid, setCid] = useState();
  const [selectedRowId, setSelectedRowId] = useState();
  const [isRowSelected, setIsRowSelected] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [T41, setT41] = useState();
  const [cVocherType, setcVocherType] = useState();
  const [amountName, setAmountName] = useState();
  const [bankAmount, setBankAmount] = useState(0);
  const [M45IDT, setM45IDT] = useState();

  const [narrationValue, setNarrationValue] = useState("");
  const [balanceObj, setBalanceObj] = useState("");

  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);

  const [rowId, setRowID] = useState("");
  // const [row, setRow] = useState({});

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);

  useEffect(() => {
    obj.id === "04500447" ?
      setData({
        ...data,
        Account: {
          FIELD01: "ACASHACT",
          FIELD02: "Cash Account",
          FIELD05: "G0000005",
          FIELD07: "CS",
          FIELD16: "",
          FIELD17: "",
          FIELD18: "",
          FIELD22: "",
          FIELD36: "",
          FIELD37: "",
          FIELD40: "",
          FIELD99: -22952,
        },
        ReceiptPayment: "R",
      })
      :
      setData({
        ...data,
        Account: {
          FIELD01: "AHP5S1OG",
          FIELD02: "3M ENTERPRISE",
          FIELD05: "G000001A",
          FIELD07: "CP",
          FIELD16: "THT66ZLK",
          FIELD17: "RAJKOT",
          FIELD18: "",
          FIELD22: "O",
          FIELD36: "24",
          FIELD37: "R",
          FIELD40: "",
          FIELD99: -174660,
        },
        Type: "J",
      })
    dispatch(CashBankApi(obj)).then(() => {
      inputRef?.current?.handlePopoverToggle()
      dispatch(setToggleButton(false))
      obj.id === "04500447" && setAmountName("Cash Amount")
      dispatch(setInvoiceValue({}))
      dispatch(setSuccess(true))
    });
  }, [])

  let stringOfNames;
  useEffect(() => {
    if (SelectedNarrationData?.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray?.join(" ");
      // console.log("stringOfNames", stringOfNames);
    }
    setT41({ ...T41, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    console.log("CashBankData", CashBankData);
    setState(CashBankData)
    setT41GRD(CashBankData?.T41GRD)
  }, [CashBankData])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error" + ErrorMsg.code, ErrorMsg.message)
    }
  }, [hasError]);

  useEffect(() => {
    if (AddEditDeleteApiHasError) {
      GlobalClass.Notify("error", "Error" + AddEditDeleteApiErrorMsg?.code, AddEditDeleteApiErrorMsg?.message)
    }
  }, [AddEditDeleteApiHasError])

  useEffect(() => {
    setBankAmount({})
    setNarrationValue("")
    setBalanceObj({})
  }, [T41List])

  useEffect(() => {
    switch (true) {
      case (data?.Account?.FIELD07 == "BK"):
        cVType = "BR"
        setAmountName("Bank Amount")
        break;
      case (data?.Account?.FIELD07 == "CS"):
        cVType = "CR"
        setAmountName("Cash Amount")
        break;
      default:
        break;
    }
  }, [data?.Account?.FIELD07])

  useEffect(() => {
    // console.log("State", state);
    // console.log("data updation", data);
    // console.log("State", state);
    // console.log("data updation", data);
    // console.log("T41List", state?.T41List);

    let pDate, pFrom, pTo;

    let accBalance = state?.M01BADT?.jData?.filter(item => data?.Account?.FIELD01 === item.FIELD01).map(item => item.FIELD99).join();
    setAccBalance(accBalance)

    if (obj.id === "04500447") {
      switch (true) {
        case (data?.Account?.FIELD07 == "BK" && data?.ReceiptPayment == "R"):
          cVType = "BR"
          break;
        case (data?.Account?.FIELD07 == "BK" && data?.ReceiptPayment == "P"):
          cVType = "BP"
          break;
        case (data?.Account?.FIELD07 == "CS" && data?.ReceiptPayment == "R"):
          cVType = "CR"
          break;
        case (data?.Account?.FIELD07 == "CS" && data?.ReceiptPayment == "P"):
          cVType = "CP"
          break;
        default:
          break;
      }
    }
    else if (obj.id === "04560447") {
      switch (data?.Type) {
        case "C":
          cVType = "NC"
          break;
        case "D":
          cVType = "ND"
          break;
        case "J":
          cVType = "JR"
          break;
        default:
          break;
      }

    }
    setcVocherType(cVType);

    let fromDate = data?.From?.getDate() + "/" + (data?.From?.getMonth() + 1) + "/" + data?.From?.getFullYear();
    let toDate = data?.To?.getDate() + "/" + (data?.To?.getMonth() + 1) + "/" + data?.To?.getFullYear();

    // console.log("data.From->>>>>>>>>", fromDate);
    // console.log("data.To->>>>>>>>>", toDate);

    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("/");
      return new Date(`${year}-${month}-${day}`).getTime();
    };

    pFrom = parseDate(fromDate);
    pTo = parseDate(toDate);

    // console.log("pDate", pFrom);
    // console.log("pDate", pTo);

    // const list = {...T41List[0],FIELD05:"APJO3DSY",T41PVT:"BP",FIELD02:"20240320"}
    // console.log(list,"list");

    // let Final = state?.T41List?.filter((item) => {
    // let year = list.FIELD02.slice(0, 4);
    // let month = list.FIELD02.slice(4, 6);
    // let day = list.FIELD02.slice(6);
    // let date = (day + "/" + month + "/" + year);
    // pDate = parseDate(date);
    // if(pDate)
    // {
    //   console.log("yyyyyyy");
    //   if (list.FIELD05 === data?.Account?.FIELD01 && list.T41PVT === cVType && (pDate >= pFrom && pDate <= pTo)) {
    //     console.log("yessssssssss");
    //     return list;
    //   }
    // }
    // })

    let Final = state?.T41List?.filter((item) => {
      let year = item.FIELD02.slice(0, 4);
      let month = item.FIELD02.slice(4, 6);
      let day = item.FIELD02.slice(6);
      let date = (day + "/" + month + "/" + year);
      pDate = parseDate(date);
      if (item.FIELD05 === data?.Account?.FIELD01 && item.T41PVT === cVType && (pDate >= pFrom && pDate <= pTo)) {
        return item;
      }
    })

    // console.log("Final", Final);
    setT41List(Final)

  }, [data, state])

  useEffect(() => {
    if (data) {
      Promise.resolve(
        setT41((prevT41) => ({
          ...prevT41,
          FIELD05: data?.Account?.FIELD01,
          T41PVT: cVocherType
        }))
      ).then(() => {
        dispatch(getData({ ...T41, FIELD05: data?.Account?.FIELD01, T41PVT: cVocherType }))
      })
    }
    setRowSelection({})
  }, [data, cVocherType])

  useEffect(() => {
    let id = Object.keys(rowSelection).join();
    setSelectedRowId(id)
    let RowSelected = Object.values(rowSelection).join()
    setIsRowSelected(RowSelected)

    // Promise.resolve(() => {
    dispatch(GetInvTypeDropDown(null))
    dispatch(GetExpenseDataNull([]))
    // })
    // .then(() => {
    if (RowSelected == "false") {
      dispatch(setInvoiceValue({}))
      setBankAmount({})
      setNarrationValue("")
      setBalanceObj({})
      setRowID("")
      // dispatch(GetInvTypeDropDown(null))
      // dispatch(GetExpenseDataNull([]))
    }
    else {
      T41List?.filter((item, index) => {
        if (id && index == id) {
          console.log("item", item);
          setBankAmount(item)
        }
      })

    }
    // })


    console.log(CashBankAddData?.M45IDT, invoiceValue, ExpenseData);


  }, [rowSelection])

  useEffect(() => {

    console.log("invoiceValue", invoiceValue);
    if (obj?.id === "04500447" && invoiceValue?.FIELD13 && invoiceValue?.FIELD13 != {} && invoiceValue?.FIELD13 != null && invoiceValue?.FIELD13 != undefined) {
      dispatch(GetExpenseData({ action: "A", code: cVocherType + cVocherType, invCode: invoiceValue?.FIELD13 }))
      let result = CashBankAddData?.M45IDT?.find((item) => item.FIELD01 == invoiceValue?.FIELD13)
      result && dispatch(GetInvTypeDropDown(result))
      console.log(result, "result");
    }
    // else{
    //   dispatch(GetInvTypeDropDown(null))
    //   dispatch(GetExpenseDataNull([]))
    // }
  }, [invoiceValue])

  const deleteButton = () => {

    if (selectedRowId && isRowSelected === "true") {

      const confirm = window.confirm("Are you sure ?");
      if (confirm) {
        GlobalClass.Notify(
          "info",
          "Deleting",
          "Please wait while we process your data"
        );

        let result = T41List?.find((item, index) => index == selectedRowId);
        // console.log("result", result);
        setCid(result?.FIELD01)

        if (result?.FIELD01) {
          let cid = result?.FIELD01
          Promise.resolve(dispatch(CashBankDeleteApi({ obj, cid })))
            .then(() => { })
            .catch((error) => {
              dispatch(getDeletedRowStatus(false))
              setCid()
              GlobalClass.Notify("error", "Error", `${error.message}`);

            })

        }

      }
    }

  }

  useEffect(() => {
    if (isDeleted === true) {
      let ary = T41List?.filter(item => item.FIELD01 != cid);
      setT41List(ary)
      GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      setSelectedRowId();
      setRowSelection({});
      dispatch(getDeletedRowStatus(""));
      setNarrationValue("")
    }
    else if (isDeleted === false) {
      GlobalClass.Notify("error", "Error", "Item is not Deleted");
      dispatch(getDeletedRowStatus(""))
    }
  }, [isDeleted])

  const changeDate = () => {
    const dateInput = document.getElementById('dateInput');
    dateInput && dateInput.focus()
  }

  const changeVouType = () => {
    if (obj.id === "04500447") {
      (data?.ReceiptPayment === "R") ? setData({ ...data, ReceiptPayment: "P" }) : setData({ ...data, ReceiptPayment: "R" })
    }
    else if (obj.id === "04560447") {

      if (data?.Type === "J") {
        setData({ ...data, Type: "C" })
      }
      else if (data?.Type === "C") {
        setData({ ...data, Type: "D" })
      }
      else if (data?.Type === "D") {
        setData({ ...data, Type: "J" })
      }

    }
  }

  const changeAccount = () => {
    inputRef.current.handlePopoverToggle()
  }

  const auditButton = () => {
    // let index = Number(selectedRowId)
    // if (index < T41List.length - 1) {
    //   setRowSelection({ [index + 1]: true })
    // }

    // console.log(object);



    if (rowId) {
      const OBJ = { p0: "T", id: rowId }
      dispatch(TransactionApi(OBJ))
    }
  }

  useEffect(() => {
    if (rowId && !transactionIsLoading && !transactionHasError) {
      setRowSelection({})
      dispatch(CashBankApi(obj))
      setRowID("")
    }
  }, [transactionHasError, transactionIsLoading])

  const importButton = () => {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>Data Import</Text>,
        MAction: true,
        MSize: "md",
        MBody: (i) => (
          <Grid>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={4}>
                <Text>Voucher No</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <PLTextBox />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={4}>
                <Text>Profile Name</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <PLDataGrid
                  setEdit={() => {

                  }} />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={4}>
                <Text>File Name</Text>
              </Grid.Col>
              <Grid.Col span={7}>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={4}>
                <Text>Starting Row</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <PLNumberBox />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button>Ok</Button>
            </Grid.Col>
          </Grid>
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  }

  return (
    <div>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => { }}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
              setDrawerObj(dra);
            }
        }
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
      {
        isLoading === true ? GlobalClass.RenderLoader("dots") :
          <div>
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: "flex" }}>

                <Grid.Col span={4} style={{ display: 'flex' }}>
                  <Grid.Col span={3} style={{ display: 'flex' }} >
                    <Text style={{ fontSize: 12, paddingLeft: 25, paddingTop: 10 }}>Account</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    {
                      enable === true ?
                        <PLDataGrid width={500} ref={inputRef}
                          value={data?.Account?.FIELD01}
                          TmData={state?.M01BA}
                          data={state?.M01BADT?.jData}
                          setEdit={(e) => {
                            console.log("Account Datagrid", e);
                            Promise.resolve(setData({ ...data, Account: e })).then(() => {
                              const receiptPayment = document.getElementById("receiptPayment")
                              receiptPayment && receiptPayment.focus()
                            })

                          }}
                          handleClose={() => {
                            // console.log("Account handleClose", data?.Account?.FIELD01);
                          }}
                        />
                        :
                        <PLTextBox value={data?.Account.FIELD02} disabled />
                    }
                    {
                      accBalance ?
                        <>
                          <Grid.Col span={12} style={{ display: 'flex' }}>
                            <Text style={{ fontSize: 12 }}>Balance</Text>
                            <Text style={{ fontSize: 12 }} m='auto'>{accBalance}</Text>
                          </Grid.Col>
                        </>
                        : null
                    }
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col span={8} style={{ display: 'flex' }}>
                  {
                    obj?.id == "04500447" ?
                      <Grid.Col span={4} style={{ display: 'flex' }}>
                        <Text style={{ fontSize: 12, padding: 5 }}>Receipt/<br />Payment</Text>
                        {
                          enable === true ?
                            <PLComboBox cmbid="receiptPayment" size="sm" value={data?.ReceiptPayment}
                              data={state?.COP99}
                              dispexpr="DisplayMember"
                              valexpr="ValueMember"
                              setEdit={(e) => {
                                setData({ ...data, ReceiptPayment: e })
                              }}
                            />
                            :
                            <PLComboBox size="sm" value={data?.ReceiptPayment} disabled />
                        }
                      </Grid.Col>
                      :
                      <Grid.Col span={4} style={{ display: 'flex' }}>
                        <Text style={{ fontSize: 12, padding: 5 }}>Type</Text>
                        {
                          enable === true ?
                            <PLComboBox size="sm" value={data?.Type}
                              data={state?.COP100}
                              dispexpr="DisplayMember"
                              valexpr="ValueMember"
                              setEdit={(e) => {
                                setData({ ...data, Type: e })
                              }}
                            />
                            :
                            <PLComboBox size="sm" value={data?.Type} disabled />
                        }
                      </Grid.Col>
                  }

                  <Grid.Col span={4} style={{ display: 'flex' }}>
                    <Text style={{ fontSize: 12, padding: 5 }}>From</Text>
                    <PLDateBox name="dateInput" zIndex={90} value={data?.From} dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        setData({ ...data, From: e })
                      }}
                    />
                  </Grid.Col>

                  <Grid.Col span={4} style={{ display: 'flex' }}>
                    <Text style={{ fontSize: 12, padding: 5 }}>To</Text>
                    <PLDateBox zIndex={90} value={data?.To} dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        setData({ ...data, To: e })
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>

              </Grid.Col>

              <Grid.Col span={12}>
                {
                  tablevisibility === true ?
                    <CashBankTable
                      obj={obj}
                      T41GRD={T41GRD}
                      T41={T41}
                      setT41={setT41}
                      T41List={T41List}
                      setT41List={setT41List}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      funEnable={(e) => {
                        setEnable(e)
                      }}
                      setNarrationValue={setNarrationValue}
                      setBalanceObj={setBalanceObj}
                      rowId={rowId}
                      setRowID={setRowID}
                      
                    // row={row}
                    // setRow={setRow}
                    />
                    : GlobalClass.RenderLoader("dots")
                }
              </Grid.Col>

            </Grid>
            <Grid mt={5}>
              <Grid.Col span={12} display='flex'>
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Balance</Text>
                  <Text style={{ fontSize: 12 }}>{balanceObj?.balance ? balanceObj?.balance : ""}</Text>
                  <Text style={{ fontSize: 12 }}>{balanceObj?.balance ? balanceObj?.CRDB : ""}</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <Button variant='light' size='sm' compact m={2} onClick={() => { enable === true && changeAccount() }}>Change A/C</Button>
                  <Button variant='light' size='sm' compact m={2} onClick={() => { enable === true && auditButton() }}>Audit</Button>
                  <Button variant='light' size='sm' compact m={2} onClick={() => { enable === true && deleteButton() }}>Delete</Button>
                  <Button variant='light' size='sm' compact m={2} onClick={() => { enable === true && changeDate() }}>Date</Button>
                  <Button variant='light' size='sm' compact m={2}>Print</Button>
                  <Button variant='light' size='sm' compact m={2} onClick={() => { enable === true && changeVouType() }}>Vou.Type</Button>
                </Grid.Col>
                <Grid.Col span={1}></Grid.Col>
              </Grid.Col>

              <Grid.Col span={12} display='flex'>
                {/* <Grid.Col span={6}>
                </Grid.Col>
                <Grid.Col> */}
                {
                  obj.id === "04500447" && ExpenseData?.dtExp &&
                  <ExpenseEntryForm
                    // ProductObj={selectedProductObj}
                    isExpense={false}
                    expenseArr={ExpenseData?.dtExp}
                    // // expenseArrDef={ExpenseData?.dtExpDef}
                    // // M01PData={state?.M01BADT?.jData}
                    // // M01Format={state?.M01BA}//Not Needed
                    valueSaveObj={T41}
                    TableExpenseValue={T41?.FIELD07}
                    // TableExpenseArr={T02Data}//Not Needed
                    onChange={(e) => {
                      console.log("ONCHANGEe", e);
                      if (e.FIELD06) {
                        setT41(e)
                        setBankAmount(e)
                      }
                      else {
                        setT41({ ...T41, FIELD06: e.FIELD07 })
                      }
      

                      // setFooterExpenseObj(e)
                      // setExpenseRowSum(e.FIELD07)
                      // console.log("TableExpenseEntry=>", e);
                      // if (e.FIELD06) {
                      //   setT41(e)
                      // }
                      // else {
                      //   setT41({ ...T41, FIELD06: e.FIELD07 })
                      // }

                      // setT41Flag(true)
                    }}
                    DrawerOBJ={DrawerObj}
                    setDrawerOBJ={(e) => setDrawerObj(e)}
                  />
                }
                {/* </Grid.Col> */}
              </Grid.Col>

              <Grid.Col span={12} display='flex'>
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>Narration</Text>
                  {
                    enable === true ? <Textarea value={narrationValue} disabled /> :
                      <Textarea value={T41?.FIELD15}
                        onChange={(e) => {
                          setT41((prevT41) => ({
                            ...prevT41,
                            FIELD15: e.target.value,
                          }))
                        }}
                        onKeyDown={(e) => {
                          // console.log("Narration=>", e);
                          if (e.ctrlKey && e.key === "F1") {
                            // console.log("Narration call");
                            // modals.open({
                            //   title: "Naration",
                            //   size: "50%",
                            //   zIndex: 301,
                            //   children: (
                            //     <>
                            //       <PLNarration
                            //         // NarrationVal={T41Obj}
                            //         // NarrationValObj={(e) => {
                            //         //     setT41Obj(e)
                            //         // }}
                            //         NarrationArray={NarrationData}
                            //         selectedNarrationArray={SelectedNarrationData}
                            //         // NarrationVal={T41Obj}
                            //         setNarrationArray={(e) => {
                            //           // setT41Obj(e)
                            //           // console.log("setNarrationArray", e);
                            //           setNarrationData(e.NarrationData);
                            //           setSelectedNarrationData(e.selectedNarration);
                            //         }}
                            //       />
                            //     </>
                            //   ),
                            // });
                            setDrawerObj({
                              ...dra,
                              title: <Text fw={700}>Naration</Text>,
                              body: (
                                <>
                                  <PLNarration
                                    // NarrationVal={T41Obj}
                                    // NarrationValObj={(e) => {
                                    //     setT41Obj(e)
                                    // }}
                                    NarrationArray={NarrationData}
                                    selectedNarrationArray={SelectedNarrationData}
                                    // NarrationVal={T41Obj}
                                    setNarrationArray={(e) => {
                                      // setT41Obj(e)
                                      // console.log("setNarrationArray", e);
                                      setNarrationData(e.NarrationData);
                                      setSelectedNarrationData(e.selectedNarration);
                                    }}
                                    DrawerOBJ={DrawerObj}
                                    setDrawerOBJ={(e) => {
                                      setDrawerObj(e);
                                    }}
                                  />
                                </>
                              ),
                              open: true,
                              size: "sm",
                              position: "right",
                            });
                          }
                        }}

                      />
                  }
                </Grid.Col>
                <Grid.Col span={4} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                  <Button onClick={importButton}>Import</Button>
                </Grid.Col>
                <Grid.Col span={4} >
                  {
                    obj.id === "04500447" && T41List?.length > 0 &&
                    <Grid.Col span={12} style={{ display: 'flex' }}>
                      <Text style={{ fontSize: 12, marginRight: 10, marginTop: 10 }}>{amountName}</Text>
                      <PLNumberBox decimalSeparator={true}
                        precision={2} value={bankAmount?.FIELD06} disabled />
                    </Grid.Col>
                  }
                  {/* {
                    obj.id === "04500447" && T41List?.length < 0 &&
                    <Grid.Col span={12} style={{ display: 'flex' }}>
                      <Text style={{ fontSize: 12, marginRight: 10, marginTop: 10 }}>Cash Amount</Text>
                      <PLNumberBox value="0.00" disabled />
                    </Grid.Col>
                  } */}
                </Grid.Col>
              </Grid.Col>
            </Grid>
          </div>
      }
    </div>
  )
}





