import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { createColumnHelper } from "@tanstack/react-table";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import PRUserField from "./PRUserField";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../utils/gensetting";
import Store from "../../utils/store";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { GetAccountList, GetTMFormat } from "../../utils/slices/AccountListSlice";
import {
  BatchLogAddRemoveFun,
  TransactionApi,
} from "../../utils/TransactionSlices/Transaction";
import PLNarration from "../../PlusComponent/PLNarration";
import PRUserFieldEntry from "./PRUserFieldEntry";
import PLTextarea from "../../PlusComponent/PLTextarea";
import PLUserFieldBtn from "../../PlusComponent/PLUserFieldBtn";

const ProductionForm = (props) => {
  const { obj } = props;
  // console.log('production Form obj==>>', obj)
  const formRef = useRef();
  const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend)
  const { isUserFieldLoading, UserField } = useSelector(store => store?.Transaction)
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const ProductionData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  console.log('OSV', OSV)
  const P_M73DT = useSelector((state) => state.Transaction?.TransactionData?.P_M73DT);
  const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
  console.log('OS11', OS11)
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const T02ENT = useSelector((state) => state.Transaction?.TransactionData?.T02ENT);
  const T02UF = useSelector((state) => state.Transaction?.TransactionData?.T02UF);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector((state) => state.Transaction?.TransactionData?.T92DT);
  const P_M71 = useSelector((state) => state.Transaction?.TransactionData?.P_M71);
  const P_M71DT = useSelector((state) => state.Transaction?.TransactionData?.P_M71DT);
  const UFT41H = useSelector((state) => state.Transaction?.TransactionData?.UFT41H);
  const PLDATA = useSelector((state) => state.Transaction?.TransactionData?.PLDATA);
  const dtUFT11 = useSelector((state) => state.Transaction?.TransactionData?.dtUFT11);
  const lEditVou = useSelector((state) => state.Transaction?.TransactionData?.lEditVou);
  const { oYear } = useSelector((state) => state?.Language?.General);
  const {
    isLoading: AccountisLoading,
    AccountListData: accList,
    TMFormatData: tmList
  } = useSelector((state) => state.AccountList);

  // useEffect(() => {
  //   console.log("AccountisLoading['T_M32G']", AccountisLoading["T_M32G"])
  //   console.log("AccountisLoading['P_M21']", AccountisLoading["P_M21"])
  //   console.log("AccountisLoading['T_M32C']", AccountisLoading["T_M32C"])
  // }, [AccountisLoading["T_M32C"], AccountisLoading["P_M21"], AccountisLoading["T_M32G"]])

  const dispatch = useDispatch();

  const [T41Obj, setT41Obj] = useState({
    FIELD04NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "",
    FIELD07: "",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "O",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "IN",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    USALM: "",
    UDISP: "",
    UPAYT: "",
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    UCRE: "",
    UCRE2: "",
    UDCNO: "",
    UDE: "",
    UDEL: "",
    UDT: "",
    ULEN: "",
    ULRD: "",
    UOD: "",
    UODD: "",
    USAL: 0,
    UTR: "",
    UUUASD09: "",
    UUJA: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUSALM: "",
    UUUOS: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    UREST6: "",
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F02: "",
    CFRMTITLE: "Add Production",
    BTOBEFF: " ",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });

  const [T02Obj, setT02Obj] = useState({
    CATNM: "",
    GRPNM: "",
    F03NM: "",
    F20NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    UGRO: 0.0,
    URATE: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    UAP: 0.0,
    URE: "",
    UISSUERA: 0.0,
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    UUDIS: "",
    UUDISAAA: "",
    UUGROAAA: "",
    UUGR01: "",
    UUGRO: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UDIS: 0.0,
    UUOS: "",
    UT76: "",
    USERFLD: "",
    USERFLDB: "2",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    _EXPTOTALAMT: 0.0,
  });
  // console.log('T02Obj==>', T02Obj)
  const [T02Data, setT02Data] = useState([]);
  // console.log('T02Data', T02Data)
  const [columns, setColumns] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  }
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [FIELD01Value, setFIELD01value] = useState("");
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [T02FilteredRow, setT02FilteredRow] = useState([])
  const [processVal, setProcessVal] = useState(null)
  const [rowSelection, setRowSelection] = useState({})
  console.log('rowSelection', rowSelection)
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true
  })
  const [userFieldHideShow, setUserFieldHideShow] = useState("")

  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    "Group": "",
    "Catogary": "",
    "Product": ""
  })
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [BatchIssueObj, setBatchIssueObj] = useState()
  const [recieptIssueVal, setRecieptIssueVal] = useState()
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [LocationObj, setLocationObj] = useState()
  const [AddMode, setAddMode] = useState(true)
  const [flag, setFlag] = useState(false)
  const [eventMode, setEventMode] = useState(false)

  const pr = useRef();
  const VoucherRef = useRef();
  const QtyRef = useRef()

  function parseDateString(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Months are 0-indexed
    const day = dateString.substring(6, 8);

    return new Date(year, month, day);
  }

  // Example date string
  const dateString = T41Obj?.FIELD02;
  const date = parseDateString(dateString);

  // Step 2: Format the Date object
  function formatDate(date) {
    return date.toString(); // Converts to a string in the format you need
  }

  // Get formatted date string
  const formattedDate = formatDate(date);
  // console.log("formattedDate",formattedDate);

  useEffect(() => {
    if (formattedDate) {
      setSelectedDay(formattedDate)
    }
  }, [formattedDate])

  useEffect(() => {
    console.log('selectedProductObj', selectedProductObj)
    console.log('T41Obj', T41Obj)
  }, [selectedProductObj, T41Obj])

  useEffect(() => {
    if (oYear) {
      setUserFieldHideShow(oYear?.OSC?._USERFIELD)
    }
  }, [oYear])

  // useEffect(() => {
  //   if (OS11?.S11F22 == "I") {
  //     setT02Obj({
  //       ...T02Obj,
  //       FIELD05: "D"
  //     })
  //   } else {
  //     setT02Obj({
  //       ...T02Obj,
  //       FIELD05: "C"
  //     })
  //   }
  // }, [OS11?.S11F22])

  // let newRow = { ...T02Obj };

  useEffect(() => {
    if (obj) {
      Promise.resolve(dispatch(TransactionApi(obj)))
        .then(() => {
          // Promise.resolve(dispatch(GetAccountList({
          //   "id": "T_M32G",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32G",
          //   "p1": "T_M32G",
          //   "p2": "",
          //   "p3": ""
          // }))).then(() => dispatch(GetTMFormat({
          //   "id": "T_M32G",
          //   "name": "",
          //   "type": "A",
          //   "p0": "T_M32G",
          //   "p1": "T_M32G",
          //   "p2": "",
          //   "p3": ""
          // })))
          Promise.resolve()
            .then(() => dispatch(GetAccountList({
              "id": "T_M32C",
              "name": "",
              "type": "A",
              "p0": "T_M32C",
              "p1": "T_M32C",
              "p2": "",
              "p3": ""
            }))).then(() => dispatch(GetTMFormat({
              "id": "T_M32C",
              "name": "",
              "type": "A",
              "p0": "T_M32C",
              "p1": "T_M32C",
              "p2": "",
              "p3": ""
            })))
        }).then(() => dispatch(GetAccountList({
          "id": "P_M21",
          "name": "",
          "type": "A",
          "p0": "P_M21",
          "p1": "P_M21",
          "p2": "",
          "p3": ""
        }))).then(() => dispatch(GetTMFormat({
          "id": "P_M21",
          "name": "",
          "type": "A",
          "p0": "P_M21",
          "p1": "P_M21",
          "p2": "",
          "p3": ""
        })))
    }
  }, [obj]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41);
    }
  }, [T41]);

  useEffect(() => {
    if (T02) {
      // const updatedT02 = T02.slice(0, -1);
      const updatedT02 = T02?.filter((v, i) => v?.FIELD03 !== "")
      console.log('updatedT02', updatedT02)
      setT02Data(updatedT02);
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0])
      }
    }
  }, [T02]);

  useEffect(() => {
    //   console.log('T02Data', T02Data)
    //   console.log('tableEditingRow', tableEditingRow)
    //   console.log('selectedProductObj', selectedProductObj)
    console.log('T02Obj', T02Obj)
    //   console.log('GroupCatogaryProduct', GroupCatogaryProduct)
    //   console.log('LocationObj', LocationObj)
    //   console.log('oYear', oYear)
    //   console.log('T02FilteredRow', T02FilteredRow)
    //   console.log('flag', flag)
    //   console.log('BatchIssueObj', BatchIssueObj)
  }, [selectedProductObj, BatchIssueObj, tableEditingRow, flag, T02Obj, GroupCatogaryProduct, LocationObj, T02Data, tableEditingRow, oYear, T02FilteredRow])

  let stringOfNames;
  useEffect(() => {
    if (SelectedNarrationData.length >= 0) {
      const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  // for T02 Row Add
  useEffect(() => {
    if (P_M73DT && T41Obj?.FIELD04 !== "" && obj.p0 == "A") {
      const FilteredArr = P_M73DT?.filter((v) => v?.FIELD01 == T41Obj?.FIELD04);
      // console.log("FilteredArr", FilteredArr)
      setT02FilteredRow(FilteredArr)
    }
  }, [P_M73DT, T41Obj?.FIELD04, obj])

  const flagRef = useRef(flag);

  useEffect(() => {
    flagRef.current = flag;
  }, [flag]);

  let updatedData = [];
  useEffect(() => {
    if (T02FilteredRow?.length > 0 && T41Obj?.FIELD18 !== 0 && processVal !== null && obj?.p0 != "E" && flagRef.current) {

      // setT02Data([]);
      T02FilteredRow?.map((r) => {
        let tempT02 = { ...T02Obj }

        tempT02.FIELD03 = r?.FIELD04;
        tempT02.FIELD05 = r.FIELD07 === "O" ? "D" : "C";
        tempT02.FIELD14 = r.FIELD00;
        tempT02.FIELD19 = r.FIELD08;

        accList["P_M21"]?.map((p) => {

          if (r?.FIELD04 == p?.FIELD01) {

            console.log("match data==>", p);
            tempT02.FIELD07 = tempT02?.FIELD05 == "D" ? p?.FIELD21 : p?.FIELD22
            let RateConv = tempT02.FIELD05 == "D" ? p?.FIELD10 : p?.FIELD18
            let nResultRate = tempT02.FIELD07 / RateConv
            console.log('RateConv', RateConv)
            console.log('nResultRate', nResultRate)
            tempT02.FIELD22 = r.FIELD27 == 0 ? p.FIELD28 : r.FIELD27
            tempT02.FIELD06 = parseInt(((r.FIELD05 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))
            tempT02.FIELD08 = parseInt((tempT02?.FIELD06 * nResultRate).toFixed(0))
            tempT02.FIELD33 = parseInt(((r.FIELD05 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))
            tempT02.FIELD21 = parseInt(((r.FIELD26 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))

            updatedData.push({
              ...tempT02,
              F03NM: p?.FIELD02
            })
          }
        })
        // return tempT02
      });
      console.log("updatedData==>", updatedData);
      //  Promise.resolve(setT02Data([...T02, ...updatedData]))
      // .then(() => setFlag(false))
      setT02Data([...updatedData])
      setFlag(false)
    } else if (T02FilteredRow?.length === 0 && obj?.p0 !== "E" && flagRef.current) {
      // Clear or reset T02Data when T02FilteredRow is empty
      setT02Data([T02]); // or any default value you need
      setFlag(false);
    }
    //  else {
    //   updatedData = []
    //   // setT02Data()
    //   setFlag(false)
    //   setT02Data([...T02, ...updatedData])
    // }
  }, [T02FilteredRow, T41Obj?.FIELD18, accList["P_M21"], tmList, processVal, obj?.p0]);

  useEffect(() => {
    if (T02Obj?.FIELD05 == "D" && BatchIssueObj !== undefined) {
      setT02Obj({
        ...T02Obj,
        FIELD41: BatchIssueObj?.FIELD02 ?? T02Obj?.FIELD41,
      })
    }
  }, [BatchIssueObj, T02Obj?.FIELD41])

  // pricelist value set
  useEffect(() => {
    setT02Obj({
      ...T02Obj,
      FIELD27: priceListParemeter?.priceListCode
    })
  }, [priceListParemeter])

  // useEffect(() => {
  //   Promise.resolve()
  //     .then(() => {
  //       setT02Obj({
  //         ...T02Obj,
  //         FIELD07: T02Obj?.FIELD05 === "C" ? selectedProductObj?.FIELD21 : T02Obj?.FIELD05 === "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 ?? 0,
  //       })
  //     })
  // }, [selectedProductObj, T02Obj?.FIELD07])

  //   useEffect(() => {
  // if(tableEditingRow !== null) {
  //  const filterObj = accList["P_M21"]?.filter((v) => v?.FIELD01 == tableEditingRow?.FIELD01)
  //  console.log('filterObj',filterObj)
  //  setSelectedProductObj(filterObj)
  // }
  //   },[tableEditingRow])

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
    }
  }, [BillSeriesHideShow.BillNoVal])

  // Final Submit
  const handleSubmit = (e) => {
    // console.log('JData', JData)
    if (obj.p0 == "E" && ((JSON.stringify(T02) === JSON.stringify(T02Data)) && (JSON.stringify(T41) === JSON.stringify(T41Obj)))) {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
      return;
    }
    // console.log('T02Data=>>', T02Data)
    // if (T41Obj?.FIELD18 == 0) {
    //   GlobalClass.Notify("warning", "Production Qauntity cannot be Zero")
    // }
    // else if (T02Obj?.FIELD41 == "" && obj.p0 == "A") {
    //   GlobalClass.Notify("warning", "Batch Required")
    // } 
    else {
      dispatch(DataSaveLoading(true));
      GlobalClass.Notify(
        "info",
        obj.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      );
      var JData = { T41: e ? e : { ...T41Obj }, T02: T02Data };

      console.log("JData==>", JData);
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj.p0 == "E" ? obj.id : "ININ",
        cSData: JSON.stringify(JData),
      };
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj.p0 == "E" ? obj.id : "ININ",
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log('api data', data);
          // console.log('api data status', data?.status);
          if (data.status == "SUCCESS") {
            let PaginationObj =
              Store.getState().DataSaveLoadingSlice.PaginationObj;
            Promise.resolve(dispatch(DataSaveLoading(false)))
              .then(() => {
                if (obj.p0 == "A") {
                  Promise.resolve(dispatch(TransactionApi(obj)))
                    .then(() => {
                      // Promise.resolve(dispatch(GetAccountList({
                      //   "id": "T_M32G",
                      //   "name": "",
                      //   "type": "A",
                      //   "p0": "T_M32G",
                      //   "p1": "T_M32G",
                      //   "p2": "",
                      //   "p3": ""
                      // }))).then(() => dispatch(GetTMFormat({
                      //   "id": "T_M32G",
                      //   "name": "",
                      //   "type": "A",
                      //   "p0": "T_M32G",
                      //   "p1": "T_M32G",
                      //   "p2": "",
                      //   "p3": ""
                      // })))
                      Promise.resolve()
                        .then(() => dispatch(GetAccountList({
                          "id": "T_M32C",
                          "name": "",
                          "type": "A",
                          "p0": "T_M32C",
                          "p1": "T_M32C",
                          "p2": "",
                          "p3": ""
                        }))).then(() => dispatch(GetTMFormat({
                          "id": "T_M32C",
                          "name": "",
                          "type": "A",
                          "p0": "T_M32C",
                          "p1": "T_M32C",
                          "p2": "",
                          "p3": ""
                        })))
                    }).then(() => dispatch(GetAccountList({
                      "id": "P_M21",
                      "name": "",
                      "type": "A",
                      "p0": "P_M21",
                      "p1": "P_M21",
                      "p2": "",
                      "p3": ""
                    }))).then(() => dispatch(GetTMFormat({
                      "id": "P_M21",
                      "name": "",
                      "type": "A",
                      "p0": "P_M21",
                      "p1": "P_M21",
                      "p2": "",
                      "p3": ""
                    })))
                } else {
                  dispatch(ModalDelete(props.index))
                }
              })
              .then(() =>
                dispatch(
                  GetAccountList({
                    id: "03900002",
                    name: "Production",
                    p0: "MT",
                    p1: "TXNVOULISTIN",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["03900002"],
                  })
                )
              )
              .then(() =>
                GlobalClass.Notify(
                  "success",
                  obj.p0 == "A" ? "Added" : "Edited",
                  obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                )
              );
          } else {
            Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
              GlobalClass.Notify("error", data?.status, data?.message)
            );
          }
        })
        .catch((e) => {
          Promise.resolve(
            GlobalClass.Notify("error", "Error", `${e.message}`)
          ).then(() => dispatch(DataSaveLoading(false)));

        });
    }
    // console.log('data', data)
  };

  const handleNullT02Obj = () => {
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    setBatchIssueObj()
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    setTableEditingRow(null)
    setSelectedProductObj({})
    setAddMode(true)
  };

  const DeleteRow = (row) => {

    console.log("DeleteRow", row);
    if (window.confirm('Confirm to Delete ?')) {
      let newArray = T02Data?.filter((d, i) => i != row?.index)
      setT02Data(newArray)
    }
  }

  // Amount Calculation
  useEffect(() => {
    if (T02Obj?.FIELD33 !== null && T02Obj?.FIELD07 !== null) {
      setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
    }
  }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

  // Quantity Calculation
  useEffect(() => {
    if (T02Obj?.FIELD21 !== null && T02Obj?.FIELD22 !== null) {
      setT02Obj({ ...T02Obj, FIELD33: T02Obj?.FIELD21 * T02Obj?.FIELD22 });
    }
  }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

  // useEffect(() => {
  //   if (T02Obj?.FIELD33) {
  //     setT02Obj({ ...T02Obj, FIELD21: parseInt(T02Obj?.FIELD33) / parseInt(T02Obj?.FIELD22) })
  //   }
  // }, [T02Obj?.FIELD33])

  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    console.log("nextInd=>", nextInd)
    const nextInput = document.getElementById(`T02Ent_${nextInd}`)
    console.log("nextInput=>", nextInput)

    if (nextInput) {
      nextInput.focus()
    }
  }

  const columnHelper = createColumnHelper();

  const rowSum = T02Data.reduce((a, b) => {
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD08 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD08 || 0);
    }
    return a;
  }, 0)

  let QtySum = T02Data.reduce((a, b) => {
    // (a = a + b?.FIELD33)
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD33 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD33 || 0);
    }
    return a;
  }, 0);
  let Qty2Sum = T02Data.reduce((a, b) => {
    // (a = a + b?.FIELD21)
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD21 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD21 || 0);
    }
    return a;
  }, 0);

  const Summary = (obj, i, tag) => {
    var value = "";

    switch (obj?.S53F03) {
      // case "Product Name":
      //   value = (
      //     <Text py={2}>
      //       {" "}
      //       Total Entry :{" "}
      //       {/* {selectedRow ? TotalRow + "/" + (selectedRow?.index + 1) : TotalRow} */}
      //       {T02Data?.length}
      //     </Text>
      //   );
      //   break;
      case "Qty":
        value = QtySum;
        // T02Data.reduce((a,b) => a = a + b?.FIELD33, 0)
        break;
      case "F. Qty":
        value = T02Data?.reduce((a, b) => (a = a + b?.FIELD81), 0);
        break;
      case "Amount":
        value = rowSum;
        // rowSum
        break;
      case "Qty-2":
        value = Qty2Sum;
        // T02Data.reduce((a,b) => a = a + b?.FIELD21, 0)
        break;
      default:
        value = null;
        break;
    }
    return value;
  };

  const productDataFun = () => {
    if (GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary == "") {
      console.log('if 1')
      return accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group)
    } else if (GroupCatogaryProduct?.Catogary != "" && GroupCatogaryProduct?.Group == "") {
      console.log('if 2')
      return accList["P_M21"]?.filter((a) => a?.FIELD12 == GroupCatogaryProduct?.Catogary)
    } else if (GroupCatogaryProduct?.Group != "" && GroupCatogaryProduct?.Catogary != "") {
      console.log('if 3')
      return accList["P_M21"]?.filter((a) => a?.FIELD11 == GroupCatogaryProduct?.Group && a?.FIELD12 == GroupCatogaryProduct?.Catogary)
    } else {
      console.log('if 4')
      return accList["P_M21"]
    }
    // return data
  }

  const renderInputBox = (uID, v, c, cellid, index) => {
    // console.log('v=>', v)
    if (c?.S53F07 == 0) {
      return <Button
        id={`T02Ent_${index}`}
        variant="default"
        onFocus={() => {
          document.activeElement.blur()
          console.log("Test=>", `T02Ent_${index}`)
          HiddenButtonField(uID, v, c, cellid, index)
        }}
        onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
        style={{ padding: "0px", margin: "0px" }}
      />
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "PLPOPUP":
          if (c.S53F02 == "E00FIELD03") { // Product Name
            setInput = <PLDataGrid
              ref={pr}
              id={`T02Ent_${index}`}
              width={'500px'}
              // autoFocus={true}
              // cWhere={`M21.FIELD11 LIKE '%${GroupCatogaryProduct?.Group}%' AND M21.FIELD12 LIKE '%${GroupCatogaryProduct?.Catogary}%'`}
              value={T02Obj?.[c?.S53F04]}
              // p1={seperate(c.S53F15)[1]}
              // p1={productDataFun()}
              TmData={tmList["P_M21"]?.cSTID}
              data={productDataFun()}
              setEdit={(e) => {
                console.log("Product Name PLDataGrid", e);
                Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02, FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28 }))
                  .then(() => setSelectedProductObj(e))
                  .then(() => setFIELD01value(e.FIELD01))
                  .then(() => {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  })
              }}
            />
          } else if (c.S53F02 === "E00FIELD20") { // Location
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={{ value: T02Obj[c?.S53F04], label: T02Obj?.F20NM == "" ? "Select" : T02Obj?.F20NM }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  Promise.resolve()
                    .then(() => {
                      setT02Obj({
                        ...T02Obj,
                        [c?.S53F04]: e?.FIELD01,
                        F20NM: e?.FIELD02
                      })
                    })
                    .then(() => {
                      if (selectedProductObj?.FIELD51 === "N" && dtUFT11?.length > 0) {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      } else {
                        setTimeout(() => {
                          NextIndexFun(index + 1)
                        }, 500)
                      }
                    })
                }}
              />
            );
          } else {
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={T02Obj[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })).then(() => {
                    if (dtUFT11 && selectedProductObj?.FIELD51 === "N") {
                      NextIndexFun(index)
                    } else {
                      NextIndexFun(index + 1)
                    }
                  })
                }}
              />
            );
          }
          break;
        case "COMBOBOX":
          if (c.S53F02 == "E00FIELD05") { // Receipt/Issue
            // console.log('receipt/issue call')
            setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                // autoFocus={true}
                value={T02Obj[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  console.log('reciept/issue value', e.value)
                  Promise.resolve(setRecieptIssueVal(e))
                    .then(() => setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))
                    .then(() => {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    })
                }}
              />
            );
          }
          else {
            setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                // autoFocus={true}
                value={T02Obj[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })).then(() => {
                    NextIndexFun(index)
                  })
                }}
              />
            );
          }
          break;
        case "TEXTBOX":
          if (c?.S53F02 == "E00FIELD41") { // Batch Receipt
            console.log('Batch Receipt Call')
            setInput = (
              <>
                <PLTextBox
                  disabled={selectedProductObj?.FIELD51 === "N" && T02Obj?.FIELD05 == "D" ? true : false}
                  id={`T02Ent_${index}`}
                  value={T02Obj?.[c?.S53F04]}
                  // onKeyDown={(e) => {
                  //   if (e.key == "Tab") {
                  //     setTimeout(() => {
                  //       NextIndexFun(index)
                  //     }, 500)
                  //   }
                  // }}
                  setEdit={(e) => {
                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                  }}
                />
              </>
            );
          } else {
            setInput = (
              <PLTextBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    NextIndexFun(index)
                  }
                }}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                }}
              />
            );
          }
          break;
        case "NUMBOX":
          if (c?.S53F02 == "E00FIELD21") { // Qty - 2
            setInput = (
              <PLNumberBox
                disabled={selectedProductObj?.FIELD59 == "N" ? true : false}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    NextIndexFun(index - 1)
                  }
                }}
              />
            );
          } else if (c?.S53F02 == "E00FIELD33") { // Qty
            setInput = (
              <PLNumberBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    if (selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D" && oYear?.OSC?._BATCHSTOC == "Y") {
                      NextIndexFun(index + 1)
                    } else if (oYear?.OSC?._BATCHSTOC == "Y") {
                      NextIndexFun(index - 1)
                    }
                  }
                }}
              />
            );
          } else if (c?.S53F02 == "E00FIELD22") { // conversion
            setInput = (
              <PLNumberBox
                disabled={selectedProductObj?.FIELD59 == "N" ? true : false}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    NextIndexFun(index - 1)
                  }
                }}
              />
            );
          } else {
            setInput = (
              <PLNumberBox
                decimalSeparator={c.S53F02 == "E00FIELD08" || c.S53F02 == "E00FIELD07" ? true : false}
                precision={c.S53F02 == "E00FIELD08" || c.S53F02 == "E00FIELD07" ? 3 : 0}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                // onKeyUp={(e) => {
                //   console.log("Enter key Press", e)
                //   if (c.S53F02 == "E00FIELD08" && index == `T02Ent_${index}`) { // Amount
                //     if (e.key == "Enter") {
                //       if (tableEditingRow == null) {
                //         console.log("Add Product Call")
                //         if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                //           GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                //         } else {
                //           Promise.resolve(handleCreateRow()).then(() => table.setCreatingRow(true))
                //         }
                //       } else {
                //         console.log("Edit Product Call")
                //         if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                //           GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                //         } else {
                //           Promise.resolve(handleEditSave()).then(() => table.setCreatingRow(true))
                //         }
                //       }
                //     }
                //   }
                // }}
                onKeyDown={(e) => {
                  // e.stopPropagation();
                  if (e.key == "Tab" || e.key == "Enter") {
                    if (c.S53F02 == "E00FIELD08") { // Amount
                      if (tableEditingRow == null) {
                        console.log("Add Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {
                          Promise.resolve().then(() => handleCreateRow()).then(() =>
                            setTimeout(() => {
                              if (obj.p0 == "E") {
                                setT02Obj(T02?.[T02?.length - 1]);
                              } else {
                                setT02Obj(T02?.[0])
                              }
                              table.setCreatingRow(true)
                            }, 500)
                          )
                        }
                      } else {
                        console.log("Edit Product Call")
                        if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {
                          Promise.resolve().then(() => handleEditSave())
                            .then(() => table.setEditingRow(null))
                            .then(() => {
                              setTimeout(() => {
                                if (obj.p0 == "E") {
                                  setT02Obj(T02?.[T02?.length - 1]);
                                } else {
                                  setT02Obj(T02?.[0])
                                }
                                table.setCreatingRow(true)
                              }, 500)
                            })
                        }
                      }
                    } else if (selectedProductObj?.FIELD51 === "N" && c?.S53F02 == "E00FIELD41") {
                      console.log('if call')
                      NextIndexFun(index)
                    } else {
                      console.log('else call')
                      NextIndexFun(index - 1)
                    }
                  }
                }}
              />
            );
          }
          break;
        case "DATEPICKER":
          setInput = <PLDateBox dispformat="YYYY/MM/DD" disabled />;
          break;
        default:
          setInput = null;
          break;
      }
      return setInput;
    }
  };

  const HiddenButtonField = (uID, v, c, cellid, index) => {

    switch (c.S53F02) {
      case "E00FIELD65": //Purchase Detail
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
          },
          body: <>
            <PLTextBox
              id={`T02Ent_${index}`}
              value={T02Obj[c?.S53F04]}
              setEdit={(e) => {
                setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
              }}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
              <Button
                onClick={() => {
                  // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                  Promise.resolve(NextIndexFun(index))
                    .then(() => {
                      setDrawerObj({ ...DrawerObj, open: false })
                    })
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter" && c.S53F02 == "E00FIELD65") {
                    Promise.resolve()
                      .then(() => {
                        setDrawerObj({ ...DrawerObj, open: false })
                      })
                      .then(() => {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      })
                  }
                }}
              >OK</Button>
            </div>
          </>,
          open: true,
          size: "sm",
          position: "right"
        })
        break;
      case "E00FIELD86": // Quantity Detail
        console.log('Quantity Detail')
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
          },
          body: <>
            <PLTextBox
              id={`T02Ent_${index}`}
              value={T02Obj[c?.S53F04]}
              setEdit={(e) => {
                setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
              }}
            />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
              <Button
                onClick={() => {
                  // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                  Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                    .then(() => {
                      setTimeout(() => {
                        if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 == "Y" && T02Obj?.FIELD05 == "D") {
                          NextIndexFun(index)
                        } else {
                          NextIndexFun(index + 1)
                        }
                      }, 500)
                    })
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        setTimeout(() => {
                          if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 == "Y" && T02Obj?.FIELD05 == "D") {
                            NextIndexFun(index)
                          } else {
                            NextIndexFun(index + 1)
                          }
                        }, 500)
                        // selectedProductObj?.FIELD51 == "Y" && T02Obj?.FIELD05 == "D" && oYear?.OSC?._BATCHSTOC == "Y" ? NextIndexFun(index) : NextIndexFun(index + 1)
                      }
                      )
                  }
                }}
              >OK</Button>
            </div>
          </>,
          open: true,
          size: "sm",
          position: "right"
        })
        break;
      case "E00FIELD11": // Batch Issue
        console.log('Batch Issue')
        if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 == "Y" && T02Obj?.FIELD05 == "D") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PLDataGrid
                autoFocus={true}
                // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                id={`T02Ent_${index}`}
                cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                width={"500px"}
                value={T02Obj?.[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                // p1={'P_M21'}
                setEdit={(e) => {
                  console.log("Batch Issue Object=>", e);
                  setBatchIssueObj(e)

                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        if (T02UF && T02UF?.length > 0) {
                          NextIndexFun(index)
                        } else {
                          NextIndexFun(index + 1)
                        }
                      })
                  }}
                  onKeyUp={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                        .then(() => {
                          setTimeout(() => {
                            if (T02UF && T02UF?.length > 0) {
                              NextIndexFun(index)
                            } else {
                              NextIndexFun(index + 1)
                            }
                          }, 500)
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>,
            open: true,
            size: "40%",
            position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00USERFLD": // user Field
        console.log("userfield call");
        if (T02UF && T02UF.length > 0) {
          setDrawerObj({
            ...dra, title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PRUserFieldEntry
                T02UF={T02UF}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve(setT02Obj(e)).then(() => {
                    if (selectedProductObj?.FIELD59 == "N") {
                      NextIndexFun(index + 2)
                    } else {
                      NextIndexFun(index)
                    }
                  })
                  // console.log("FormUserField=>",e);
                }}
                DrawerObjSend={DrawerObj}
                setDrawerObjSend={(e) => {
                  setDrawerObj(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00USERFLDB": //Batch User Field
        console.log('Batch User Field')
        if (selectedProductObj?.FIELD51 === "N" && dtUFT11 && dtUFT11?.length > 0) {
          setDrawerObj({
            ...dra, title: <Text fw={700}>Batch User FIeld</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PRUserField
                dtUFT11={dtUFT11}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve()
                    .then(() => setT02Obj(e))
                    .then(() => {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    })
                  // console.log("FormUserField=>",e);
                }}
                DrawerObjSend={DrawerObj}
                setDrawerObjSend={(e) => {
                  setDrawerObj(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00T02F23": // PriceList
        console.log('priceList')
        if (selectedProductObj?.FIELD53 == "N") {
          NextIndexFun(index)
        } else {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PLComboBox
                initiallyOpened={true}
                cmbid={`T02Ent_${index}`}
                data={typeof PLDATA == "string" ? [{}] : PLDATA}
                value={T02Obj?.[c?.S53F04]}
                dispexpr={"FIELD02"}
                valexpr={"FIELD01"}
                setEdit={(e) => {
                  // console.log("Pricelist OBJ=>", e);
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                    if (T41Obj.FIELD16 == "C") {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD31,
                        priceListCode: e,
                      });
                    } else {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD04,
                        priceListCode: e,
                      });
                    }
                  })
                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        NextIndexFun(index)
                      })
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                        .then(() => {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>, open: true, size: "sm", position: "right"
          })
        }
        break;
      default:
        break;
    }
  }

  const ProductGrpFun = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Product Group</Text>,
      body: <>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
          <Button
            onClick={() => {
              // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
              setDrawerObj({ ...DrawerObj, open: false })
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                setDrawerObj({ ...DrawerObj, open: false })
              }
            }}
          >OK</Button>
        </div>
      </>,
      open: true,
      size: "sm",
      position: "right"
    })
  }

  //  Columns
  useEffect(() => {
    if (T02ENT) {
      let cols = [];
      // cols.push(
      //   columnHelper.accessor('GRPCD', {
      //     header: "Product Group",
      //     id: "Product_Group",
      //     size: 150,
      //     Edit: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return <PLDataGrid
      //         autoFocus={true}
      //         width={"500px"}
      //         TmData={tmList["T_M32G"]?.cSTID}
      //         // data={accList["T_M32G"]}
      //         value={{ value: T02Obj?.GRPCD, label: T02Obj?.GRPNM }}
      //         setEdit={(e) => {
      //           console.log("Product Group Obj=>", e);
      //           setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
      //           setT02Obj({ ...T02Obj, GRPCD: e?.FIELD01, GRPNM: e?.FIELD02 })
      //         }}
      //       />
      //     },
      //     Cell: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return T02Data[row.id]?.GRPNM
      //     },
      //   })
      // )
      cols.push(
        columnHelper.accessor('CATCD', {
          header: "Product Category",
          id: "Product_Category",
          size: 150,
          Edit: ({ cell, row }) => {
            let val = cell.getValue();
            return <PLDataGrid
              // autoFocus={true}
              width={"500px"}
              TmData={tmList["T_M32C"]?.cSTID}
              // data={accList["T_M32C"]}
              value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);
                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                Promise.resolve(setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 }))
                  .then(() => {
                    pr?.current?.handlePopoverToggle();
                  });
              }}
            />
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return T02Data[row.id]?.CATNM

          },
        })
      )
      T02ENT?.filter((v, i) => v?.S53F00 === "C").map((v, i) => {
        cols.push(
          columnHelper.accessor(v.S53F04, {
            // enableEditing: v.S53F03 == "Product Name" ? false : true,
            header: v.S53F07 == 0 ? "" : v.S53F03,
            id: v.S53UID,
            size: v.S53F07 == 0 ? 0 : 150,
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(v.S53UID, val, v, cell?.id, i);
            },
            Cell: ({ cell, row }) => {
              let val = cell.getValue();
              if (v.S53F02 == "E00FIELD03") {
                return T02Data[row.id]?.F03NM ?? T02Data[row.id]?.PrdNM
                // return "Test"
              } else if (v.S53F02 == "E00FIELD20") {
                return T02Data[row.id]?.F20NM
              }
              return v.S53F07 == 0 ? "" : val
            },
            Footer: () => <div>{v.S53F02 == "E00FIELD03" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(v, i, "c")}</div>,
          })
        );
      });
      return setColumns(cols);
    }
  }, [T02Data, T02ENT, T02, T02Obj, selectedProductObj, accList, tmList, GroupCatogaryProduct, T02Data]);

  const UFT41HRenderField = (e) => {
    //S13F02 Save UserField
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H?.map((h) => {
          // console.log("User Field=>", h);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            // //console.log("UFT41HRenderHeader==>",h);
            let inputBox;
            switch (h?.S13F14) {
              case "TEXTBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        nmaxlength={h?.S13F04}
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "NUMBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        nmaxlength={h?.S13F04}
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "PLPOPUP":

                if (h.S13F07 == "Sales Executive") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLDataGrid
                          setFocus={(e) => {
                            // console.log("page",e);
                            if (spoilerControlRef?.current?.innerText == "Show more") {
                              spoilerControlRef.current.click()
                            }
                          }}

                          width={"500px"}
                          name={h.S13F02}
                          value={T41Obj?.[h?.S13F02]}
                          p0={seperate(h.S13F09)[0]}
                          p1={seperate(h.S13F09)[1]}
                          // dispexpr={"USALES_E"}
                          setEdit={(e) => {
                            console.log("@P", e);
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 })
                            // M01handlechange({ id: item.S13F02, value: e })
                            // setUserFieldSpoiler(true)
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLDataGrid
                          width={"500px"}
                          name={h.S13F02}
                          value={T41Obj?.[h?.S13F02]}
                          p0={seperate(h.S13F09)[0]}
                          p1={seperate(h.S13F09)[1]}
                          setEdit={(e) => {
                            //console.log(e);
                            console.log("@P", e);
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                            // M01handlechange({ id: item.S13F02, value: e })
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }

                break;
              case "COMBOBOX":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        value={T41Obj?.[h?.S13F02]}
                        copno={seperate(h.S13F09)[1]}
                        // data={}
                        name={h?.S13F07}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "DATEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                        // defaultval={T41Obj?.[h?.S13F02]}
                        defaultval={T41Obj?.[h?.S13F02]?.trim() != "" ? T41Obj?.[h?.S13F02] : GetYearAndDate?.strtDate}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          console.log("DATEPICKER=>", e)
                          let year = e.toLocaleString("en-IN", {
                            year: "numeric",
                          });
                          let month = e.toLocaleString("en-IN", {
                            month: "2-digit",
                          });
                          let day = e.toLocaleString("en-IN", {
                            day: "2-digit",
                          });

                          let StringDate = year + month + day;
                          if (StringDate?.length == 8) {
                            setT41Obj({ ...T41Obj, [h?.S13F02]: StringDate });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "TIMEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={T41Obj?.[h?.S13F02].trim() == "" ? new Date() : T41Obj?.[h?.S13F02]}
                        // defaultval={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "MEMOBOX":
                if (h.S13F21 === "N") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else if (h.S13F21 === "H") {
                  // h.S13F21 === "H"
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;
              case "FILEUPLOAD":
                if (h.S13F21 === "I") {
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else if (h.S13F21 === "A") {
                  // h.S13F21 === "A"
                  inputBox = <>{
                    <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          value={T41Obj?.[h?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;

              default:
                break;
            }
            return inputBox
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H?.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            let inputBox;
            switch (f?.S13F14) {
              case "TEXTBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        nmaxlength={f?.S13F04}
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "NUMBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLNumberBox
                        nmaxlength={f?.S13F04}
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "PLPOPUP":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        width={"500px"}
                        name={f.S13F02}
                        value={T41Obj?.[f?.S13F02]}
                        p0={seperate(f.S13F09)[0]}
                        p1={seperate(f.S13F09)[1]}
                        setEdit={(e) => {
                          //console.log(e);
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "COMBOBOX":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        value={T41Obj?.[f?.S13F02]}
                        copno={seperate(f.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          //console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "DATEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                        // defaultval={T41Obj?.[f?.S13F02]}
                        defaultval={T41Obj?.[f?.S13F02]?.trim() != "" ? T41Obj?.[f?.S13F02] : GetYearAndDate?.strtDate}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          console.log(e)

                          let year = e.toLocaleString("en-IN", {
                            year: "numeric",
                          });
                          let month = e.toLocaleString("en-IN", {
                            month: "2-digit",
                          });
                          let day = e.toLocaleString("en-IN", {
                            day: "2-digit",
                          });

                          let StringDate = year + month + day;
                          if (StringDate?.length == 8) {
                            setT41Obj({ ...T41Obj, [f?.S13F02]: StringDate });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "TIMEPICKER":
                inputBox = <>{
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        // value={T41Obj?.[f?.S13F02].trim() == "" ? new Date() : T41Obj?.[f?.S13F02]}
                        // defaultval={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                }</>
                break;
              case "MEMOBOX":
                if (f.S13F21 === "N") {
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  // h.S13F21 === "H"
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;
              case "FILEUPLOAD":
                if (f.S13F21 === "I") {
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                } else {
                  // h.S13F21 === "A"
                  inputBox = <>{
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <PLTextBox
                          value={T41Obj?.[f?.S13F02]}
                          setEdit={(e) => {
                            //console.log(e)
                            setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  }</>
                }
                break;

              default:
                break;
            }
            return inputBox
          }
        })
      );
    }
  };

  //Add New Row
  const handleCreateRow = () => {
    console.log('on enter call function')
    console.log('handleCreateUser T02obj=>>', T02Obj)
    console.log('handleCreateUser T02Data=>>', T02Data)

    dispatch(BatchLogAddRemoveFun({ PageCode: "ININ", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));

    setT02Data([...T02Data, T02Obj]);
    // exitCreatingMode();
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    setSelectedProductObj({})
    setBatchIssueObj()
    setLocationObj(null)
    setRecieptIssueVal(null)
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    table.setCreatingRow(null)
  };

  // Update Row
  const handleEditSave = () => {
    setAddMode(true)
    console.log('on enter call function')
    console.log('T02Data in Edit Case', T02Data)
    let EditT02Data = [...T02Data];
    EditT02Data[tableEditingRow.index] = T02Obj;

    dispatch(BatchLogAddRemoveFun({ PageCode: "ININ", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));

    setT02Data(EditT02Data);
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    table.setEditingRow(null); //exit editing mode
    // setSelectedProductObj(null)
    // setBatchIssueObj(null)
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    setTableEditingRow(null)
  };

  const RefreshInput = () => {
    // setT41Obj(null)
    setT41Obj({
      FIELD01: "",
      FIELD02: "",
      FIELD03: 0,
      FIELD04: "",
      FIELD05: "",
      FIELD06: "",
      FIELD07: "",
      FIELD08: 0,
      FIELD09: 0,
      FIELD10: "",
      FIELD11: "",
      FIELD12: "",
      FIELD13: "",
      FIELD14: "N",
      FIELD15: "",
      FIELD16: "",
      FIELD17: "",
      FIELD18: 0,
      FIELD21: "O",
      FIELD22: "",
      FIELD23: "",
      FIELD30: "",
      FIELD31: "",
      FIELD32: "",
      FIELD33: "",
      FIELD70: "",
      FIELD71: "",
      FIELD75: "0",
      FIELD99: "",
      FIELD72: "",
      FIELD20: 0,
      FIELD80: "",
      FIELD51: 0,
      FIELD34: "",
      FIELD35: "",
      FIELD36: "",
      FIELD37: "",
      FIELD38: "",
      FLDJIO: "",
      FIELD39: "",
      FIELD40: "",
      FIELD41: "",
      FIELD42: "",
      FIELD43: "",
      FIELD44: "",
      FIELD45: "",
      FIELD46: "",
      FIELD47: "",
      FIELD48: "",
      FIELD49: "",
      FIELD50: "",
      FIELD52: "",
      FIELD53: "",
      T41PVT: "IN",
      FIELD54: "",
      T41VNO: "",
      CMID: 0,
      ADDID: 0,
      PRESCPATH: "",
      OSTATUS: "",
      DTYPE: "",
      DELURL: "",
      DELMBNO: "",
      FIELD55: "",
      FIELD56: "",
      T41POS: "",
      UBILTO: "",
      UGSTR: "",
      USALM: "",
      UDISP: "",
      UPAYT: "",
      UPR: "",
      UNAME: "",
      UOP: "",
      UTRA: "",
      UODP: "",
      UREMA: "",
      UEMAIL: "",
      URA: "",
      FLDUNQ: 0,
      FLDAED: "A",
      FLDBRC: "",
      PA000001: "",
      PA000002: "",
      PD000001: 0,
      PD000002: 0,
      UUASDF05: "",
      UUBAER: "",
      UUUBAER: "",
      EA00000001: "",
      EA00000002: "",
      EA00000003: "",
      EA00000004: "",
      EA00000005: "",
      EA00000006: "",
      EA00000007: "",
      EA00000008: "",
      EA00000009: "",
      EA00000010: "",
      EA00000011: "",
      EA00000012: "",
      EA00000013: "",
      EA00000014: "",
      EA00000015: "",
      ED00000001: 0,
      ED00000002: 0,
      ED00000003: 0,
      ED00000004: 0,
      ED00000005: 0,
      ED00000006: 0,
      ED00000007: 0,
      ED00000008: 0,
      ED00000009: 0,
      ED00000010: 0,
      ED00000011: 0,
      ED00000012: 0,
      ED00000013: 0,
      ED00000014: 0,
      ED00000015: 0,
      EP00000001: 0,
      EP00000002: 0,
      EP00000003: 0,
      EP00000004: 0,
      EP00000005: 0,
      EP00000006: 0,
      EP00000007: 0,
      EP00000008: 0,
      EP00000009: 0,
      EP00000010: 0,
      EP00000011: 0,
      EP00000012: 0,
      EP00000013: 0,
      EP00000014: 0,
      EP00000015: 0,
      UUASWER: "",
      UUUASWER: "",
      UCRE: "",
      UCRE2: "",
      UDCNO: "",
      UDE: "",
      UDEL: "",
      UDT: "",
      ULEN: "",
      ULRD: "",
      UOD: "",
      UODD: "",
      USAL: 0,
      UTR: "",
      UUUASD09: "",
      UUJA: "",
      UUUJBOS: "",
      UUUJOB: "",
      UUSALM: "",
      UUUOS: "",
      EA00000016: "",
      ED00000016: 0,
      EP00000016: 0,
      UREST6: "",
      DSDATE: "2023-04-01T00:00:00+03:00",
      DEDATE: "2024-03-31T00:00:00+02:00",
      CACTION: "A",
      lInvType: false,
      lMTaxEnt: false,
      T92F02: "",
      CFRMTITLE: "Add Production",
      BTOBEFF: " ",
      M01BAL: "0",
      M01GRP: "",
      M01OPPGRP: "",
      M01CT: "",
      F07ACC: "",
      LBLF08AMT: "0.00   ",
      LBLF07AMT: "0.00   ",
      LBLF08SYMB: null,
      LBLF07SYMB: null,
    })
    dispatch(TransactionApi(obj));
    // setT02Obj({
    //   ...T02Obj,
    //   FIELD21: 0.0,
    // });
    // if (T02Data && T02Data.length > 1) {
    //   T02Data.map((v, i) => {
    //     setT02Obj({
    //       ...v,
    //       FIELD21: 0.0
    //     })
    //   })
    // }
  };

  // const DataImport = () => {
  //   modals.openContextModal({
  //     modal: "DataImportForm",
  //     title: <Text fw={700}>Data Import</Text>,
  //     zIndex: 300,
  //     size: "lg",
  //     withCloseButton: true,
  //     innerProps:{
  //       modalBody : (
  //         <>
  //         <DataImportForm />
  //         </>
  //       )
  //     }
  //   })
  // }

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      if (row?.id == 'mrt-row-create') {
        return
      } else if (T02Obj?.LVALID == "N") {
        GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
      } else {
        table.setEditingRow(row)
        setTableEditingRow(row)
        setT02Obj(row?.original)
      }
    }
  }

  const table = useMantineReactTable({
    columns,
    data: T02Data,
    enableTopToolbar: true,
    enableHiding: false,
    enableEditing: true,
    mantineTableContainerProps: {
      sx: { maxHeight: "250px", minHeight: "300px" },
    },
    editDisplayMode: "row",
    createDisplayMode: "row",
    enableMultiRowSelection: false,
    enableSelectAll: false,
    enableColumnActions: false,
    enableSorting: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: false,
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onClick: () => {
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "0px",
      rowSelection
    },
    onCreatingRowSave: () => {
      if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
        return GlobalClass.Notify(
          "warning",
          "Validation error",
          "Please fill out all the required fields"
        );
      } else {
        handleCreateRow();
      }
    },
    onEditingRowSave: handleEditSave,
    onCreatingRowCancel: handleNullT02Obj,
    onEditingRowCancel: handleNullT02Obj,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              if (T02Obj?.LVALID == "N") {
                GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
              } else {
                setEventMode(!eventMode)
                setAddMode(!AddMode)
                table.setEditingRow(row);
                console.log("table.setEditingRow=>", row);
                setT02Obj(row.original);
                setTableEditingRow(row)
              }
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red"
            onClick={() => {
              if (T02Obj?.LVALID == "N") {
                GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
              } else {
                DeleteRow(row)
              }
            }}
          >
            <IconTrash size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onFocus={() => {
          if (spoilerControlRef?.current?.innerText == "Hide") {
            spoilerControlRef.current.click()
          }
          Promise.resolve(table.setCreatingRow(true))
          setEventMode(!eventMode)
        }}
        onClick={() => {
          table.setCreatingRow(true);
          setEventMode(!eventMode)
        }}
        size="xs"
      >
        Add New
      </Button>
    ),
  });

  // let TotalRow = table.getRowModel().rows?.length;
  // let selectedRow = table.getSelectedRowModel().flatRows[0];

  let ReceiptArray = T02Data?.filter((d, i) => d?.FIELD05 == "C");
  let IssueArray = T02Data?.filter((d, i) => d?.FIELD05 == "D");

  let recieptAmountSum = ReceiptArray?.reduce((a, b) => (a = a + b?.FIELD08), 0);
  let IssueAmountSum = IssueArray?.reduce((a, b) => (a = a + b?.FIELD08), 0);
  // console.log('recieptAmount==>>',recieptAmountSum)
  // console.log('IssueAmountSum=>>',IssueAmountSum)

  // reciept amount & issue amount
  useEffect(() => {
    if (recieptAmountSum >= 0 && IssueAmountSum >= 0) {
      setT41Obj({
        ...T41Obj,
        FIELD06: recieptAmountSum,
        FIELD07: IssueAmountSum,
      });
    }
  }, [recieptAmountSum, IssueAmountSum]);

  // reciept Qty sum
  let ReceiptQtySum = ReceiptArray?.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log('ReceiptQtySum', ReceiptQtySum)

  // issue Qty sum
  let IssueQtySum = IssueArray?.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log('IssueQtySum', IssueQtySum)

  const spoilerControlRef = useRef();

  // For Enter Key Press Edit mode
  // useEffect(() => {
  //   // if (Object.values(rowSelection).some(value => value === true)) {
  //   T02Data?.map((t, i) => {
  //     if (i in rowSelection) {
  //       document.addEventListener('keydown', (e) => {
  //         console.log('enter useEffect run')
  //         if (e.key == "Enter") {
  //           Promise.resolve().then(() => {
  //             table.setEditingRow(table.getSelectedRowModel().flatRows[0])
  //             setT02Obj(t)
  //             setTableEditingRow(table.getSelectedRowModel().flatRows[0])
  //           })
  //         }
  //       })
  //     }
  //   })
  //   // }
  // }, [rowSelection, T02Data])

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      console.log('formelement run')
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        console.log('formelement run')
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        Promise.resolve()
          .then(() => handleNullT02Obj())
          .then(() => {
            setTimeout(() => {
              if (tableEditingRow == null) {
                table.setCreatingRow(false)
              } else {
                table.setEditingRow(null)
              }
            }, 500)
          })
      }
    })
  }, [tableEditingRow])

  return (
    <>
      {
        SaveLoading == true ?
          GlobalClass.RenderLoader("dots")
          :
          <>
            {
              AccountisLoading["T_M32G"] || AccountisLoading["T_M32C"] || AccountisLoading["P_M21"] || isLoading || isUserFieldLoading
                ? GlobalClass.RenderLoader("dots")
                : ProductionData &&
                <>
                  {
                    obj?.p0 == "U" ? <>
                      <PLUserFieldBtn
                        UserFieldArray={UserField?.UFTbl}
                        T41={T41Obj}
                        onChange={(e) => {
                          // Promise.resolve(setT41Obj(e)).then(()=>
                          handleSubmit(e)
                          // ) 
                        }}
                      />
                    </> :
                      <div ref={formRef}>
                        <Modal
                          opened={DrawerObj?.open}
                          // onClose={close}
                          withCloseButton={() => {

                          }}
                          fullScreen={false}
                          closeButtonProps={{
                            onClick: () => {

                              if (typeof DrawerObj?.onClickCloseButton == "function") {
                                DrawerObj?.onClickCloseButton();
                              }
                              else {
                                setDrawerObj(dra);
                              }
                            },
                          }}
                          title={DrawerObj.title}
                          size={DrawerObj.size}
                          // centered={.Centered}
                          closeOnClickOutside={true}
                          position={DrawerObj?.position ?? 'bottom'}
                          onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                        >
                          {DrawerObj.body}
                        </Modal>
                        <Paper shadow="md" radius="md" p="xs" withBorder>
                          <Grid gutter={4}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                          >
                            <Grid.Col
                              span={10}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid.Col span={1}>
                                <Text size={12}>Date :</Text>
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <PLDateBox
                                  // initiallyOpened={true}
                                  autoFocus={true}
                                  // onKeyDown={(e)=>{
                                  //   GlobalClass.formNextInput(e,formRef);
                                  // }}
                                  dispformat="DD/MM/YYYY"
                                  setEdit={(e) => {
                                    console.log('setEdit in e', e)
                                    let year = e.toLocaleString("en-IN", {
                                      year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                      month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                      day: "2-digit",
                                    });

                                    let Date = year + month + day;

                                    if (Date?.length == 8) {
                                      setT41Obj({ ...T41Obj, FIELD02: Date });
                                      // .then(() => {
                                      pr?.current?.handlePopoverToggle();
                                      // });
                                    }
                                    // setSelectedDay(e);
                                  }}
                                  defaultval={T41Obj?.FIELD02}
                                />
                              </Grid.Col>

                              <Grid.Col span={1}>
                                <Text size={12}>
                                  {selectedDay.toString().slice(0, 3)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={1}>
                                <Text size={12}>Process :</Text>
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <PLDataGrid
                                  //  onKeyDown={(e)=>{
                                  //   GlobalClass.formNextInput(e,formRef);
                                  // }}
                                  ref={pr}
                                  width={"500px"}
                                  value={{ value: T41Obj?.FIELD04, label: T41Obj?.FIELD04NM }}
                                  TmData={P_M71}
                                  // data={P_M71DT?.jData}
                                  setEdit={(e) => {
                                    console.log('Process datagrid value=>>', e)
                                    Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e?.FIELD02 }))
                                      .then(() => setProcessVal(e))
                                      .then(() => setFlag(true))
                                      .then(() => {
                                        VoucherRef?.current?.handlePopoverToggle();
                                      });
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          </Grid>

                          <Grid gutter={4}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                          >
                            <Grid.Col
                              span={10}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid.Col span={1}>
                                <Text size={12}>Vou No :</Text>
                              </Grid.Col>
                              {
                                obj?.p0 == "E" ?
                                  <Grid.Col span={4}>
                                    <PLTextBox
                                      //  onKeyDown={(e)=>{
                                      //   GlobalClass.formNextInput(e,formRef);
                                      // }}
                                      value={T41Obj?.FIELD12 ?? T41Obj?.T92F00}
                                      setEdit={(e) => {
                                        console.log("setEdit=>", e);
                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                      }}
                                    />
                                  </Grid.Col> :
                                  <Grid.Col span={4}>
                                    {
                                      T92 ? <>
                                        {
                                          BillSeriesHideShow?.DataGridInput == true && <>
                                            {
                                              !T41Obj?.T92F00 ?
                                                <PLDataGrid
                                                  // onKeyDown={(e)=>{
                                                  //   GlobalClass.formNextInput(e,formRef);
                                                  // }}
                                                  ref={VoucherRef}
                                                  width={"400px"}
                                                  position={"unset"}
                                                  valexpr={"FIELD00"}
                                                  // width={350} 
                                                  value={BillSeriesHideShow?.BillNoVal}
                                                  TmData={T92}
                                                  // data={T92DT}
                                                  setEdit={(e) => {
                                                    console.log("Bill No.=>", e)
                                                    // Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD01 })).then(()=>{
                                                    //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                    // })
                                                    //"FIELD00"
                                                    Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                      if (e) {
                                                        var letr = e.FIELD02
                                                        console.log("num=>", num);
                                                        var num = parseInt(e.FIELD03) + 1
                                                        setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                      }
                                                    }).then(() => QtyRef?.currentTarget?.focus())
                                                  }}
                                                /> : <PLTextBox
                                                  // ref={pr}
                                                  value={T41Obj?.FIELD12}
                                                  // value={T41Obj?.T92F00}
                                                  setEdit={(e) => {
                                                    console.log("setEdit=>", e);
                                                    // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                    setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                  }}
                                                />
                                            }
                                          </>
                                        }
                                        {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                          // setText={() => {
                                          //   setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                          // }}
                                          value={BillSeriesHideShow?.BillNoVal}
                                          // value={T41Obj?.T92F00}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                            setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                          }}
                                        />
                                        }
                                      </> :
                                        < PLTextBox
                                          // onKeyDown={(e)=>{
                                          //   GlobalClass.formNextInput(e,formRef);
                                          // }} 
                                          disabled={true}
                                          placeholder={'Select'}
                                        />
                                    }
                                  </Grid.Col>
                              }
                              <Grid.Col span={1}></Grid.Col>

                              <Grid.Col span={1}>
                                <Text size={12}>Quantity :</Text>
                              </Grid.Col>
                              <Grid.Col span={3}>
                                <PLNumberBox
                                  //  onKeyDown={(e)=>{
                                  //   GlobalClass.formNextInput(e,formRef);
                                  // }}
                                  ref={QtyRef}
                                  disabled={obj.p0 == "E" ? true : false}
                                  setNumber={(e) => {
                                    if (T41Obj?.FIELD18 == 0) {
                                      GlobalClass.Notify("warning", "Production Quantity cannot be Zero")
                                    }
                                  }}
                                  value={T41Obj?.FIELD18}
                                  setEdit={(e) => {
                                    setT41Obj({ ...T41Obj, FIELD18: e });
                                    setFlag(true)
                                  }}
                                />
                              </Grid.Col>

                              <Grid.Col span={1}>
                                <Button
                                  style={{ height: "30px", width: "100px" }}
                                  variant="light"
                                  size="sm"
                                  onClick={RefreshInput}
                                >
                                  Refresh
                                </Button>
                              </Grid.Col>
                            </Grid.Col>
                          </Grid>

                          {/* Header User Field  */}
                          {UFT41H?.length > 0 && userFieldHideShow == "Y" ? (
                            <>
                              <Divider
                                my="xs"
                                label="User Fields"
                                labelPosition="center"
                                labelProps={{ component: "strong" }}
                              />
                              <Spoiler
                                maxHeight={80}
                                showLabel="Show more"
                                hideLabel="Hide"
                                controlRef={spoilerControlRef}
                              >
                                <Grid gutter={4} mb={5}>
                                  {UFT41HRenderField("Header")}
                                </Grid>
                              </Spoiler>
                            </>
                          ) : (
                            ""
                          )}

                          <Grid gutter={4}>
                            <Grid.Col span={12} my="xs" style={{ padding: 0 }}>
                              <MantineReactTable table={table} />
                            </Grid.Col>

                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Group style={{ display: "flex" }}>
                                <Button
                                  size="sm"
                                  variant="light"
                                  compact
                                  style={{ height: "30px", width: "80px" }}
                                // onClick={() => DataImport()}
                                >
                                  Import
                                </Button>
                                <Button
                                  size="sm"
                                  variant="light"
                                  compact
                                  style={{ height: "30px", width: "80px" }}
                                  onClick={() => {
                                    if (table?.getSelectedRowModel()?.flatRows?.length > 0) {
                                      setT02Data([...T02Data, table.getSelectedRowModel()?.flatRows[0]?.original])
                                    }
                                  }}
                                >
                                  Copy
                                </Button>
                              </Group>
                            </Grid.Col>
                          </Grid>

                          {/* {T02Obj?.FIELD03 !== "" ? (
                  <Grid gutter={4} my={2}>
                    <Grid.Col
                      span={12}
                      style={{ display: "flex", fontSize: 12 }}
                    >
                      <Grid.Col span={3}>
                        <Text size={12} style={{ fontWeight: "bold" }}>
                          Qty - 1 : {T02Obj?.FIELD33}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={3}>
                        <Text size={12} style={{ fontWeight: "bold" }}>
                          Qty - 2 : {T02Obj?.FIELD21}
                        </Text>
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>
                ) : (
                  ""
                )} */}

                          {/* Footer User Field  */}
                          <Grid gutter={4}>
                            <Grid.Col span={6}>{userFieldHideShow == "Y" && UFT41HRenderField("Footer")}</Grid.Col>
                          </Grid>

                          <Grid gutter={4}>
                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid.Col span={5}>
                                <Grid.Col span={12}>
                                  <Text size={12}>Narration :</Text>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                  <PLTextarea
                                    minRows={2}
                                    value={T41Obj?.FIELD15}
                                    onChange={(e) => {
                                      // console.log(e)
                                      setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.ctrlKey && e.key === "F1") {

                                        setDrawerObj({
                                          ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                            <PLNarration
                                              NarrationArray={NarrationData}
                                              selectedNarrationArray={
                                                SelectedNarrationData
                                              }
                                              // NarrationVal={T41Obj}
                                              setNarrationArray={(e) => {
                                                // setT41Obj(e)
                                                console.log("setNarrationArray", e);
                                                setNarrationData(e.NarrationData);
                                                setSelectedNarrationData(
                                                  e.selectedNarration
                                                );
                                              }}
                                              DrawerOBJ={DrawerObj}
                                              setDrawerOBJ={(e) => setDrawerObj(e)}
                                            />
                                          </>, open: true, size: "50%", position: "right"
                                        })
                                      }
                                    }}
                                  />
                                </Grid.Col>
                              </Grid.Col>

                              <Grid.Col span={5}>
                                <Grid.Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Grid.Col span={5}>
                                    <Text size={12}>Quantity</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <Text size={12}>Amount</Text>
                                  </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: "flex" }}>
                                  <Grid.Col span={2}>
                                    <Text size={12}>Input :</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={ReceiptQtySum} />
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={T41Obj?.FIELD06} />
                                  </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: "flex" }}>
                                  <Grid.Col span={2}>
                                    <Text size={12}>Output :</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={IssueQtySum} />
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={T41Obj?.FIELD07} />
                                  </Grid.Col>
                                </Grid.Col>
                              </Grid.Col>
                            </Grid.Col>
                          </Grid>

                          <Grid gutter={4}>
                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Group>
                                <Button
                                  style={{ height: "30px", width: "100px" }}
                                  size="sm"
                                  onClick={() => handleSubmit()}
                                // disabled={T02Data.length < 2 ? true : false}
                                >OK</Button>
                                <Button style={{ height: "30px", width: "100px" }} size="sm">Print</Button>
                              </Group>
                            </Grid.Col>
                          </Grid>
                        </Paper>
                        {
                          lEditVou == false && GlobalClass.Alert("info", props.index)
                        }
                      </div>
                  }
                </>
            }
          </>
      }
    </>
  );
};

export default ProductionForm;
