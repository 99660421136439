import { Button, Grid, Group, Paper, Textarea } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { useSelector } from 'react-redux'
import Store from '../../utils/store'
import { GetButtonLoading } from '../../utils/TransactionSlices/EWayBillSlice'

const UpdateVehNum = (props) => {
    const { G04Obj, setG04Obj, DrawerObjSend, setDrawerObjSend, oYearSend, handleSaveEwayBill } = props
    const [updateVehNumObj, setUpdateVehNumObj] = useState({})
    const ButtonLoading = useSelector((state) => state.EWayBill?.ButtonLoading)
    const Reason = [
        {
            "DisplayMember": "Due to Break Down",
            "ValueMember": "B"
        },
        {
            "DisplayMember": "Due to Transhipment",
            "ValueMember": "T"
        },
        {
            "DisplayMember": "Others (Pls. Specify)",
            "ValueMember": "O"
        },
        {
            "DisplayMember": "First Time",
            "ValueMember": "F"
        }
    ]

    useEffect(() => {
        if (G04Obj) {
            setUpdateVehNumObj(G04Obj)
        }
    }, [G04Obj])

    const updateVehNumFun = () => {
        setG04Obj(updateVehNumObj)
    }

    const handleButtonClick = (type) => {
        updateVehNumFun();
        handleSaveEwayBill(type);
    };

    return (
        <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3} style={{ fontSize: 12 }}>Vehicle No.</Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            value={updateVehNumObj?.G04F22}
                            setEdit={(e) => {
                                setUpdateVehNumObj({ ...updateVehNumObj, G04F22: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3} style={{ fontSize: 12 }}>Reason</Grid.Col>
                    <Grid.Col span={9}>
                        <PLComboBox
                            data={Reason}
                            value={updateVehNumObj?.G04F27}
                            valexpr={"ValueMember"}
                            dispexpr={"DisplayMember"}
                            setEdit={(e) => {
                                setUpdateVehNumObj({ ...updateVehNumObj, G04F27: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3} style={{ fontSize: 12 }}>Remarks</Grid.Col>
                    <Grid.Col span={9}>
                        <Textarea
                            value={updateVehNumObj?.G04F28}
                            minRows={2}
                            onChange={(e) => {
                                setUpdateVehNumObj({ ...updateVehNumObj, G04F28: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Group>
                        <Button
                            loading={ButtonLoading?.updateVeh}
                            style={{ height: "30px" }}
                            size="sm"
                            onClick={() => {
                                // setButtonLoading({ ...buttonLoading, updateVeh: true })
                                handleButtonClick("V")
                                Store.dispatch(GetButtonLoading({ ...ButtonLoading, updateVeh: true }))
                            }}
                        >WEB</Button>
                        <Button
                            // disabled={oYearSend?.OSC?._EWBSMS == "N" ? true : false}
                            loading={ButtonLoading?.generateQr}
                            style={{ height: "30px" }}
                            size="sm"
                            onClick={() => {
                                Store.dispatch(GetButtonLoading({ ...ButtonLoading, generateQr: true }))
                                handleButtonClick("Q")
                            }}
                        >Generate QR</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Paper>
    )
}

export default UpdateVehNum