import React, { useState, useEffect } from 'react';
import {
  Modal,
  Popover,
  Button,
  Text,
  Group,
  Checkbox,
  Alert,
  Badge,
  Avatar,
  Loader,
  Notification,
  Stack,
  Divider,
  Center,
  Image,
  ActionIcon,
} from '@mantine/core';
import {
  IconBrandWhatsapp,
  IconAlertCircle,
  IconCheck,
  IconLogout,
  IconLogin,
  IconDownload,
  IconQrCode,
} from '@tabler/icons-react';
import axios from 'axios';
import GlobalClass from '../utils/GlobalClass';

function WhatsAppIntegration() {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showNotification, setShowNotification] = useState(null);
  const [isLocalServerAvailable, setIsLocalServerAvailable] = useState(null);
  const [checkingLocalServer, setCheckingLocalServer] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [checkingLoginStatus, setCheckingLoginStatus] = useState(false);

  useEffect(() => {
    // Check if the user has previously accepted the terms
    const hasAccepted = localStorage.getItem('termsAccepted');
    if (hasAccepted) {
      setTermsAccepted(true);
    }
    else{
        setTermsAccepted(false);
    }
  }, [popoverOpened]);

  const checkLocalServer = async () => {
    setCheckingLocalServer(true);
    try {
      // Attempt to make a GET request to localhost:3999
      await axios.get('http://localhost:3000/'); // Adjust endpoint as needed
      setIsLocalServerAvailable(true);
    } catch (error) {
      setIsLocalServerAvailable(false);
    } finally {
      setCheckingLocalServer(false);
    }
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    localStorage.setItem('termsAccepted', 'true'); // Store the acceptance
    setModalOpened(false);
    // After accepting terms, check if localhost:3999 is reachable
    checkLocalServer();
    setPopoverOpened(true);
  };

  const handleLogin = async () => {
    setConnecting(true);
    try {
      // Initiate login request to local server to get QR code
      const response = await axios.get('http://localhost:3000/get-qr-code'); // Adjust endpoint
      const qrCodeImage = response.data.qrCode; // Assume the server returns base64 image data

      setQrCodeData(qrCodeImage);
      setConnectionStatus('Awaiting QR Code Scan');

      // Start polling for login status
      pollLoginStatus();
    } catch (error) {
      
      GlobalClass.Notify("error","Failed to get QR code.","Please try again.");
      setConnecting(false);
      setConnectionStatus('Disconnected');
    }
  };

  const pollLoginStatus = () => {
    setCheckingLoginStatus(true);
    const interval = setInterval(async () => {
      try {
        const statusResponse = await axios.get('http://localhost:3000/login-status'); // Adjust endpoint
        const { loggedIn, phoneNumber } = statusResponse.data;

        if (loggedIn) {
          clearInterval(interval);
          setLoggedIn(true);
          setConnectionStatus('Connected');
          setPhoneNumber(phoneNumber);
          setConnecting(false);
          setQrCodeData(null);
          setCheckingLoginStatus(false);
          GlobalClass.Notify("success","Logged in successfully!");
          
        }
      } catch (error) {
        // Handle error if needed
      }
    }, 3000); // Poll every 3 seconds
  };

  const handleLogout = async () => {
    try {
      // Send logout request to local server
      await axios.get('http://localhost:3000/logout'); // Adjust endpoint
      setConnectionStatus('Disconnected');
      setPhoneNumber(null);
      setLoggedIn(false);
      GlobalClass.Notify("warning","Logged out successfully!");
    } catch (error) {
      GlobalClass.Notify("error","Failed to logout.","Please try again.");
    }
  };

  const handleWhatsAppClick = () => {
    if (!termsAccepted) {
      setModalOpened(true);
    } else {
      checkLocalServer();
      setPopoverOpened((prev) => !prev);
    }
  };

  return (
    <div>
     

      {/* Notification */}
      {showNotification && (
        <Notification
          icon={<IconCheck size={18} />}
          color="teal"
          title="Notification"
          onClose={() => setShowNotification(null)}
          withCloseButton
          mt="md"
        >
          {showNotification}
        </Notification>
      )}

      {/* Disclaimer Modal */}
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Disclaimer & Terms"
        centered
        overlayOpacity={0.55}
        overlayBlur={3}
      >
        <Alert icon={<IconAlertCircle size={16} />} title="Important" color="yellow">
          <Text size="sm">
            You are about to use an unofficial WhatsApp service. Please ensure you comply with
            WhatsApp's terms and conditions.
          </Text>
        </Alert>
        <Text mt="md" size="sm">
          By proceeding, you accept the use of this unofficial WhatsApp service and agree to our{' '}
          <a href="#terms">Terms and Conditions</a>.
        </Text>
        <Checkbox
          mt="lg"
          label="I have read and accept the terms and conditions"
          onChange={(event) => setTermsAccepted(event.currentTarget.checked)}
        />
        <Group position="right" mt="md">
          <Button
            onClick={handleAcceptTerms}
            disabled={!termsAccepted}
            variant="filled"
            color="teal"
          >
            Accept & Continue
          </Button>
        </Group>
      </Modal>

      {/* WhatsApp Popover */}
      <Popover
        opened={popoverOpened}
        onChange={setPopoverOpened}
        position="bottom"
        withArrow
        shadow="md"
        width={300}
      >
        <Popover.Target>
        <ActionIcon onClick={handleWhatsAppClick} color="green"  variant="transparent">
                  <IconBrandWhatsapp/>
                </ActionIcon>
        {/* <Button
          onClick={handleWhatsAppClick}
          variant="gradient"
          size='xs'
          gradient={{ from: 'teal', to: 'lime', deg: 105 }}
          leftIcon={<IconBrandWhatsapp size={18} />}
        >
          WhatsApp
        </Button> */}
        </Popover.Target>

        <Popover.Dropdown>
          <Stack spacing="sm">
            <Group position="apart">
              <Text weight={500}>WhatsApp Integration</Text>
            </Group>
            <Divider />

            {checkingLocalServer ? (
              <Center>
                <Loader size="sm" variant="dots" />
                <Text size="sm" ml="sm">
                  Checking local server...
                </Text>
              </Center>
            ) : isLocalServerAvailable === false ? (
              <div>
                <Alert
                  icon={<IconAlertCircle size={16} />}
                  title="Local Server Not Detected"
                  color="red"
                >
                  <Text size="sm">
                    To use this feature, please download and run our desktop application.
                  </Text>
                </Alert>
                <Group position="center" mt="md">
                  <Button
                    leftIcon={<IconDownload size={18} />}
                    component="a"
                    href="https://plusaccount.in/beta/plws.exe"
                    // target="_blank"
                    variant="gradient"
                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                  >
                    Download Application
                  </Button>
                </Group>
                <Text size="xs" mt="md" color="dimmed" align="center">
                  Once the application is running, click the button below to re-check the connection.
                </Text>
                <Group position="center" mt="xs">
                  <Button onClick={checkLocalServer} size="xs" variant="outline">
                    Re-check Connection
                  </Button>
                </Group>
              </div>
            ) : (
              <>
                <Group position="apart">
                  <Badge
                    color={
                      connectionStatus === 'Connected'
                        ? 'green'
                        : connectionStatus === 'Awaiting QR Code Scan'
                        ? 'yellow'
                        : 'red'
                    }
                    leftSection={
                      connectionStatus === 'Connected' ? (
                        <IconCheck size={12} />
                      ) : (
                        <IconAlertCircle size={12} />
                      )
                    }
                  >
                    {connectionStatus}
                  </Badge>
                </Group>

                {connecting ? (
                  <>
                    {qrCodeData ? (
                      <div>
                        <Text size="sm" mt="sm">
                          Please scan the QR code with your WhatsApp app to log in.
                        </Text>
                        <Center mt="md">
                          <Image
                            src={`data:image/png;base64,${qrCodeData}`}
                            alt="WhatsApp QR Code"
                            width={200}
                            height={200}
                          />
                        </Center>
                        <Text size="xs" mt="sm" color="dimmed" align="center">
                          Waiting for QR code scan...
                        </Text>
                      </div>
                    ) : (
                      <Center>
                        <Loader size="sm" variant="dots" />
                        <Text size="sm" ml="sm">
                          Generating QR code...
                        </Text>
                      </Center>
                    )}
                  </>
                ) : (
                  <>
                    {phoneNumber ? (
                      <Group>
                        <Avatar color="teal" radius="xl">
                          {phoneNumber.slice(-2)}
                        </Avatar>
                        <div>
                          <Text size="sm">Connected Number:</Text>
                          <Text weight={500}>{phoneNumber}</Text>
                        </div>
                      </Group>
                    ) : (
                      <Text size="sm" color="dimmed">
                        Not connected to any number.
                      </Text>
                    )}

                    <Group position="center" mt="md">
                      {!loggedIn ? (
                        <Button
                          onClick={handleLogin}
                          leftIcon={<IconLogin size={18} />}
                          fullWidth
                          variant="light"
                          color="teal"
                        >
                          Login
                        </Button>
                      ) : (
                        <Button
                          onClick={handleLogout}
                          leftIcon={<IconLogout size={18} />}
                          fullWidth
                          variant="light"
                          color="red"
                        >
                          Logout
                        </Button>
                      )}
                    </Group>
                  </>
                )}
              </>
            )}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}

export default WhatsAppIntegration;
