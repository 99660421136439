import { Button, Grid, Group, Image, Modal, Paper, Text, Textarea } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { useDispatch, useSelector } from 'react-redux'
import { GetButtonLoading, GetEWayBill } from '../../utils/TransactionSlices/EWayBillSlice'
import GlobalClass from '../../utils/GlobalClass'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import gensetting from '../../utils/gensetting'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import GetEwb from './GetEwb'
import Store from '../../utils/store'
import UpdateVehNum from './UpdateVehNum'
import CancelEWayBill from './CancelEWayBill'
import GenerateEWayBill from './GenerateEWayBill'

const EWayBill = (props) => {
    console.log("Ewaybill props", props)
    const { obj, OBJ, DrawerObjSend, setDrawerObjSend } = props
    console.log('EWayBill obj', obj, "EWayBill OBJ", OBJ)
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const ButtonLoading = useSelector((state) => state.EWayBill?.ButtonLoading)
    const { oYear } = useSelector((state) => state?.Language?.General);
    console.log("oYear", oYear)
    const isLoading = useSelector((state) => state.EWayBill?.isLoading)
    const EwayBillData = useSelector((state) => state.EWayBill?.EWayBillData)
    console.log("EwayBillData", EwayBillData)
    // const EInvoiceData = useSelector((state) => state.EInvoice?.EInvoiceData)
    const { G04, COPGIO, COPGSS, COPGDC, COPGTT, COPGTM, COPGVT, P_G07DF, P_G07DT, P_G06, COPGCN } = EwayBillData || {}
    const dispatch = useDispatch()
    const [G04Obj, setG04Obj] = useState({
        "G04F01": "",
        "G04F02": "",
        "G04F03": 0,
        "G04F04": "",
        "G04F05": "",
        "G04F06": "",
        "G04F07": "",
        "G04F08": "",
        "G04F09": "",
        "G04F10": 0,
        "G04F11": "",
        "G04F12": "",
        "G04F13": "",
        "G04F14": "",
        "G04F15": "",
        "G04F16": 0,
        "G04F17": 0,
        "G04F18": "",
        "G04F19": "",
        "G04F20": "",
        "G04F21": "",
        "G04F22": "",
        "G04F23": 0,
        "G04F24": "",
        "G04F25": "",
        "G04F26": "",
        "G04F27": "",
        "G04F28": "",
        "G04F29": "",
        "G04F30": "",
        "G04F31": "",
        "G04F32": "",
        "G04F51": "",
        "G04F54": "",
        "G04F35": 0,
        "G04F52": "",
        "G04F55": "",
        "G04F36": 0,
        "G04F53": "",
        "G04F56": "",
        "G04F37": 0,
        "G04F38": 0,
        "G04F39": 0,
        "G04F40": "",
        "TOTINAMT": 0,
        "G04F41": "",
        "G04F57": "",
        "G04F58": 0,
        "G04F59": "",
        "G04F60": "",
        "G04F61": "",
        "G04F62": "",
        "G04F63": "",
        "G04F64": "",
        "FLDUNQ": 0,
        "FLDAED": "A",
        "FLDBRC": "",
        "G04F65": ""
    })
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: null
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [fromTime, setFromTime] = useState()
    const [validTime, setValidTime] = useState()
    const [fileVal, setFileVal] = useState("")
    // const [buttonLoading, setButtonLoading] = useState({
    //     generate: false,
    //     upload: false,
    //     generateQr: false,
    //     getEWB: false,
    //     updateVeh: false,
    //     cancel: false,
    // });
    const FileData = [
        {
            "DisplayMember": "Json",
            "ValueMember": "J"
        },
        {
            "DisplayMember": "Excel",
            "ValueMember": "E"
        }
    ]

    useEffect(() => {
        if (obj?.p0 == "W" || obj?.p0 == "G") {
            dispatch(GetEWayBill(obj))
        } else {
            dispatch(GetEWayBill(OBJ))
        }
    }, [OBJ, obj])

    useEffect(() => {
        console.log("G04Obj", G04Obj)
        // console.log("buttonLoading", buttonLoading)
    }, [G04Obj])

    useEffect(() => {
        if (G04) {
            setG04Obj(G04)
        } else {
            setG04Obj({
                "G04F01": "",
                "G04F02": "",
                "G04F03": 0,
                "G04F04": "",
                "G04F05": "",
                "G04F06": "",
                "G04F07": "",
                "G04F08": "",
                "G04F09": "",
                "G04F10": 0,
                "G04F11": "",
                "G04F12": "",
                "G04F13": "",
                "G04F14": "",
                "G04F15": "",
                "G04F16": 0,
                "G04F17": 0,
                "G04F18": "",
                "G04F19": "",
                "G04F20": "",
                "G04F21": "",
                "G04F22": "",
                "G04F23": 0,
                "G04F24": "",
                "G04F25": "",
                "G04F26": "",
                "G04F27": "",
                "G04F28": "",
                "G04F29": "",
                "G04F30": "",
                "G04F31": "",
                "G04F32": "",
                "G04F51": "",
                "G04F54": "",
                "G04F35": 0,
                "G04F52": "",
                "G04F55": "",
                "G04F36": 0,
                "G04F53": "",
                "G04F56": "",
                "G04F37": 0,
                "G04F38": 0,
                "G04F39": 0,
                "G04F40": "",
                "TOTINAMT": 0,
                "G04F41": "",
                "G04F57": "",
                "G04F58": 0,
                "G04F59": "",
                "G04F60": "",
                "G04F61": "",
                "G04F62": "",
                "G04F63": "",
                "G04F64": "",
                "FLDUNQ": 0,
                "FLDAED": "A",
                "FLDBRC": "",
                "G04F65": ""
            })
        }
    }, [G04])

    const saveJsonToFile = (jsonData, filename) => {
        // Convert the JSON data to a string
        console.log('jsonData', [{
            "jsonData": jsonData,
            "filename": filename
        }])
        let parsedJsonData;
        try {
            parsedJsonData = JSON.parse(jsonData);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return; // Exit if JSON parsing fails
        }
        const jsonString = JSON.stringify(parsedJsonData, null, 2); // Pretty-print with 2 spaces indentation

        // Create a Blob from the JSON string
        const blob = new Blob([jsonString], { type: 'application/json' });

        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        link.download = filename; // Set the filename for the download

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up by removing the link
        document.body.removeChild(link);
    };

    const handleSaveEWB = () => {
        dispatch(DataSaveLoading(true));
        let JData = { "G04": { ...G04Obj, G04F01: OBJ?.id } }
        console.log("JData", JData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "J",
            cCode: OBJ?.id,
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "J",
            cCode: OBJ?.id,
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostG04Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => {
                            setDrawerObjSend({ ...DrawerObjSend, open: false })
                        })
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Successfull"
                            ))
                } else if (data.status == "FAIL") {
                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    )
                }
            })
            .catch((e) =>
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading(false)))
            );

    }

    const GenerateQRFun = (data) => {
        console.log("data=>>", data)
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>E-Way Bill QR</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Image src={`data:image/png;base64,${data}`} />
                    </Grid.Col>
                </Grid>
            </>,
            open: true,
            size: "20%",
        })
    }

    const handleSaveEwayBill = (action) => {

        // dispatch(DataSaveLoading(true));
        GlobalClass.Notify(
            "info",
            action == "Q" ? "Generating" :
                action == "U" ? "Uploading" :
                    action == "C" ? "Canceling" :
                        action == "V" ? "Updating" :
                            action == "G" ? "Updating" :
                                "File Generating",
            "Please wait while we process your data"
        );
        let JData = { "G04": { ...G04Obj, G04F01: OBJ?.p0 == "I" ? OBJ?.id : obj.id } }
        console.log("JData", JData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: OBJ?.p0 == "I" ? OBJ?.id : obj?.id,
            cSData: JSON.stringify(JData)
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: action,
            cCode: OBJ?.p0 == "I" ? OBJ?.id : obj?.id,
            cSData: JSON.stringify(JData)
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostG04Ent + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading(false)))
                        .then(() => {
                            if (OBJ?.p0 == "I") {
                                setDrawerObjSend({ ...DrawerObjSend, open: false })
                            } else {
                                if (action == "J") {
                                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                                        .then(() => saveJsonToFile(data?.data?.Json, `EWB_${obj.DataGridRow?.FIELD12}`))
                                        .then(() => setTimeout(() => {
                                            setDrawerObj({ ...DrawerObj, open: false })
                                            // setButtonLoading({ ...buttonLoading, generate: false })
                                            dispatch(GetButtonLoading({
                                                ...ButtonLoading,
                                                generate: false
                                            }))
                                        }, 1000))
                                } else if (action == "Q") {
                                    dispatch(GetButtonLoading({
                                        ...ButtonLoading,
                                        generateQr: false
                                    }))
                                    GenerateQRFun(data?.data?.QRIMG)
                                } else {
                                    Promise.resolve(
                                        dispatch(GetButtonLoading({
                                            ...ButtonLoading,
                                            getEWB: false,
                                            generate: false,
                                            generateQr: false,
                                            upload: false,
                                            updateVeh: false,
                                            cancel: false
                                        }))
                                        // setButtonLoading({
                                        //     ...buttonLoading,
                                        //     upload: false,
                                        //     getEWB: false,
                                        //     updateVeh: false,
                                        //     cancel: false,
                                        // })
                                    )
                                }
                            }
                        })
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                action == "Q" ? "Generated" :
                                    action == "U" ? "Uploaded" :
                                        action == "C" ? "Canceled" :
                                            action == "V" ? "Updated" :
                                                action == "G" ? "Updated" :
                                                    "File Created",

                                action == "Q" ? "QR Generate Successfull" :
                                    action == "U" ? "Uploading Successfull" :
                                        action == "C" ? "Canceled Successfull" :
                                            action == "V" ? "Updating Successfull" :
                                                action == "G" ? "Updated Successfully" :
                                                    "File has been Generated successfully"
                            ))
                } else if (data.status == "FAIL") {

                    Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    ).then(() => dispatch(GetButtonLoading({
                        ...ButtonLoading,
                        getEWB: false,
                        generate: false,
                        generateQr: false,
                        upload: false,
                        updateVeh: false,
                        cancel: false
                    })))
                }
            })
            .catch((e) =>
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                )
                    .then(() => dispatch(DataSaveLoading(false)))
                    .then(() => Store.dispatch(GetButtonLoading({
                        ...ButtonLoading,
                        getEWB: false,
                        generate: false,
                        generateQr: false,
                        upload: false,
                        updateVeh: false
                    }))).then(() => setDrawerObj({ ...DrawerObj, open: false }))
            );

    }

    const cancelEWayBill = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Cancel E-Way Bill No</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <CancelEWayBill
                    G04Obj={G04Obj}
                    setG04Obj={(e) => {
                        console.log("setG04Obj e", e)
                        setG04Obj(e)
                    }}
                    handleSaveEwayBill={(e) => {
                        console.log("handleSaveEwayBill", e)
                        if (e == "C") {
                            handleSaveEwayBill("C")
                        } else {
                            handleSaveEwayBill("Q")
                        }
                    }}
                    oYearSend={oYear}
                    COPGCNSend={COPGCN}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>,
            open: true,
            size: "md",
            // position: "right"
        })
    }

    const UpdateVehNumForm = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Update Vh. No. for E-Way Bill No.</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <UpdateVehNum
                    G04Obj={G04Obj}
                    setG04Obj={(e) => {
                        setG04Obj(e)
                    }}
                    handleSaveEwayBill={(e) => {
                        console.log("handleSaveEwayBill", e)
                        if (e == "V") {
                            handleSaveEwayBill("V")
                        } else {
                            handleSaveEwayBill("Q")
                        }
                    }}
                    oYearSend={oYear}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>,
            open: true,
            size: "md",
            // position: "right"
        })
    }

    const GenerateFun = (b) => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Format Type</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <GenerateEWayBill
                    G04Obj={G04Obj}
                    setG04Obj={(e) => {
                        Promise.resolve(setG04Obj(e))
                            .then(() => handleSaveEwayBill("J"))
                        // handleSaveEwayBill("Q")
                    }}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
                {/* <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={2} style={{ fontSize: 12 }}>File</Grid.Col>
                            <Grid.Col span={10}>
                                <PLComboBox
                                    data={FileData}
                                    valexpr={"ValueMember"}
                                    dispexpr={"DisplayMember"}
                                    setEdit={(e) => {
                                        setFileVal(e)
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                            span={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                // loading={buttonLoading?.generate}
                                style={{ height: "30px" }}
                                size="sm"
                                onClick={() => {
                                    // Promise.resolve(setButtonLoading({ ...buttonLoading, generate: true })).then(() => {

                                        handleSaveEwayBill("J")
                                    // })

                                }}
                            >OK</Button>

                        </Grid.Col>
                    </Grid>
                </Paper> */}
            </>,
            open: true,
            size: "md",
        })
    }

    const UpdateEWB = () => {
        return setDrawerObj({
            ...dra,
            title: <Text fw={700}>Update EWB</Text>,
            onClickCloseButton: () => {
                console.log('close button')
                setDrawerObj({ ...DrawerObj, open: false })
            },
            body: <>
                <GetEwb
                    G04Obj={G04Obj}
                    setG04Obj={(e) => {
                        Promise.resolve(setG04Obj(e))
                            .then(() => handleSaveEwayBill("G"))
                    }}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>,
            open: true,
            size: "md",
        })
    }

    return (
        <>
            {
                SaveLoading == true ?
                    GlobalClass.RenderLoader('dots') :
                    <>
                        {
                            isLoading ? GlobalClass.RenderLoader("dots") :
                                EwayBillData &&
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        withCloseButton={true}
                                        fullScreen={false}
                                        closeButtonProps={{
                                            onClick: (e) => {
                                                setDrawerObj(dra);
                                                console.log("onclick close modal")
                                            },
                                        }}
                                        title={DrawerObj?.title}
                                        size={DrawerObj?.size}
                                        closeOnClickOutside={true}
                                        position={DrawerObj?.position ?? 'bottom'}
                                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}

                                    >
                                        {DrawerObj.body}
                                    </Modal>
                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                        <Text fw={700}>Voucher Detail</Text>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Supply Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F02}
                                                            data={COPGIO}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F02: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Doc. Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F04}
                                                            data={COPGDC}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F04: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F03}
                                                            data={COPGSS}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F03: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Tran Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F30}
                                                            data={COPGTT}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F30: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        <Text fw={700}>To Detail</Text>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12}>
                                                {/* <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Party Name</Grid.Col>
                                    <Grid.Col span={9}>
                                        <PLTextBox
                                            value={OBJ.DataGridRow?.MAPRTYNAME}
                                            disabled={true}
                                        />
                                    </Grid.Col>
                                </Grid.Col> */}
                                            </Grid.Col>
                                            <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch To</Grid.Col>
                                                <Grid.Col span={9}>
                                                    <PLDataGrid
                                                        TmData={P_G07DT}
                                                        value={G04Obj?.G04F65}
                                                        setEdit={(e) => {
                                                            console.log("dispatch to", e)
                                                            setG04Obj({ ...G04Obj, G04F65: e.FIELD01 })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        <Text fw={700}>From Detail</Text>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Dispatch From</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            TmData={P_G07DF}
                                                            value={G04Obj?.G04F64}
                                                            setEdit={(e) => {
                                                                console.log('dispatch from', e)
                                                                setG04Obj({ ...G04Obj, G04F64: e.FIELD01 })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>
                                        <Text fw={700}>Transporter Detail</Text>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Tran Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F17}
                                                            data={COPGTM}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                console.log('tranporter type', e)
                                                                setG04Obj({ ...G04Obj, G04F17: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Doc No</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLTextBox
                                                            // G04F20 
                                                            value={G04Obj?.G04F20}
                                                            // disabled={true}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F20: e.target.value })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Transporter ID</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDataGrid
                                                            value={G04Obj?.G04F63}
                                                            TmData={P_G06}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F63: e.FIELD01, G04F19: e.FIELD02 })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Date</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLDateBox
                                                            dispformat="DD/MM/YYYY"
                                                            defaultval={G04Obj?.G04F21}
                                                            setEdit={(e) => {
                                                                let year = e.toLocaleString("en-IN", {
                                                                    year: "numeric",
                                                                });
                                                                let month = e.toLocaleString("en-IN", {
                                                                    month: "2-digit",
                                                                });
                                                                let day = e.toLocaleString("en-IN", {
                                                                    day: "2-digit",
                                                                });

                                                                let Date = year + month + day;
                                                                setG04Obj({ ...G04Obj, G04F21: Date });
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Name</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLTextBox
                                                            value={G04Obj?.G04F19}
                                                            disabled={true}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F19: e.target.value })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Distance</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLNumberBox
                                                            // G04F23
                                                            value={G04Obj?.G04F23}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F23: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Vehicle No.</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLTextBox
                                                            // G04F22
                                                            value={G04Obj?.G04F22}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F22: e.target.value })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={3} style={{ fontSize: 12 }}>Vehicle Type</Grid.Col>
                                                    <Grid.Col span={9}>
                                                        <PLComboBox
                                                            value={G04Obj?.G04F53}
                                                            data={COPGVT}
                                                            dispexpr={"DisplayMember"}
                                                            valexpr={"ValueMember"}
                                                            setEdit={(e) => {
                                                                setG04Obj({ ...G04Obj, G04F53: e })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>
                                            {
                                                OBJ?.p0 == "I" ?
                                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button
                                                            onClick={() => {
                                                                if (G04Obj?.G04F23 == 0) {
                                                                    GlobalClass.Notify("warning", "Distance can not be Empty")
                                                                } else if (G04Obj?.G04F21 == "") {
                                                                    GlobalClass.Notify("warning", "Transport Date can not be Empty")
                                                                } else if (G04Obj?.G04F21 < OBJ.DataGridRow?.FIELD02) {
                                                                    GlobalClass.Notify("warning", "Transport Date Greater or Equal to Doc Date")
                                                                } else {
                                                                    // handleSaveEwayBill("J")
                                                                    handleSaveEWB()
                                                                }
                                                            }}
                                                        >Save</Button>
                                                    </Grid.Col> :
                                                    <>
                                                        <Grid.Col
                                                            span={12}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Group>
                                                                <Button
                                                                    // loading={buttonLoading?.generate}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        if (G04Obj?.G04F23 == 0) {
                                                                            GlobalClass.Notify("warning", "Distance can not be Empty")
                                                                        } else if (G04Obj?.G04F21 == "") {
                                                                            GlobalClass.Notify("warning", "Transport Date can not be Empty")
                                                                        } else if (G04Obj?.G04F21 < obj.DataGridRow?.FIELD02) {
                                                                            GlobalClass.Notify("warning", "Transport Date Greater or Equal to Doc Date")
                                                                        } else {

                                                                            GenerateFun()
                                                                        }
                                                                    }}
                                                                >Generate</Button>
                                                                <Button
                                                                    loading={ButtonLoading?.upload}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        if (G04Obj?.G04F23 == 0) {
                                                                            GlobalClass.Notify("warning", "Distance can not be Empty")
                                                                        } else if (G04Obj?.G04F21 == "") {
                                                                            GlobalClass.Notify("warning", "Transport Date can not be Empty")
                                                                        } else if (G04Obj?.G04F21 < obj.DataGridRow?.FIELD02) {
                                                                            GlobalClass.Notify("warning", "Transport Date Greater or Equal to Doc Date")
                                                                        } else {
                                                                            // setButtonLoading({ ...buttonLoading, upload: true })
                                                                            dispatch(GetButtonLoading({ ...ButtonLoading, upload: true }))
                                                                            handleSaveEwayBill("U")
                                                                        }
                                                                    }}
                                                                >Upload</Button>
                                                                <Button
                                                                    loading={ButtonLoading?.generateQr}
                                                                    disabled={oYear?.OSC?._EWBSMS == "N" ? true : false}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        if (G04Obj?.G04F23 == 0) {
                                                                            GlobalClass.Notify("warning", "Distance can not be Empty")
                                                                        } else if (G04Obj?.G04F21 == "") {
                                                                            GlobalClass.Notify("warning", "Transport Date can not be Empty")
                                                                        } else if (G04Obj?.G04F21 < obj.DataGridRow?.FIELD02) {
                                                                            GlobalClass.Notify("warning", "Transport Date Greater or Equal to Doc Date")
                                                                        } else {
                                                                            // setButtonLoading({ ...buttonLoading, generateQr: true })
                                                                            dispatch(GetButtonLoading({
                                                                                ...ButtonLoading,
                                                                                generateQr: true
                                                                            }))
                                                                            handleSaveEwayBill("Q")
                                                                        }
                                                                    }}
                                                                >Generate QR</Button>
                                                            </Group>
                                                        </Grid.Col>
                                                        <Grid.Col
                                                            span={12}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Group>
                                                                <Button
                                                                    disabled={oYear?.OSC?._EWBSMS == "N" ? true : false}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        UpdateEWB()
                                                                    }}
                                                                >Get EWB</Button>
                                                                <Button
                                                                    // loading={buttonLoading?.updateVeh}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        UpdateVehNumForm()
                                                                    }}
                                                                >Update Veh. No.</Button>
                                                                <Button
                                                                    // loading={buttonLoading?.cancel}
                                                                    style={{ height: "30px", width: "150px" }}
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        cancelEWayBill()
                                                                    }}
                                                                >Cancel</Button>
                                                            </Group>
                                                        </Grid.Col>
                                                    </>
                                            }
                                        </Grid>
                                    </Paper>
                                </>
                        }
                    </>
            }
        </>
    )
}

export default EWayBill