import { Button, Grid, Paper } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import Store from '../../utils/store'
import { GetButtonLoading } from '../../utils/TransactionSlices/EWayBillSlice'
import { useSelector } from 'react-redux'

const GenerateEWayBill = (props) => {
    const { G04Obj, setG04Obj, DrawerObjSend, setDrawerObjSend } = props
    const [generateObj, setGenerateObj] = useState({})
    const ButtonLoading = useSelector((state) => state.EWayBill?.ButtonLoading)
    const FileData = [
        {
            "DisplayMember": "Json",
            "ValueMember": "J"
        },
        {
            "DisplayMember": "Excel",
            "ValueMember": "E"
        }
    ]

    useEffect(() => {
        if (G04Obj) {
            setGenerateObj(G04Obj)
        }
    }, [G04Obj])

    const GenerateEwayFun = () => {
        setG04Obj(generateObj)
    }

    return (
        <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={2} style={{ fontSize: 12 }}>File</Grid.Col>
                    <Grid.Col span={10}>
                        <PLComboBox
                            data={FileData}
                            valexpr={"ValueMember"}
                            dispexpr={"DisplayMember"}
                            setEdit={(e) => {
                                // setFileVal(e)
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col
                    span={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        loading={ButtonLoading?.generate}
                        style={{ height: "30px" }}
                        size="sm"
                        onClick={() => {
                            // Promise.resolve(setButtonLoading({ ...buttonLoading, generate: true })).then(() => {
Store.dispatch(GetButtonLoading({...ButtonLoading, generate:true}))
                            GenerateEwayFun()
                            // })

                        }}
                    >OK</Button>

                </Grid.Col>
            </Grid>
        </Paper>
    )
}

export default GenerateEWayBill