import '../global.js'
/* eslint-disable */
import '../App.css';
//Report designer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/office-365/bold.reports.all.min.css';
import '@boldreports/javascript-reporting-controls/Content/office-365/bold.reportdesigner.min.css';
// import '@boldreports/javascript-reporting-controls/Content/v2.0/bootstrap-light/bold.report-designer.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import useWindowDimensions from '../utils/UseWindowDimensions'
import React, { useEffect, useRef, useState } from 'react';
import GlobalClass from '../utils/GlobalClass.jsx';
import { GetReportDesignerData } from '../utils/PrintSlice/ReportDesignerSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { DOMParser } from 'xmldom';
import { Button, Dialog, Group, Modal, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import gensetting from '../utils/gensetting.js';
import Store from '../utils/store.js';
import { ModalDelete } from '../utils/slices/ModalSlice.js';
import { saveAs } from "file-saver";
let defaultFields={
  "FIELD00": "", //id
  "FIELD01": "", //file name eg: sales.xml
  "FIELD02": "", //ccode
  "FIELD03": "C",//c
  "FIELD04": "", //Report name
  "FIELD05": "", 
  "FIELD06": "",
  "FIELD07": 0, //version
  "FIELD10": "",// XML code
  "FIELD21": "",
  "FIELD22": "",
  "FIELD23": "",
  "FIELD24": "",
  "FIELD25": "",
  "FIELD46": "",
  "FIELD51": "",
  "FIELD52": 0,
  "FIELD64": "",
  "FIELD91": "",
  "FIELD92": "", //name
  "FIELD95": "",
  "FIELD97": ""
}
const MyReportDesigner = (props) => {
  const reportDesigner = useRef(null);
  const { height,width } = useWindowDimensions();
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  var viewerStyle = { 'height': "92vh", 'width': "98vw"};
  var datasource ={ Name:'DataSource1' };
  const [values,setValues] = useState(defaultFields);
  const [Loading,setLoading] = useState(true);
  const [Error,setErrors] = useState("");
  // const [isNew,setIsNew] = useState()
    function controlInitialized(){
      var designerObj = $("#designer").data("boldReportDesigner");
      console.log("designerObj",designerObj.openReport);
      designerObj.addDataSource(datasource);
      if(props.edit && values?.FIELD10){
        console.log("editcase",values);
        
        designerObj.openReportDefinition(values?.FIELD10);
        // if(props.Data){
        //   props.Data.map(e=>{
        //         designerObj.updateDataset(e.name,e);
        //     })
        // }
      }
      else if(props.Data){
        props.Data.map(e=>{
              designerObj.addDataSet(e);
          })
      }
    }
    useEffect(()=>{
      setLoading(true);
        let param = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cCode: props.edit??"",
          cAction: props.edit?"E":"A",
          cOPara:"",
        };
        fetch(GlobalClass.ApiUrl+'Vouformat/GetC50Ent?pa='+JSON.stringify(param))
        .then((res)=>res.json())
        .then((e)=>{
          if(e.status == "SUCCESS"){
            var dt = e.data.C50;
              setValues(dt);
              setLoading(false);
              setErrors("")
          }
          else{
            setErrors(e.message)
            setLoading(false);
          }
        })
        .catch((e)=>{
          setErrors("Network Error!!");
          setLoading(false);
        })
    },[])
    const saveReport= ()=>{
      GlobalClass.Notify("info","Saving Report","Please Wait...",true,false,"reportsave");
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction":(values?.SaveAsNew ?"A": (props.edit?"E":"A")),
        "cOPara":"",
        "cCode":(values?.SaveAsNew ?"":(props.edit??"")),
        "cSData":JSON.stringify({
          "C50":values
        })
      });
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: param
      };
      fetch(GlobalClass.ApiUrl + GlobalClass.PostC50Ent, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log("respons",data);
          if(data.status == "FAIL"){
            GlobalClass.Notifyupdate("error","Error While Saving Report",data.message,false,2000,"reportsave");
          }
          else{
            GlobalClass.Notifyupdate("success","Report Saved","",false,2000,"reportsave");
            props.getData();
            Store.dispatch(ModalDelete(props.modalIndex))
          }
          
        })
        .catch((e)=>{
          console.log("Error",e);
          GlobalClass.Notifyupdate("error","Error","",false,2000,"reportsave");
        })
      // setTimeout(() => {
      //   GlobalClass.Notifyupdate("success","Report Saved","",false,2000,"reportsave");
      //   close()
      // }, 1500);
    }
    function saveAsRdlcFile(xmlString, filename) {
      // Step 1: Convert the XML string to a Blob object
      const blob = new Blob([xmlString], { type: 'application/xml' });
      console.log("filename",filename + '.rdlc');
      
      saveAs(blob,filename + '.rdlc')
  }
    function ontoolBarItemClick(args) {
      GlobalClass.Notify("info","Processing","Please Wait...",true,false,"reportsave");
      args.cancel = true;
      console.log("toolbarclick",args);
      
      var designerObj = $("#designer").data("boldReportDesigner");
      designerObj.saveReportDefinition((args2) => {
        
        if(args.select == "Save"){
          setValues({...values,FIELD10:args2.data.substring(1),FIELD02:props?.cCode})
          // setTimeout(()=>{
          //   if(props.edit){
          //     saveReport()
          //   }
          //   else{
              open()
          //   }
          // },500);
          
          // open()
          
        }
        else if(args.select == "SaveAsDevice"){
          saveAsRdlcFile(args2.data.substring(1),(values.FIELD04 && values.FIELD04 != "" ?values.FIELD04:"New Report"))
        }
        else if(args.select == "SaveAsServer"){
          setValues({...values,FIELD10:args2.data.substring(1),FIELD02:props?.cCode,FIELD00:"",SaveAsNew:true})
          open()
        }
        GlobalClass.Notifyupdate("info","","",false,0,"reportsave");
      }, ej.ReportDesigner.DataFormat.XML);
    }
    var toolbarSettings = {
        showToolbar: true,
        // templateId: "customtoolbarId",
        items: ej.ReportDesigner.ToolbarItems.All  & ~ej.ReportDesigner.ToolbarItems.New  & ~ej.ReportDesigner.ToolbarItems.SaveAs, //& ~ej.ReportDesigner.ToolbarItems.Preview
      }
    var configurePaneSettings = {
      showPane: true,
      items:ej.ReportDesigner.ConfigureItems.All //& ~ej.ReportDesigner.ConfigureItems.Property  & ~ej.ReportDesigner.ConfigureItems.Parameter
    }
    const pageSettings = {
      paperSize: "A4",
      orientation: "Portrait",
      width: "8.27in", // 210mm in inches
      height: "11.69in", // 297mm in inches
      margins: {
        left: 0.5,
        right: 0.5,
        top: 0.5,
        bottom: 0.5,
      },
    };
    return (
        <div style={viewerStyle}>
         <Modal 
         opened={opened} 
         onClose={close} 
         title="Save Report" 
         centered
         overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        transitionProps={{ transition: 'scale', duration: 300, timingFunction: 'linear' }}
        >
        <Group align="flex-end">
          <TextInput 
          placeholder="Report Name" 
          // maxLength={15}
          value={values.FIELD04} 
          onChange={(e)=>{
            var val = e.target.value;
            setValues({...values,FIELD04:val,FIELD92:val,FIELD01:((GlobalClass.slugify(val.toString().substring(0,10)))+".xml")})
          }} 
          sx={{ flex: 1 }} />
          <Button onClick={()=>{saveReport()}}>{props.edit?"Update":"Save"}</Button>
        </Group>
      </Modal>
        {Loading? GlobalClass.RenderLoader("skeleton",20,true): <BoldReportDesignerComponent
            ref={reportDesigner}
            id="designer"
            serviceUrl={GlobalClass.ApiUrl+"ReportingAPI"}
            reportServerUrl={GlobalClass.ApiUrl+'ReportViewer'}
            permissionSettings= {'dataSet: ~ej.ReportDesigner.Permission.All,dataSource: ~ej.ReportDesigner.Permission.All, '}
            // toolbarSettings= { 'items: ~ej.ReportDesigner.ToolbarItems.All' }
            toolbarSettings = {toolbarSettings}
            saveReportClick= {ontoolBarItemClick}
            configurePaneSettings= {configurePaneSettings}
            // toolOptions={toolOptions}
            create={controlInitialized}
            // reportPath={XMLFile}
            reportType= {'RDLC'}
            // ajaxBeforeLoad = {onAjaxRequest}
            reportError={ (args)=> {
                // Write a code block to perform any operation when report error occurs.
                // console.log("Report Error: ",args);
            }}
            processingMode={"Local"}
            // toolbarSettings={{}}
            toolbarRendering={(arg)=>{
              console.log("args",arg.target);
              
            }}
            pageSettings={pageSettings}
            // reportType: ej.ReportDesigner.ReportType.RDLC,
        />}
        </div>
    );
};

export default MyReportDesigner;
