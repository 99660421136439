import { ActionIcon, Alert, Button, Center, Checkbox, Drawer, Flex, Grid, Modal, Text, Tooltip } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CashBankAddApi, CashBankApi, CashBankEditApi, getData, getFlagStatus, getSaveBtnLoader, setInvoiceValue, setSuccess, setToggleButton } from '../../utils/TransactionSlices/CashBankSlice';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { IconEdit } from '@tabler/icons-react';
import GlobalClass from '../../utils/GlobalClass';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import gensetting from '../../utils/gensetting';
import PLBillToBill from '../../PlusComponent/PLBillToBill';
import { GetExpenseData } from '../../utils/TransactionSlices/TSalesSlices';
import { GetAccountListForPrint } from '../../utils/PrintSlice/PrintSlice';

let data;
let flag = false;
var isPostSuccess = false;


export const CashBankTable = (props) => {

    console.log("props", props);

    const { obj, funEnable, rowSelection, setRowSelection, T41List, setT41List, T41, setT41, setNarrationValue, setBalanceObj, rowId, setRowID, row, setRow } = props;

    const invType = useRef(null)
    const accName = useRef(null)
    const narrationRef = useRef(null)

    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const WDAY = weekdays[new Date().getDay()];
    const [weekday, setWeekDay] = useState(WDAY)

    const dispatch = useDispatch()
    const columnHelper = createColumnHelper();
    const [validationErrors, setValidationErrors] = useState({});
    const [columns, setColumns] = useState();
    const [visible, setVisible] = useState(false);
    const [dataObj, setdataObj] = useState();
    const [T05, setT05] = useState([]);
    const [T05ENT, setT05ENT] = useState([]);
    const [isBillToBill, setIsBillToBill] = useState(false);
    const [action, setAction] = useState("")
    const [accListData, setAccListData] = useState([])
    const [message, setMessage] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const CashBankAddData = useSelector(store => store?.CashBank?.CashBankAddData);
    const CashBankEditData = useSelector(store => store?.CashBank?.CashBankEditData);
    const T41Data = useSelector(store => store?.CashBank?.Data);
    const success = useSelector(store => store?.CashBank?.success);
    const toggleButton = useSelector(store => store?.CashBank?.toggleButton);
    const CashBankData = useSelector(store => store?.CashBank?.CashBankData);
    const printData = useSelector((state) => state?.PrintList?.PrintData[obj?.id]);
    const invoiceValue = useSelector((store) => store?.CashBank?.invoiceValue);

    const [tableEditingRow, setTableEditingRow] = useState(null);


    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    const obj1 = {
        id: obj?.id,
        // name : "Account List",
        // type: "A",
        // p0: "MT",
        p1: "P_M01",
        p2: "",
        // p3: ""
    }

    useEffect(() => {
        console.log("useEffect cashbank add api call", success);
        if (success) {
            dispatch(CashBankAddApi({ obj }))
            dispatch(GetAccountListForPrint(obj1))
        }
    }, [success])

    useEffect(() => {
        setAccListData(printData)
    }, [printData])

    useEffect(() => {
        console.log("message", message);
    }, [message])

    useEffect(() => {

        console.log("T41", T41);

        const date = T41?.FIELD02;
        const date1 = date?.slice(0, 4) +
            "-" +
            date?.slice(4, 6) +
            "-" +
            date?.slice(6, 8)
        const newDate = new Date(`"${date1}"`)
        const day = newDate.getDay();
        setWeekDay(weekdays[day])
        if (!T41?.MIDDLECRDB) {
            setT41({ ...T41, MIDDLECRDB: "C" })
        }

        if (accListData) {
            let result = accListData?.filter(item => item?.FIELD01 == T41?.FIELD04)?.map(ele => ele.FIELD08).join("")
            console.log("result", result);
            if (result == "2") {
                setIsBillToBill(true)
            }
        }

        // // if (action === "E") {
        // const oldAmt = action === "E" ? T41?.FIELD08 : 0
        // console.log("oldAmt", oldAmt);

        // let oM01 = accListData?.find(item => item?.FIELD01 == T41?.FIELD04)
        // console.log("oM01", oM01);


        // // const FIELD06 = oM01?.filter(item => item.FIELD06 != "G000016A")
        // // .join("")

        // // console.log("FIELD06",FIELD06);

        // if (CashBankEditData?.OSV?._NGBALWARN != "N" && oM01?.FIELD06 != "G000016A") {
        //     let nBal = (oM01?.FIELD99 + (action === "E" ? oldAmt * -1 : 0)) - T41?.FIELD07 * (T41?.FIELD16 == "P" ? -1 : 1)
        //     console.log("nBal", nBal, typeof nBal);
        //     if (nBal > 0) {
        //         let message = `Negative Balance Of ${oM01?.FIELD02.Trim()}` + nBal;


        //         // console.log("message", message);
        //     }
        // }

        // console.log("oldAmt", oldAmt)
        // // }

    }, [T41])

    useEffect(() => {
        console.log("CashBankAddData", CashBankAddData);

        if (CashBankAddData?.T41) {
            data = obj.id === "04500447" ? { ...CashBankAddData?.T41, FIELD05: "ACASHACT" } : { ...CashBankAddData?.T41, FIELD05: "AHP5S1OG" }
            setT41(data);
            dispatch(getData(data));
        }
        setT05(CashBankAddData?.T05)
        setT05ENT(CashBankAddData?.T05ENT)
    }, [CashBankAddData])

    useEffect(() => {
        console.log("CashBankEditData", CashBankEditData);

        const newData = CashBankEditData?.T41 ? CashBankEditData?.T41 : data;
        setT41(newData)
        setdataObj(newData)
        // setAction("E")
    }, [CashBankEditData])

    // useEffect(() => {
    //     console.log("invoiceValue", invoiceValue);
    //     if (invoiceValue) {
    //         // dispatch(GetExpenseData({ action: "A", code: obj?.id === "04500447" ? "CRCR" : "JRJR", invCode: invoiceValue }))
    //     } // Expense Calculation
    // }, [invoiceValue])

    const refreshBtn = () => {
        dispatch(CashBankApi(obj));
        setRowSelection({});
        setRowID("");
        dispatch(setInvoiceValue({}));
        dispatch(setSuccess(true));
        dispatch(setToggleButton(false));
        funEnable(true)
        setAction("")
    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        paraArr[1] = paraArr[1] + "";
        var paraArrSep = paraArr[1].split("~R~");
        console.log("paraArrSep", paraArrSep[0]);
        return paraArrSep;
    };

    const seperate1 = (e) => {
        var paraArr = e.split('~C~');
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr
    }

    const NextIndexFun = (index) => {

        console.log("index", index);

        let nextInd;
        nextInd = index + 1;
        const nextInput = document.getElementById(`T41_${nextInd}`);
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus();
        }
    };

    const BilltoBillBtn = (table, row, e) => {

        console.log("row", row);

        if (isBillToBill) {
            setDrawerObj({
                ...dra, title: <Text fw={700}>Bill to Bill</Text>,
                // onClickCloseButton: () => {
                //     console.log("after bill to bill");

                // },
                body: <>
                    <PLBillToBill
                        T41ObjSend={T41}
                        T05Format={T05ENT}
                        T05UserField={rowId ? CashBankEditData?.UFT05H : CashBankAddData?.UFT05H}
                        T05Data={T05}
                        setT05Data={(e) => setT05(e)}
                    />
                </>, open: true, size: "50%", position: "right"
            })
        }
        else if (e.key == "Enter") {
            handleSaveUser({ table, row })
        }
    }

    const okBtn = () => {
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
        setMessage("")
        setIsOpen(false)
    }

    const renderValue = (cellValue, row, column, item, index) => {
        var editor;
        props?.T41GRD?.map((item, index) => {
            // console.log("item",item,index);
            if (item.S53F03 == column?.columnDef?.header) {
                switch (item?.S53F14) {
                    case "PLPOPUP":
                        if (item.S53F02 == "QUICK010") {
                            editor = (
                                <PLDataGrid
                                    // autoFocus={index == 0 ? true : false}
                                    ref={invType}
                                    id={`T41_${index}`}
                                    position="bottom"
                                    width="500px"
                                    data={CashBankAddData?.M45IDT}
                                    TmData={CashBankAddData?.M45I}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        Promise.resolve(setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.FIELD01,
                                            FIELD13NM: e.FIELD02,
                                            M45F02: e.FIELD02
                                        })))
                                            .then(() => {
                                                NextIndexFun(index);
                                            })

                                    }}
                                />
                            );
                        }// INV Type
                        else {
                            editor = (
                                <PLDataGrid
                                    // autoFocus={index == 0 ? true : false}
                                    ref={accName}
                                    id={`T41_${index}`}
                                    position="bottom"
                                    width="500px"
                                    p1={seperate(item.S53F15)[0]}
                                    value={item.S53F02 == "QUICK006" ? { value: T41?.[item.S53F04], label: T41?.FIELD04NM } : T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        console.log("e", e);
                                        if (item.S53F02 == "QUICK006") {
                                            Promise.resolve(
                                                setT41((prevT41) => ({
                                                    ...prevT41,
                                                    [item.S53F04]: e.FIELD01,
                                                    FIELD04NM: e.FIELD02,
                                                    M01F02: e.FIELD02
                                                }))
                                            ).then(() => {
                                                if (CashBankEditData?.OSV?._CHQNMBP && CashBankEditData?.OSV?._CHQNMBP == "Y") {
                                                    setIsOpen(true)
                                                    setDrawerObj({
                                                        ...dra,
                                                        title: <Text fw={700}>Cheque Name</Text>,
                                                        body: (
                                                            <>
                                                                {
                                                                    <div>
                                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                                                            <Grid.Col span={3}>
                                                                                <Text style={{ fontSize: 12 }}>Cheque Name</Text>
                                                                            </Grid.Col>
                                                                            <Grid.Col span={9}>
                                                                                <PLTextBox
                                                                                    //  position=""
                                                                                    // name={item?.S13F02}
                                                                                    value={T41.FIELD32}
                                                                                    setEdit={(e) => {
                                                                                        setT41({ ...T41, FIELD32: e.target.value })

                                                                                    }}
                                                                                />
                                                                            </Grid.Col>
                                                                        </Grid.Col>
                                                                        <Center><Button onClick={okBtn}>Ok</Button></Center>
                                                                    </div>
                                                                }
                                                            </>
                                                        ),
                                                        open: true,
                                                        size: "md",
                                                        position: "right",
                                                    });
                                                }
                                                else {
                                                    NextIndexFun(index);
                                                }
                                            })

                                            if (e.FIELD08 == "2") {
                                                setIsBillToBill(true)
                                            }
                                        }
                                        else {
                                            Promise.resolve(setT41((prevT41) => ({
                                                ...prevT41,
                                                [item.S53F04]: e.FIELD01,
                                                // FIELD13NM : e.FIELD02
                                            }))).then(() => {
                                                NextIndexFun(index);
                                            })
                                        }

                                    }}
                                />
                            );
                        }//Account Name
                        break;
                    case "DATEPICKER":

                        if (item.S53F02 == "QUICK002") {
                            editor = (
                                <PLDateBox
                                    autoFocus={true}
                                    id={`T41_${index}`}
                                    dispformat="DD/MM/YYYY"
                                    defaultval={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        let dayName = e.getDay();
                                        setWeekDay(weekdays[dayName])
                                        let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                        let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                        let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                        let startDate = year + month + day;
                                        Promise.resolve(setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: startDate,
                                        }))).then(() => {
                                            NextIndexFun(index + 3)
                                        })
                                    }}
                                />
                            );
                        }
                        else {
                            editor = (
                                <PLDateBox
                                    // autoFocus={true}
                                    id={`T41_${index}`}
                                    dispformat="DD/MM/YYYY"
                                    defaultval={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        let dayName = e.getDay();
                                        setWeekDay(weekdays[dayName])
                                        let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                        let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                        let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                        let startDate = year + month + day;
                                        Promise.resolve(setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: startDate,
                                        }))).then(() => {
                                            NextIndexFun(index)
                                        })
                                    }}
                                />
                            );
                        }

                        break;
                    case "NUMBOX":
                        editor = (
                            <PLNumberBox
                                // autoFocus={index == 0 ? true : false}
                                id={`T41_${index}`}
                                decimalSeparator={item.S53F03 == "Amount" ? true : false}
                                precision={item.S53F03 == "Amount" ? 2 : ""}
                                value={T41?.[item.S53F04]}
                                setEdit={(e) => {
                                    setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: e,
                                    }))
                                }}
                                onKeyDown={(e) => {
                                    if (item.S53F03 == "Amount") {
                                        if (e.key == "Tab" || e.key == "Enter") {

                                            const oldAmt = action === "E" ? T41?.FIELD08 : 0

                                            let oM01 = accListData?.find(item => item?.FIELD01 == T41?.FIELD04)
                                            console.log("oM01", oM01);

                                            if (CashBankEditData?.OSV?._NGBALWARN != "N" && oM01?.FIELD06 != "G000016A") {
                                                let nBal = (oM01?.FIELD99 + (action === "E" ? oldAmt * -1 : 0)) - T41?.FIELD07 * (T41?.FIELD16 == "P" ? -1 : 1)
                                                if (nBal > 0) {
                                                    let message = `Negative Balance Of ${oM01?.FIELD02?.trim()}` + nBal;
                                                    message && setMessage(message)
                                                    console.log("message", message, nBal);
                                                    setDrawerObj({
                                                        ...dra,
                                                        title: <Text fw={700}>Warning</Text>,
                                                        body: (
                                                            <>
                                                                {
                                                                    message &&
                                                                    <div>
                                                                        <Text style={{ fontSize: 14 }}>{message}</Text>
                                                                        <Center><Button onClick={okBtn}>Ok</Button></Center>
                                                                    </div>
                                                                }
                                                            </>
                                                        ),
                                                        open: true,
                                                        size: "md",
                                                        position: "right",
                                                    });
                                                }
                                                else {
                                                    NextIndexFun(index - 1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        NextIndexFun(index)
                                    }

                                }}
                                setNumber={() => {
                                    if (item.S53F03 == "Amount") {

                                        const oldAmt = action === "E" ? T41?.FIELD08 : 0

                                        let oM01 = accListData?.find(item => item?.FIELD01 == T41?.FIELD04)
                                        console.log("oM01", oM01);

                                        if (CashBankEditData?.OSV?._NGBALWARN != "N" && oM01?.FIELD06 != "G000016A") {
                                            let nBal = (oM01?.FIELD99 + (action === "E" ? oldAmt * -1 : 0)) - T41?.FIELD07 * (T41?.FIELD16 == "P" ? -1 : 1)
                                            if (nBal > 0) {
                                                let message = `Negative Balance Of ${oM01?.FIELD02?.trim()}` + nBal;
                                                message && setMessage(message)
                                                console.log("message", message, nBal);
                                                setDrawerObj({
                                                    ...dra,
                                                    title: <Text fw={700}>Warning</Text>,
                                                    body: (
                                                        <>
                                                            {
                                                                message &&
                                                                <div>
                                                                    <Text style={{ fontSize: 14 }}>{message}</Text>
                                                                    <Center><Button onClick={okBtn}>Ok</Button></Center>
                                                                </div>
                                                            }
                                                        </>
                                                    ),
                                                    open: true,
                                                    size: "md",
                                                    position: "right",
                                                });
                                            }
                                            else {
                                                NextIndexFun(index - 1);
                                            }
                                        }

                                    }

                                }}
                            />
                        );
                        break;
                    case "TEXTBOX":
                        if (item.S53F03 == "Day") {
                            editor = (<PLTextBox value={weekday} disabled />)
                        }
                        else if (item.S53F02 == "QUICK009") {
                            editor = (
                                <PLTextBox
                                    // autoFocus={index == 0 ? true : false}
                                    ref={narrationRef}
                                    id={`T41_${index}`}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.target.value,
                                        }))
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Tab" || e.key == "Enter") {
                                            // if (item.S53F02 == "QUICK009") {
                                            BilltoBillBtn(table, row, e)
                                            // }
                                        }
                                        // else if (e.key == "Enter") {
                                        //     if (item.S53F02 == "QUICK009") {
                                        //         BilltoBillBtn()
                                        //     }
                                        // }
                                    }}
                                />
                            );
                        }//Naration
                        else if (item.S53F02 == "QUICK008") {
                            editor = (
                                <PLTextBox
                                    // autoFocus={index == 0 ? true : false}
                                    id={`T41_${index}`}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.target.value,
                                        }))
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Tab") {
                                            invType.current.handlePopoverToggle();
                                        }
                                    }}
                                />
                            );

                        }// Bill/Vou No
                        else {
                            editor = (
                                <PLTextBox
                                    // autoFocus={index == 0 ? true : false}
                                    id={`T41_${index}`}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.target.value,
                                        }))
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Tab") {
                                            if (item.S53F02 == "QUICK005") {
                                                accName.current.handlePopoverToggle();
                                            }  //Doc/Bill/ChecqueNo
                                            else {
                                                NextIndexFun(index);
                                            }
                                        }
                                    }}
                                />
                            );
                        }
                        break;
                    case "COMBOBOX":
                        editor = (
                            <PLComboBox
                                // autoFocus={index == 0 ? true : false}
                                cmbid={`T41_${index}`}
                                copno={seperate1(item.S53F15)[1]}
                                value={T41?.[item.S53F04]}
                                dispexpr='DisplayMember'
                                valexpr='ValueMember'
                                setEdit={(e) => {
                                    Promise.resolve(setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: e.value,
                                    }))).then(() => {
                                        NextIndexFun(index);
                                    })
                                }}
                            />
                        );
                        break;
                    case "CHECKBOX":
                        editor = (
                            <Checkbox
                                // autoFocus={index == 0 ? true : false}
                                checked={T41?.[item.S53F04] == "A" ? true : false}
                                onChange={(event) => {
                                    setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: event.target.checked == true ? "A" : "N",
                                    }))
                                }}

                            />
                        );
                        break;
                    default:
                        break;
                }
            }
        });

        return editor;
    };

    useEffect(() => {
        var cols = []

        T41List && props?.T41GRD?.filter(item => item.S53F00 == "C").map((item, index) => {
            // props?.T41GRD?.map((item, index) => {
            cols.push(columnHelper.accessor(item?.S53F04, {
                header: item?.S53F03,
                uid: item?.S53F03,
                size: item?.S53F02 == "QUICK006" ? 200 : 120,
                Cell: ({ cell, row }) => {
                    var val = cell.getValue();
                    if (item.S53F03.trim() == "Date") {
                        return val?.slice(6, 8) +
                            "-" +
                            val?.slice(4, 6) +
                            "-" +
                            val?.slice(0, 4)
                    }
                    else if (item.S53F02 == "QUICK010") {
                        return T41List[row?.id]?.M45F02
                    }// INV Type
                    else if (item.S53F02 == "QUICK006") {
                        return T41List[row?.id]?.M01F02
                    }//Account Name
                    else {
                        return val
                    }
                },
                Edit: ({ cell, column, table, row }) =>
                    renderValue(cell.getValue(), row, column, item, index),
            }))
        })
        console.log("cols", cols);
        setColumns(cols)
    }, [props?.T41GRD, T41, weekday])

    // useEffect(() => {
    document.addEventListener("keydown", (e) => {
        if (e?.key == "Escape") {
            console.log("yes");
            Promise.resolve()
                .then(() => {
                    setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}); setIsBillToBill(false); setRowID(""); setInvoiceValue({})
                })
                .then(() => {
                    if (tableEditingRow == null) {
                        table.setCreatingRow(null);
                    } else {
                        table.setEditingRow(null);
                    }
                });
        }
    });
    // },[tableEditingRow])


    //CREATE action
    const handleCreateUser = async () => {

        if (props?.obj?.id === "04500447") { // Cash/Bank
            if (T41.FIELD04) {

                GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
                dispatch(getSaveBtnLoader(true))

                try {
                    let T41Obj = { T41: T41 };
                    let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

                    var data2 = {
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "A",
                        "cCode": cCode,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                        "cSData": JSON.stringify(T41Obj)
                    };

                    let param =
                        JSON.stringify({
                            "CorpID": gensetting.getcorpid(),
                            "cUser": gensetting.getcuser(),
                            "cPass": gensetting.getcpass(),
                            "CmpNo": gensetting.getcmpno(),
                            "cAction": "A",
                            "cCode": cCode,
                            "cOPara": obj.id === "04500447" ? "C" : "J",
                        });

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data2),
                    };

                    const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                    const responseData = await response.json();

                    // console.log("data=>>>>>>>>>>>", responseData);

                    if (responseData.status === 'SUCCESS') {
                        setT41List([...T41List, T41])
                        GlobalClass.Notify(
                            "success",
                            "Added",
                            "Added successfully"
                        );
                        dispatch(getFlagStatus(true))
                        isPostSuccess = true
                        

                        console.log("isPostSuccess", isPostSuccess);

                        Promise.resolve(dispatch(CashBankApi(obj)))
                            .then()
                            .catch((error) => {
                                GlobalClass.Notify("error", "Error", error?.message);
                            })
                    }
                    else if (responseData.status === 'FAIL') {
                        setT41List(T41List)
                        GlobalClass.Notify("error", responseData?.status, responseData?.message);
                        dispatch(getSaveBtnLoader(false))
                        isPostSuccess = false

                    }
                }
                catch (error) {
                    setT41List(T41List)
                    GlobalClass.Notify("error", "Error ", error.message);
                    dispatch(getSaveBtnLoader(false))
                    isPostSuccess = false

                }

                finally {
                    if (!isPostSuccess) {
                        table.setEditingRow(row);
                    }
                    else {
                        funEnable(true)
                        table.setCreatingRow(null);
                        setInvoiceValue({})
                        setValidationErrors({});
                        setT41(T41Data)
                        setWeekDay(WDAY)
                        dispatch(setToggleButton(false))
                        flag = false
                        setRowSelection({})
                        setRowID("");
                        setIsBillToBill(false)

                        setTimeout(() => {
                            table.setCreatingRow(true);
                            setVisible(true);
                            funEnable(false);
                            dispatch(setToggleButton(true));
                            // flag = true;
                        }, 500);

                    }
                };

            }
            else {
                GlobalClass.Notify("warning", "", "Please Select Account Code")
            }
        }
        else if (props?.obj?.id === "04560447") { // Journal

            if (T41.FIELD04 && T41?.FIELD07) {

                GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
                dispatch(getSaveBtnLoader(true))

                try {

                    // let T41Data;
                    // if (T41?.MIDDLECRDB) {
                    //     T41Data = T41
                    // }
                    // else {
                    //     T41Data = { ...T41, MIDDLECRDB: "C" }
                    // }
                    // let T41Obj = { T41: T41Data };

                    // console.log("T41Data", T41Data);

                    let T41Obj = { T41: T41 };

                    let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

                    var data2 = {
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "A",
                        "cCode": cCode,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                        "cSData": JSON.stringify(T41Obj)
                    };

                    let param =
                        JSON.stringify({
                            "CorpID": gensetting.getcorpid(),
                            "cUser": gensetting.getcuser(),
                            "cPass": gensetting.getcpass(),
                            "CmpNo": gensetting.getcmpno(),
                            "cAction": "A",
                            "cCode": cCode,
                            "cOPara": obj.id === "04500447" ? "C" : "J",
                        });

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data2),
                    };

                    const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                    const responseData = await response.json();

                    // console.log("data=>>>>>>>>>>>", responseData);

                    if (responseData.status === 'SUCCESS') {
                        setT41List([...T41List, T41])
                        GlobalClass.Notify(
                            "success",
                            "Added",
                            "Added successfully"
                        );
                        dispatch(getFlagStatus(true))
                        isPostSuccess = true

                        Promise.resolve(dispatch(CashBankApi(obj)))
                            .then()
                            .catch((error) => {
                                GlobalClass.Notify("error", "Error", error?.message);
                            })
                    }
                    else if (responseData.status === 'FAIL') {
                        setT41List(T41List)
                        GlobalClass.Notify("error", responseData?.status, responseData?.message);
                        dispatch(getSaveBtnLoader(false))
                        isPostSuccess = false

                    }
                }
                catch (error) {
                    setT41List(T41List)
                    GlobalClass.Notify("error", "Error ", error.message);
                    dispatch(getSaveBtnLoader(false))
                    isPostSuccess = false

                }

                finally {
                    if (!isPostSuccess) {
                        table.setEditingRow(row);
                    }
                    else {

                        funEnable(true)
                        table.setCreatingRow(null);
                        setValidationErrors({});
                        setInvoiceValue({})
                        setT41(T41Data)
                        setWeekDay(WDAY)
                        dispatch(setToggleButton(false))
                        flag = false
                        setRowSelection({})
                        setRowID("")
                        setIsBillToBill(false)

                        setTimeout(() => {
                            table.setCreatingRow(true);
                            setVisible(true);
                            funEnable(false);
                            dispatch(setToggleButton(true));
                            // flag = true;
                        }, 500);

                    }
                };

            }
            else {
                GlobalClass.Notify("warning", "", "Please Enter Account and Amount")
            }
        }

        // <FormatListTable obj={{...obj,p0:"S"}} index={props.index} />
    }

    //UPDATE action
    const handleSaveUser = async ({ table, row }) => {

        console.log("row", row);

        GlobalClass.Notify("info", "Editing", "Please wait while we process your data");
        if (JSON.stringify(T41) === JSON.stringify(dataObj)) {
            GlobalClass.Notify("success", "Edited", "Edited successfully");
            funEnable(true)
            table.setEditingRow(null);
            setValidationErrors({})
            dispatch(setToggleButton(false))
            flag = false;
            setT41(T41Data)
            setWeekDay(WDAY)
            setRowSelection({})
            setRowID("")
            setInvoiceValue({})
            setAction("")
        }
        else {
            try {
                let T41Obj = { T41: T41 };
                const rowId = T41?.FIELD01;

                var data1 = {
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "E",
                    "cCode": rowId,
                    "cOPara": obj.id === "04500447" ? "C" : "J",
                    "cSData": JSON.stringify(T41Obj)
                };

                let param =
                    JSON.stringify({
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "E",
                        "cCode": rowId,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                    });

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data1),
                };

                const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                const responseData = await response.json();

                console.log("data=>>>>>>>>>>>", responseData);

                if (responseData.status == 'SUCCESS') {
                    GlobalClass.Notify(
                        "success",
                        "Edited",
                        "Edited successfully"
                    );
                    var tempT41List = [...T41List];
                    tempT41List[row.index] = T41;
                    setT41List(tempT41List)
                    isPostSuccess = true;
                    console.log("SUCCESS", isPostSuccess);
                    setAction("")

                }
                else if (responseData.status == 'FAIL') {
                    console.log("FAIL", isPostSuccess);
                    GlobalClass.Notify("error", responseData?.status, responseData?.message);
                    setT41List(T41List)
                    isPostSuccess = false
                }
            }
            catch (e) {
                setT41List(T41List)
                GlobalClass.Notify("error", "Error", `${e.message}`);
                isPostSuccess = false
            }
            finally {
                // funEnable(true)
                // table.setEditingRow(null);
                // setValidationErrors({})
                // dispatch(setToggleButton(false))
                // flag = false
                // setT41(T41Data)
                // setWeekDay(WDAY)
                // setRowSelection({})
                // setRowID("")
                // setInvoiceValue({})

                if (!isPostSuccess) {
                    console.log("isPostSuccess", isPostSuccess);
                    console.log("row", row);
                    table.setEditingRow(row);
                }
                else {
                    console.log("else");
                    setTableEditingRow(null);
                    setT41(T41Data)
                    funEnable(true)
                    table.setEditingRow(null);
                    setValidationErrors({})
                    dispatch(setToggleButton(false))
                    flag = false
                    setWeekDay(WDAY)
                    setRowSelection({})
                    setRowID("")
                    setInvoiceValue({})
                    setIsBillToBill(false)

                    setTimeout(() => {
                        table.setCreatingRow(true);
                        setVisible(true);
                        funEnable(false);
                        dispatch(setToggleButton(true));
                    }, 500);

                }
            };
        };
    }

    const handleRowKeyDown = (e, row) => {
        if (row?.id == 'mrt-row-create') {
            return
        }
        else if (e?.key == "Enter" && toggleButton == false) {
            console.log("handleRowKeyDown")
            table.setEditingRow(row);
            funEnable(false);
            dispatch(setToggleButton(true));
            setTableEditingRow(row);

            let rowId = row.original.FIELD01;
            if (rowId) {
                dispatch(CashBankEditApi({ obj, rowId }));
            }
            else {
                setT41(row.original);
            }
        }
    };

    const table = useMantineReactTable({
        columns: columns || [],
        data: T41List || [],
        enableFilters: false,
        enableSorting: false,
        positionToolbarAlertBanner: "none",
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableTopToolbar: true,
        enableHiding: false,
        enableRowNumbers: false,
        enableColumnOrdering: false,
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        // enablePagination:false, 
        initialState: {
            density: "0px",
            columnVisibility: {
                CVOUNAME: obj.id === "04500447" ? false : false,
                MIDDLECRDB: obj.id === "04500447" ? false : true,
                FIELD14: obj.id === "04500447" ? true : true,
                CDAYNAME: obj.id === "04500447" ? true : false,
                FIELD10: obj.id === "04500447" ? true : false,
            }
        },
        state: {
            rowSelection,
            density: "1.5px"
        },
        enableEditing: true,
        editDisplayMode: 'row',
        createDisplayMode: 'row',
        // getCoreRowModel: getCoreRowModel(),

        mantineTableContainerProps: { sx: { height: '300px' } },
        enableSelectAll: false,
        enableMultiRowSelection: false,
        enableTableFooter: false,
        mantineTableBodyRowProps: ({ row }) => ({
            tabIndex: 0,
            onKeyUp: (e) => {
                handleRowKeyDown(e, row);
            },
            onClick: (e) => {

                console.log("row", row);
                console.log("rowSelection", rowSelection);

                row?.original && dispatch(setInvoiceValue(row?.original))// Inv Type

                setNarrationValue(row?.original?.FIELD15)

                setBalanceObj({ balance: row?.original?.M01F99, CRDB: row?.original?.M01F99 > 0 ? "CR" : "DB" })

                setRowID(row?.original?.FIELD01)

                // setRow(row?.original)

                setRowSelection((prev) => {
                    // [row.id]: !prev[row.id],
                    console.log("prev", prev, !prev[row.id], row?.id);
                    if (!prev[row.id]) {
                        return { [row.id]: true }
                    }
                    return prev
                })
            },
            selected: rowSelection[row.id],
            sx: {
                cursor: 'pointer',
                color: 'black',
            },
            height: 30

        }),



        onCreatingRowCancel: () => { setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}); setIsBillToBill(false); setRowID(""); setInvoiceValue({})},
        onCreatingRowSave: handleCreateUser,
        onEditingRowCancel: () => { setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}); setIsBillToBill(false); setRowID(""); setInvoiceValue({});setAction("") },
        onEditingRowSave: handleSaveUser,
        renderRowActions: ({ row, table }) => (
            !toggleButton &&
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row);
                        funEnable(false);
                        dispatch(setToggleButton(true));
                        setTableEditingRow(row);
                        setAction("E")
                        // flag = true;

                        let rowId = row.original.FIELD01;
                        if (rowId) {
                            dispatch(CashBankEditApi({ obj, rowId }));
                        }
                        else {
                            setT41(row.original);
                        }
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Grid>
                <Grid.Col span={10} >
                    <Button
                        disabled={toggleButton}
                        onClick={() => {
                            table.setCreatingRow(true);
                            setVisible(true);
                            funEnable(false);
                            dispatch(setToggleButton(true));
                            // flag = true;
                        }}
                        size='xs'
                    >
                        Add New
                    </Button>
                </Grid.Col>

                <Grid.Col span={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant='light'
                        size='xs'
                        onClick={refreshBtn}
                    >
                        Refresh
                    </Button>
                </Grid.Col>
            </Grid>


        ),
    });

    const selectedRow = table.getSelectedRowModel()?.flatRows[0]

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: (e) => {
                        console.log("E", e);

                        console.log("onClick", table.getSelectedRowModel()?.flatRows[0]?.original)
                        console.log("selectedRow", selectedRow);
                        console.log("table.getSelectedRowModel()?.flatRows[0]?.original", table.getSelectedRowModel()?.flatRows[0]);

                        // if (typeof DrawerObj?.onClickCloseButton == "function") {
                        //     DrawerObj?.onClickCloseButton();
                        // }
                        // else {
                        // setDrawerObj(dra);
                        // }
                        if (message || isOpen) {
                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                            setMessage("")
                            setIsOpen(false)
                            console.log("narrationRef", narrationRef);
                            // narrationRef?.current?.focus()
                        }
                        else {
                            const row = selectedRow

                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                                .then(() => {
                                    if (rowId) {
                                        handleSaveUser(({ table, row }))
                                    }
                                    else {
                                        handleCreateUser()
                                    }

                                }, [])
                        }
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>
            <MantineReactTable table={table} />
        </>

    )

}



