import React, { useEffect } from 'react'
import Store from '../../utils/store'

const AssignItemDefaultFun = (props) => {
    return (
        <div>AssignItemDefaultFun</div>
    )
}

export default AssignItemDefaultFun

// const OS11 = Store.getState().Transaction?.TransactionData?.OS11;
// const OSV = Store.getState().Transaction?.TransactionData?.OSV;
// const oYear = Store.getState().Language?.General?.oYear;
// const M01SP = Store.getState().Transaction?.TransactionData?.M01SP;
// const M01SPDT = Store.getState().Transaction?.TransactionData?.M01SPDT;
// const dtM14DT = Store.getState().Transaction?.TransactionData?.dtM14;
// const dtM51DT = Store.getState().Transaction?.TransactionData?.dtM51;
// const dtM52DT = Store.getState().Transaction?.TransactionData?.dtM52;
// const dtM46DT = Store.getState().Transaction?.TransactionData?.dtM46;
// const dtExp = Store.getState().Transaction?.TransactionData?.dtExp;
// const dtExpDef = Store.getState().Transaction?.TransactionData?.dtExpDef;
// const dtExp_I = Store.getState().Transaction?.TransactionData?.dtExp_I;
// const dtExpDef_I = Store.getState().Transaction?.TransactionData?.dtExpDef_I;

let dtExpArr = [];
let T02Object = {};

// console.log("dtExp_I =>",dtExp_I);
// ItemCode, Product, T02, T41,
export const AssignItemDefault = (ExpenseObj) => {
    console.log("AssignItemDefaultfun para ExpenseObj =>", ExpenseObj);

    //Unknown name
    let dtExp = ExpenseObj?.dtExp_I;
    let cVouId = ExpenseObj?.OS11.S11F23;
    let lEdited = false;
    let oExp = ExpenseObj?.T02Obj;
    let SpAcc = oExp["FIELD05"];
    let lEnable;
    let VorC = ExpenseObj?.T02Obj?.VorC;
    let cVType = ExpenseObj?.OS11?.S11F01;
    // let TotalAmt = formValue;

    //Unknown name end
    let newDtExpe = [...(ExpenseObj?.dtExp_I || [])];
    //  console.log("new Dtexp =>");

    if (ExpenseObj?.ItemCode && dtExp != null && dtExp?.length > 0) {

        console.log("AssignItemDefault if condition when itemCode null");

        // console.log("ItemCode =>", ItemCode, "dtExp =>", dtExp);

        // dtExp.DefaultView.RowFilter = "EXPACCDISP=1 OR EXPPERDISP=1";
        dtExp = ExpenseObj?.dtExp_I?.filter((a) => a.EXPACCDISP == 1 || a.EXPPERDISP == 1)


        let dr = null;
        let lObjItemwise = false
        let lFItemwise = false;
        let lFlag = true;
        let lSales = false;
        let lFixed = false;
        let cVCO = "";
        let cAcType = "";
        let cType = "";
        let cAccFld = "";
        let cPerFld = "";
        //    let oM46 = new M46Table(oYear); //Unknown
        // let dtP = oM46.GetItemDet(oVou.oM21D, cVouId); //ItemExpense(ItemCode); // Unknown
        let oM46 = ExpenseObj?.dtM46DT;
        let dtP = ExpenseObj?.dtM46DT;
        // let dtM46 = [...dtM46DT]
        // oM46 = null;
        let drM46 = null;
        let M46F13 = "";
        let c52Flt = "";
        // for ( i = 0; i < dtExp.DefaultView.Count; i++)
        dtExp?.map((d, ind) => {
            dr = { ...d };

            console.log('dr["FIELD03"]=>', d);
            lObjItemwise = dr["FIELD53"] == "I";
            lFItemwise = dr["FIELD53"] == "D";
            cAcType = dr["FIELD05"];
            lSales = cAcType == "S"; // Decide if Sales Account
            lFixed = cAcType == "F";
            cVCO = dr["FIELD03"];
            cType = dr["FIELD06"] == "M" ? "F" : dr["FIELD06"];
            if (!lObjItemwise && lFItemwise) {
                // continue;
                return;
            }
            lFlag = true;

            console.log("dtP=>", dtP, 'cVCO.includes("E", "T", "X")=>', cVCO.includes("E", "T", "X"));

            if (dtP != null && dtP?.length > 0 && (["E", "T", "X"].includes(cVCO))) {

                // for (int j = 0; j < dtP.Rows.Count; j++)
                dtP.map((e) => {
                    // dtM46.DefaultView.RowFilter = "FIELD02='" + dtP.Rows[0]["CODE"].ToString() + "' AND FIELD12='" + dr["FIELD54"].ToString() + "' AND FIELD10='" + cVouId + "'";

                    console.log("ItemCode =>", ExpenseObj?.ItemCode);

                    console.table("dtM46 Filter Field =>", [{
                        "ExpenseObj?.ItemCode": ExpenseObj?.ItemCode,
                        'dr["FIELD54"]': dr["FIELD54"],
                        "cVouId": cVouId,
                        'dtM46?.FIELD02 == ExpenseObj?.ItemCode': ExpenseObj?.dtM46DT?.filter((f) => f?.FIELD02 == ExpenseObj?.ItemCode),
                        'dtM46?.FIELD12 == dr["FIELD54"]': ExpenseObj?.dtM46DT?.filter((f) => f?.FIELD12 == dr["FIELD54"]),
                        'dtM46?.FIELD10 == cVouId': ExpenseObj?.dtM46DT?.filter((f) => f?.FIELD10 == cVouId)
                    }])

                    let dtM46 = ExpenseObj?.dtM46DT?.filter((f) => f?.FIELD02 == ExpenseObj?.ItemCode && f?.FIELD12 == dr["FIELD54"] && f?.FIELD10 == cVouId);
                    console.log("dtM46 after filter==>", dtM46);
                    if (dtM46?.length > 0) {
                        drM46 = [...dtM46];
                        if (!lEdited) {
                            M46F13 = drM46["FIELD13"];
                            if ((lFixed || !lSales) && M46F13 != "") {
                                oExp["IA" + dr["FIELD54"]] = M46F13;
                            } else {
                                oExp["IA" + dr["FIELD54"]] = SpAcc;
                            }
                        }

                        // If Calculation Type is not fixed or Round and Percentage is empty and new entry 
                        // then make percentage textbox visible and assign calculation type to quantity 
                        // so that if from setup calculation type is qty and from itemwise Exp(master)
                        // it is changed then calculation on Qty is prevented.


                        if (!["F", "R"].includes(drM46[0]["FIELD03"])) { //Unknown
                            console.log('condition 1');
                            dr["FIELD06"] = drM46[0]["FIELD03"];
                            dr["EXPPERREQ"] = 1;
                            dr["EXPPERDISP"] = 1;
                        }
                        console.log('drM46["FIELD03"]=>', drM46[0]["FIELD03"]);
                        if (drM46["FIELD03"] == "C") { //Unknown
                            console.log('condition 2');
                            dr["FIELD06"] = drM46[0]["FIELD03"];
                        }
                        if ((dr["EXPPERC"] == "" || dr["EXPPERC"] == "0") && !lEdited) {
                            console.log('condition 3');
                            dr["EXPPERC"] = drM46[0]["FIELD04"];
                            oExp[dr["EXPPERSRC"]] = dr["EXPPERC"];// Update Dictionary so get default values in save without Editing Expense
                        }

                        //If Fixed Expense and amount is empty and new entry then make percentage textbox invisible.
                        if (["F", "R"].includes(drM46["FIELD03"]) || cType == "K") {
                            console.log('condition 4');

                            dr["EXPPERREQ"] = 1;
                            dr["EXPPERDISP"] = 0;
                            if ((dr["EXPPERC"] == "" || dr["EXPPERC"] == "0") && !lEdited) {
                                dr["EXPAMT"] = drM46[0]["FIELD04"];
                                oExp[dr["EXPAMTSRC"]] = dr["EXPAMT"];    // Update Dictionary so get default values in save without Editing Expense
                            }
                        }

                        lFlag = false;
                        // break;
                    }
                    // dtM46.DefaultView.RowFilter = ""; // Unknown
                })
            }

            if (lFlag) {
                // console.log("lFlag=>", lFlag);

                cAccFld = "IA" + dr["FIELD54"];
                cPerFld = "IP" + dr["FIELD54"];

                console.table("condition run =>", [{
                    "lFlag": lFlag,
                    "lFixed": lFixed,
                    "lEdited": lEdited,
                    "cVCO": cVCO,
                    "lSales": lSales,
                    "cVouId": cVouId,
                    "VorC": VorC,
                    "cAcType": cAcType,
                    "cType": cType,
                    "cAccFld": cAccFld,
                    "cPerFld": cPerFld
                }])

                // If Item Contain It's Own Account Effect, Otherwise Take From Invoice Type.
                // If New Entry and Expense is not VAT / CST / CST(Other) or Expense is VAT / CST / CST(Other)
                // and Account is empty then assign account code
                if (lFixed && (!lEdited || (!["V", "C", "D", "3", "4"].includes(cVCO))) || (!lEdited || (["V", "C", "D", "3", "4"].includes(cVCO)) && oExp["IA" + dr["FIELD54"]] == "")) {
                    oExp[cAccFld] = dr["EXPSPACC"];
                }
                if (!lFixed && !lSales && oExp["IA" + dr["FIELD54"]] == "") {
                    oExp[cAccFld] = dr["EXPSPACC"];
                }

                if (["3", "4"].includes(cVCO)) {
                    lEnable = false;
                    if (cVouId == "SS" && false)// true is (This.oYear.lBlwThre OR This.oYear.lCompound)----Pending
                    {
                        // Do Nothing
                    } else if ((false && cVouId == "PP") || (["K", "G"].includes(VorC)))// true id (This.oYear.lCompound)--- Pending
                    {
                        switch (cAcType) {
                            case "B":   //SGST Account
                                oExp[cPerFld] = RetTaxAcc("STP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                break;
                            case "D":   //CGST Account
                                oExp[cPerFld] = RetTaxAcc("CTP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                break;
                            case "I":   //IGST Account
                                oExp[cPerFld] = RetTaxAcc("ITP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                break;
                            default:
                                break;
                        }
                        if (cAcType == "J" && cType != "K") {
                            oExp[cPerFld] = RetTaxAcc("ETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                        }
                        if (cType == "K") {
                            dr["EXPADDICESS"] = RetTaxAcc("AETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                        }
                        oExp[cAccFld] = RetTaxAcc("TS", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                    } else {
                        console.log("Switch Case else Condition");
                        switch (cAcType) {
                            case "B":   //SGST Account
                                oExp[cAccFld] = RetTaxAcc("STA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                oExp[cPerFld] = RetTaxAcc("STP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                console.table("cAcType = SGST =>", [{
                                    "cAccFld": cAccFld,
                                    "cPerFld": cPerFld,
                                    "oExp[cAccFld]": oExp[cAccFld],
                                    "oExp[cPerFld]": oExp[cPerFld],
                                    "oExp[cAccFld]": RetTaxAcc("STA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj),
                                    "oExp[cPerFld]": RetTaxAcc("STP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj)
                                }])
                                break;
                            case "D":   //CGST Account
                                oExp[cAccFld] = RetTaxAcc("CTA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                oExp[cPerFld] = RetTaxAcc("CTP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                console.table("cAcType = CGST =>", [{
                                    "cAccFld": cAccFld,
                                    "cPerFld": cPerFld,
                                    "oExp[cAccFld]": oExp[cAccFld],
                                    "oExp[cPerFld]": oExp[cPerFld],
                                    "oExp[cAccFld]": RetTaxAcc("CTA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj),
                                    "oExp[cPerFld]": RetTaxAcc("CTP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj)
                                }])

                                break;
                            case "I":   //IGST Account
                                oExp[cAccFld] = RetTaxAcc("ITA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                oExp[cPerFld] = RetTaxAcc("ITP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                                console.table("cAcType = IGST =>", [{
                                    "cAccFld": cAccFld,
                                    "cPerFld": cPerFld,
                                    "oExp[cAccFld]": oExp[cAccFld],
                                    "oExp[cPerFld]": oExp[cPerFld],
                                    "oExp[cAccFld]": RetTaxAcc("ITA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj),
                                    "oExp[cPerFld]": RetTaxAcc("ITP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj)
                                }])

                                break;
                            default:
                                break;
                        }
                        if (cAcType == "J" && cType != "K") {
                            console.log("cAcType == 'J' & cType != K condition Inside");
                            oExp[cAccFld] = RetTaxAcc("ETA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                            oExp[cPerFld] = RetTaxAcc("ETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);

                            console.table("cAcType == 'J' & cType != K =>", [{
                                "cAccFld": cAccFld,
                                "cPerFld": cPerFld,
                                "oExp[cAccFld]": oExp[cAccFld],
                                "oExp[cPerFld]": oExp[cPerFld],
                                "oExp[cAccFld] Fun": RetTaxAcc("ETA", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj),
                                "oExp[cPerFld] Fun": RetTaxAcc("ETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj)

                            }])
                        }
                        if (cType == "K") {
                            dr["EXPADDICESS"] = RetTaxAcc("AETP", lEnable, dr["EXPSPSRC"], 0, "", oExp, false, ExpenseObj);
                        }
                    }
                }

                //if Type is Expense
                if (["E", "T", "X"].includes(cVCO)) {
                    if (["F", "R"].includes(cType)) {
                        dr["EXPPERREQ"] = 0;
                    }
                    else {
                        dr["EXPPERREQ"] = 1;
                        dr["EXPPERDISP"] = 1;
                    }
                }

                if (cVCO == "V" && lFixed) {
                    // c52Flt = "FIELD02='" + (MApp.pc.InList(oVou.cVType, "SS", "HS", "SR") ? "S" : "P") + "'" +
                    //     " AND FIELD03='" + oVou.oM21D["M21F20"].ToString() + "'" +
                    //     " AND FIELD04='" + (oExp[lItemWise ? "FIELD13" : "FIELD13"].ToString()) + "'" +
                    //     " AND FIELD05='" + oVou.oM21D["M21F77"].ToString() + "'" +
                    //     " AND ISNULL(FIELD12,'')<>''";

                    // c52Flt = (FIELD02 == (cVType.includes("SS", "HS", "SR") ? "S" : "P") && FIELD03 == oVou.oM21D["M21F20"] && FIELD04 == (oExp[lItemWise ? "FIELD13" : "FIELD13"]) && FIELD05 == oVou.oM21D["M21F77"] && (FIELD12 != null || FIELD12 != "")) // Unknown

                    // let dtM52 = new M52Table(oYear).GetList(c52Flt, "", "FIELD02,FIELD03,FIELD04,FIELD05"); // Unknown


                    let dtM52 = ExpenseObj?.dtM52DT;
                    if (dtM52 != null && dtM52?.length > 0) {
                        oExp[cAccFld] = dtM52[0][cType == "A" ? "FIELD14" : "FIELD12"];
                    }
                }

                //If Item Does Not Contain It's Own % Value, Then Take From General Expense Definition
                if ((!lEdited || (["V", "C", "D", "3", "4"].includes(cVCO))) && dr["EXPPERREQ"] == "1" && (dr["EXPPERC"] == "" || dr["EXPPERC"] == "0")) {
                    if (oExp[dr["EXPPERSRC"]] == "" || oExp[dr["EXPPERSRC"]] == "0") {
                        dr["EXPPERC"] = dr["FIELD07"];
                    } else {
                        dr["EXPPERC"] = oExp[dr["EXPPERSRC"]];
                    }
                    oExp[dr["EXPPERSRC"]] = dr["EXPPERC"];// Update Dictionary so get default values in save without Editing Expense
                }

                if (!(lEdited || (["V", "C", "D", "3", "4"].includes(cVCO)))
                    && dr["EXPAMT"] == ""
                    && dr["EXPPERREQ"] == "0" && !lFItemwise) {
                    dr["EXPAMT"] = (oExp[dr["EXPAMTSRC"]] ? oExp[dr["EXPAMTSRC"]] : dr["FIELD07"]);
                    oExp[dr["EXPAMTSRC"]] = dr["EXPAMT"];    // Update Dictionary so get default values in save without Editing Expense
                }
            }
            console.log("AssignItemDefault dr =>", dr);
            newDtExpe[ind] = { ...dr }
            // Recalc(FieldObj, ind, isExpense, dr)
        })

        console.log("AssignItemDefault dtExp =>", dtExp);

        console.log("AssignItemDefault oExp =>", oExp);
        console.log("AssignItemDefault newDtExpe =>", newDtExpe);
        dtExpArr = [...newDtExpe];
        T02Object = { ...oExp }

        // Promise.resolve().then(() => setDtExpTableField(newDtExpe)).then(() => SetFieldObj(oExp))


        // setDtExpTableField(newDtExpe)
        // SetFieldObj(oExp)

        // dtExp.DefaultView.RowFilter = ""; // Unknown
        // dtExp.AcceptChanges(); // Unknown

        // if (!lNoRecalc) {
        //     TotalAmtAssign(TotalAmt, cItemExp, cMSG, false, false, !lNoRecalc);
        // }
        // GC.Collect(); // Unknown
    } else {
        return { dtExpArr: ExpenseObj?.dtExp_I, T02Object: ExpenseObj?.T02Obj }
    }
    return { dtExpArr, T02Object }
}

export const RetTaxAcc = (DType, lEnable, ExpName = "", nPer = 0, cAcc = "", oExpEntry = null, lAdditional = false, ExpenseObj) => {
    console.log("RetTaxAcc function call");
    // console.log("oExpEntry =>", oExpEntry);
    // console.log("oExpEntry?.oExp[ExpName] =>", oExpEntry[ExpName]);
    // console.log("RetTaxAcc ExpenseObj =>", ExpenseObj);

    //dr["EXPSPACC"] = cAcc

    console.table("RetTaxAcc Parameter =>", [{
        "DType": DType,
        "lEnable": lEnable,
        "ExpName": ExpName,
        "nPer": nPer,
        "cAcc": cAcc,
        "oExpEntry": oExpEntry,
        "lAdditional": lAdditional,
        "ExpenseObj": ExpenseObj
    }])



    //oExpEntry = Expense Object

    //Unknown name
    let cVType = ExpenseObj?.OS11?.S11F01;
    let oM21D = { ...ExpenseObj?.selectedProductObj };
    let dr = ExpenseObj?.M01SPDT;
    let lCapGoods = (ExpenseObj?.InvTypeDropDown?.FIELD10 == "Y");
    let oT41D = ExpenseObj?.T41Obj;
    //  oExpEntry = {...FieldObj}
    //Unknown name end

    if (ExpenseObj?.oYear.OSC._VATSETUP == "N" && ExpenseObj?.oYear.OSC._GSTSETUP == "N") {
        console.log("oExpEntry[ExpName] =>", oExpEntry[ExpName]);
        return oExpEntry[ExpName];
    }

    // Example = Expence object dr["EXPSPSRC"] = "IA00000018"

    let cRet = "";
    let lSales = ["SS", "HS", "SR", "OS", "HC", "JZ", "QT", "RB"].includes(cVType);

    // console.table("lSales true false =>", [{ "cVType": cVType, "lSales": lSales, }]);
    // [{"cVType":cVType,"lSales":lSales,}]

    console.table("cVType & lSales =>", [{
        "cVType": cVType,
        "lSales": lSales,
        "ExpName": ExpName
    }])

    let nType = 0;
    let nFound = 0;//count how many sale/purchase a/c. exist in account master.
    if (ExpName == "") {
        console.log("condition TS no");
        if (!["TS", "TA", "STA", "CTA", "ITA", "ETA"].includes(DType)) {

            cRet = nPer ? nPer : 0
            // eval(nPer, 0);
        }
    } else {
        console.log("condition TS yes =>", [{
            "oExpEntry[ExpName]": oExpEntry[ExpName]
        }]);
        cRet = oExpEntry[ExpName];
    }


    oM21D["FIELD20"] = GetSlab(oM21D["FIELD03"], oT41D["FIELD02"], "", ExpenseObj);

    console.table('oM21D["FIELD20"] =>', [{
        'oM21D["FIELD20"]': oM21D["FIELD20"],
        'oM21D["FIELD03"]': oM21D["FIELD03"],
        'oT41D["FIELD02"]': oT41D["FIELD02"],
        'ExpenseObj': ExpenseObj
    }])

    // console.log('oM21D["FIELD20"] =>',oM21D["FIELD20"])
    // let oM52 = new M52Table(oYear); // Unknown
    // let oM52 = [...dtM52DT]
    let cF02 = "";
    switch (cVType) {
        case "SS":
        case "HS":
        case "SR":
            cF02 = "S";
            break;
        case "JJ":
            cF02 = "J";
            break;
        case "JZ":
            cF02 = "Z";
            break;
        default:
            cF02 = "P";
            break;
    }
    let dtM01 = ExpenseObj?.M01SPDT;
    //let dtM52 = [...dtM52DT] // Unknown
    // let dtM52 = oM52.GetList("FIELD02='" + cF02 + "' AND FIELD03='" + oM21D["FIELD20"] + "' AND FIELD04='" + oT41D["FIELD13"] + "' AND FIELD05='" + oM21D["FIELD27"] + "'"); // Unknown

    let dtM52 = ExpenseObj?.dtM52DT?.filter((a, i) => a?.FIELD02 == cF02 && a?.FIELD03 == oM21D["FIELD21"] && a?.FIELD04 == oT41D["FIELD13"] && a?.FIELD05 == oM21D["FIELD27"])

    console.table("RetTaxAcc dtM52 =>", [{
        "ExpName": ExpName,
        "dtM52": dtM52
    }]);

    if (dtM52?.length > 0 && (["JJ", "JZ"].includes(cVType) ? DType == "TS" : true)) {
        console.log("dtM52 =>", dtM52, "#dtM52?.length > 0 =>", dtM52?.length > 0);
        let drM52 = dtM52[0];
        if (DType == "TS") {
            cRet = drM52["FIELD11"];
            if (ExpName != "") {
                oExpEntry[ExpName] = cRet;
                // nPer = MApp.pc.EvlStr(drM52["FIELD13"], "0");
                nPer = drM52["FIELD13"] ? drM52["FIELD13"] : "0"
                oExpEntry.lSaleAcc = false;
            }
        } else {

            if (!["JJ", "JZ"].includes(cVType)) {
                switch (DType) {
                    case "TP":
                        //        IF FIELD13 == m.nPer And NOT _VPType == "T"
                        //    m.RetVal = FIELD13
                        //ELSE
                        //    m.RetVal = 0
                        //ENDIF
                        break;
                    case "STP": //   SGST %
                        cRet = drM52["FIELD07"];
                        break;
                    case "CTP": //   CGST %
                        cRet = drM52["FIELD06"];
                        break;
                    case "ITP": //   IGST %
                        cRet = drM52["FIELD08"];
                        break;
                    case "ETP": //   Cess %
                        cRet = drM52["FIELD09"];
                        break;
                    case "TA":
                        if (lAdditional) {
                            cRet = drM52["FIELD14"];
                            lEnable = false;
                        }
                        else {
                            cRet = drM52["FIELD12"];
                            lEnable = false;
                        }
                        break;
                    case "CTA":
                        cRet = drM52["FIELD12"];
                        lEnable = false;
                        break;
                    case "STA":
                        cRet = drM52["FIELD14"];
                        lEnable = false;
                        break;
                    case "ITA":
                        cRet = drM52["FIELD15"];
                        lEnable = false;
                        break;
                    case "ETA":
                        cRet = drM52["FIELD16"];
                        lEnable = false;
                        break;
                    default:
                        break;
                }
            }
        }

        if (!["TP", "STP", "CTP", "ITP", "ETP"].includes(DType)) {
            if (cRet != "") {
                console.log("DTSeek 1=>", cRet);
                if (DTSeek(dtM01, "FIELD01", cRet, false) == null) {
                    if (DType == "TS") {
                        oExpEntry.lSaleAcc = true;
                    } else {
                        lEnable = true;
                    }
                }
            } else {
                if (DType == "TS") {
                    oExpEntry.lSaleAcc = true;
                }
                else {
                    lEnable = true;
                }
            }
        }
    } else {
        //  Record not found in PLUSM52, So give possible values
        let cAccCode = "";
        let PrvSlb = "";
        if (["STA", "CTA", "ITA", "ETA"].includes(DType)) {
            switch (DType) {
                case "STA":
                    nType = 4;
                    break;
                case "CTA":
                    nType = 2;
                    break;
                case "ITA":
                    nType = 3;
                    break;
                case "ETA":
                    nType = 1;
                    break;
            }

            cAccCode = oExpEntry["FIELD16"];
            PrvSlb = oExpEntry["FIELD56"];
            console.log("DTSeek 2=>", cAccCode);
            let drM01 = DTSeek(dtM01, "FIELD01", cAccCode);
            if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                PrvSlb = drM01["FIELD20"];
            }

            nFound = 0;
            // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'G' AND FIELD13='" + nType.ToString() + "'");

            // dtM01 = ExpenseObj?.M01SPDT
            //T02.FIELD05 $ 'C,R' => "I" else "O"       
            let drM01Arr = dtM01?.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "G" && d.FIELD13 == nType)
            // foreach(dr in drM01Arr) // Unknown

            console.table("drM01Arr Filters=>", [{
                "ExpName": ExpName,
                'd.FIELD07 = "TX"': dtM01?.filter((d) => d.FIELD07 == "TX"),
                'd.FIELD22 == "G"': dtM01?.filter((d) => d.FIELD22 == "G"),
                'nType': nType,
                'd.FIELD13 == nType': dtM01?.filter((d) => d.FIELD13 == nType)
            }])


            console.table("DTSeek condition 2 =>", [{
                "ExpName": ExpName,
                "cAccCode": cAccCode,
                'oExpEntry["FIELD16"]': oExpEntry["FIELD16"],
                'PrvSlb': PrvSlb,
                'oExpEntry["FIELD56"]': oExpEntry["FIELD56"],
                'drM01': drM01,
                "drM01Arr": drM01Arr
            }])



            //dr = ExpenseObj?.M01SPDT;

            console.table("dr_M01SPDT & drM01Arr_M01SPDT =>", [{
                "dr": dr,
                "drM01Arr": drM01Arr
            }])
            dr?.map((d) => {
                drM01Arr?.map((r) => {
                    // console.log("dr.map if One Condition");
                    if (d?.FIELD01 == r?.FIELD01) {
                        console.table("dr.map if Two Condition =>", [{
                            "ExpName": ExpName,
                            "oExpEntry.VorC": oExpEntry.VorC,
                            "PrvSlb": PrvSlb,
                            "lSales": lSales,
                            "dr": d,
                            "drM01Arr": r
                        }])
                        if (["V", "C", "D"].includes(oExpEntry.VorC)) {
                            console.log("condition One=>One run")
                            if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                                console.log("condition One=>Two run");
                                cRet = d["FIELD01"];
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        } else {
                            console.log("condition Two=>One run")
                            if (d["FIELD08"] == (lSales ? "O" : "I")) {
                                console.log("condition Two=>Two run")
                                cRet = d["FIELD01"];
                                nFound += 1;
                                if (nFound > 1) {
                                    return
                                }
                            }
                        }
                    }
                })
                // if (d in drM01Arr)
                // console.log("dr.map Condition");
            })


            lEnable = nFound != 1;

        } else if (["STP", "CTP", "ITP", "ETP", "AETP"].includes(DType)) {
            // PrvSlb = MApp.pc.EvlStr(oExpEntry.oExp["FIELD56"], oM21D["FIELD20"]);
            PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"] ? oM21D["FIELD20"] : "")
            // let oM51 = new M51Table(oYear); Unknown
            // let dtM51 = oM51.GetList("FIELD01='" + PrvSlb + "'"); Unknown
            let dtM51 = ExpenseObj?.dtM51DT?.filter((a) => a.FIELD01 == PrvSlb)
            let oM51;
            // let dtM51;
            if (dtM51 != null && dtM51?.length > 0) {
                let drM51 = dtM51[0];
                switch (DType) {
                    case "STP":
                        cRet = drM51["FIELD05"];
                        break;
                    case "CTP":
                        cRet = drM51["FIELD07"];
                        break;
                    case "ITP":
                        cRet = drM51["FIELD06"];
                        break;
                    case "ETP":
                        cRet = drM51["FIELD09"];
                        break;
                    case "AETP":
                        cRet = ((drM51["FIELD11"] ? drM51["FIELD11"] : "0") / ((drM51["FIELD12"] ? drM51["FIELD12"] : "1") == 0 ? 1 : (drM51["FIELD12"] ? drM51["FIELD12"] : "1")));
                        break;
                    default:
                        break;
                }
            } else {
                cRet = "0";
            }
        } else if (DType == "TA" && lAdditional) {
            cAccCode = oExpEntry["FIELD16"];
            PrvSlb = oExpEntry["FIELD56"];
            console.log("DTSeek 3=>", cAccCode);
            let drM01 = DTSeek(dtM01, "FIELD01", cAccCode);

            if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                PrvSlb = drM01["FIELD20"];
            }
            // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'V'AND FIELD13='A'");
            let drM01Arr = dtM01?.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "V" && d.FIELD13 == "A")
            nFound = 0;
            // foreach(DataRow dr in drM01Arr)
            dr.map((d) => {
                if (d in drM01Arr) {
                    if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                        cRet = d["FIELD01"];
                        nFound += 1;
                        if (nFound > 1) {
                            return
                        }
                    }
                }
            })

            lEnable = nFound != 1;

        } else if (DType == "TA" && !lAdditional) {
            console.table("DType & lAdditional =>", [{
                "DType": DType,
                "lAdditional": lAdditional
            }])
            if (["V", "D"].includes(oExpEntry.VorC)) {
                cAccCode = oExpEntry["FIELD16"];
                PrvSlb = oExpEntry["FIELD56"];
                console.log("DTSeek 4=>", cAccCode);
                let drM01 = DTSeek(dtM01, "FIELD01", cAccCode);
                if (cAccCode != "" && PrvSlb != "" && drM01 != null) {
                    PrvSlb = drM01["FIELD20"];
                }
                // let drM01Arr = dtM01.Select("FIELD07='TX' AND FIELD22 = 'V' AND FIELD13<>'A'");
                let drM01Arr = dtM01?.filter((d) => d.FIELD07 == "TX" && d.FIELD22 == "V" && d.FIELD13 == "A")

                // console.table()
                nFound = 0;
                // foreach(DataRow dr in drM01Arr)
                dr.map((d) => {
                    if (d in drM01Arr) {
                        if (d["FIELD20"] == PrvSlb && d["FIELD08"] == (lSales ? "O" : "I")) {
                            cRet = d["FIELD01"];
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }
                        }
                    }
                })

                lEnable = nFound != 1;
            } else {
                console.table("if condition out", [{
                    "cRet": cRet,
                    "cAcc": cAcc
                }])
                if (cRet == "" && cAcc != "") {
                    console.table("if condition in", [{
                        "cRet": cRet,
                        "cAcc": cAcc
                    }])
                    cRet = cAcc;
                }
            }
        } else if (DType == "TP") {
            cRet = cRet != "" ? cRet : nPer;
        } else if (DType == "TS") {
            let SPStatus = "";
            let PrTAcc = "";
            let cEffect = "";
            if (oExpEntry.VorC == "V" || lCapGoods) {
                console.log("oExpEntry.VorC condition 1");
                PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"]);//Vat Slab
                PrTAcc = oM21D["FIELD77"];    // Trading A/c.
                cAccCode = (oExpEntry[ExpName] ? oExpEntry[ExpName] : "");
                SPStatus = lCapGoods ? "FA" : lSales ? "TS" : "TP";
                cEffect = lCapGoods ? "B" : "T";



                // Seek old account code. If Vatslab and trading a/c. is not same then make account code blank so that it not assigned to field16 again
                console.log("DTSeek 5=>", cAccCode);
                let drM01 = DTSeek(dtM01, "FIELD01", cAccCode);
                if (drM01 != null) {
                    cAccCode = (drM01["FIELD20"] == PrvSlb && drM01["FIELD18"] == PrTAcc) ? cAccCode : "";
                }
                // let drM01Arr = dtM01.Select("FIELD07='" + SPStatus + "' AND FIELD04 = '" + cEffect + "'");
                let drM01Arr = dtM01?.filter((d) => d.FIELD07 == SPStatus && d.FIELD04 == cEffect)

                // let drM01Arr1 = dtM01?.filter((d) => d.FIELD07 == SPStatus)

                // let drM01Arr2 = dtM01?.filter((d) => d.FIELD04 == cEffect)

                // let drM01Arr3 = dtM01?.filter((d) =>d?.FIELD20 == PrvSlb)

                // let drM01Arr4 = dtM01?.filter((d) =>d?.FIELD18 == PrTAcc)


                console.log("oExpEntry.VorC condition 1=>", [{
                    "PrvSlb": PrvSlb, //"VIB00063"
                    "PrTAcc": PrTAcc, //"G000030G"
                    "cAccCode": cAccCode,
                    "SPStatus": SPStatus,
                    "cEffect": cEffect,
                    'oM21D["FIELD77"]': oM21D["FIELD77"],
                    "dtM01": dtM01,
                    "drM01Arr": drM01Arr,
                    "oExpEntry.VorC": oExpEntry.VorC,
                    // "drM01Arr1":drM01Arr1,
                    // "drM01Arr2":drM01Arr2,
                    // "drM01Arr3":drM01Arr3,
                    // "drM01Arr4":drM01Arr4,
                    '(nFound == 1 && !lCapGoods) true': (nFound == 1 && !lCapGoods),
                    '(nFound == 1 && !lCapGoods) false': (!(nFound == 1 && !lCapGoods))
                }]);
                nFound = 0;
                // foreach(DataRow dr in drM01Arr)
                dr?.map((d) => {
                    if (d in drM01Arr) {
                        if (d["FIELD20"] == PrvSlb && d["FIELD18"] == PrTAcc) {
                            if (ExpName == "") {
                                cRet = d["FIELD01"];
                            } else {
                                oExpEntry[ExpName] = d["FIELD01"];
                            }
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }

                        }
                    }
                })

                //If account code is not empty then assign that code to keep old account selected.
                if (cAccCode != "") {
                    oExpEntry[ExpName] = cAccCode;
                }
                if (oExpEntry?.lSaleAcc) {

                    oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
                }
            } else if (["3", "4", "T", "E", "X", "I", "U", "O", "M", "S", "F", "N", "8", "9", "K", "G"].includes(oExpEntry.VorC)) {
                console.log("oExpEntry.VorC condition 2");
                nFound = 0;
                SPStatus = lCapGoods ? "FA" : (["JJ", "JZ"].includes(cVType)) ? "J" : lSales ? "TS" : "TP";
                cEffect = lCapGoods ? "B" : "T";
                let cF22 = "";
                if (["JJ", "JZ"].includes(cVType)) {
                    cF22 = "V";
                } else if (["X", "I", "M", "S", "F", "G"].includes(oExpEntry.VorC)) {
                    cF22 = "4";
                } else if (["N", "K"].includes(oExpEntry.VorC)) {
                    cF22 = "3";
                } else {
                    cF22 = oExpEntry.VorC;
                }
                // let drM01Arr = dtM01.Select("FIELD07='" + SPStatus + "' AND FIELD22 = '" + cF22 + "'");
                let drM01Arr = dtM01?.filter((d) => d?.FIELD07 == SPStatus && d?.FIELD22 == cF22)
                nFound = 0;

                console.table("oExpEntry.VorC condition Check =>", [{
                    "oExpEntry.VorC": oExpEntry.VorC,
                    "SPStatus": SPStatus,
                    "cEffect": cEffect,
                    "lCapGoods": lCapGoods,
                    "cVType": cVType,
                    "cF22": cF22,
                    "drM01Arr": drM01Arr,
                    "dr": dr
                }])

                // foreach(DataRow dr in drM01Arr)
                if (drM01Arr?.length > 0) {
                    dr?.map((d) => {
                        console.log("map if condition out", [{
                            "d": d,
                            "drM01Arr": drM01Arr,
                            "drM01Arr[0]": drM01Arr[0]
                        }]);
                        if (d?.FIELD01 == drM01Arr[0]?.["FIELD01"]) {
                            console.table("map if condition in", [{
                                "d": d,
                                "drM01Arr": drM01Arr,
                                "drM01Arr[0]": drM01Arr[0]
                            }]);
                            if (cRet == "") {
                                if (ExpName != "") {
                                    oExpEntry[ExpName] = d["FIELD01"];
                                }
                                cRet = d["FIELD01"];
                            }
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }
                        }
                    })
                }

                // IF Invoice Type is of Capital Goods then Ask Sale/Purchase A/c.                        
                oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
            } else if (["C", "D"].includes(oExpEntry.VorC)) {// Entry of CST/CST (Other) type
                console.log("oExpEntry.VorC condition 3");

                PrvSlb = (oExpEntry["FIELD56"] ? oExpEntry["FIELD56"] : oM21D["FIELD20"]);//Vat Slab
                PrTAcc = oM21D["FIELD77"];                // Trading A/c.
                SPStatus = lCapGoods ? "FA" : lSales ? "TS" : "TP"; // Sales/Purchase A/c. Status
                cEffect = lCapGoods ? "B" : "T";
                let nPerc = 0;
                let nAddPerc = 0;
                // let oM51 = new M51Table(oYear);
                // let dtM51 = oM51.GetList("FIELD01='" + PrvSlb + "'");
                let oM51;
                let dtM51 = ExpenseObj?.dtM51DT?.filter((a) => a?.FIELD01 == PrvSlb)
                if (dtM51 != null && dtM51.length > 0) {
                    nPerc = (dtM51[0]["FIELD05"] ? dtM51[0]["FIELD05"] : "0");
                    nAddPerc = (dtM51[0]["FIELD07"] ? dtM51[0]["FIELD07"] : "0");
                }
                // dtM01 = TM01.GetListByQry("SELECT * FROM " + oYear.TblYr + "M01 M01 INER JOIN " + oYear.TblYr + "M51 M51 on M01.FIELD20=M51.FIELD01 WHERE M01.FIELD07='" + SPStatus + "' AND M01.FIELD04 ='" + cEffect + "'"); // Unknown
                // foreach(DataRow dr in dtM01.Rows)
                dr.map((d) => {
                    if (d in dtM01) {
                        if (d["FIELD03"] == oExpEntry.VorC && dr["FIELD18"] == PrTAcc && (oExpEntry.VorC == "D" ? (d["M51F05"] ? d["M51F05"] : "0") == nPer : (d["FIELD07"] ? d["FIELD07"] : "0") == nAddPerc)) {
                            if (ExpName == "") {
                                cRet = d["FIELD01"];
                            } else {
                                oExpEntry[ExpName] = dr["FIELD01"];
                            }
                            nFound += 1;
                            if (nFound > 1) {
                                return
                            }
                        }
                    }
                })

                //If only one account is found, then donot take sale/purchase a/c. input in itemwise expense entry.
                //IF Invoice Type is of Capital Goods then Ask Sale/Purchase A/c.
                oExpEntry.lSaleAcc = !(nFound == 1 && !lCapGoods);
            }
        }
    }
    console.log("RetTaxAcc cRet=>", cRet);
    return cRet;
}

const DTSeek = (data, field, dFilter, lBlank = false) => {
    console.log("DTSeek data =>", data, "#field =>", field, "#dFilter =>", dFilter, "#lBlank =>", lBlank);
    // let DR = data?.filter(dFilter);
    // return DR?.length > 0 ? DR[0] : lBlank ? data : null

    const result = data?.find(row => row[field] === dFilter);
    if (result) {
        return result;
    }
    return lBlank ? { [field]: '' } : null;
}

const GetSlab = (cM21F03, dDate, nRate = 0, ExpenseObj) => {

    console.log("GetSlab ExpenseObj =>", ExpenseObj);

    if (cM21F03 == "") {
        return "";
    }
    console.log("cM21F03=>", cM21F03);
    //Unknown name
    let dTRM14 = ExpenseObj?.dtM14DT;
    let DateTime;
    let OSC;
    //Unknown name end
    let sCode = "";
    let curCode = "";
    let sDate;
    let lRate = false;
    let DTM = STOD(dDate);
    let curDate = DateTime?.MinValue;
    // let dTRM14 = ODB.GetTable(oYear.TblYr+"M14", "", "","FIELD01 = '" + cM21F03 + "'"); // Unknown
    console.log("dtM14DT =>", ExpenseObj?.dtM14DT);
    dTRM14 = ExpenseObj?.dtM14DT?.filter((a) => a?.FIELD01 == cM21F03)



    lRate = nRate != "" && ExpenseObj?.OSC?.GETL?._GSTONRATE;
    curCode = (dTRM14?.length > 0 ? dTRM14[0]["FIELD03"] : "");
    console.log("dTRM14 & curCode =>", [{
        'dTRM14': dTRM14,
        'curCode': curCode
    }]);
    // for (let nI = 0; nI < dTRM14.length; nI++) 
    dTRM14?.map((dt) => {
        if (STOD(dt["FIELD04"]) <= DTM) {
            if (lRate) {
                if (nRate > dt["FIELD06"]) {
                    curCode = dt["FIELD03"];
                }
            } else {
                curCode = dt["FIELD03"];
            }
        }
    })

    return curCode;
}

const STOD = (dDate) => {
    let StringSlice;
    if (dDate == "") {
        StringSlice = "";
    } else {
        StringSlice = dDate?.slice(0, 4) + "-" + dDate?.slice(4, 6) + "-" + dDate?.slice(6, 8)
    }
    return StringSlice
}

export const RecalcFun = (d, index1, t, dr1, ExpenseObj) => {
    console.log("Itemwise");
    // DtExpTableField?.map((d, ind) => {
    //     Recalc(FieldObj, ind, true, d)
    // })

    // parameter description

    // d = FieldObj
    //index1 = expense index
    //isExpense = true
    //dr1 = expense obj

    //formValue = FIELD08

    // parameter description end

    console.table("Recalc function call", [{
        "d": d,
        "index1": index1,
        "t": t,
        "dr1": dr1,
        "ExpenseObj": ExpenseObj,
        "ExpenseObj?.T02Obj?.FIELD08": ExpenseObj?.T02Obj?.FIELD08,
        "ExpenseObj?.T41Obj?.FIELD07": ExpenseObj?.T41Obj?.FIELD07
    }]);

    let oExp = d;
    let lItemWise = t
    // let TotalAmt = lItemWise ? d.FIELD08 : TableExpenseValue;

    let TotalAmt = lItemWise ? parseFloat(ExpenseObj?.T02Obj?.FIELD08) : parseFloat(ExpenseObj?.T41Obj?.FIELD07)
    // let TotalAmt = 1000;
    let GstAmt = 0;
    let FreeAmt = 0;
    let TmpVal = 0;
    let nAmt = TotalAmt;
    let nShAmt = nAmt;
    let cVType = ExpenseObj?.OS11?.S11F01;
    let EqnAmt = "";
    let sFQty = "";
    let sSQty = "";
    let dFQty = 0;
    let dSQty = 0;
    let nTemp = 0;
    let SurcVal = 0;
    let nType = 0;
    let ExpenseArr = (lItemWise ? ExpenseObj?.dtExp_I : ExpenseObj?.dtExp)

    if (ExpenseObj?.oYear?.OSC?._GSTFREQTY == "Y") {
        // Check if FIELD08 is empty and FIELD81 is not empty
        if (lItemWise) {
            if (oExp.FIELD08 == "" && oExp.FIELD81 != "") {
                FreeAmt = oExp?.FIELD07 * oExp?.FIELD81;
            } else if (oExp.FIELD33 != "" && oExp.FIELD81 != "") {
                // Check if FIELD33 and FIELD81 are not empty
                FreeAmt = oExp?.FIELD08 / oExp?.FIELD33 * oExp?.FIELD81;
            }
        }
    };
    // let TmpVal = 0;
    //    let nT = MApp.pc.ToInt(dr["SeqNo"]); // Unknown
    // console.log("TmpVal Amt", TmpVal);
    let newarray = (lItemWise ? [...(ExpenseObj?.dtExp_I || [])] : [...(ExpenseObj?.dtExp || [])]);
    let newFieldObj = (lItemWise ? { ...ExpenseObj?.T02Obj } : { ...ExpenseObj?.T41Obj });
    ExpenseArr?.map((dr, index) => {

        console.table("ExpenseObj?.dtExp_I map =>", [{
            "index": index,
            "dr": dr
        }]);
        GstAmt = parseFloat(GstAmt);
        FreeAmt = parseFloat(FreeAmt);
        TmpVal = parseFloat(TmpVal);
        dFQty = parseFloat(dFQty);
        dSQty = parseFloat(dSQty);
        nTemp = parseFloat(nTemp);
        SurcVal = parseFloat(SurcVal);
        nType = parseFloat(nType);
        // let dr = aa[i]
        let cRorI = (["R", "C"].includes(dr["FIELD05"])) ? "C" : "D";

        let nT = parseInt(dr?.SeqNo);
        let lObjItemwise = dr?.FIELD53 == "I";  // Expense is Itemwise
        let lFItemExp = dr?.FIELD53 == "D";     // Fix-Itemwise
        let lSurcharge = dr?.FIELD06 == "U";    // Surcharge
        let lCum = dr?.FIELD06 == "C";          // Cummulative
        let lFQty = dr?.FIELD06 == "Q";         // First Qty
        let lSQty = dr?.FIELD06 == "S";         // Second Qty
        let cType = dr?.FIELD06 == "M" ? "F" : dr?.FIELD06;//Calc Type
        // let Perc = dr?.EXPPERC;
        let Perc = dr?.EXPPERC && !isNaN(dr?.EXPPERC) ? parseFloat(dr?.EXPPERC * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)) : 0;
        //oExp['IP' + dr.FIELD54] //dr.EXPPERC   // Expense Percentage

        let Amt = (dr?.EXPAMT && !isNaN(dr?.EXPAMT)) ? parseFloat(dr?.EXPAMT) : 0;   // Expense Amount // EXPAMT ma value set nathi ... FIELD07 ma set kr tyare aama pan thavi joi e ...
        // let Amt = lItemWise ? oExp.FIELD08 : oExp.FIELD07;
        let cVCO = dr?.FIELD03;                 // Expense Type
        let cRoundOff = dr?.FIELD75;            // Round off Type 
        //lRound = MApp.pc.InList(cRoundOff, "F", "C", "Y");  // Round Off - Yes/No             
        // let lRound = cRoundOff?.includes("F", "C", "Y");
        let lRound = ["F", "C", "Y"].includes(cRoundOff);
        let lAChange = false; //unknown

        console.table("Values", [{ "TotalAmt": TotalAmt, "Amt": Amt, "perc": Perc }]);

        if (dr?.EXPPERDISP == "1" || dr?.EXPACCDISP == "1") {
            // d.EXPPERC = d.EXPPERC == null ? 0 : d.EXPPERC; //(EXPPERC- Expense%) Unknown
            if (lItemWise && !lObjItemwise && !lFItemExp) { //1
                return null
            } else if (nT < index) { //2
                TmpVal = Amt;
                if (["3", "4"].includes(cVCO)) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (!lItemWise && lObjItemwise) { //3
                TmpVal = Amt;
                if (["3", "4"].includes(cVCO)) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (lSurcharge) { // 4
                TmpVal = Amt;

                if (dr.EXPPERC != null && !lAChange) {
                    let drArr = ExpenseObj?.dtExp_I?.filter((a) => a.EXPSURSEQ >= 0);
                    drArr?.map((dtSur, dtSuri) => {
                        let nIndex = dtSur.EXPSURSEQ;

                        SurcVal = SurcVal + ExpenseObj?.dtExp_I[nIndex].EXPAMT ? 0 : ExpenseObj?.dtExp_I[nIndex].EXPAMT
                            * (ExpenseObj?.dtExp_I[nIndex].EXPDeduct == "-" ? -1 : 1) * (ExpenseObj?.dtExp_I[index].EXPDeduct == "-" ? -1 : 1);
                    })
                    TmpVal = Math.round(SurcVal * Perc / 100).toFixed(2);
                }
                // IF lRound the set Calculated value to roundoff According to Expense defination
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal).toFixed(0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }

            } else if (EqnAmt != "" && !lAChange && ["E", "T"].includes(cVCO) || (["V", "C"].includes(cVCO) && ExpenseObj?.oYear?.OSC?._STATE == "S02")) { // 5
                //Equation Will be perform Here Pending this case right now
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal).toFixed(0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }
            } else if (dr?.FIELD06 == "R") { //6
                TmpVal = nAmt - Math.round(nAmt);
                TmpVal = TmpVal * (TmpVal <= 0.50 ? -1 : 1);
            } else if (dr?.EXPPERREQ != "1" || lAChange) { //7
                TmpVal = Amt;
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal).toFixed(0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }
                if (["3", "4"].includes(cVCO)) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (lFQty || cType == "K") { //8
                if (cType == "K") {
                    TmpVal = (dr.EXPADDICESS ?? 0) * (oExp.FIELD06 ?? 0);
                }
                else {
                    sFQty = lItemWise ? oExp.FIELD06 : "";
                    dFQty = (sFQty ?? 0);
                    TmpVal = Perc * dFQty; // Percentage Value & Quantity
                }

            } else if (lSQty) { // 9
                sSQty = lItemWise ? d.FIELD21 : "";
                dSQty = sSQty ?? 0;
                TmpVal = Perc * dSQty; // Percentage Value & Quantity
            } else if (dr.EXPPERREQ == "1" && ["V", "D"].includes(cVCO) && cType == "A" && dr.M45F08) {//10
                nTemp = nAmt - ExpenseArr[index - 1]?.EXPAMT;
                TmpVal = Math.round(nTemp * (dr.EXPPERC / 100)).toFixed(2);
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal).toFixed(0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }
            } else if (dr?.EXPPERREQ == "1" && cType != "A") { //11
                // console.log("Perc", Perc);
                if (Perc == 0 && Amt != 0 && (!["V", "D", "3", "4"].includes(cVCO))) {
                    if ((nType > 0 || dr.FIELD53 == "D") && cVType != "SH" && !lItemWise) {
                        TmpVal = 0;
                    } else {
                        TmpVal = Amt;
                    }
                } else {
                    if (["B", "D", "I", "J"].includes(cType)) {
                        nTemp = nAmt - GstAmt;
                        if (ExpenseObj?.oYear?.OSC?._GSTFREQTY)//AND NOT This.oYear.lBlwThre
                        {
                            nTemp = nTemp + FreeAmt;
                        }
                    } else {
                        nTemp = lCum ? nAmt : TotalAmt;
                    }
                    TmpVal = Math.round(nTemp * (Perc / 100)).toFixed(2);
                }
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal).toFixed(0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }

                if (["3", "4"].includes(cVCO)) {
                    if (ExpenseObj?.oYear?.OSC?._GR1RTTAX && oExp.FIELD53 == "Y" && (["SS", "SR", "PP", "PR", "HC", "HD"].includes(cVType))) {
                        // oVou.cVType.includes("SS", "SR", "PP", "PR", "HC", "HD")) {

                        TmpVal = TmpVal * 0.65;
                    }
                    GstAmt = GstAmt + TmpVal;

                }
            }
            // dr.EXPAMT = TmpVal; //Unknown
            //  oExp[dr.EXPAMTSRC] = dr.EXPAMT; // Unknown
            // Add To Total Only If Account effect Is Yes. Do Not Add In Case Of Account Effect No/Seperate
            console.log("TmpVal==> 1 ", TmpVal);

            newarray[index] = { ...dr, EXPAMT: TmpVal * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1) }
            if (dr.FIELD61 == "Y") {
                // Add calculated value to final total
                nAmt = nAmt + (TmpVal * (dr.EXPDEDUCT == "-" ? -1 : 1));
                console.log("namt", nAmt);
                // return FieldObj['ID' + MObj?.FIELD54] = nAmt
                if (lItemWise) {
                    console.log("TmpVal==>", TmpVal);
                    console.log("dr?.FIELD04 =>", dr?.FIELD04);
                    if (ExpenseObj?.T02Obj?.['IA' + dr?.FIELD54] == "") {
                        newFieldObj = {
                            ...newFieldObj,
                            FIELD90: nAmt,
                            ['ID' + dr?.FIELD54]: TmpVal,
                            ['IA' + dr?.FIELD54]: dr?.FIELD04 == "" ? ExpenseObj?.T02Obj?.FIELD16 : dr?.FIELD04
                        }
                    } else {
                        newFieldObj = { ...newFieldObj, FIELD90: nAmt, ['ID' + dr?.FIELD54]: TmpVal }
                    }
                    // newFieldObj = { ...newFieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal, ExpAmount: nAmt }
                    // SetFieldObj({ ...FieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
                } else {
                    console.table("FIELD02 & FIELD04", [{
                        "FIELD02": dr.FIELD02,
                        "FIELD04": dr?.FIELD04
                    }])
                    if (ExpenseObj?.T41Obj?.['EA' + dr?.FIELD54] == "") {
                        // newFieldObj = {
                        //     ...newFieldObj,
                        //     FIELD06: nAmt,
                        //     ['ED' + dr?.FIELD54]: TmpVal,
                        //     ['EA' + dr?.FIELD54]: dr?.FIELD04 != "" ? dr?.FIELD04 : ExpenseObj?.T02Obj['ID' + dr?.FIELD54] != "" ? ExpenseObj?.T02Obj['ID' + dr?.FIELD54] : ExpenseObj?.T02Obj?.FIELD16
                        // }



                        newFieldObj = {
                            ...newFieldObj,
                            FIELD06: nAmt,
                            ['ED' + dr?.FIELD54]: TmpVal,
                            ['EA' + dr?.FIELD54]: dr?.FIELD04 != "" ? dr?.FIELD04 : ExpenseObj?.T02Obj?.FIELD16
                        }
                    } else {
                        let FooterKey = (dr?.FIELD53 == "F" ? dr?.FIELD13 : ('ED' + dr?.FIELD54))
                        newFieldObj = { ...newFieldObj, FIELD06: nAmt, [FooterKey]: TmpVal }
                    }

                    // SetFieldObj({ ...FieldObj, FIELD07: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
                }
                return nAmt
            }
            else {
                newFieldObj = { ...newFieldObj, ['ID' + dr?.FIELD54]: TmpVal }
                // SetFieldObj({ ...FieldObj, ['ID' + dr?.FIELD54]: TmpVal })
            }
        } else {
            // dr.EXPAMT = 0;
            // oExp[dr.EXPAMTSRC] = dr.EXPAMT //(EXPAMTSRC - sourcefield name)
            // // console.log("oExp[dr.EXPAMTSRC]", oExp[dr.EXPAMTSRC]);
            // let newarray = [...DtExpTableField];
            // newarray[index] = { ...dr, EXPAMT: 0 }
            // // console.log("newarray", newarray);
            // setDtExpTableField(newarray)

        }

    })
    // setDtExpTableField(newarray);
    // SetFieldObj(newFieldObj);

    console.table("ExpenseCalArray & T02CalAarray", [{
        "ExpenseCalArray": newarray,
        "T02CalAarray": newFieldObj
    }]);

    return { ExpenseCalArray: newarray, T02CalAarray: newFieldObj }

}

export const RefMe = (dtT02, oExpChild = null, ExpenseObj) => {
    console.log("RefMe Function Parameter =>", [{
        "dtT02": dtT02,
        "oExpChild": oExpChild,
        "ExpenseObj": ExpenseObj
    }]);
    // dtExpM45.DefaultView.RowFilter = "FIELD53  IN ('I')";
    // DataTable dtC = dtExpM45.DefaultView.ToTable();
    // dtExp.DefaultView.Sort = "EGKEYID";

    //Unknown Field start
    let dtExp = oExpChild?.filter((d) => d?.FIELD53 == "I" && d?.FIELD53 == "");
    //Unknown Field End

    // dtExpM45.DefaultView.RowFilter = "";
    // dtExp.DefaultView.Sort = "";
    // dtExp.DefaultView.RowFilter = "FIELD53  IN ('I')";
    let TotalAmt = dtT02?.reduce((a, b) => {
        if (b?.FIELD05 === "C") {
            a = a + (b?.FIELD08 || 0);
        } else if (b?.FIELD05 === "D") {
            a = a - (b?.FIELD08 || 0);
        }
        return a;
    }, 0)
    let IdStr = 0;
    let IAStr = 0;
    let nAmt = TotalAmt;
    let dr = null;
    let drT02 = null;
    let cChldAmt = "";
    let cChldAcc = "";
    let cAcType = "";
    let oThAmt = 0;
    let oExp = ExpenseObj?.T02Obj
    // let oM01 = new M01Table(oYear);
    let oM01 = ExpenseObj?.M01SPDT;
    let drChild = [...oExpChild];
    let drc = null;
    let lUpdate = false;
    let lSeq = false;
    // for (int i = 0; i < dtExp.DefaultView.Count; i++)
    dtExp?.map((d) => {
        lUpdate = false;
        dr = { ...d };
        if (oExpChild != null) {
            // drChild = oExpChild.dtExp.Select("FIELD01='" + dr["FIELD01"].ToString() + "' AND FIELD53  IN ('I','') AND FIELD09 <>'D'AND FIELD21='" + dr["FIELD21"] + "' AND FIELD13='" + dr["FIELD13"] + "'");
            if (drChild != null && drChild.Length > 0) {
                lUpdate = true;
                lSeq = lSeq ? lSeq : true;
                drc = drChild[0];
                dr["EXPAMT"] = drc["EXPAMT"];
                dr["EXPACCDISP"] = drc["EXPACCDISP"];
                dr["EXPPERDISP"] = drc["EXPPERDISP"];
                dr["LACTIVE"] = drc["LACTIVE"];
                dr["SeqNo"] = drc["SeqNo"];
                //dr["EXPSPACC"] = drc["EXPSPACC"];
                //dr["EXPPERC"] = drc["EXPPERC"];
            }
        }
        // Store 0 into IdStr Variable
        IdStr = 0;
        cChldAmt = "ID" + dr["FIELD54"];
        cChldAcc = "IA" + dr["FIELD54"];
        cAcType = dr["FIELD05"];
        // for (int j = 0; j < dtT02.Rows.Count; j++)
        dtT02?.map((t) => {
            drT02 = { ...t }
            // If Voucher is of share Type then Always Add Expense regardless of Receipt/Issue
            if (false) // oVou.lShare
            {
                IdStr = IdStr + drT02[cChldAmt];
            } else if (cChldAmt?.length > 2) {
                if ((["C", "R"].includes(drT02["FIELD05"]) ? "R" : "I") == (ExpenseObj?.OS11["S11F21"] == "D" ? "I" : "R")) {
                    IdStr = IdStr + (drT02[cChldAmt] ? 0 : drT02[cChldAmt]);
                }
                else {
                    IdStr = IdStr - (drT02[cChldAmt] ? 0 : drT02[cChldAmt]);
                }
            }

            if (cAcType == "V") {
                IAStr = (drT02[cChldAcc] ? 0 : drT02[cChldAcc]);
            }
        })

        if (lUpdate || IdStr >= 0) {
            oExp[cChldAmt] = IdStr;
            dr["EXPAMT"] = IdStr;
        }
        cAcType = dr["FIELD05"];// oM01.GetField(dr["EXPSPACC"].ToString(), "", "M01F05");
        if (cAcType == "V") {
            oExp[cChldAcc] = IAStr;
            dr["EXPAMT"] = IAStr;
        }
        //if (lUpdate)
        //{
        //    dr["EXPSPACC"] = oExpChild.oExp[cChldAcc];
        //}
        nAmt = nAmt + IdStr;
    })

    // dtExp.DefaultView.RowFilter = "";
    oThAmt = nAmt - TotalAmt;

    console.log("RefMe Function Amount =>", [{
        "nAmt": nAmt,
        "TotalAmt": TotalAmt,
        "oThAmt": oThAmt
    }]);

    // if (lSeq) {
    //     foreach(DataRow drS in dtExp.Rows){
    //         drS["SeqNo"] = drS["EGKEYID"];
    //     }
    //     dtExp.AcceptChanges();
    //     ChangeSequence();
    // }
    // SetControls(true);
}

export const MidCalc = (ExpenseObj) => {

    console.log("ExpenseObj MidCalc=>", ExpenseObj);

    let dr;
    let cORd = "";
    let cRorI = "";
    let nAmt06 = 0;
    let nAmt07 = 0;
    let oT41D = { ...ExpenseObj?.T41Obj };
    cORd = ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C";
    oT41D["FIELD07"] = 0;
    oT41D["FIELD06"] = 0;
    // for (int i = 0; i < oVou.dtT02.Rows.Count; i++)
    ExpenseObj?.T02Data?.map((d) => {
        dr = { ...d };
        if (dr["FIELD03"] == "") {
            // continue;
            return null
        }

        // cRorI = MApp.pc.InList(dr["FIELD05"].ToString(), "R", "C") ? "C" : "D";
        cRorI = (["R", "C"].includes(dr["FIELD05"])) ? "C" : "D";

        nAmt07 = parseFloat(nAmt07) + (parseFloat(dr["FIELD08"]) * (cRorI == cORd ? 1 : -1));
        nAmt06 = parseFloat(nAmt06) + (parseFloat(dr["FIELD90"] ? dr["FIELD90"] : 0) * (cRorI == cORd ? 1 : -1));


        console.table("Assign value =>", [{
            'dr': dr,
            'dr["FIELD05"]': dr["FIELD05"],
            'dr["FIELD08"]': dr["FIELD08"],
            'dr["FIELD90"]': dr["FIELD90"],
            'cRorI': cRorI,
            'cORd': cORd,
            "nAmt07": nAmt07,
            "nAmt06": nAmt06
        }])
    })

    oT41D["FIELD07"] = nAmt07;
    // oT41D["FIELD06"] = oT41D["FIELD07"];    //Temp FOr TEst, Need to be Removed After Expense Entry
    oT41D["FIELD06"] = (nAmt06 != 0 ? nAmt06 : nAmt07);

    console.log("oT41D MidCalc =>", [{
        "oT41D": oT41D
    }])

    return { T41Object: oT41D }

    // //In case Of Bill to Bill, update Bill Amount of T05 Table for Bill Adjustment
    // if (oVou.lBtoBPrt && oVou.lBtoB) {
    //     oVou.TT05.nBillAmt = MApp.pc.ToDouble(oVou.oT41D["FIELD06"]);
    // }
    // if (oVou.cSVType == "PO") {//POS
    //     oVou.nAmt_Assign(MApp.pc.ToDouble(oVou.oT41D["FIELD06"]));
    // }
}

export const CalCFI = (ExpenseObj, oExp) => {
    let returnVal = { ...oExp };
    let productObjFind = {};
    ExpenseObj?.ProductAllData?.forEach((p, i) => {
        if (p?.FIELD01 == oExp?.FIELD03) {
            productObjFind = { ...p }
        }
    })

    ExpenseObj = {
        ...ExpenseObj,
        ItemCode: productObjFind?.FIELD03,
        selectedProductObj: { ...productObjFind },
        T02Obj: { ...oExp }
    }

    if (ExpenseObj?.dtExp != null && ExpenseObj?.dtExp?.length > 0) {
        let lFItemwise = false;
        let _lFItemwise = false;
        let _lItemwise = false;
        let dr = null;
        let drT02 = null;
        let mFactor = 0;
        let TmpVal = 0;
        let nFIValue = 0;
        let tVal = 0;
        let TtAmt = 0;
        let MultVal = 0;
        let aAmt = 0;
        let nSeq = 0;
        let _nSeq = 0;
        let cType = "";
        let fName = "";
        let cItemExp = "";
        let cMSG = "";
        let lRefme;
        let cVType = ExpenseObj?.OS11?.S11F01
        let changedT02;
        for (let i = 0; i < ExpenseObj?.dtExp?.length; i++) {
            dr = { ...ExpenseObj?.dtExp[i] };
            TmpVal = (dr["EXPAMT"] == "" || dr["EXPAMT"] == null) ? 0 : parseFloat(dr["EXPAMT"]);
            nFIValue = (dr["EXPFIVALUE"] == "" || dr["EXPFIVALUE"] == null) ? 0 : parseFloat(dr["EXPFIVALUE"]);
            lFItemwise = dr["FIELD53"] == "D";
            nSeq = (dr["FIELD12"] == "" || dr["FIELD12"] == null) ? 0 : dr["FIELD12"];
            cType = dr["FIELD06"] == "M" ? "F" : dr["FIELD06"];
            fName = "ID" + dr["FIELD54"];

            if (!lFItemwise) {
                continue
            }
            if (TmpVal == nFIValue) {
                continue
            }

            for (let j = 0; j < ExpenseObj?.T02Data?.length; j++) {
                drT02 = { ...ExpenseObj?.T02Data[j] };
                if (drT02["FIELD03"] == "" || drT02["FIELD03"] == null) {
                    continue
                }
                mFactor = 1;

                if (["SS", "HS"].includes(cVType) && ["C", "R"].includes(drT02["FIELD05"])) {
                    mFactor = -1;
                } else if (["PP", "HP"].includes(cVType) && ["D", "I"].includes(drT02["FIELD05"])) {
                    mFactor = -1;
                }

                if (cType == "Q") {
                    tVal = tVal + (((drT02["FIELD06"] == "" || drT02["FIELD06"] == null) ? 0 : drT02["FIELD06"]) * mFactor);
                } else if (cType == "S") {
                    tVal = tVal + (((drT02["FIELD21"] == "" || drT02["FIELD21"] == null) ? 0 : drT02["FIELD21"]) * mFactor);
                } else {
                    tVal = tVal + (((drT02["FIELD08"] == "" || drT02["FIELD08"] == null) ? 0 : drT02["FIELD08"]) * mFactor);
                    for (let k = 0; k < ExpenseObj?.dtExp?.length; k++) {
                        let dt = { ...ExpenseObj?.dtExp[k] }
                        _lItemwise = dt["FIELD53"] == "I";
                        _lFItemwise = dt["FIELD53"] == "D";
                        _nSeq = dt["FIELD12"] ? 0 : parseInt(dt["FIELD12"]);
                        if (!_lItemwise || _lFItemwise) {
                            continue
                        }
                        if (_nSeq >= nSeq) {
                            // return
                            break
                        }
                        fName = "ID" + dt["FIELD54"];
                        tVal += drT02[fName] * (dt["FIELD14"] == "-" ? -1 : 1) * mFactor;
                    }
                }
            }

            if (tVal != 0) {
                MultVal = Math.abs(TmpVal / tVal);
                let lExpChild = false;
                for (let j = 0; j < ExpenseObj?.T02Data?.length; j++) {
                    let tr = { ...ExpenseObj?.T02Data[j] }
                    if ((tr["FIELD03"] == "" || tr["FIELD03"] == null) || tr["LADDNEW"] == "Y") {
                        continue
                    }
                    drT02 = { ...ExpenseObj?.T02Data[j] };

                    fName = "ID" + dr["FIELD54"];
                    if (cType == "Q") {
                        drT02[fName] = Math.round(((drT02["FIELD06"] == "" || drT02["FIELD06"] == null) ? 0 : drT02["FIELD06"]) * MultVal).toFixed(2);
                        TtAmt = TtAmt + drT02[fName];
                    } else if (cType == "S") {
                        drT02[fName] = Math.round(((drT02["FIELD21"] == "" || drT02["FIELD21"] == null) ? 0 : drT02["FIELD21"]) * MultVal).toFixed(2);
                        TtAmt = TtAmt + drT02[fName];
                    } else {
                        aAmt = (drT02["FIELD08"] == "" || drT02["FIELD08"] == null) ? 0 : drT02["FIELD08"];
                        for (let k = 0; k < ExpenseObj?.dtExp?.length; k++) {
                            _lItemwise = ExpenseObj?.dtExp[k]["FIELD53"] == "I";
                            _lFItemwise = ExpenseObj?.dtExp[k]["FIELD53"] == "D";
                            _nSeq = (ExpenseObj?.dtExp[k]["FIELD12"] == "" || ExpenseObj?.dtExp[k]["FIELD12"] == null) ? 0 : parseInt(ExpenseObj?.dtExp[k]["FIELD12"]);
                            if (!_lItemwise || _lFItemwise) {
                                continue
                            }
                            if (_nSeq >= nSeq) {
                                break
                            }
                            fName = "ID" + ExpenseObj?.dtExp[k]["FIELD54"];
                            aAmt = aAmt + drT02[fName] * (ExpenseObj?.dtExp[k]["FIELD14"] == "-" ? -1 : 1) * mFactor;
                        }
                        fName = "ID" + dr["FIELD54"];
                        drT02[fName] = Math.abs(aAmt * MultVal).toFixed(2);
                    }

                    if (oExp != null) {
                        if (oExp["FIELD03"] == drT02["FIELD03"] && oExp["FIELD09"] == drT02["FIELD09"]) {
                            // oExp = DrToDic(drT02); // unknown
                            oExp = DrToDic(drT02)
                            

                            let resp = new Promise((resolve) => {
                                let dt = AssignItemDefault(ExpenseObj);
                                resolve(dt);
                            }).then((res) => {
                                return TotalAmtAssign(oExp["FIELD08"], cItemExp, cMSG, false, false, false, { ...ExpenseObj, T02Obj: res?.T02Object, dtExp_I: res?.dtExpArr });

                            }).then((res1) => {
                                return res1
                            })
                            returnVal = CopyDicToDr(resp?.Recalc?.T02CalAarray, drT02);
                            return returnVal
                            // return drT02;
                        }
                    }
                    // oVou.dtT02.AcceptChanges();
                    if (lExpChild && ((j < ExpenseObj?.T02Data?.length - 1 && ExpenseObj?.T02Data?.filter((a) => a?.LADDNEW == 'Y').length <= 0) || (j < ExpenseObj?.T02Data?.length - 2 && ExpenseObj?.T02Data?.filter((a) => a?.LADDNEW == 'Y').length > 0))) {
                        // RefMe(oVou.dtT02, oExpChild, false, false, true);
                        oExp = null;
                        lExpChild = false;
                    }

                }

                if (Math.abs(TtAmt) != Math.abs(TmpVal)) {
                    for (let l = ExpenseObj?.T02Data?.length; l < 0; l--) {
                        drT02 = { ...ExpenseObj?.T02Data[l] };
                        if (drT02["FIELD03"] == "" || drT02["FIELD03"] == null) {
                            continue
                        }
                        fName = "ID" + dr["FIELD54"];
                        drT02[fName] = ((drT02[fName] == "" || drT02[fName] == null) ? 0 : drT02[fName]) + (TmpVal - TtAmt);
                        break;
                    }

                    lRefme = false;
                    if (oExp != null) {
                        if (oExp["FIELD03"] == drT02["FIELD03"] && oExp["FIELD09"] == drT02["FIELD09"]) {
                            lRefme = true;
                            oExp = DrToDic(drT02);
                            let resp = new Promise((resolve) => {
                                let dt = AssignItemDefault(ExpenseObj);
                                resolve(dt);
                            }).then((res) => {
                                return TotalAmtAssign(oExp["FIELD08"], cItemExp, cMSG, false, false, false, { ...ExpenseObj, T02Obj: res?.T02Object, dtExp_I: res?.dtExpArr });

                            }).then((res1) => {
                                return res1
                            })
                            returnVal = CopyDicToDr(resp?.Recalc?.T02CalAarray, drT02)
                            return returnVal
                            // return drT02;
                        }
                    }
                    // oVou.dtT02.AcceptChanges();
                }
            }
        }
    }
    return returnVal
}



// export const CalCFI = (ExpenseObj) => {
//     console.log("CalCFI ExpenseObj =>", [{
//         "ExpenseObj": ExpenseObj
//     }]);
// }

// const TotalAmtAssign = (nTotAmount, cItemExp, cMSG, lForce = false, lfrq = false, lFromT02 = false) => {
//     //This.lnoforce = Not lForce 
//     //This.lfrqty = m.lfrq
//     //This.TotAmt = nTotAmount
//     //This.lNoforce = .F.
//     //This.lNoforce = .F.
//     if (!(TotalAmt == nTotAmount || oVou.lFQtyOnGST || nTotAmount == 0) || lFromT02 || lForce) {
//         TotalAmt = nTotAmount;
//         lFromTotal = true;

//         if (lItemWise) {
// cItemExp = TranForm(TotalAmt * (oVou.S11Dic["S11F21"] == "C" ? -1 : 1));
//         }

//         if (oVou.lFQtyOnGST) {
//             ReCalc(0, lfrq, cMSG);
//         } else if (oYear.OSC.GETL("_GR1RTTAX")) {
//             ReCalc(0, true, cMSG);
//         } else {
//             ReCalc(0, lForce, cMSG);
//         }
//     }
// }

const TotalAmtAssign = async (nTotAmount, cItemExp, cMSG, lForce = false, lfrq = false, lFromT02 = false, ExpenseObj) => {
    console.log("TotalAmtAssign Para =>", [{
        "nTotAmount": nTotAmount,
        "cItemExp": cItemExp,
        "cMSG": cMSG,
        "lForce": lForce,
        "lfrq": lfrq,
        "lFromT02": lFromT02,
        "ExpenseObj": ExpenseObj
    }]);
    // let TotalAmt = nTotAmount;
    // cItemExp = TranForm(TotalAmt * (ExpenseObj?.OS11["S11F21"] == "C" ? -1 : 1));


    let TotalAmt;
    let FQty1 = (ExpenseObj?.OSV?._FREEQTY == "N" ? false : true);
    let FQty2 = (ExpenseObj?.oYear?.OSC?._GSTFREQTY == "N" ? false : true);
    let lFQtyOnGST = (FQty1 && FQty2 ? true : false);

    //if condition testing mate comment karel 6e
    // if (!(TotalAmt == nTotAmount || lFQtyOnGST || nTotAmount == 0) || lFromT02 || lForce) { 
    TotalAmt = nTotAmount;
    // lFromTotal = true; Unknown

    // if (ExpenseObj?.lItemWise) {
    cItemExp = TranForm(TotalAmt * (ExpenseObj?.OS11["S11F21"] == "C" ? -1 : 1));
    // }

    console.log("TotalAmtAssign return =>", [{
        "cItemExp": cItemExp,
        'TranForm(TotalAmt * (oVou.S11Dic["S11F21"] == "C" ? -1 : 1))': TranForm(TotalAmt * (ExpenseObj?.OS11["S11F21"] == "C" ? -1 : 1))
    }]);
    let oYearOSC = ExpenseObj?.oYear?.OSC?._GR1RTTAX == "N" ? false : true

    // d = FieldObj
    //index1 = expense index
    //isExpense = true
    //dr1 = expense obj

    if (lFQtyOnGST) {
        const res = await new Promise((resolve) => {
            let fun = RecalcFun(ExpenseObj?.T02Obj, "", true, "", ExpenseObj)
            resolve(fun)
        })
        console.log("TotalAmtAssignRecalcFun res1=>", res)
        return { Recalc: res, cItemExp: cItemExp }
        // RecalcFun(ExpenseObj?.T02Obj, "", true,"",ExpenseObj);
    } else if (oYearOSC) {
        // RecalcFun(0, true, cMSG);
        // RecalcFun(ExpenseObj?.T02Obj, "", true,"",ExpenseObj)
        const res_1 = await new Promise((resolve_1) => {
            let fun_1 = RecalcFun(ExpenseObj?.T02Obj, "", true, "", ExpenseObj)
            resolve_1(fun_1)
        })
        console.log("TotalAmtAssignRecalcFun res2=>", res_1)
        return { Recalc: res_1, cItemExp: cItemExp }
    } else {
        // RecalcFun(0, lForce, cMSG);
        // RecalcFun(ExpenseObj?.T02Obj, "", true,"",ExpenseObj)
        const res_2 = await new Promise((resolve_2) => {
            let fun_2 = RecalcFun(ExpenseObj?.T02Obj, "", true, "", ExpenseObj)
            resolve_2(fun_2)
        })
        console.log("TotalAmtAssignRecalcFun res3=>", res_2)
        return { Recalc: res_2, cItemExp: cItemExp }
    }
    // }
}


function TranForm(nAmt, cFormat = '', lCRDB = true) {
    let cTranAmt = '';
    cFormat = cFormat || 'n'; // Equivalent of EVL function, sets default format if not provided.

    if (lCRDB) {
        cTranAmt = `${Math.abs(nAmt).toLocaleString(undefined, { style: 'decimal', minimumFractionDigits: cFormat === 'n' ? 2 : 0 })}` +
            (nAmt === 0 ? '   ' : nAmt > 0 ? ' CR' : ' DB');
    } else {
        cTranAmt = `${nAmt.toLocaleString(undefined, { style: 'decimal', minimumFractionDigits: cFormat === 'n' ? 2 : 0 })}`;
    }

    return cTranAmt;
}

// const TranForm=(nAmt, cFormat = "", lCRDB = true)=>{
//     let cTranAmt = "";
//     cFormat = evl(cFormat, "n");

//     if (lCRDB){
//         cTranAmt = string.Format("{0:" + cFormat + "}", Math.Abs(nAmt)) + (nAmt == 0 ? "   " : nAmt > 0 ? " CR" : nAmt < 0 ? " DB" : "");
//     }else{
//         cTranAmt = string.Format("{0:" + cFormat + "}", nAmt);
//     }
//     console.log("cTranAmt =>",cTranAmt);
//     return cTranAmt;
// }

const CopyDicToDr = (oSDic, oDRow) => {
    if (!oSDic) {
        return oDRow
    }

    Object.keys(oSDic).forEach((key) => {
        if (oDRow.hasOwnProperty(key)) {
            oDRow[key] = oSDic[key] === null || oSDic[key] === undefined ? null : oSDic[key];
        }
    });

    return oDRow;
}

function DrToDic(dr, cExKey = '') {
    let oDic = {};

    if (dr !== null && dr !== undefined) {
        oDic = copyDrToDic(dr, oDic);
    }

    if (!cExKey || cExKey.trim() === '') {
        return oDic;
    } else {
        return addExKeyToDic(oDic, cExKey);
    }
}

function copyDrToDic(oDRow, oDic) {
    if (!oDic) {
        oDic = {};
    }

    let cColNM = '';

    // oDRow.columns.forEach(function(DC) {
    //     cColNM = DC.columnName.toString();
    // if(DC in oDRow){
    Object.keys(oDRow)?.map((DC) => {
        cColNM = DC;

        if (oDic.hasOwnProperty(cColNM)) {
            delete oDic[cColNM];
        }

        const value = oDRow[cColNM];
        if (value === null || value === undefined) {
            const DCType = DC.dataType;

            if (DCType === 'string') {
                oDic[cColNM] = "";
            } else if (DCType === 'decimal' || DCType === 'int64' || DCType === 'int32' || DCType === 'int16' || DCType === 'double') {
                oDic[cColNM] = 0;
            } else if (DCType === 'boolean') {
                oDic[cColNM] = false;
            } else if (DCType === 'char') {
                oDic[cColNM] = ' ';
            } else if (DCType === 'byte') {
                oDic[cColNM] = "";
            } else if (DCType === 'timespan') {
                oDic[cColNM] = "";
            } else {
                oDic[cColNM] = value;
            }
        } else {
            oDic[cColNM] = value;
        }
    });

    return oDic;
}

function addExKeyToDic(oDic, cExKey, RSep = "", CSep = "") {
    if (!cExKey || cExKey.trim() === '') {
        return oDic;
    }

    RSep = evl(RSep, RSep);
    CSep = evl(CSep, CSep);
    const aKeys = strTo2DArray(cExKey, RSep, CSep, 2, true);

    for (let nI = 0; nI < aKeys.length; nI++) {
        let cKey = aKeys[nI][0].trim().toUpperCase();
        if (cKey !== '') {
            if (oDic.hasOwnProperty(cKey)) {
                oDic[cKey] = aKeys[nI][1].trim();
            } else {
                oDic[cKey] = aKeys[nI][1].trim();
            }
        }
    }
    return oDic;
}

function evl(cExp1, cExp2) {
    return (cExp1 !== "" && cExp1 !== null ? cExp1 : cExp2);
}

function strTo2DArray(cStr, rowSep, colSep, nCol = 0, lRemoveEmptyEntry = false) {
    if (!cStr || cStr.trim() === "") {
        return Array.from({ length: 0 }, () => Array(nCol > 0 ? nCol : 1).fill(""));
    }

    const aSRow = cStr.split(rowSep).filter(entry => !lRemoveEmptyEntry || entry.trim() !== "");
    let aSCol = aSRow[0].split(colSep);

    if (nCol <= 0) {
        nCol = aSCol.length;
    }

    const cArray = Array.from({ length: aSRow.length }, () => Array(nCol).fill(""));

    for (let nR = 0; nR < aSRow.length; nR++) {
        aSCol = aSRow[nR].split(colSep);
        const nMC = Math.min(aSCol.length, nCol);

        for (let nC = 0; nC < nMC; nC++) {
            cArray[nR][nC] = aSCol[nC].trim();
        }

        if (nCol > nMC) {
            for (let nJ = nMC; nJ < nCol; nJ++) {
                cArray[nR][nJ] = "";
            }
        }
    }

    return cArray;
}