import { configureStore, combineReducers } from "@reduxjs/toolkit";
import menuReducer from "./slices/TabList";
import CompanySetupReducer from "./slices/CompanySetupSlice";
import AccountListReducer from "./slices/AccountListSlice";
import M01FormSliceReducer from "./slices/M01FormSlice";
import LanguageReducer from "./slices/LanguageSlice";
import UserListReducer from "./slices/UserListSlice";
import ModalSliceReducer from "./slices/ModalSlice";
import AccountGroupReducer from "./slices/AccountGroupSlice";
import M32LocationFormReducer from "./slices/M32LocationFormSlice";
import M24PriceListReducer from "./slices/PriceListSlice";
import ProductGroupReducer from "./slices/ProductGroup";
import ProductListReducer from "./slices/ProductList";
import GSTMasterReducer from "./slices/GSTMasterSlice";
import TDSMasterReducer from "./slices/TDSMasterSlice";
import StockRateReducer from "../utils/slices/StockRateSlice";
import VSalesSliceReducer from "./slices/VSalesSlice";
import ECommerceOperator from "./slices/ECommerceOperator";
import Transaction from "./TransactionSlices/Transaction";
import VSalesEntrySliceReducer from "./slices/VSalesEntrySlice";
import GSTListReducer from "./slices/GSTListSlice";
import ProcessMasterReducer from "./slices/ProcessMasterSlice";
import CityListReducer from "./slices/CityListSlice";
import AreaListReducer from "./slices/AreaListSlice";
import ItemwiseExpenseListReducer from "./slices/ItemwiseExpenseSlice";
import VoucherNumSliceReducer from "./slices/VoucherNumSlice";
import UserMasterSliceReducer from "./slices/UserMasterSlice";
import ItemwiseExpenseListEntReducer from "./slices/ItemwiseExpenseEntSlice";
// import TSalesSlicesReducer from "./TransactionSlices/TSalesSlices";
import VoucherSetupReducer from "./slices/VoucherSetupSlice";
import CashBankSliceReducer from "./TransactionSlices/CashBankSlice";
import OpeningStockTMReducer from "./TransactionSlices/openingStockSliceTM";
import OpeningStockGenReducer from "./TransactionSlices/openingStockSliceGen";
import OpeningStockEntReducer from "./TransactionSlices/openingStockEntSlice";
import DataSaveLoadingSliceReducer from "./slices/DataSaveLoadingSlice";
import AutoBillSliceReducer from "./TransactionSlices/AutoBillSlice";
import stockJournalSliceReducer from "./TransactionSlices/stockJournalSlice";
import TSalesSlicesReducer from "./TransactionSlices/TSalesSlices";
import LoginSlices from "./Auth/LoginSlices";
import PrintSlice from "./PrintSlice/PrintSlice";
import TaxClassSliceReducer from "./slices/TaxClassSlice";
import CompanySetupPopUpReducer from "./slices/CompanySetUpPopUpSlice";
import PendingIssueSliceReducer from "./TransactionSlices/PendingIssueSlice";
import JobProcessListSliceReducer from "./slices/JobProcessListSlice";
import MailingLetter from "./TransactionSlices/MailingLetter";
import AddressBook from "./TransactionSlices/AddressBook";
import VoucherSetupPopUpReducer from "./slices/VoucherSetupPopUpSlice";
import DataFreeze from "./TransactionSlices/DataFreeze";
import GSTEntryPageIDSliceReducer from "./TransactionSlices/GSTEntryPageIDSlice"
import Reminder from "./TransactionSlices/Reminder";
import UserMasterEntrySliceReducer from "./slices/UserMasterEntrySlice";
import ReportDesignerSlice from "./PrintSlice/ReportDesignerSlice";
import UserSecuritySlice from "./slices/UserSecuritySlice";
import FilterSlice from "./FilterSlice/FilterSlice";
import PrintVoucherReportSlice from "./TransactionSlices/PrintVoucherReportSlice"
import NarrationSliceReducer from "./TransactionSlices/NarrationSlice"
import EInvoiceReducer from "./TransactionSlices/EInvoiceSlice"
import EWayBillReducer from "./TransactionSlices/EWayBillSlice"
import MessageBtnSliceReducer from "./TransactionSlices/MessageBtnSlice"
const rootReducer = combineReducers({
  LoginSlices: LoginSlices,
  TabList: menuReducer,
  CompanySetup: CompanySetupReducer,
  AccountList: AccountListReducer,
  M01FormSlice: M01FormSliceReducer,
  Language: LanguageReducer,
  UserList: UserListReducer,
  ModalSlice: ModalSliceReducer,
  AccountGroup: AccountGroupReducer,
  M32LocationForm: M32LocationFormReducer,
  M24PriceList: M24PriceListReducer,
  ProductGroup: ProductGroupReducer,
  ProductList: ProductListReducer,
  GSTMasterGroup: GSTMasterReducer,
  TDSMasterGroup: TDSMasterReducer,
  StockRate: StockRateReducer,
  VSalesData: VSalesSliceReducer,
  ECommerceOperator: ECommerceOperator,
  MailingLetter : MailingLetter,
  Transaction: Transaction,
  VSalesEntryData: VSalesEntrySliceReducer,
  ProcessMaster: ProcessMasterReducer,
  GSTListGroup: GSTListReducer,
  CityListGroup: CityListReducer,
  AreaListGroup: AreaListReducer,
  ItemwiseExpenseListGroup: ItemwiseExpenseListReducer,
  M41UMList: UserMasterSliceReducer,
  VoucherNum: VoucherNumSliceReducer,
  UserMasterSlice: UserMasterSliceReducer,
  ItemwiseExpenseListEntGroup: ItemwiseExpenseListEntReducer,
  TSalesSlices: TSalesSlicesReducer,
  VoucherSetup: VoucherSetupReducer,
  CashBank: CashBankSliceReducer,
  OpeningStockTM: OpeningStockTMReducer,
  OpeningStockGen: OpeningStockGenReducer,
  OpeningStockEnt: OpeningStockEntReducer,
  DataSaveLoadingSlice: DataSaveLoadingSliceReducer,
  ABillFormData: AutoBillSliceReducer,
  StockJournalData: stockJournalSliceReducer,
  TaxClassData: TaxClassSliceReducer,
  PrintList: PrintSlice,
  CompanySetupPopUp:CompanySetupPopUpReducer,
  GetPendingIssue: PendingIssueSliceReducer,
  JobProcessListSlice:JobProcessListSliceReducer,
  AddressBook : AddressBook,
  VoucherSetupPopUp : VoucherSetupPopUpReducer,
  DataFreeze : DataFreeze,
  Reminder : Reminder,
  UserMasterEntry : UserMasterEntrySliceReducer,
  GSTEntryPageID:GSTEntryPageIDSliceReducer,
  UserSecurity:UserSecuritySlice,
  FilterSlice : FilterSlice,
  ReportDesigner: ReportDesignerSlice,
  PrintVoucherReport:PrintVoucherReportSlice,
  NarrationSlice:NarrationSliceReducer,
  MessageBtnData : MessageBtnSliceReducer,
  EInvoice:EInvoiceReducer,
  EWayBill:EWayBillReducer
});

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default Store;
